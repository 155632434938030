import { createSelector } from '@ngrx/store';
import { getSegmentModels } from '../reducers/segment-models.reducer';

export const getSegmentModelsList = createSelector(
  getSegmentModels,
  (data) => data.segmentModels
);
export const isLoadingSegmentModels = createSelector(
  getSegmentModels,
  (data) => data.isLoading
);
export const getCurrentSegmentModelLabel = createSelector(
  getSegmentModels,
  (data) => data.segmentModelLabel
);

export const getCurrentSegmentModel = createSelector(
  getSegmentModels,
  (data) => {
    return data.segmentModels.find((it) => it.path === data.segmentModelLabel);
  }
);

export const getSegmentModelsByNames = (names: string[]) =>
  createSelector(getSegmentModels, (data) => {
    return data.segmentModels.filter((it) => names.includes(it.path));
  });

export const getSegmentModelByName = (name: string) =>
  createSelector(getSegmentModels, (data) => {
    return data.segmentModels.find((it) => it.path === name);
  });

export const getOutputSegmentModelsByName = (name: string) =>
  createSelector(getSegmentModels, (data) => {
    const model = data.segmentModels.find((it) => it.path === name);
    return [
      model,
      ...data.segmentModels.filter((m) => m.output_model === model.id),
    ];
  });

export const getSegmentModelById = (id: string) =>
  createSelector(getSegmentModels, (data) => {
    return data.segmentModels.find((it) => it.id === id);
  });
