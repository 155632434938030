import { createSelector, createFeatureSelector } from '@ngrx/store';
import { PaymentState } from './payment.reducer';

export const getPaymentState = createFeatureSelector('payment');

export const getPayments = createSelector(
  getPaymentState,
  (data: PaymentState) => data.payments
);

export const getPaymentsData = createSelector(
  getPaymentState,
  (data: PaymentState) => ({
    data: data.payments,
    hasNextPage: !!data.nextPage
  })
);

export const isLoadingPayments = createSelector(
  getPaymentState,
  (data: PaymentState) => data.isLoadingPayments
);
export const getPaymentsFilter = createSelector(
  getPaymentState,
  (data: PaymentState) => data.filter
);
export const getPayment = createSelector(
  getPaymentState,
  (data: PaymentState) => data.payment
);
export const isLoadingPayment = createSelector(
  getPaymentState,
  (data: PaymentState) => data.isLoadingPayment
);

export const isReloadPayments = createSelector(
  getPaymentState,
  (data: PaymentState) => data.isReload
);

export const getPaymentsNextPage = createSelector(
  getPaymentState,
  (data: PaymentState) => data.nextPage
);

export const getRefreshingPaymentId = createSelector(getPaymentState, (data: PaymentState) => data.refreshingPaymentId);
