import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { AppState } from '../../../../store/reducers';
import { Call } from '../../models/calls.model';
import { CallsHistorySetFilter, ClearCallsHistory, LoadCallsHistory, LoadCallsHistoryDebounced } from '../../store/call.actions';
import { isLoadingCallsHistory, getCallsHistoryData, getCurrentAudio } from '../../store/call.selector';
import { CallAudio } from '../../models/call-audio';
import { InfiniteScrollableComponent } from 'src/app/modules/shared/components/infinite-scrollable/InfiniteScrollableComponent';

@Component({
  selector: 'app-history-calls',
  templateUrl: './calls-history.component.html',
  styleUrls: ['./calls-history.component.scss'],
})
export class CallsHistoryComponent extends InfiniteScrollableComponent<Call> implements OnInit, OnChanges, OnDestroy {
  @Input() customerId: string = null;
  modalRef;
  currentCall: Call;
  currentAudio: CallAudio;
  currentAudio$: Observable<CallAudio>;

  constructor( protected store: Store<AppState>,
              private modalService: BsModalService) {
    super(store);
  }

  ngOnInit() {
    if (this.customerId) {
      this.store.dispatch(new CallsHistorySetFilter({customer: this.customerId}));
    }
  }

  protected registerPipes() {
    this.data$ = this.store.pipe(
      select(getCallsHistoryData),
      tap(data => {
        this.loadedCount = data.data.length;
        this.totalCount = data.totalCount;
      }),
      map(data => data.data)
    );
    this.loading$ = this.store.pipe(select(isLoadingCallsHistory));
    this.currentAudio$ = this.store.pipe(select(getCurrentAudio));
  }

  protected getData() {
    if (this.totalCount > this.loadedCount) {
      this.store.dispatch(new LoadCallsHistoryDebounced({isReload: false}));
    }
  }

  openComment(history, dialog) {
    this.currentCall = history;
    this.modalRef = this.modalService.show(dialog);
  }

  play(callId: string, file: string) {
    if (this.currentAudio) {
      this.currentAudio.audio.pause();
      this.currentAudio = null;
    }
    this.currentAudio = {audio: new Audio(), callId: '', duration: 0, currentTime: 0};
    this.currentAudio.audio.src = file;
    this.currentAudio.audio.load();
    this.currentAudio.audio.onloadeddata = (val: any) => {
      if (val.path[0]) {
        this.currentAudio.duration = val.path[0].duration;
        this.currentAudio.callId = callId;
      }
    };
    this.currentAudio.audio.play();
  }

  stop() {
    this.currentAudio.audio.pause();
    this.currentAudio = null;
  }

  pause() {
    this.currentAudio.audio.pause();
  }

  resume() {
    this.currentAudio.audio.play();
  }

  canPlay(history: Call): boolean {
    return history.file && (!this.currentAudio || history.id !== this.currentAudio.callId);
  }

  canStop(history: Call): boolean {
    return history.file && this.currentAudio
      && history.id === this.currentAudio.callId;
  }

  canPause(history: Call): boolean {
    return history.file && this.currentAudio && history.id === this.currentAudio.callId
      && !this.currentAudio.audio.paused;
  }

  canResume(history: Call): boolean {
    return history.file && this.currentAudio
      && history.id === this.currentAudio.callId && this.currentAudio.audio.paused
      && this.currentAudio.audio.currentTime !== 0;
  }

  changeSeek(value: any) {
    if (value.target && this.currentAudio.audio) {
      this.currentAudio.audio.currentTime = value.target.valueAsNumber;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerId.currentValue) {
      this.store.dispatch(new CallsHistorySetFilter({customer: changes.customerId.currentValue}));
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new ClearCallsHistory());
  }
}
