import * as SIP from 'sip.js';
import { Customer } from '../../customers/models/customer';
import { Employee } from '../../employees/models/employee.model';

export type CallState =
  | 'NEW'
  | 'ASSIGNED'
  | 'ANSWERED'
  | 'HOLD'
  | 'FINISHED'
  | 'HANG_UP_CUSTOMER'
  | 'HANG_UP_EMPLOYEE';

export const CallStates = {
  NEW: 'NEW',
  ASSIGNED: 'ASSIGNED',
  ANSWERED: 'ANSWERED',
  HOLD: 'HOLD',
  FINISHED: 'FINISHED',
  HANG_UP_CUSTOMER: 'HANG_UP_CUSTOMER',
  HANG_UP_EMPLOYEE: 'HANG_UP_EMPLOYEE'
};

export type Call = {
  id: string;
  sip_call_uuid?: string;
  customer?: Customer;
  created?: string;
  ended?: string;
  state: CallState;
  employee?: Employee;
  from_number?: 'string';
  to_number?: 'string';
  subjects?: CallSubject[];
  comment?: string;
  file?: string;
  duration?: number;
};

export type PartialCall = { [P in keyof Call]?: Call[P] };

export interface SipCall {
  sipSession?: SIP.Session;
}

export type CallDirection = { id: 'string'; name: 'string' };

export type CallSubject = { id: 'string'; name: 'string'; direction: 'string' };

export type CallActionReason = { id: 'string'; name: 'string' };

export class CallsFilter {
  customer?: string;
  employee?: string;
  state?: CallState;
  subjects?: string;
  subjects__direction?: string;
  created_date?: string;
  state__in?: string;
}

export interface RedirectCall {
  employee: string;
  comment?: string;
}
