import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../store/reducers';
import { Dictionary } from '../../models/dictionary';
import { DictionaryItem } from '../../models/dictionary-item';
import {
  DictionaryItemCreate,
  DictionaryItemDelete,
  DictionaryItemUpdate,
  DictionaryItemInit
} from '../../store/dictionary-items/dictionary-item.actions';
import {
  getDictionaryItems,
  getTotalDictionaryItemCount,
  isLoadingDictionaryItems,
  isReloadDictionaryItems
} from '../../store/dictionary-items/dictionary-item.selectors';
import { DictionaryUpdate } from '../../store/dictionary/dictionary.actions';
import { getDictionary } from '../../store/dictionary/dictionary.selectors';

@Component({
  selector: 'app-dictionary-items',
  templateUrl: './dictionary-items.component.html',
  styleUrls: ['./dictionary-items.component.scss']
})
export class DictionaryItemsComponent implements OnDestroy {
  isReload$: Observable<boolean>;
  isLoading: boolean;
  dictionaryItems$: Observable<DictionaryItem[]>;
  dictionaryItemsSource$: Observable<DictionaryItem[]>;
  totalCount: number;
  subscriptions = new Subject();
  dictionary$: Observable<Dictionary>;
  newLink$: Observable<string[]>;
  initialState: { dictionary: Dictionary; dictionaryItems: DictionaryItem[] };
  filter$ = new BehaviorSubject<string>(undefined);

  constructor(private store: Store<AppState>, private router: Router) {
    this.dictionaryItemsSource$ = this.store.pipe(
      select(getDictionaryItems),
      filter(data => !!data),
    );

    this.dictionaryItems$ = combineLatest(this.dictionaryItemsSource$, this.filter$).pipe(
      map( ([items, filterValue]) => {
        if (filterValue === undefined) {
          return items;
        }
        return items.filter(item => item.name && item.name.toUpperCase().indexOf(filterValue.toUpperCase()) !== -1 );
      })
    );

    this.isReload$ = this.store.pipe(select(isReloadDictionaryItems));

    this.dictionary$ = this.store.pipe(
      select(getDictionary),
      filter(data => !!data)
    );

    combineLatest(this.dictionary$, this.dictionaryItems$)
      .pipe(takeUntil(this.subscriptions))
      .subscribe(([dictionary, items]) => {
        if (
          !this.initialState ||
          this.initialState.dictionary.id !== dictionary.id
        ) {
          this.initialState = {
            dictionary: dictionary,
            dictionaryItems: items
          };
        }
      });

    this.store
      .pipe(
        takeUntil(this.subscriptions),
        select(getTotalDictionaryItemCount)
      )
      .subscribe(data => {
        this.totalCount = data;
      });

    this.store
      .pipe(
        takeUntil(this.subscriptions),
        select(isLoadingDictionaryItems)
      )
      .subscribe(data => {
        this.isLoading = data;
      });
    this.newLink$ = this.store.pipe(
      select(getDictionary),
      map(data => ['/dictionaries', data.id, 'items', 'new'])
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
  }

  changeDictionary(value) {
    this.store.dispatch(new DictionaryUpdate(value));
  }

  changeDictionaryItem(value) {
    if (!value.id && !value._isTouched) {
      this.store.dispatch(new DictionaryItemCreate(value));
      this.store.dispatch(new DictionaryItemInit());
    } else {
      this.store.dispatch(new DictionaryItemUpdate(value));
    }
  }

  removeDictionaryItem(value) {
    this.store.dispatch(new DictionaryItemDelete(value.id));
  }

  trackByFn(index, item: DictionaryItem) {
    return item._trackId;
  }

  revertChanges() {
    this.store.dispatch(
      new DictionaryUpdate(this.initialState.dictionary, true)
    );
  }

  onFilter(filterValue?: string) {
    this.filter$.next(filterValue);
  }
}
