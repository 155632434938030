import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '../../../../store/reducers';
import { _ } from '../../../shared/helpers/translation-marker';
import { Dictionary, UpdateDictionaryData } from '../../models/dictionary';
import { isLoadingDictionary } from '../../store/dictionary/dictionary.selectors';

@Component({
  selector: 'app-dictionary-form',
  templateUrl: './dictionary-form.component.html',
  styleUrls: ['./dictionary-form.component.scss']
})
export class DictionaryFormComponent {
  @Input() title = _('Add dictionary');
  @Input() isEdit = false;

  @Input() set dictionary(value: Dictionary) {
    if (!value || this.isFormChanged) {
      return;
    }

    this.form.patchValue(value, { emitEvent: false });
  }

  @Output() onSubmit = new EventEmitter<UpdateDictionaryData>();
  @Output() changed = new EventEmitter<UpdateDictionaryData>();

  form: FormGroup;
  loading$: Observable<boolean>;
  submitted = false;
  subscription: Subscription;
  skipChanges = false;
  isFormChanged = false;

  constructor(private store: Store<AppState>, private fb: FormBuilder) {
    this.loading$ = this.store.pipe(select(isLoadingDictionary));
    this.form = this.fb.group({
      name: ['', Validators.required]
    });
  }

  submit() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }

    this.onSubmit.emit(this.form.value);
  }
}
