import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ReclamationStateChangeDialogComponent } from '../components/reclamation-state-change-dialog/reclamation-state-change-dialog.component';
import { ReclamationDialogComponent } from '../components/reclamation-dialog/reclamation-dialog.component';
import { SharedModule } from '../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentsSharedModule } from '../../payments/shared/payments-shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ReclamationDialogComponent,
    ReclamationStateChangeDialogComponent,
  ],
  imports: [
    CommonModule,
    ModalModule,
    RouterModule,
    SharedModule,
    TranslateModule,
    ReactiveFormsModule,
    PaymentsSharedModule,
    TabsModule,
    TooltipModule,
  ],
  entryComponents: [
    ReclamationDialogComponent,
    ReclamationStateChangeDialogComponent,
  ],
  exports: [ReclamationDialogComponent, ReclamationStateChangeDialogComponent],
})
export class ReclamationsSharedModule {}
