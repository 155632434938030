<div class="form-group row" [formGroup]="formGroup">
  <label class="col-sm-2 col-form-label">
    {{'Address' | translate}}
  </label>
  <div class="col-sm-10">
    <app-dadata-address formControlName="address" (coordinateChanged)="coordinateChanged($event)"></app-dadata-address>
  </div>

  <div class="map">
    <div class="form-group">
      <label for="latitude"
             class="col-sm-2 col-form-label">
        {{'Latitude' | translate}}
      </label>
      <div class="col-sm-3">
        <input type="text"
               class="form-control"
               id="latitude"
               formControlName="latitude"
               (keyup)="coordinateChanged([+formGroup.value.latitude, +formGroup.value.longitude])"
        >
      </div>
      <label for="longitude"
             class="col-sm-2 col-form-label text-right">
        {{'Longitude' | translate}}
      </label>
      <div class="col-sm-3">
        <input type="text"
               class="form-control"
               id="longitude"
               formControlName="longitude"
               (keyup)="coordinateChanged([+formGroup.value.latitude, +formGroup.value.longitude])"
        >
      </div>

      <div class="map-switcher col-sm-2">
        <a
          class="link-icon btn-toolbar-action mr-4"
          *ngIf="mapView"
          (click)="switchToMap()"><i class="material-icons">close</i> {{ 'Hide map' | translate}}
        </a>
        <a
          class="link-icon btn-toolbar-action mr-4"
          *ngIf="!mapView"
          (click)="switchToMap()"><i class="material-icons">location_on</i> {{'Show map' | translate}}
        </a>
      </div>
    </div>

    <div [class.hidden]="!mapView" class="address-map col" id="map"></div>
  </div>
</div>
