import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import {
  FullSegment,
  SegmentFilter,
  SegmentForm
} from '../../models/full-segment';
import { AppState } from '../../../../store/reducers/index';
import { Store } from '@ngrx/store';
import { SegmentCreate } from '../../store/actions/segments.action';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { _ } from '../../../shared/helpers/translation-marker';

@Component({
  selector: 'app-segment-create',
  templateUrl: './segment-create.component.html',
  styleUrls: ['./segment-create.component.scss']
})
export class SegmentCreateComponent implements OnInit {
  newSegment = new FullSegment();
  @Input() segmentModel;
  @Input() title = _('Create segment');
  @Output() cancelClick = new EventEmitter<void>();
  constructor(
    private store: Store<AppState>,
    @Optional() private modalRef: BsModalRef
  ) {}

  ngOnInit() {}

  create(segment: SegmentForm) {
    this.store.dispatch(new SegmentCreate(segment));
  }

  onCancelClick() {
    this.cancelClick.emit();
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
}
