import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, mapTo, take, tap } from 'rxjs/operators';
import { AppState } from '../../../../store/reducers';
import { SegmentsSetFilter } from '../../store/actions/segments.action';
import { getCurrentSegmentModelLabel } from '../../store/selectors/segment-models.selector';

@Injectable({
  providedIn: 'root'
})
export class LoadSegmentsGuard implements CanActivate {
  constructor(private store: Store<AppState>) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(getCurrentSegmentModelLabel).pipe(
      filter(data => !!data),
      take(1),
      tap(() => this.store.dispatch(
        new SegmentsSetFilter({search: null}))),
      mapTo(true)
    );
  }
}
