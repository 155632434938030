import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { conformToMask } from 'angular2-text-mask';
import { PHONE_MASK } from '../../config/data-formats.config';

@Injectable({
  providedIn: 'root'
})
export class FormatPhoneService {
  constructor() {}

  format(phone: string) {
    if (isEmpty(phone)) {
      return phone;
    }

    if (phone.startsWith('7')) {
      phone = phone.substring(1);
    }

    return '+7' + conformToMask(phone, PHONE_MASK, {}).conformedValue;
  }
}
