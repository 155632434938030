import {Action} from '@ngrx/store';
import {SegmentGroup} from '../../models/segment-group';

export const SEGMENTS_GROUPS_LOAD = '[Segments] groups load';
export const SEGMENTS_GROUPS_LOAD_SUCCESS = '[Segments] groups load success';
export const SEGMENTS_GROUPS_LOAD_FAIL = '[Segments] groups load fail';


export class SegmentsGroupsLoad implements Action {
  readonly type = SEGMENTS_GROUPS_LOAD;
}

export class SegmentsGroupsLoadSuccess implements Action {
  readonly type = SEGMENTS_GROUPS_LOAD_SUCCESS;
  constructor(public payload: SegmentGroup[]) {}
}

export class SegmentsGroupsLoadFail implements Action {
  readonly type = SEGMENTS_GROUPS_LOAD_FAIL;
}

export type SegmentGroupsAction =
  | SegmentsGroupsLoad
  | SegmentsGroupsLoadSuccess
  | SegmentsGroupsLoadFail
;
