import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ControlInputs, ControlType, DynamicControl } from '../../../models/dynamic-control';

@Component({
  selector: 'app-dynamic-control',
  templateUrl: './dynamic-control.component.html',
  styleUrls: ['./dynamic-control.component.scss']
})
export class DynamicControlComponent implements DynamicControl {
  ControlTypes: typeof ControlType = ControlType;
  control: FormControl;
  inputs: ControlInputs;
}
