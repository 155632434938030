import { createSelector } from '@ngrx/store';
import {
  getRouterState,
  getRouterMeta,
  RouterStateUrl,
  RouterMetaState, CrumbDetail
} from '../reducers/router.reducer';
import { RouterReducerState } from '@ngrx/router-store';

export const getNavChain = createSelector(
  getRouterState,
  getRouterMeta,
  (router: RouterReducerState<RouterStateUrl>, meta: RouterMetaState) => {
    const chain = router.state.chain;
    const breadcrumbs = [];

    for (const item of chain) {
      if (
        item.data &&
        (item.data.title || item.data.crumb || item.data.page_id)
      ) {
        const page_id = item.data.page_id;
        const page_title = item.data.title || meta.page_titles[page_id];
        const crumb: CrumbDetail = item.data.crumb || meta.crumbs[item.data.page_id];

        breadcrumbs.push({
          url: (crumb && crumb.url) ? crumb.url : item.url,
          name: (crumb ? crumb.name : page_title || page_id)
        });
      }
    }

    return breadcrumbs;
  }
);

export const getPageTitle = createSelector(
  getRouterState,
  getRouterMeta,
  (router: RouterReducerState<RouterStateUrl>, meta: RouterMetaState) => {
    const chain = router.state.chain;
    let title = '';

    for (const item of chain) {
      if (item.data && (item.data.title || item.data.crumb || item.data.page_id)) {
        const page_id = item.data.page_id;
        const page_title = item.data.title || meta.page_titles[page_id];
        const crumb = item.data.crumb || meta.crumbs[item.data.page_id];

        if (page_title || crumb) {
          title = page_title ? page_title : crumb.name;
        } else {
          title = page_id;
        }
      }
      if (item.data && item.data.hideTitle) {
        title = undefined;
      }
    }

    return title;
  }
);
