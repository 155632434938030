import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { TextMaskModule } from 'angular2-text-mask';
import {
  AUTH_SERVICE,
  AuthModule,
  PROTECTED_FALLBACK_PAGE_URI,
  PUBLIC_FALLBACK_PAGE_URI,
} from 'ngx-auth';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MomentModule } from 'ngx-moment';
import { ClipboardModule } from 'ngx-clipboard';

import { ButtonGroupComponent } from './components/button-group/button-group.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DadataAddressComponent } from './components/dadata-address/dadata-address.component';
import { EmptyListPlaceholderComponent } from './components/empty-list-placeholder/empty-list-placeholder.component';
import { ErrorsComponentComponent } from './components/errors-component/errors-component.component';
import { FormStatusComponent } from './components/form-status/form-status.component';
import { DatepickerComponent } from './components/form/datepicker/datepicker.component';
import { SelectComponent } from './components/form/select/select.component';
import { ImageItemComponent } from './components/image-item/image-item.component';
import { ImagesEditComponent } from './components/images-edit/images-edit.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NgSelectOptionWithCountComponent } from './components/ng-select-option-with-count/ng-select-option-with-count.component';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { OutdatedBrowserComponent } from './components/outdated-browser/outdated-browser.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PeriodWithProgressComponent } from './components/period-with-progress/period-with-progress.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { PromotionStatesMenuComponent } from './components/promotion-states-menu/promotion-states-menu.component';
import { RegionSelectorComponent } from './components/region-selector/region-selector.component';
import { PushTopicSelectorComponent } from './components/push-topic-selector/push-topic-selector.component';
import { RemoteModelSelectorComponent } from './components/remote-model-selector/remote-model-selector.component';
import { SegmentSelectComponent } from './components/segment-select/segment-select.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { UserBarComponent } from './components/user-bar/user-bar.component';
import { ImageDirective } from './directives/image.directive';
import { UiElementsComponent } from './pages/ui-elements/ui-elements.component';
import { CapitalizePipe } from './pipes/capitalize/capitalize.pipe';
import { CustomerPipe } from './pipes/customer/customer.pipe';
import { EmployeePipe } from './pipes/employee/employee.pipe';
import { FormatCurrencyPipe } from './pipes/format-currency/format-currency.pipe';
import { FormatDatePipe } from './pipes/format-date/format-date.pipe';
import { FormatDatetimePipe } from './pipes/format-datetime/format-datetime.pipe';
import { FormatPhonePipe } from './pipes/format-phone/format-phone.pipe';
import { FormatSecondsPipe } from './pipes/format-seconds/format-seconds.pipe';
import { KeysPipe } from './pipes/keys/keys.pipe';
import { OutletPipe } from './pipes/outlet/outlet.pipe';
import { PricePipe } from './pipes/price/price.pipe';
import { AuthenticationService } from './services/authentication/authentication.service';
import { ConfirmationDialogService } from './services/confirmation-dialog/confirmation-dialog.service';
import { FormatPhoneService } from './services/format-phone/format-phone.service';
import { HandleGuardRequestService } from './services/handle-guard-request/handle-guard-request.service';
import { NotificationsService } from './services/notifications/notifications.service';
import { NumberDemaskService } from './services/number-demask/number-demask.service';
import { effects } from './store/effects';
import { reducers } from './store/reducers';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';
import { ConfirmationDialogV1Component } from './components/confirmation-dialog-v1/confirmation-dialog-v1.component';
import { RejectionReasonDialogComponent } from '../tasks/components/rejection-reason-dialog/rejection-reason-dialog.component';
import { UncollectionRejectionReasonDialogComponent } from '../tasks/components/uncollection-rejection-reason-dialog/uncollection-rejection-reason-dialog.component';
import { InputAddressComponent } from './components/input-address/input-address.component';
import { OrderItemReturnDialogComponent } from '../orders/components/order-item-return-dialog/order-item-return-dialog.component';
import { TextEditModalComponent } from './components/text-edit-modal/text-edit-modal.component';
import { TransferInfoComponent } from '../reclamations/components/transfer-info/transfer-info.component';
import { PaymentTypePipe } from '../payments/shared/pipes/payment-type/payment-type.pipe';

@NgModule({
  imports: [
    CommonModule,
    AuthModule,
    StoreModule.forFeature('share', reducers),
    EffectsModule.forFeature(effects),
    TranslateModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MomentModule,
    BsDropdownModule.forRoot(),
    TimepickerModule,
    BsDatepickerModule,
    NgSelectModule,
    TextMaskModule,
    SortableModule,
    ClipboardModule,
    TooltipModule,
  ],
  declarations: [
    UiElementsComponent,
    UserBarComponent,
    PageHeaderComponent,
    SelectComponent,
    LoaderComponent,
    LoaderComponent,
    ErrorsComponentComponent,
    PromotionStatesMenuComponent,
    PeriodWithProgressComponent,
    ConfirmationDialogComponent,
    ConfirmationDialogV1Component,
    NotificationDialogComponent,
    NgSelectOptionWithCountComponent,
    EmptyListPlaceholderComponent,
    NumberInputComponent,
    DatepickerComponent,
    KeysPipe,
    FormatPhonePipe,
    FormatDatePipe,
    CapitalizePipe,
    FormatDatetimePipe,
    FormatCurrencyPipe,
    PricePipe,
    PhoneInputComponent,
    SidebarComponent,
    SegmentSelectComponent,
    ButtonGroupComponent,
    ImageDirective,
    RegionSelectorComponent,
    PushTopicSelectorComponent,
    FormStatusComponent,
    FormatSecondsPipe,
    EmployeePipe,
    CustomerPipe,
    OutletPipe,
    OutdatedBrowserComponent,
    DadataAddressComponent,
    RemoteModelSelectorComponent,
    ImagesEditComponent,
    ImageItemComponent,
    RejectionReasonDialogComponent,
    UncollectionRejectionReasonDialogComponent,
    InputAddressComponent,
    OrderItemReturnDialogComponent,
    TextEditModalComponent,
    TransferInfoComponent,
    PaymentTypePipe,
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    ConfirmationDialogV1Component,
    NotificationDialogComponent,
    RejectionReasonDialogComponent,
    UncollectionRejectionReasonDialogComponent,
    OrderItemReturnDialogComponent,
    TextEditModalComponent,
    TransferInfoComponent,
  ],
  exports: [
    PageHeaderComponent,
    UserBarComponent,
    LoaderComponent,
    ErrorsComponentComponent,
    PromotionStatesMenuComponent,
    TranslateModule,
    SelectComponent,
    PeriodWithProgressComponent,
    NgSelectModule,
    NgSelectOptionWithCountComponent,
    EmptyListPlaceholderComponent,
    NumberInputComponent,
    DatepickerComponent,
    KeysPipe,
    FormatPhonePipe,
    FormatDatePipe,
    CapitalizePipe,
    FormatDatetimePipe,
    FormatCurrencyPipe,
    PricePipe,
    PhoneInputComponent,
    SidebarComponent,
    SegmentSelectComponent,
    ButtonGroupComponent,
    ImageDirective,
    RegionSelectorComponent,
    PushTopicSelectorComponent,
    FormStatusComponent,
    FormatSecondsPipe,
    EmployeePipe,
    CustomerPipe,
    OutletPipe,
    OutdatedBrowserComponent,
    DadataAddressComponent,
    RemoteModelSelectorComponent,
    ImagesEditComponent,
    ImageItemComponent,
    InputAddressComponent,
    TextEditModalComponent,
    PaymentTypePipe,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ConfirmationDialogService,
        AuthenticationService,
        HandleGuardRequestService,
        { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/' },
        { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/auth/login' },
        { provide: AUTH_SERVICE, useClass: AuthenticationService },
        NotificationsService,
        NumberDemaskService,
        FormatPhoneService,
      ],
    };
  }
}
