import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ContactTipState } from './contact-tip.reducer';
import { escapeRegExp, words } from 'lodash';
import JaroWinkler from '../services/jarowinkler.class';

export const getContactTipsState = createFeatureSelector('contactTip');
export const getContactTips = createSelector(
  getContactTipsState,
  (state: ContactTipState) => state.contactTips
);

export const getContactTipsBySubject = subject =>
  createSelector(
    getContactTipsState,
    (state: ContactTipState) =>
      state.contactTips.filter(tip => tip.subject === subject)
  );

export const getContactTipsByTerm = term =>
  createSelector(
    getContactTipsState,
    (state: ContactTipState) => {
      if (!term) return [];
      const lowerTerms = words(term.toLocaleLowerCase()).filter(
        term => term.length > 1
      );
      return state.contactTips
        .map(tip => {
          const lowerDesc = tip.description
            ? tip.description.toLocaleLowerCase()
            : '';
          const lowerName = tip.name ? tip.name.toLocaleLowerCase() : '';
          const nameCount = words(lowerName)
            .filter(word => word.length > 1)
            .reduce((prev, word) => {
              return (
                prev +
                lowerTerms.reduce((dist, term) => {
                  const jw = JaroWinkler.Distance(term, word);
                  return jw > 0.85 ? dist + jw : dist;
                }, 0)
              );
            }, 0);
          const descCount = words(lowerDesc).reduce((prev, word) => {
            return (
              prev +
              lowerTerms.reduce((dist, term) => {
                const jw = JaroWinkler.Distance(term, word);
                return jw > 0.85 ? dist + jw : dist;
              }, 0)
            );
          }, 0);

          return { ...tip, rank: nameCount * 5 + descCount * 2 };
        })
        .filter(tip => tip.rank)
        .sort((tipA, tipB) => tipB.rank - tipA.rank);
    }
  );
