import { Component } from '@angular/core';
import { FilterValueAccessor, MakeProvider } from '../filter-value-accessor';
import { Validators } from '@angular/forms';
import { SegmentOperatorLabels } from '../../../models/segment-property';
import { SelectItem } from '../../../../shared/components/form/select/select.component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-segment-filter-enum',
  templateUrl: './segment-filter-enum.component.html',
  styleUrls: ['./segment-filter-enum.component.scss'],
  providers: [...MakeProvider(SegmentFilterEnumComponent)]
})
export class SegmentFilterEnumComponent extends FilterValueAccessor {
  operators: string[] = [];

  ngOnInit() {
    this.initForm();
    this.initSubscriptions();
    this.updateOperators();
  }

  private initForm() {
    this.form = this.fb.group({
      operator: [null, Validators.required],
      value: [null, Validators.required]
    });
  }

  private initSubscriptions() {
    this.subscriptions.add(
      this.form.valueChanges
        .pipe(filter(() => this.form.valid))
        .subscribe(values => {
          this.pushValue({
            ...this.filter,
            ...values
          });
        })
    );
  }

  updateOperators() {
    if (this.property && this.property.operators) {
      this.operators = this.property.operators;
    }
  }

  onWrite(values) {
    const formValues = {
      ...values,
      operator: values.operator || this.property.operators[0]
    };
    this.form.patchValue(formValues, { emitEvent: false });
  }
}
