import { Action } from '@ngrx/store';
import { PagebleEntity } from '../../../shared/models/pageble-entity';
import { Payment, PaymentsFilter } from '../../models/payment.model';
import {
  PagebleAction,
  PagebleActionPayload
} from '../../../shared/helpers/calculate-page';

export enum PaymentActionTypes {
  LoadPayments = '[Payment] Load Payments',
  LoadPaymentsDebounced = '[Payment] Load Payments if not loading',
  LoadPaymentsSuccess = '[Payment] Load Payments Success',
  LoadPaymentsFail = '[Payment] Load Payments Fail',
  ClearPayments = '[Payment] Clear',
  LoadPayment = '[Payment] Load Payment',
  LoadPaymentSuccess = '[Payment] Load Payment Success',
  LoadPaymentFail = '[Payment] Load Payment Fail',
  RefreshPayment = '[Payment] Refresh Payment',
  RefreshPaymentSuccess = '[Payment] Refresh Payment Success',
  RefreshPaymentFail = '[Payment] Refresh Payment Fail',
  SetPaymentsFilter = '[Payment] Set Payments Filter',
}

export class LoadPayments implements Action, PagebleAction {
  readonly type = PaymentActionTypes.LoadPayments;
  constructor(public payload?: PagebleActionPayload) {}
}

export class LoadPaymentsDebounced implements Action, PagebleAction {
  readonly type = PaymentActionTypes.LoadPaymentsDebounced;
  constructor(public payload?: PagebleActionPayload) {}
}

export class LoadPaymentsSuccess implements Action {
  readonly type = PaymentActionTypes.LoadPaymentsSuccess;
  constructor(public payload?: PagebleEntity<Payment>) {}
}

export class LoadPaymentsFail implements Action {
  readonly type = PaymentActionTypes.LoadPaymentsFail;
}

export class ClearPayments implements Action {
  readonly type = PaymentActionTypes.ClearPayments;
}

export class LoadPayment implements Action {
  readonly type = PaymentActionTypes.LoadPayment;
  constructor(public id: string) {}
}

export class LoadPaymentSuccess implements Action {
  readonly type = PaymentActionTypes.LoadPaymentSuccess;
  constructor(public payload: Payment) {}
}

export class LoadPaymentFail implements Action {
  readonly type = PaymentActionTypes.LoadPaymentFail;
}

export class RefreshPayment implements Action {
  readonly type = PaymentActionTypes.RefreshPayment;
  constructor(public id: string) {}
}

export class RefreshPaymentSuccess implements Action {
  readonly type = PaymentActionTypes.RefreshPaymentSuccess;
  constructor(public payload: Payment) {}
}

export class RefreshPaymentFail implements Action {
  readonly type = PaymentActionTypes.RefreshPaymentFail;
}

export class SetPaymentsFilter implements Action {
  readonly type = PaymentActionTypes.SetPaymentsFilter;

  constructor(public payload: PaymentsFilter) {}
}

export type PaymentActions =
  | LoadPayments
  | LoadPaymentsSuccess
  | LoadPaymentsFail
  | ClearPayments
  | LoadPayment
  | LoadPaymentSuccess
  | LoadPaymentFail
  | RefreshPayment
  | RefreshPaymentSuccess
  | RefreshPaymentFail
  | SetPaymentsFilter;
