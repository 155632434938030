import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Call } from '../../models/calls.model';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { getActiveCall } from '../../store/call.selector';

@Component({
  selector: 'app-customer-card',
  templateUrl: './customer-card.component.html',
  styleUrls: ['./customer-card.component.scss']
})
export class CustomerCardComponent {
  call$: Observable<Call>;

  constructor(private store: Store<AppState>) {
    this.call$ = this.store.pipe(select(getActiveCall));
  }
}
