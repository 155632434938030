import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../store/reducers';
import { CallService } from '../../../calls/services/call/call.service';
import { CallToCustomer } from '../../../calls/store/call.actions';
import { FormStatusReset } from '../../../shared/store/actions/form-status.actions';
import { CreateCustomerData, Customer } from '../../models/customer';
import { CustomersClear, CustomerUpdate } from '../../store/customer/customer.actions';
import { getCurrentCustomer } from '../../store/customer/customer.selectors';

@Component({
  selector: 'app-customer-update',
  templateUrl: './customer-update.component.html',
  styleUrls: ['./customer-update.component.scss']
})
export class CustomerUpdateComponent implements OnInit, OnDestroy {
  customer$: Observable<Customer>;
  initialState: Customer;
  subscriptions = new Subject();

  constructor(
    private store: Store<AppState>,
    private callService: CallService
  ) {
    this.customer$ = this.store.pipe(select(getCurrentCustomer));
    this.customer$.pipe(takeUntil(this.subscriptions)).subscribe(customer => {
        if (!this.initialState || this.initialState.id !== customer.id) {
          this.store.dispatch(new FormStatusReset());
          this.initialState = customer;
        }
      }
    );
  }

  ngOnInit() {
    this.store.dispatch(new FormStatusReset());
  }

  save(data: CreateCustomerData) {
    const newData: Customer = {
      ...data,
      id: this.initialState.id
    };
    this.store.dispatch(new CustomerUpdate(newData));
  }

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
    this.store.dispatch(new CustomersClear());
  }

  revertChanges() {
    this.store.dispatch(new CustomerUpdate(this.initialState));
    this.initialState = null;
  }

  callToCustomer() {
    this.store.dispatch(new CallToCustomer(this.initialState.id));
  }
}
