<div class="table-header">
  <div class="column text-muted" *ngFor="let prop of arrayProperties"
       [ngStyle]="{'width': 'calc(100% / ' + arrayProperties.length +')'}">
    {{(propertyNames[prop.name] ? propertyNames[prop.name] : prop.name) | translate }}
  </div>
  <div class="remove"></div>
</div>
<div class="table-body">
  <div class="array-item" *ngFor="let item of form.controls; let i=index; trackBy: trackByFn">
    <div class="array-field"
         [ngStyle]="{'width': 'calc(100% / ' + arrayProperties.length +')'}"
         *ngFor="let prop of arrayProperties; let j=index">
      <app-array-model-item-value *ngIf="isObject"
                                  [modelName]="modelName"
                                  [formControl]="item.get(prop.fieldName)"
                                  [property]="prop"
                                  [hasRemoveButton]="true"
                                  [submitted]="submitted"
      ></app-array-model-item-value>
      <app-model-scalar-field *ngIf="!isObject"
                              [hasRemoveButton]="true"
                              (onRemoveClick)="remove(i)"
                              [submitted]="submitted"></app-model-scalar-field>
    </div>
    <div class="remove">
      <a class='icon-link'
         (click)='remove(i)'>
        <i class="material-icons">delete_outline</i>
      </a>
    </div>
  </div>
  <div class="array-item">
    <div class="array-field"
         [ngStyle]="{'width': 'calc(100% / ' + arrayProperties.length +')'}"
         *ngFor="let prop of arrayProperties; let j=index">
      <app-array-model-item-value *ngIf="isObject && newElementForm"
                                  [modelName]="modelName"
                                  [formControl]="newElementForm.get(prop.fieldName)"
                                  [property]="prop"
                                  [submitted]="submitted"
      ></app-array-model-item-value>
    </div>
    <div class="remove">
      <a class='icon-link'
         (click)='add($event)'>
        <i class="material-icons">add</i>
      </a>
    </div>
  </div>
</div>
