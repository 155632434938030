<ng-container class="table-row" [formGroup]="form">
  <td class="form-group">
    <input type="text" class="form-control" id="name" formControlName="name" />
    <div>
      <app-errors-component
        [control]="form.get('name')"
      ></app-errors-component>
    </div>
  </td>
  <td class="form-group">
    <input
      type="text"
      class="form-control"
      id="description"
      formControlName="description"
    />
    <div>
      <app-errors-component
        [control]="form.get('description')"
      ></app-errors-component>
    </div>
  </td>
  <td class="form-group">
    <app-segment-select
      [models]="['sku', 'balance', 'product']"
      id="segments"
      formControlName="segments"
      [multiple]="true"
    ></app-segment-select>
    <div>
      <app-errors-component
        [control]="form.get('segments')"
      ></app-errors-component>
    </div>
  </td>
  <td class="form-group">
    <input type="number"
            class="form-control"
            id="sort"
            formControlName="sort" />
    <div>
      <app-errors-component [control]="form.get('sort')"></app-errors-component>
    </div>
  </td>
  <td class="remove-col">
    <ng-container *ngIf="!dictionaryItem._isLoading; else loading">
      <a
        *ngIf="dictionaryItem.id && dictionaryItem.actions?.includes('DELETE')"
        class="icon-link"
        (click)="remove()"
      >
        <i class="material-icons">delete_outline</i>
      </a>
    </ng-container>
  </td>
  <td class="remove-col">
    <a
        class="icon-link"
        (click)="save()"
        [class.disabled]="form.pristine || form.invalid"
        >
      <i class="material-icons">check</i>
    </a>
  </td>
</ng-container>

<ng-template #loading> <app-loader class="sm"></app-loader> </ng-template>
