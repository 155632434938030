import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {AppState} from '../../../../store/reducers';
import {select, Store} from '@ngrx/store';
import {DICTIONARY_LOAD_FAIL, DictionaryLoad} from '../../store/dictionary/dictionary.actions';
import {getDictionary} from '../../store/dictionary/dictionary.selectors';
import {filter, mapTo, take, tap} from 'rxjs/operators';
import {SetCrumb, SetPageTitle} from '../../../../store/actions/router.action';
import {DICTIONARY_ITEM_KEY} from '../../models/dictionary-item';
import {HandleGuardRequestService} from '../../../shared/services/handle-guard-request/handle-guard-request.service';

@Injectable({
  providedIn: 'root'
})
export class LoadDictionaryGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private handler: HandleGuardRequestService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(new DictionaryLoad(next.params.id));
    return this.handler.handle(
      this.store.pipe(select(getDictionary)).pipe(
        filter(data => !!data && data.name && data.name.length > 0),
        tap(data => {
          this.store.dispatch(
            new SetPageTitle(DICTIONARY_ITEM_KEY, data.name)
          );
          this.store.dispatch(
            new SetCrumb(DICTIONARY_ITEM_KEY, {
              name: data.name,
              url: `/dictionaries/${data.id}/items`
            })
          )
        }),
        mapTo(true)
      ),
    DICTIONARY_LOAD_FAIL);
  }
}
