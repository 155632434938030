import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { SegmentProperty } from '../../../../../segments/models/segment-property';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-model-string-field',
  templateUrl: './model-string-field.component.html',
  styleUrls: ['./model-string-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModelStringFieldComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ModelStringFieldComponent),
      multi: true
    }
  ]
})
export class ModelStringFieldComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {
  @Input() property: SegmentProperty;

  value: FormControl = new FormControl();
  subscriptions = new Subject();
  isChanging: boolean;

  onChange = _ => {};
  onTouched = () => {};

  constructor() {
    this.value.valueChanges.pipe(
      takeUntil(this.subscriptions),
      tap(data => {
        this.onChange(data);
      }),
      filter(() => {
        return !this.isChanging;
      })
    ).subscribe(_ => {});
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.value.valueChanges.pipe(takeUntil(this.subscriptions)).subscribe(fn);
  }

  registerOnValidatorChange(fn: () => void): void {
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.value.disable();
    } else {
      this.value.enable();
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.value.errors;
  }

  writeValue(obj: any): void {
    this.value.patchValue(obj);
  }

  isMultiSelect() {
    return this.property.is_many;
  }

  touch() {
    !!this.onTouched && this.onTouched();
  }

}
