import { SegmentProperty } from '../../models/segment-property';
import {
  SEGMENT_PROPERTIES_LOAD,
  SEGMENT_PROPERTIES_LOAD_FAIL,
  SEGMENT_PROPERTIES_LOAD_SUCCESS,
  SegmentPropertiesAction
} from '../actions/segment-properties.action';
import { createSelector } from '@ngrx/store';
import { getSegmentsFeatureState } from '../selectors/index';

export interface SegmentPropertiesState {
  segmentProperties: SegmentProperty[];
  isLoading: boolean;
}

export const initialState: SegmentPropertiesState = {
  isLoading: false,
  segmentProperties: []
};

export function SegmentPropertiesReducer(state = initialState, action: SegmentPropertiesAction): SegmentPropertiesState {
  switch (action.type) {
    case SEGMENT_PROPERTIES_LOAD:
      return { ...state, isLoading: true };
    case SEGMENT_PROPERTIES_LOAD_SUCCESS:
      return { ...state, isLoading: false, segmentProperties: action.payload };
    case SEGMENT_PROPERTIES_LOAD_FAIL:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export const getSegmentPropertiesState = createSelector(getSegmentsFeatureState, data => data.segmentProperties);
