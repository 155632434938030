import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { FullSegment, SegmentFilter} from '../../models/full-segment';
import { AppState } from '../../../../store/reducers/index';
import { getCurrentSegment, isCurrentSegmentLoading} from '../../store/selectors/segments.selector';
import {
  SegmentFilterAdd,
  SegmentsFilterDelete,
  SegmentsFilterUpdate,
  SegmentUpdate
} from '../../store/actions/segments.action';
import { getCurrentSegmentModelLabel } from '../../store/selectors/segment-models.selector';
import { SegmentFormComponent } from '../segment-form/segment-form.component';

@Component({
  selector: 'app-segment-edit',
  templateUrl: './segment-edit.component.html',
  styleUrls: ['./segment-edit.component.scss']
})
export class SegmentEditComponent implements OnDestroy {
  @ViewChild(SegmentFormComponent) segmentForm: SegmentFormComponent;

  segment$: Observable<FullSegment>;
  isLoading$: Observable<boolean>;
  segment: FullSegment;
  segmentSubscription: Subscription;
  currentSegmentModel$: Observable<string>;


  public get showConfirmationOnNavigation() {
    return this.segmentForm && this.segmentForm.showConfirmationOnNavigation;
  }

  constructor(private store: Store<AppState>, private router: Router) {
    this.segment$ = this.store.pipe(select(getCurrentSegment));
    this.segmentSubscription = this.segment$.subscribe(
      data => (this.segment = data)
    );
    this.isLoading$ = this.store.pipe(select(isCurrentSegmentLoading));
    this.currentSegmentModel$ = this.store.pipe(select(getCurrentSegmentModelLabel));
  }

  ngOnDestroy(): void {
    this.segmentSubscription.unsubscribe();
  }

  updateName(name: string) {
    this.store.dispatch( new SegmentUpdate({ name }) );
  }

  saveFilter([id, filter]: [string, SegmentFilter]) {
    if (filter.id || filter._isTouched) {
      this.store.dispatch(new SegmentsFilterUpdate(filter, filter._trackId));
    } else {
      this.store.dispatch(new SegmentFilterAdd(filter, filter._trackId));
    }
  }

  removeFilter(filter: SegmentFilter) {
    this.store.dispatch(new SegmentsFilterDelete(filter.id, filter._trackId));
  }

  cancelClick() {
    this.currentSegmentModel$.pipe(take(1)).subscribe(model => {
      this.router.navigate(['/segments', model]);
    });
  }
}
