import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/reducers';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SegmentsSetFilter } from '../../store/actions/segments.action';

@Component({
  selector: 'app-segments-search',
  templateUrl: './segments-search.component.html',
  styleUrls: ['./segments-search.component.scss']
})
export class SegmentsSearchComponent implements OnInit {
  filter: FormGroup;
  formSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private route: ActivatedRoute
  ) {
    const initialFilterValue = {
      search: null
    };
    this.filter = fb.group(initialFilterValue);

    this.formSubscription = this.filter.valueChanges
      .pipe(debounceTime(1000))
      .subscribe(data => {
        this.store.dispatch(new SegmentsSetFilter(data));
      });
    this.formSubscription.add(
      this.route.params.subscribe(() => {
        this.filter.reset(initialFilterValue, { emitEvent: false });
      })
    );
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  trackByFn(index) {
    return index;
  }
}
