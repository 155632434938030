import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../store/reducers';
import { CreateCustomerData, Customer } from '../../models/customer';
import {
  CustomerLoad,
  CustomerUpdate
} from '../../store/customer/customer.actions';
import { getCurrentCustomer } from '../../store/customer/customer.selectors';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss']
})
export class CustomerEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() customerId: string;
  customer$: Observable<Customer>;
  isCollapsed = false;
  initialState: Customer;
  subscriptions = new Subject();

  constructor(private store: Store<AppState>) {
    this.customer$ = this.store.pipe(select(getCurrentCustomer));
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.customerId &&
      changes.customerId.currentValue !== changes.customerId.previousValue
    ) {
      this.store.dispatch(new CustomerLoad(changes.customerId.currentValue));
    }
  }

  save(data: CreateCustomerData) {
    const newData: Customer = { ...data, id: this.customerId };
    this.store.dispatch(new CustomerUpdate(newData));
  }

  revertChanges() {
    this.store.dispatch(new CustomerUpdate(this.initialState));
  }

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
  }
}
