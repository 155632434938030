import { ContactCenterState } from 'src/app/bootstrap-modules/contact-center/store/contact-center.reducer';
import { createSelector, createFeatureSelector } from '@ngrx/store';

export const getContactCenterState = createFeatureSelector('contactCenter');

export const getCollectionTasksCount = createSelector(
  getContactCenterState,
  (data: ContactCenterState) => data.collectionTasksCount
);

export const getUncollectionTasksCount = createSelector(
  getContactCenterState,
  (data: ContactCenterState) => data.uncollectionTasksCount
);
