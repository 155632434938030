import { ContactCenterActions, ContactCenterActionTypes } from './contact-center.actions';

export interface ContactCenterState {
  collectionTasksCount: number;
  uncollectionTasksCount: number;
}

export const initialState: ContactCenterState = {
  collectionTasksCount: undefined,
  uncollectionTasksCount: undefined
};

export function contactCenterReducer(
  state = initialState,
  action: ContactCenterActions
): ContactCenterState {
  switch (action.type) {
    case ContactCenterActionTypes.UpdateTaskCountSuccess :
      if ( !action.payload || !action.payload.data) {
        return state;
      }
      return {
        ...state,
        collectionTasksCount: action.payload.data.collect,
        uncollectionTasksCount: action.payload.data.uncollect
      };

    default:
      return state;
  }
}
