import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { isEqual, omit, pick } from 'lodash';
import { of, Observable } from 'rxjs';
import {
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  scan,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { AppState } from 'src/app/store/reducers';
import { EmployeesService } from '../../employees/services/employees/employees.service';
import { appendOrSetCollection } from '../../shared/helpers/append-or-set-collection';
import {
  calculatePage,
  OutputPagebleAction
} from '../../shared/helpers/calculate-page';
import { ofMessage } from '../../shared/helpers/operators/ofMessage';
import { PagebleEntity } from '../../shared/models/pageble-entity';
import { User } from '../../shared/models/user';
import { ErrorHandlingService } from '../../shared/services/error-handling/error-handling.service';
import {
  LOAD_USER_SUCCESS,
  LoadUserSuccess,
  NoopAction
} from '../../shared/store/actions/user.action';
import { getCurrentUser } from '../../shared/store/selectors/user.selector';
import {
  Call,
  CallActionReason,
  CallDirection,
  CallsFilter,
  CallSubject,
  PartialCall
} from '../models/calls.model';
import { callUpdateMessageType } from '../models/messages/call-update.model';
import { contactRequestMessageType } from '../models/messages/contact-request.model';
import { CallService } from '../services/call/call.service';
import { SipService } from '../services/sip/sip.service';
import {
  CallActionTypes,
  CallToCustomer,
  CallToCustomerSuccess,
  CreateCall,
  FinishCall,
  FinishCallFail,
  FinishCallSuccess,
  HoldCall,
  LoadActionReasonsFail,
  LoadActionReasonsSuccess,
  LoadCall,
  LoadCallFail,
  LoadCallsHistory,
  LoadCallsHistoryFail,
  LoadCallsHistorySuccess,
  LoadCallSuccess,
  LoadDirections,
  LoadDirectionsSuccess,
  LoadSubjects,
  LoadSubjectsFail,
  LoadSubjectsSuccess,
  LoadUnfinishedCalls,
  LoadUnfinishedCallsFail,
  LoadUnfinishedCallsSuccess,
  ResumeCall,
  TransferCall,
  TransferCallFail,
  TransferCallSuccess,
  UpdateCall,
  CallToOrderRecipient,
  CallToOrderRecipientSuccess,
  NavigateToCall,
  LoadCallsHistoryDebounced
} from './call.actions';
import {
  getActiveCall,
  getCallsHistory,
  getCallsHistoryFilter,
  getLastUnfinishedCall,
  getCallPurpose,
  isLoadingCallsHistory
} from './call.selector';

@Injectable()
export class CallEffects {
  @Effect()
  socketContactRequest$ = this.actions$.pipe(
    ofMessage(contactRequestMessageType),
    switchMap(message => {
      return this.callService
        .one(message.payload.id)
        .pipe(map(call => new LoadCallSuccess(call)));
    })
  );

  @Effect()
  newContactRequest$ = this.actions$.pipe(
    ofType(CallActionTypes.CreateCall),
    map((action: CreateCall) => {
      this.router.navigate(['/calls/call']);
      return new LoadCallSuccess(action.payload);
    })
  );

  @Effect({ dispatch: false })
  navigateToCall$ = this.actions$.pipe(
    ofType(CallActionTypes.NavigateToCall),
    withLatestFrom(this.store.pipe(select(getCallPurpose))),
    tap(([action, purpose]: [NavigateToCall, string]) => {
      const [path, fragment] = purpose ? purpose.split('#') : ['', undefined];
      this.router.navigate([`/calls/${action.id}${path}`], { fragment });
    })
  );

  @Effect()
  holdCall$ = this.actions$.pipe(
    ofType(CallActionTypes.HoldCall),
    switchMap((action: HoldCall) =>
      this.callService.holdCall(action.id).pipe(
        map(response => new LoadCallSuccess(response)),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handle(error.error);
          return of(new LoadCallFail());
        })
      )
    )
  );

  @Effect()
  resumeCall$ = this.actions$.pipe(
    ofType(CallActionTypes.ResumeCall),
    switchMap((action: ResumeCall) =>
      this.callService.resumeCall(action.id).pipe(
        map(response => new LoadCallSuccess(response)),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handle(error.error);
          return of(new LoadCallFail());
        })
      )
    )
  );

  @Effect()
  transferCall$ = this.actions$.pipe(
    ofType(CallActionTypes.TransferCall),
    switchMap((action: TransferCall) =>
      this.callService.transferCall(action.id, action.payload).pipe(
        map(response => new TransferCallSuccess()),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handle(error.error);
          return of(new TransferCallFail());
        })
      )
    )
  );

  @Effect()
  finishCall$ = this.actions$.pipe(
    ofType(CallActionTypes.FinishCall),
    switchMap((action: FinishCall) =>
      this.callService.finishCall(action.id, action.payload).pipe(
        map(response => new FinishCallSuccess(response)),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handle(error.error);
          return of(new FinishCallFail());
        })
      )
    )
  );

  @Effect()
  loadCall$ = this.actions$.pipe(
    ofType(CallActionTypes.LoadCall),
    switchMap((action: LoadCall) =>
      this.callService.one(action.id).pipe(
        map(response => new LoadCallSuccess(response)),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handle(error.error);
          return of(new LoadCallFail());
        })
      )
    )
  );

  @Effect()
  updateCall$ = this.actions$.pipe(
    ofType(CallActionTypes.UpdateCall),
    map((action: UpdateCall) => action.payload),
    scan((acc: PartialCall, curr: PartialCall) => {
      return acc.id && acc.id === curr.id ? { ...acc, ...curr } : curr;
    }, {}),
    debounceTime(300),
    switchMap((call: PartialCall) =>
      this.callService.update(call.id, omit(call, ['id'])).pipe(
        map(response => new LoadCallSuccess(response)),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handle(error.error);
          return of(new LoadCallFail());
        })
      )
    )
  );

  @Effect({ dispatch: false })
  sipCredentialsUpdate$ = this.actions$.pipe(
    ofType(LOAD_USER_SUCCESS),
    distinctUntilChanged((a: LoadUserSuccess, b: LoadUserSuccess) => {
      return isEqual(
        pick(a.payload, ['sip_extension', 'sip_password']),
        pick(b.payload, ['sip_extension', 'sip_password'])
      );
    }),
    filter(
      (action: LoadUserSuccess) =>
        action.payload.sip_extension && action.payload.sip_password
    ),
    tap((action: LoadUserSuccess) => this.sipService.initSIP(action.payload))
  );

  @Effect()
  LoadUnfinishedCalls$ = this.actions$.pipe(
    ofType(CallActionTypes.LoadUnfinishedCalls),
    combineLatest(
      this.store.pipe(
        select(getCurrentUser),
        filter(user => !!user)
      )
    ),
    switchMap(([action, user]: [LoadUnfinishedCalls, User]) => {
      return this.callService
        .all(
          {
            employee: user.id,
            state__in: 'ANSWERED,HOLD,HANG_UP_CUSTOMER,HANG_UP_EMPLOYEE'
          },
          { page: 1, page_size: 99 }
        )
        .pipe(
          map((data: PagebleEntity<Call>) => {
            return new LoadUnfinishedCallsSuccess(data);
          }),
          catchError(() => of(new LoadUnfinishedCallsFail()))
        );
    })
  );

  @Effect()
  socketCallUpdate$ = this.actions$.pipe(
    ofMessage(callUpdateMessageType),
    withLatestFrom(this.store.pipe(select(getActiveCall))),
    filter(
      ([message, call]: [any, Call]) =>
        call && message && message.data && message.data.id === call.id
    ),
    map(([message, _]: [any, Call]) => new LoadCall(message.data.id))
  );

  @Effect()
  loadCallDirections$ = this.actions$.pipe(
    ofType(CallActionTypes.LoadDirections),
    switchMap((action: LoadDirections) => {
      return this.callService.getDirections().pipe(
        map((data: CallDirection[]) => {
          return new LoadDirectionsSuccess(data);
        }),
        catchError(() => of(new LoadSubjectsFail()))
      );
    })
  );

  @Effect()
  loadCallSubjects$ = this.actions$.pipe(
    ofType(CallActionTypes.LoadSubjects),
    switchMap((action: LoadSubjects) => {
      return this.callService.getSubjects().pipe(
        map((data: CallSubject[]) => {
          return new LoadSubjectsSuccess(data);
        }),
        catchError(() => of(new LoadSubjectsFail()))
      );
    })
  );

  @Effect()
  loadCallActionReason$ = this.actions$.pipe(
    ofType(CallActionTypes.LoadActionReasons),
    switchMap((action: LoadSubjects) => {
      return this.callService.getActionReasons().pipe(
        map((data: CallActionReason[]) => {
          return new LoadActionReasonsSuccess(data);
        }),
        catchError(() => of(new LoadActionReasonsFail()))
      );
    })
  );

  @Effect()
  setFilter$ = this.actions$.pipe(
    ofType(CallActionTypes.SetCallsHistoryFilter),
    mapTo(new LoadCallsHistory({ isReload: true }))
  );

  @Effect()
  loadCallsHistoryDebounced$ = this.actions$.pipe(
    ofType(CallActionTypes.LoadCallsHistoryDebounced),
    withLatestFrom(this.store.pipe(select(isLoadingCallsHistory))),
    map(([action, isLoading]: [LoadCallsHistoryDebounced, boolean]) => {
      if (isLoading) {
        return new NoopAction();
      }
      return new LoadCallsHistory(action.payload);
    })
  );

  @Effect()
  loadCallsHistory$ = this.actions$.pipe(
    ofType(CallActionTypes.LoadCallsHistory),
    withLatestFrom(this.store.pipe(select(getCallsHistory))),
    map(([action, history]: [LoadCallsHistory, Call[]]) =>
      calculatePage(action, history.length)
    ),
    withLatestFrom(this.store.pipe(select(getCallsHistoryFilter))),
    switchMap(
      ([action, historyFilter]: [
        OutputPagebleAction<LoadCallsHistory>,
        CallsFilter
      ]) => {
        return this.callService.all(historyFilter, action.pageSettings).pipe(
          withLatestFrom(this.store.pipe(select(getCallsHistory))),
          map(([data, currentHistory]: [PagebleEntity<Call>, Call[]]) => {
            return new LoadCallsHistorySuccess(
              appendOrSetCollection(action, data, currentHistory)
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.errorHandler.handle(error.error);
            return of(new LoadCallsHistoryFail());
          })
        );
      }
    )
  );

  @Effect({ dispatch: false })
  loadUnfinishedSuccess$ = this.actions$.pipe(
    ofType(CallActionTypes.LoadUnfinishedCallsSuccess),
    withLatestFrom(this.store.select(getLastUnfinishedCall)),
    tap(([_, call]: [LoadUnfinishedCallsSuccess, Call]) => {
      if (call) {
        this.router.navigate(['/calls', call.id]);
      }
    })
  );

  @Effect({ dispatch: false })
  finishSuccess$ = this.actions$.pipe(
    ofType(CallActionTypes.FinishCallSuccess),
    withLatestFrom(this.store.select(getLastUnfinishedCall)),
    tap(([_, call]: [FinishCallSuccess, Call]) => {
      if (call) {
        this.router.navigate(['/calls', call.id]);
      } else {
        this.router.navigate(['/calls']);
      }
    })
  );

  @Effect()
  callToCustomer$ = this.actions$.pipe(
    ofType(CallActionTypes.CallToCustomer),
    switchMap((action: CallToCustomer) =>
      this.callService
        .callToCustomer(action.id)
        .pipe(map(call => new CallToCustomerSuccess(call, action.purpose)))
    )
  );

  @Effect()
  callToOrderRecipient$ = this.actions$.pipe(
    ofType(CallActionTypes.CallToOrderRecipient),
    switchMap((action: CallToOrderRecipient) =>
      this.callService
        .callToOrderRecipient(action.id)
        .pipe(
          map(call => new CallToOrderRecipientSuccess(call, action.purpose))
        )
    )
  );

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private actions$: Actions,
    private callService: CallService,
    private sipService: SipService,
    private errorHandler: ErrorHandlingService,
    private employeesService: EmployeesService
  ) {}
}
