import { createSelector } from '@ngrx/store';
import { getSocketStore, SocketState } from '../reducers/socket.reducer';

export const getConnectionState = createSelector(
  getSocketStore,
  (data: SocketState) => data.connectionState
);

export const getMessage = createSelector(
  getSocketStore,
  (data: SocketState) => data.message
)

export const getAutoRetryFlag = createSelector(
  getSocketStore,
  (data: SocketState) => data.autoRetry
)
