import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  withLatestFrom,
  combineLatest,
  concatMap,
  debounceTime,
  startWith,
} from 'rxjs/operators';
import { AppState } from '../../../../../store/reducers';
import { ErrorHandlingService } from '../../../../shared/services/error-handling/error-handling.service';
import {
  getPageSettingsFromUrl, INITIAL_PAGE_SETTINGS, OutputPagebleAction,
} from 'src/app/modules/shared/helpers/calculate-page';
import { PagebleEntity } from 'src/app/modules/shared/models/pageble-entity';
import { appendOrSetCollection } from 'src/app/modules/shared/helpers/append-or-set-collection';
import {
  LoadReturnDeliveriesFail,
  LoadReturnDeliveriesSuccess,
  LoadReturnDelivery,
  LoadReturnDeliveryFail,
  LoadReturnDeliverySuccess,
  ReturnDeliveryActionTypes
} from './return-delivery.actions';
import { getReturnDeliveries, getReturnDeliveriesFilter, getReturnDeliveriesNextPage } from './return-delivery.selector';
import { ReturnDelivery, ReturnDeliveryFilter } from '../../../models/return-delivery.model';
import { ReturnDeliveriesService } from '../../../services/return-deliveries/order-deliveries.service';
import {
  LoadOrderDeliveries,
} from '../order-delivery/order-delivery.actions';
import { ofMessage } from '../../../../shared/helpers/operators/ofMessage';
import {
  deliveryCreatedMessageType,
  deliveryUpdatedMessageType,
  OrderDeliveryUpdatedMessage
} from '../../../models/delivery-soket-message.model';

@Injectable()
export class ReturnDeliveryEffects {
  @Effect()
  loadReturnDeliveries$ = this.actions$.pipe(
    ofType(ReturnDeliveryActionTypes.LoadReturnDeliveries),
    combineLatest(
      this.store.pipe(select(getReturnDeliveriesFilter)),
      this.actions$.pipe(
        ofMessage(deliveryCreatedMessageType, deliveryUpdatedMessageType),
        startWith('null')
      ),
    ),
    debounceTime(20),
    map(([action, filter, deliveryMessage]) => [
      { originalAction: action, pageSettings: {} },
      filter,
      deliveryMessage,
    ]),
    withLatestFrom(this.store.pipe(select(getReturnDeliveriesNextPage))),
    switchMap(
      ([[action, filter, deliveryMessage], nextPage]: [
        [
          OutputPagebleAction<LoadOrderDeliveries>,
          ReturnDeliveryFilter,
          OrderDeliveryUpdatedMessage,
        ],
        string | null
      ]) => {
        return this.returnDeliveriesService
          .all(nextPage ? getPageSettingsFromUrl(nextPage) : INITIAL_PAGE_SETTINGS, filter)
          .pipe(
            withLatestFrom(this.store.pipe(select(getReturnDeliveries))),
            map(
              ([data, currentReturnDeliveries]: [
                PagebleEntity<ReturnDelivery>,
                ReturnDelivery[]
              ]) => {
                return new LoadReturnDeliveriesSuccess(
                  appendOrSetCollection(action, data, currentReturnDeliveries)
                );
              }
            ),
            catchError((error: HttpErrorResponse) => {
              this.errorHandler.handle(error.error);
              return of(new LoadReturnDeliveriesFail());
            })
          );
      }
    )
  );

  @Effect()
  loadOrderDelivery$ = this.actions$.pipe(
    ofType(ReturnDeliveryActionTypes.LoadReturnDelivery),
    concatMap((action: LoadReturnDelivery) =>
      this.returnDeliveriesService.one(action.id).pipe(
        map((data) => new LoadReturnDeliverySuccess(data)),
        catchError((error: HttpErrorResponse) =>
          of(new LoadReturnDeliveryFail())
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private errorHandler: ErrorHandlingService,
    private returnDeliveriesService: ReturnDeliveriesService
  ) {}
}
