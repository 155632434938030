import { Component, OnInit } from '@angular/core';
import { FilterValueAccessor, MakeProvider } from '../filter-value-accessor';
import { Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-segment-filter-checkbox',
  templateUrl: './segment-filter-checkbox.component.html',
  styleUrls: ['./segment-filter-checkbox.component.scss'],
  providers: [...MakeProvider(SegmentFilterCheckboxComponent)],
})
export class SegmentFilterCheckboxComponent extends FilterValueAccessor {
  operators: string[] = [];

  ngOnInit() {
    this.initForm();
    this.initSubscriptions();
    this.updateOperators();
  }

  private initForm() {
    this.form = this.fb.group({
      operator: [null],
      value: [false],
    });
  }

  private initSubscriptions() {
    this.subscriptions.add(
      this.form.valueChanges
        .pipe(filter(() => this.form.valid))
        .subscribe((values) => {
          this.pushValue({
            ...this.filter,
            ...values,
          });
        })
    );
  }

  updateOperators() {
    if (this.property && this.property.operators) {
      this.operators = this.property.operators;
    }
  }

  onWrite(values) {
    const formValues = {
      ...values,
      operator: values.operator || this.property.operators[0],
    };
    this.form.patchValue(formValues, { emitEvent: false });
  }
}
