<div class="panel">
  <div class="form-header d-flex">
    <h3>{{ 'Cutomer details' | translate }}</h3>
    <a class="icon-link ml-auto" (click)="isCollapsed = !isCollapsed"
      ><i class="material-icons">{{
        isCollapsed ? 'expand_more' : 'expand_less'
      }}</i></a
    >
  </div>
  <app-customer-form
    [hidden]="isCollapsed"
    [isEdit]="true"
    [customer]="customer$ | async"
    (onSumbitted)="save($event)"
    [title]="'Edit user' | translate"
  ></app-customer-form>
</div>
