import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { AppState } from '../../../../../../store/reducers';
import { PRODUCT_MODEL_NAME } from '../../../../../segments/models/segment';
import { SegmentProperty } from '../../../../../segments/models/segment-property';
import { getSegmentPropertiesByModel } from '../../../../../segments/store/selectors/segment-properties.selector';

@Component({
  selector: 'app-array-model-item-value',
  templateUrl: './model-array-item-value.component.html',
  styleUrls: ['./model-array-item-value.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModelArrayItemValueComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ModelArrayItemValueComponent),
      multi: true,
    },
  ],
})
export class ModelArrayItemValueComponent
  implements OnInit, ControlValueAccessor, Validator, OnDestroy
{
  @Input() modelName = PRODUCT_MODEL_NAME;
  @Input()
  submitted = false;

  get property(): SegmentProperty {
    return this._property;
  }

  @Input()
  set property(property: SegmentProperty) {
    this._property = property;
    const validators = [];
    if (property.is_required) {
      validators.push(Validators.required);
    }

    if (property.type === 'dict') {
      this.store
        .pipe(
          select(getSegmentPropertiesByModel(this.modelName)),
          map((properties) => properties.filter((p) => p.is_readable)),
          take(1)
        )
        .subscribe((data: SegmentProperty[]) => {
          const extIdProperty = data.find((it) => {
            return it.parent === property.id && it.path.endsWith('ext_id');
          });
          if (!extIdProperty) {
            return;
          }

          this.property = {
            ...extIdProperty,
            path: this.property.path,
            widget: 'enum',
          };
        });
    }

    this.fieldValue = new FormControl(null, validators);
    if (!property.is_editable) {
      this.fieldValue.disable();
    } else {
      this.fieldValue.enable();
    }
    if (this.currentValue) {
      this.fieldValue.patchValue(this.currentValue);
    }
    this.subscribeToForm();
  }

  private _property: SegmentProperty;
  @Input()
  hasRemoveButton = false;
  @Output()
  onRemoveClick = new EventEmitter();

  fieldValue: FormControl;
  subscriptions = new Subject();
  isChanging = true;
  disabled: boolean = false;
  currentValue;
  formSubscription;

  onChange = (_) => {};
  onTouched = () => {};

  constructor(private store: Store<AppState>) {}

  subscribeToForm() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    this.formSubscription = this.fieldValue.valueChanges
      .pipe(
        filter(() => this.fieldValue.valid),
        tap((data) => {
          this.onChange(data);
        }),
        filter(() => {
          return !this.isChanging;
        })
      )
      .subscribe((data) => {});
  }

  ngOnInit() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.fieldValue.valueChanges
      .pipe(takeUntil(this.subscriptions))
      .subscribe(fn);
  }

  registerOnValidatorChange(fn: () => void): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.fieldValue.disable();
    } else {
      this.fieldValue.enable();
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.fieldValue.errors;
  }

  writeValue(obj: any): void {
    this.currentValue = obj;
    if (this.currentValue && this.fieldValue) {
      this.fieldValue.patchValue(obj);
    }

    if (!isEqual(obj, this.fieldValue.value)) {
      this.isChanging = true;
      this.fieldValue.setValue(obj);
      this.isChanging = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
  }

  remove() {
    this.onRemoveClick.emit();
  }
}
