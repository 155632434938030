import { Action } from '@ngrx/store';
import {Dictionary, UpdateDictionaryData} from '../../models/dictionary';
import {FieldsError} from '../../../shared/models/http-error';

export const DICTIONARIES_LOAD = '[Dictionaries] load';
export const DICTIONARIES_LOAD_SUCCESS = '[Dictionaries] load success';
export const DICTIONARIES_LOAD_FAIL = '[Dictionaries] load fail';

export const DICTIONARY_LOAD = '[Dictionaries] dictionary load';
export const DICTIONARY_LOAD_SUCCESS = '[Dictionaries] dictionary load success';
export const DICTIONARY_LOAD_FAIL = '[Dictionaries] dictionary load fail';

export const DICTIONARY_UPDATE = '[Dictionaries] dictionary update';
export const DICTIONARY_UPDATE_SUCCESS = '[Dictionaries] dictionary update success';
export const DICTIONARY_UPDATE_FAIL = '[Dictionaries] dictionary update fail';

export const DICTIONARY_CREATE = '[Dictionaries] dictionary create';
export const DICTIONARY_CREATE_SUCCESS = '[Dictionaries] dictionary create success';
export const DICTIONARY_CREATE_FAIL = '[Dictionaries] dictionary create fail';

export class DictionariesLoad implements Action {
  readonly type = DICTIONARIES_LOAD;
}

export class DictionariesLoadSuccess implements Action {
  readonly type = DICTIONARIES_LOAD_SUCCESS;
  constructor(public payload: Dictionary[]) {}
}

export class DictionariesLoadFail implements Action {
  readonly type = DICTIONARIES_LOAD_FAIL;
}

export class DictionaryLoad implements Action {
  readonly type = DICTIONARY_LOAD;
  constructor(public payload: string) {}
}

export class DictionaryLoadSucccess implements Action {
  readonly type = DICTIONARY_LOAD_SUCCESS;
  constructor(public payload: Dictionary) {}
}

export class DictionaryLoadFail implements Action {
  readonly type = DICTIONARY_LOAD_FAIL;
}

export class DictionaryUpdate implements Action {
  readonly type = DICTIONARY_UPDATE;
  constructor(public payload: UpdateDictionaryData, public isRevert = false) {}
}

export class DictionaryUpdateSuccess implements Action {
  readonly type = DICTIONARY_UPDATE_SUCCESS;
  constructor(public payload: Dictionary) {}
}

export class DictionaryUpdateFail implements Action {
  readonly type = DICTIONARY_UPDATE_FAIL;
  constructor(public payload: FieldsError) {}
}

export class DictionaryCreate implements Action {
  readonly type = DICTIONARY_CREATE;
  constructor(public payload: UpdateDictionaryData) {}
}

export class DictionaryCreateSuccess implements Action {
  readonly type = DICTIONARY_CREATE_SUCCESS;
  constructor(public payload: Dictionary) {}
}

export class DictionaryCreateFail implements Action {
  readonly type = DICTIONARY_CREATE_FAIL;
  constructor(public payload: FieldsError) {}
}

export type DictionaryActions =
  | DictionariesLoad
  | DictionariesLoadSuccess
  | DictionariesLoadFail
  | DictionaryLoad
  | DictionaryLoadSucccess
  | DictionaryLoadFail
  | DictionaryUpdate
  | DictionaryUpdateSuccess
  | DictionaryUpdateFail
  | DictionaryCreate
  | DictionaryCreateSuccess
  | DictionaryCreateFail
;
