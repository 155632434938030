<div class="form-group row" *ngIf="property$ | async as property">
  <label class="col-sm-3 col-form-label">
    {{ propertyNames[property.name] || property.name | translate }}
    <span class="count text-black-50 small ml-3">{{ currentValue.count }}</span>

    <a
      class="icon-link exclude-icon"
      [class.active]="exclude"
      [tooltip]="'Exclude' | translate"
      (click)="toggleExclude()"
    >
      <i class="material-icons">do_not_touch</i>
    </a>
  </label>
  <div class="col flex-grow-1">
    <ng-container [ngSwitch]="property.widget">
      <app-segment-filter-enum
        *ngSwitchCase="'enum'"
        [property]="property"
        [formControl]="filter"
      >
      </app-segment-filter-enum>

      <app-segment-filter-date
        *ngSwitchCase="'date'"
        [property]="property"
        [formControl]="filter"
      >
      </app-segment-filter-date>

      <app-segment-filter-datetime
        *ngSwitchCase="'datetime'"
        [property]="property"
        [formControl]="filter"
      >
      </app-segment-filter-datetime>

      <app-segment-filter-number
        *ngSwitchCase="'number'"
        [property]="property"
        [formControl]="filter"
      >
      </app-segment-filter-number>

      <app-segment-filter-number
        *ngSwitchCase="'amount'"
        [property]="property"
        [formControl]="filter"
      >
      </app-segment-filter-number>

      <app-segment-filter-remote-model
        *ngSwitchCase="'remote_model'"
        [property]="property"
        [formControl]="filter"
      >
      </app-segment-filter-remote-model>

      <app-segment-filter-checkbox
        *ngSwitchCase="'checkbox'"
        [property]="property"
        [formControl]="filter"
      >
      </app-segment-filter-checkbox>

      <app-segment-filter-string
        *ngSwitchDefault
        [property]="property"
        [formControl]="filter"
      >
      </app-segment-filter-string>
    </ng-container>
  </div>

  <div class="remove-col">
    <a class="icon-link" [class.disabled]="disabled" (click)="remove()">
      <i class="material-icons">delete_outline</i>
    </a>
  </div>

  <div class="save-col" *ngIf="isEdit">
    <a
      *ngIf="!currentValue._isLoading; else loader"
      class="icon-link"
      [class.disabled]="isSaveDisabled()"
      (click)="save()"
    >
      <i class="material-icons">done_outline</i>
    </a>
  </div>
</div>

<ng-template #loader>
  <app-loader class="sm"></app-loader>
</ng-template>
