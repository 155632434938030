import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../../store/reducers';
import { appendOrSetCollection } from '../../shared/helpers/append-or-set-collection';
import { calculatePage, OutputPagebleAction } from '../../shared/helpers/calculate-page';
import { PagebleEntity } from '../../shared/models/pageble-entity';
import { ErrorHandlingService } from '../../shared/services/error-handling/error-handling.service';
import { Department, DepartmentsFilter } from '../models/department.model';
import { DepartmentsService } from '../services/departments.service';
import { DepartmentActionTypes, LoadDepartmentFail, LoadDepartments, LoadDepartmentsSuccess } from './department.actions';
import { getDepartments, getDepartmentsFilter } from './department.selector';


@Injectable()
export class DepartmentEffects {

  @Effect()
  setFilter$ = this.actions$.pipe(
    ofType(DepartmentActionTypes.SetFilterDepartments),
    mapTo(new LoadDepartments({isReload: true}))
  );

  @Effect()
  loadDepartments$ = this.actions$.pipe(
    ofType(DepartmentActionTypes.LoadDepartments),
    withLatestFrom(this.store.pipe(select(getDepartments))),
    map(([action, departments]: [LoadDepartments, Department[]]) => calculatePage(action, departments.length)),
    withLatestFrom(this.store.pipe(select(getDepartmentsFilter))),
    switchMap(
      ([action, departmentsFilter]: [OutputPagebleAction<LoadDepartments>, DepartmentsFilter]) =>
        this.departmentService.all(departmentsFilter, action.pageSettings).pipe(
          withLatestFrom(this.store.pipe(select(getDepartments))),
          map(([data, currentDepartments]: [PagebleEntity<Department>, Department[]]) =>
            new LoadDepartmentsSuccess(appendOrSetCollection(action, data, currentDepartments))),
          catchError((error: HttpErrorResponse) => {
            this.errorHandler.handle(error.error);
            return of(new LoadDepartmentFail());
          })
        )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private departmentService: DepartmentsService,
    private errorHandler: ErrorHandlingService
  ) {
  }
}
