import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromUser from '../actions/user.action';
import { UserService } from '../../services/user/user.service';
import {
  catchError,
  filter,
  map,
  mapTo,
  switchMap,
  tap,
  withLatestFrom,
  combineLatest,
  take,
  debounceTime
} from 'rxjs/operators';
import { of } from 'rxjs';
import { CURRENT_COMPANY_KEY } from '../../models/companies';
import { LOAD_USER_SUCCESS, LoadUser } from '../actions/user.action';
import { ProfileService } from '../../../profile/services/proifle/profile.service';
import { ofMessage } from '../../helpers/operators/ofMessage';
import {
  UserUpdatedMessage,
  userUpdatedMessageType
} from '../../models/messages/user-updated.message';
import { AppState } from '../../../../store/reducers';
import { select, Store } from '@ngrx/store';
import { getProfile } from '../../../profile/store/reducers/profile.reducer';
import { getCurrentUser } from '../selectors/user.selector';
import { User } from '../../models/user';
import {
  employeeUpdateMessageType,
  EmployeeUpdatedMessage
} from '../../models/messages/employee-updated.message';

@Injectable()
export class UserEffect {
  constructor(
    private action$: Actions,
    private userService: UserService,
    private profileService: ProfileService,
    private store: Store<AppState>
  ) {}

  @Effect()
  loadUser$ = this.action$.pipe(
    ofType(fromUser.LOAD_USER),
    debounceTime(1000),
    switchMap((action: fromUser.LoadUser) => {
      return this.userService.getUser().pipe(
        map(data => new fromUser.LoadUserSuccess(data)),
        catchError(error => {
          return of(new fromUser.LoadUserFail(error.error));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  afterLoadUser$ = this.action$.pipe(
    ofType(LOAD_USER_SUCCESS),
    tap(() => this.profileService.initLanguages())
  );

  @Effect()
  loadCompanies$ = this.action$.pipe(
    ofType(fromUser.LOAD_COMPANIES),
    switchMap((action: fromUser.LoadCompanies) => {
      return this.userService.getCompanies().pipe(
        map(data => new fromUser.LoadCompaniesSuccess(data)),
        catchError(error => {
          return of(new fromUser.LoadCompaniesFail(error.error));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  setCurrentCompany$ = this.action$.pipe(
    ofType(fromUser.SET_CURRENT_COMPANY),
    tap((action: fromUser.SetCurrentCompany) => {
      localStorage.setItem(CURRENT_COMPANY_KEY, action.id);
      if (action.reload) {
        location.reload();
      }
    })
  );

  @Effect()
  websocketUpdateUser$ = this.action$.pipe(
    ofMessage(userUpdatedMessageType),
    withLatestFrom(this.store.pipe(select(getCurrentUser))),
    filter(
      ([message, user]: [UserUpdatedMessage, User]) =>
        message.data.id === user.id
    ),
    mapTo(new LoadUser())
  );

  @Effect()
  websocketUpdateEmployee$ = this.action$.pipe(
    ofMessage(employeeUpdateMessageType),
    combineLatest(
      this.store.pipe(
        select(getCurrentUser),
        filter(user => !!user),
        take(1)
      )
    ),
    filter(
      ([message, user]: [EmployeeUpdatedMessage, User]) =>
        user && message.data.id === user.id
    ),
    mapTo(new LoadUser())
  );
}
