import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanDeactivate,
  Router,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppState } from '../../../../store/reducers';
import { Store } from '@ngrx/store';
import { take, map } from 'rxjs/operators';

import { _ } from '../../../shared/helpers/translation-marker';
import { ConfirmationDialogService } from '../../../shared/services/confirmation-dialog/confirmation-dialog.service';
import { SegmentComponent } from '../../pages/segment/segment.component';

@Injectable({
  providedIn: 'root'
})
export class SegmentLeaveGuard implements CanDeactivate<SegmentComponent> {
  constructor(private store: Store<AppState>, private router: Router, private confirmationDialog: ConfirmationDialogService) {}

  canDeactivate(
    component: SegmentComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!component.showConfirmationOnNavigation) {
      return true;
    }
    return this.confirmationDialog
      .confirmV1(_('Do you really want to leave without saving?'), _('Changes not saved yet'), _('Yes'), _('No'))
      .pipe(
        take(1),
        map(accept => !!accept)
      );
  }
}
