import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/store/reducers';
import { appendOrSetCollection } from '../../../../shared/helpers/append-or-set-collection';
import {
  calculatePage,
  OutputPagebleAction,
} from '../../../../shared/helpers/calculate-page';
import { PagebleEntity } from '../../../../shared/models/pageble-entity';
import { ErrorHandlingService } from '../../../../shared/services/error-handling/error-handling.service';
import { Courier } from '../../../models/courier.model';
import { CourierOrder } from '../../../models/order.model';
import { CourierService } from '../../../services/courier/courier.service';
import {
  CourierActionTypes,
  LoadCourierOrders,
  LoadCourierOrdersFail,
  LoadCourierOrdersSuccess,
  LoadCouriers,
  LoadCouriersFail,
  LoadCouriersSuccess,
  LoadDeliveryRegions,
  LoadDeliveryRegionsSuccess,
  LoadRegionOutlets,
  LoadRegionOutletsSuccess,
  LoadRegionOutletsFail,
} from './courier.actions';
import {
  getCourierOrders,
  getCourierOrdersFilter,
  getCouriers,
} from './courier.selector';

@Injectable()
export class CourierEffects {
  @Effect()
  loadCouriers$ = this.actions$.pipe(
    ofType(CourierActionTypes.LoadCouriers),
    withLatestFrom(this.store.pipe(select(getCouriers))),
    map(([action, orders]: [LoadCouriers, Courier[]]) =>
      calculatePage(action, orders.length)
    ),
    switchMap((action: OutputPagebleAction<LoadCouriers>) => {
      return this.courierService
        .getCouriers(action.pageSettings, action.originalAction.filter)
        .pipe(
          withLatestFrom(this.store.pipe(select(getCouriers))),
          map(
            ([data, currentCouriers]: [PagebleEntity<Courier>, Courier[]]) => {
              return new LoadCouriersSuccess(
                appendOrSetCollection(action, data, currentCouriers)
              );
            }
          ),
          catchError((error: HttpErrorResponse) => {
            this.errorHandler.handle(error.error);
            return of(new LoadCouriersFail());
          })
        );
    })
  );

  @Effect()
  loadOrders$ = this.actions$.pipe(
    ofType(CourierActionTypes.LoadCourierOrders),
    withLatestFrom(this.store.pipe(select(getCourierOrders))),
    map(([action, orders]: [LoadCourierOrders, CourierOrder[]]) =>
      calculatePage(action, orders.length)
    ),
    withLatestFrom(this.store.pipe(select(getCourierOrdersFilter))),
    switchMap(
      ([action, filter]: [OutputPagebleAction<LoadCourierOrders>, any]) => {
        return this.courierService.getOrders(action.pageSettings, filter).pipe(
          withLatestFrom(this.store.pipe(select(getCourierOrders))),
          map(
            ([data, currentOrders]: [
              PagebleEntity<CourierOrder>,
              CourierOrder[]
            ]) => {
              return new LoadCourierOrdersSuccess(
                appendOrSetCollection(action, data, currentOrders)
              );
            }
          ),
          catchError((error: HttpErrorResponse) => {
            this.errorHandler.handle(error.error);
            return of(new LoadCourierOrdersFail());
          })
        );
      }
    )
  );

  @Effect()
  loadDeliveryRegions$ = this.actions$.pipe(
    ofType(CourierActionTypes.LoadDeliveryRegions),
    switchMap((action: LoadDeliveryRegions) =>
      this.courierService.getDeliveryRegions(action.params).pipe(
        map((regions) => new LoadDeliveryRegionsSuccess(regions)),
        catchError((errors) => {
          this.errorHandler.handle(errors.error);
          return of(new LoadRegionOutletsFail());
        })
      )
    )
  );

  @Effect()
  loadRegionOutlets$ = this.actions$.pipe(
    ofType(CourierActionTypes.LoadRegionOutlets),
    switchMap((action: LoadRegionOutlets) =>
      this.courierService.getRegionOutlets(action.region).pipe(
        map((data) => new LoadRegionOutletsSuccess(data.results)),
        catchError((errors) => {
          this.errorHandler.handle(errors);
          return of(new LoadRegionOutletsFail());
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private errorHandler: ErrorHandlingService,
    private courierService: CourierService
  ) {}
}
