<div [formGroup]='form'>
  <div class='row'>
    <div class='col col-3'>
      <ng-select class='ng-select-autowidth ng-select-prepend select-filter-operator'
                 [items]='operators'
                 formControlName='dateOperator'
                 bindValue='value'
                 [clearable]='false'
                 [searchable]='false'
                 [placeholder]='"select" | translate'>
        <ng-template ng-label-tmp
                     ng-option-tmp
                     let-item="item">
          <app-operator-option [item]="item"></app-operator-option>
        </ng-template>
      </ng-select>
      <app-errors-component class='mt-1 ml-2'
                            [control]='form.get("dateOperator")'></app-errors-component>
    </div>
    <div class='col'>
      <div class='position-relative'>
        <app-datepicker
          [placeholder]="'from' | translate"
          [maxDate]="form.get('end').value"
          formControlName="start"
          additionalClass="form-control middle-input"
        ></app-datepicker>
        <a *ngIf='form.get("start").value'
           class='input-clear icon-link'
           (click)='onClearStart()'>
          <i class='material-icons'>close</i>
        </a>
      </div>
    </div>
    <div class='col col-2'>
      <input formControlName='startTime'
             class='form-control middle-input'
             placeholder='00:00'
             [textMask]='timeMask'>
    </div>
    <div class='col'>
      <div class='position-relative'>
        <app-datepicker
          [placeholder]="'to' | translate"
          [minDate]="form.get('start').value"
          formControlName="end"
          additionalClass="form-control middle-input"
        ></app-datepicker>
        <a *ngIf='form.get("end").value'
           class='input-clear icon-link'
           (click)='onClearEnd()'>
          <i class='material-icons'>close</i>
        </a>
      </div>
    </div>
    <div class='col col-2'>
      <input formControlName='endTime'
             class='form-control last-input'
             placeholder='00:00'
             [textMask]='timeMask'>
    </div>
  </div>
  <app-errors-component class='mt-1 ml-2'
                        [control]='form'></app-errors-component>
</div>