import { Component, OnInit, OnDestroy, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {
  Subject,
  Observable,
  Subscription,
  BehaviorSubject,
  combineLatest
} from 'rxjs';
import { CustomersService } from '../../services/customers.service';
import {
  pluck,
  switchMap,
  tap,
  filter,
  debounceTime,
  startWith,
  scan,
  distinctUntilChanged
} from 'rxjs/operators';
import { isEqual } from 'lodash';
import { Customer } from '../../models/customer';

@Component({
  selector: 'app-customers-finder',
  templateUrl: './customers-finder.component.html',
  styleUrls: ['./customers-finder.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomersFinderComponent),
      multi: true
    }
  ]
})
export class CustomersFinderComponent
  implements OnDestroy, ControlValueAccessor {
  currentValue = new FormControl();
  modelTypeahead$ = new Subject<string>();
  isModelsLoading = false;
  models$: Observable<any>;
  modelsLoad$: Observable<any>;
  subscriptions = new Subscription();
  operators: string[] = [];
  countLoaded: number;
  nextPage = 0;
  fetchMore$ = new BehaviorSubject('');
  hasNextPage = true;
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(protected customersService: CustomersService) {
    this.modelsLoad$ = combineLatest(
      this.modelTypeahead$.pipe(
        startWith(''),
        tap(() => {
          this.nextPage = 0;
          this.hasNextPage = true;
        })
      ),
      this.fetchMore$.pipe(
        filter(_ => !this.isModelsLoading && this.hasNextPage)
      )
    ).pipe(
      debounceTime(100),
      tap(() => (this.isModelsLoading = true)),
      switchMap(([val, _]) => {
        this.nextPage++;
        return this.customersService
          .filter({ search: val, page: this.nextPage })
          .pipe(
            tap(res => (this.hasNextPage = !!res.next)),
            pluck('results')
          );
      })
    );

    this.models$ = this.modelsLoad$.pipe(
      scan((previous, response) => {
        this.isModelsLoading = false;
        const result =
          this.nextPage <= 1 ? response : previous.concat(response);
        this.countLoaded = result.length;

        return result;
      }, [])
    );

    this.subscriptions.add(
      this.currentValue.valueChanges
        .pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
        .subscribe(v => this.onChanged(v))
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  writeValue(obj: any): void {
    this.currentValue.setValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.currentValue.disable();
    } else {
      this.currentValue.enable();
    }
  }

  onChanged(value: Customer) {
    if (this.onChange) {
      this.onChange(value);
    }

    if (this.onTouched) {
      this.onTouched();
    }
  }

  fetchMore() {
    if (this.isModelsLoading) {
      return;
    }
    this.fetchMore$.next('');
  }

  scroll({ end }) {
    if (this.isModelsLoading) {
      return;
    }
    if (end + 10 >= this.countLoaded) {
      this.fetchMore$.next('');
    }
  }
}
