import { Action } from '@ngrx/store';

export const SOCKET_CONNECT = '[Socket] Connect';
export const SOCKET_CONNECT_SUCCESS = '[Socket] Connect success';
export const SOCKET_CONNECT_FAIL = '[Socket] Connect fail';
export const SOCKET_DISCONNECT = '[Socket] Disconnect';
export const SEND_MESSAGE = '[Socket] Send message';
export const RECEIVE_MESSAGE = '[Socket] Receive message';
export const SET_RETRY_FLAG = '[Socket] Set retry flag';
export const SEND_PING = '[Socket] Send ping';

export interface IncomingSocketMessage {
  type: string;
}

export class SocketConnect implements Action {
  readonly type = SOCKET_CONNECT;
}

export class SocketConnectSuccess implements Action {
  readonly type = SOCKET_CONNECT_SUCCESS;
}

export class SocketConnectFail implements Action {
  readonly type = SOCKET_CONNECT_FAIL;
}

export class SendMessage implements Action {
  readonly type = SEND_MESSAGE;
  constructor(public payload: any) {}
}

export class ReceiveMessage<T extends IncomingSocketMessage = any> implements Action {
  readonly type = RECEIVE_MESSAGE;
  constructor(public payload: T) {}
}

export class SocketDisconnect implements Action {
  readonly type = SOCKET_DISCONNECT;
}

export class SetRetryFlag implements Action {
  readonly type = SET_RETRY_FLAG;
  constructor(public payload: boolean) {}
}

export class SendPing implements Action {
  readonly type = SEND_PING;
}

export type SocketAction =
  | SocketConnect
  | SocketConnectSuccess
  | SocketConnectFail
  | SendMessage
  | ReceiveMessage
  | SocketDisconnect
  | SetRetryFlag
  | SendPing
;
