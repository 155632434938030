import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/reducers';
import {
  getCurrentUser,
  getCurrentCompany,
  getUserCompanies
} from '../../store/selectors/user.selector';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Company } from '../../models/companies';
import { SetCurrentCompany } from '../../store/actions/user.action';
import { SendMessage } from 'src/app/store/actions/socket.action';

@Component({
  selector: 'app-user-bar',
  templateUrl: './user-bar.component.html',
  styleUrls: ['./user-bar.component.scss']
})
export class UserBarComponent implements OnInit {
  user$: Observable<User>;
  company$: Observable<Company>;
  companies$: Observable<Company[]>;
  constructor(
    private store: Store<AppState>,
    private auth: AuthenticationService
  ) {}

  ngOnInit() {
    this.user$ = this.store.select(getCurrentUser);
    this.company$ = this.store.select(getCurrentCompany);
    this.companies$ = this.store.select(getUserCompanies);
  }

  logout() {
    this.store.dispatch(new SendMessage({ type: 'logout' })); // @TODO: place somewhere in better place
    this.auth.logout();
  }

  selectCompany(company: string) {
    this.store.dispatch(new SetCurrentCompany(company));
    return false;
  }
}
