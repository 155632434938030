import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  PaymentActionTypes,
  LoadPayments,
  LoadPaymentsSuccess,
  LoadPaymentsFail,
  LoadPayment,
  LoadPaymentFail,
  LoadPaymentSuccess,
  LoadPaymentsDebounced,
  RefreshPayment,
  RefreshPaymentSuccess,
  RefreshPaymentFail,
} from './payment.actions';
import {
  switchMap,
  withLatestFrom,
  map,
  catchError,
  mapTo
} from 'rxjs/operators';
import { PaymentsService } from '../../services/payments/payments.service';
import { AppState } from 'src/app/store/reducers';
import { Store, select } from '@ngrx/store';
import { getPageSettingsFromUrl, INITIAL_PAGE_SETTINGS } from '../../../shared/helpers/calculate-page';
import { Payment, PaymentsFilter } from '../../models/payment.model';
import { PagebleEntity } from '../../../shared/models/pageble-entity';
import { getPayments, getPaymentsFilter, getPaymentsNextPage, isLoadingPayments } from './payment.selector';
import { appendOrSetCollection } from '../../../shared/helpers/append-or-set-collection';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlingService } from '../../../shared/services/error-handling/error-handling.service';
import { of } from 'rxjs';
import { NoopAction } from 'src/app/modules/shared/store/actions/user.action';

@Injectable()
export class PaymentEffects {
  @Effect()
  loadPaymentsDebounced$ = this.actions$.pipe(
    ofType(PaymentActionTypes.LoadPaymentsDebounced),
    withLatestFrom(this.store.pipe(select(isLoadingPayments))),
    map(([action, isLoading]: [LoadPaymentsDebounced, boolean]) => {
      if (isLoading) {
        return new NoopAction();
      }
      return new LoadPayments(action.payload);
    })
  );

  @Effect()
  loadPayments$ = this.actions$.pipe(
    ofType(PaymentActionTypes.LoadPayments),
    map((action) => ({ originalAction: action, pageSettings: {} })),
    withLatestFrom(this.store.pipe(select(getPaymentsFilter)), this.store.pipe(select(getPaymentsNextPage))),
    switchMap(
      ([action, filter, nextPage]: [
        any,
        PaymentsFilter,
        string | null
      ]) => {
        return this.paymentsService.getList(nextPage ? getPageSettingsFromUrl(nextPage) : INITIAL_PAGE_SETTINGS, filter).pipe(
          withLatestFrom(this.store.pipe(select(getPayments))),
          map(([data, currentPayment]: [PagebleEntity<Payment>, Payment[]]) => {
            return new LoadPaymentsSuccess(
              appendOrSetCollection(action, data, currentPayment)
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.errorHandler.handle(error.error);
            return of(new LoadPaymentsFail());
          })
        );
      }
    )
  );

  @Effect()
  loadPayment$ = this.actions$.pipe(
    ofType(PaymentActionTypes.LoadPayment),
    switchMap((action: LoadPayment) => {
      return this.paymentsService.get(action.id).pipe(
        map(data => new LoadPaymentSuccess(data)),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handle(error.error);
          return of(new LoadPaymentFail());
        })
      );
    })
  );

  @Effect()
  refreshPayment$ = this.actions$.pipe(
    ofType(PaymentActionTypes.RefreshPayment),
    switchMap((action: RefreshPayment) => {
      return this.paymentsService.refresh(action.id).pipe(
        map(data => new RefreshPaymentSuccess(data)),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handle(error.error);
          return of(new RefreshPaymentFail());
        })
      );
    })
  );

  @Effect()
  setFilter$ = this.actions$.pipe(
    ofType(PaymentActionTypes.SetPaymentsFilter),
    mapTo(new LoadPayments())
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private paymentsService: PaymentsService,
    private errorHandler: ErrorHandlingService
  ) {}
}
