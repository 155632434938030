<div class="wrap">
<app-segment-form
  [label]="title | translate"
  [segment]="newSegment"
  (saved)="create($event)"
  [isEdit]="false"
  [currentSegmentModelLabel]="segmentModel"
  (cancelClick)="onCancelClick()"
></app-segment-form>
</div>
