import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { backendErrors } from '../../services/error-handling/error-handling.service';
import * as moment from 'moment';
import { dataFormatsConfig } from '../../config/data-formats.config';

@Component({
  selector: 'app-errors-component',
  templateUrl: './errors-component.component.html',
  styleUrls: ['./errors-component.component.scss'],
})
export class ErrorsComponentComponent implements OnInit {
  @Input() control: FormControl;
  @Input() submitted: boolean;
  backendErrors = backendErrors;
  constructor() {}

  ngOnInit() {}

  formDate(date: Date) {
    return moment(date).format(dataFormatsConfig.dateInputFormat);
  }

  isPhonePattern() {
    return this.control?.errors?.pattern?.requiredPattern === '/\\(\\d{3}\\)\\d{3}-\\d{4}/';
  }
}
