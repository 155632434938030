import { ViewChild, ElementRef, Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../../store/reducers';
@Directive()
export abstract class InfiniteScrollableComponent<T> {
  @ViewChild('infiniteScrollContainer')
  public scrollContainer: ElementRef;
  private _scrollableElement: ElementRef;
  @ViewChild('infiniteScrollableElement')
  public set scrollableElement(element: ElementRef) {
    this._scrollableElement = element;
    this.getMoreDataIfNeeded();
  }
  public get scrollableElement(): ElementRef {
    return this._scrollableElement;
  }
  data$: Observable<T[]>;
  loading$: Observable<boolean>;
  reloading$: Observable<boolean>;
  loadedCount: number;
  totalCount: number;
  hasNextPage: boolean;

  constructor(protected store: Store<AppState>) {
    this.registerPipes();
  }

  protected abstract registerPipes();
  protected abstract getData();

  private getMoreDataIfNeeded() {
    if (this.scrollContainer !== undefined && this.scrollableElement !== undefined &&
      this.scrollContainer.nativeElement.clientHeight >= this.scrollableElement.nativeElement.scrollHeight) {
      this.getData();
    }
  }

  trackByFn(index, item) {
    return item.id;
  }

  onScroll() {
    this.getData();
  }

  scrollToTop() {
    this.scrollContainer?.nativeElement?.scrollTo(0, 0);
  }
}
