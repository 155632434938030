import { Actions, Effect, ofType } from '@ngrx/effects';
import { SegmentPropertiesService } from '../../service/segment-properties/segment-properties.service';
import {
  SEGMENT_PROPERTIES_LOAD,
  SegmentPropertiesLoadFail,
  SegmentPropertiesLoadSuccess
} from '../actions/segment-properties.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class SegmentPropertiesEffect {
  constructor(
    private actions$: Actions,
    private segmentPropertiesService: SegmentPropertiesService
  ) {}

  @Effect()
  load$ = this.actions$.pipe(
    ofType(SEGMENT_PROPERTIES_LOAD),
    switchMap(() => {
      return this.segmentPropertiesService.all().pipe(
        map(data => new SegmentPropertiesLoadSuccess(data)),
        catchError(() => of(new SegmentPropertiesLoadFail()))
      );
    })
  );
}
