<div class="modal-header" *ngIf="title">
  <h4 class="modal-title pull-left">{{ title | translate }}</h4>
  <button type="button" *ngIf="!hideCloseIcon" class="close pull-right" aria-label="Close" (click)="decline()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-left">
  <p>{{ message | translate }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary flex-fill mr-2" (click)="decline()">
    {{ declineButtonText | translate }}
  </button>
  <button type="button" class="btn btn-primary flex-fill ml-2" (click)="accept()">
    {{ confirmButtonText | translate }}
  </button>
</div>
