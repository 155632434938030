import { Injectable } from '@angular/core';
import { UpdateProfile } from '../../models/update-profile';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../../../shared/models/user';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith, take } from 'rxjs/operators';
import { AuthenticationService } from '../../../shared/services/authentication/authentication.service';
import { getCurrentUser } from '../../../shared/store/selectors/user.selector';
import { AppState } from '../../../../store/reducers';
import { Store } from '@ngrx/store';

export interface LanguageItem {
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  static CURRENT_LANGUAGE_FIELD = 'currentLanguage';
  private readonly basePath = 'auth/'
  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private store: Store<AppState>
  ) { }

  update(profile: UpdateProfile): Observable<User> {
    return this.http.patch<User>(this.basePath + 'user/', profile);
  }

  setPassword(password: string): Observable<any> {
    return this.http.post(this.basePath + 'password/change/', {new_password1: password, new_password2: password});
  }

  initLanguages() {
    this.getCurrentLanguage().pipe(take(1)).subscribe(lang => this.translateService.use(lang));
  }

  detectLanguage(): string {
    const browserLang = this.translateService.getBrowserLang();
    return browserLang.match(/ru/i) ? 'ru' : 'en';
  }

  getCurrentLanguage(): Observable<string> {
    return this.store.select(getCurrentUser).pipe(
      map(user => {
        let lang = user.language;
        if (!lang) {
          lang = this.detectLanguage();
          this.setCurrentLanguage(lang);
        }

        return lang;
      })
    );
  }

  setCurrentLanguage(lang: string) {
    this.translateService.use(lang);
  }

  availableLanguages(): Observable<LanguageItem[]> {
    return this.translateService.onLangChange.pipe(
      startWith({lang: this.getCurrentLanguage()}),
      map(() => {
        return [
          {name: this.translateService.instant('English'), value: 'en'},
          {name: this.translateService.instant('Russian'), value: 'ru'}
        ];
      })
    );
  }
}
