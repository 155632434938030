import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {AppState} from '../../../../store/reducers';
import {Store} from '@ngrx/store';
import {DictionariesLoad} from '../../store/dictionary/dictionary.actions';

@Injectable({
  providedIn: 'root'
})
export class LoadDictionariesGuard implements CanActivate {
  constructor(private store: Store<AppState>) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(new DictionariesLoad());
    return true;
  }
}
