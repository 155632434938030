import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mapTo,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { AppState } from '../../../store/reducers';
import { appendOrSetCollection } from '../../shared/helpers/append-or-set-collection';
import {
  calculatePage,
  OutputPagebleAction
} from '../../shared/helpers/calculate-page';
import { PagebleEntity } from '../../shared/models/pageble-entity';
import { ErrorHandlingService } from '../../shared/services/error-handling/error-handling.service';
import { EmployeeActionType } from '../models/employee-action-types.model';
import { Employee, EmployeesFilter } from '../models/employee.model';
import { EmployeesService } from '../services/employees/employees.service';
import {
  EmployeeActionTypes,
  LoadEmployeeActionTypes,
  LoadEmployeeActionTypesSuccess,
  LoadEmployees,
  LoadEmployeesFail,
  LoadEmployeesSuccess,
  LoadEmployeeActionTypesFail,
  LoadEmployeesDebounced
} from './employee.actions';
import { getEmployees, getEmployeesFilter, isLoadingEmployees } from './employee.selector';
import { NoopAction } from '../../shared/store/actions/user.action';

@Injectable()
export class EmployeeEffects {

  @Effect()
  loadActionTypes$ = this.actions$.pipe(
    ofType(EmployeeActionTypes.LoadEmployeeActionTypes),
    switchMap((action: LoadEmployeeActionTypes) =>
      this.employeeService.allActionTypes().pipe(
        map(
          (response: PagebleEntity<EmployeeActionType>) =>
            new LoadEmployeeActionTypesSuccess(response.results)
        ),
        catchError(error => {
          this.errorHandler.handle(error.error);
          return of(new LoadEmployeeActionTypesFail());
        })
      )
    )
  );

  @Effect()
  setFilter$ = this.actions$.pipe(
    ofType(EmployeeActionTypes.EmployeesSetFilter),
    mapTo(new LoadEmployees({ isReload: true }))
  );

  @Effect()
  loadEmployeesDebounced$ = this.actions$.pipe(
    ofType(EmployeeActionTypes.LoadEmployeesDebounced),
    withLatestFrom(this.store.pipe(select(isLoadingEmployees))),
    map(([action, isLoading]: [LoadEmployeesDebounced, boolean]) => {
      if (isLoading) {
        return new NoopAction();
      }
      return new LoadEmployees(action.payload);
    })
  );


  @Effect()
  loadEmployees$ = this.actions$.pipe(
    ofType(EmployeeActionTypes.LoadEmployees),
    withLatestFrom(this.store.pipe(select(getEmployees))),
    map(([action, employees]: [LoadEmployees, Employee[]]) =>
      calculatePage(action, employees.length)
    ),
    withLatestFrom(this.store.pipe(select(getEmployeesFilter))),
    switchMap(
      ([action, employeesFilter]: [
        OutputPagebleAction<LoadEmployees>,
        EmployeesFilter
      ]) => {
        return this.employeeService
          .all(employeesFilter, action.pageSettings)
          .pipe(
            withLatestFrom(this.store.pipe(select(getEmployees))),
            map(
              ([data, currentEmployees]: [
                PagebleEntity<Employee>,
                Employee[]
              ]) => {
                return new LoadEmployeesSuccess(
                  appendOrSetCollection(action, data, currentEmployees)
                );
              }
            ),
            catchError((error: HttpErrorResponse) => {
              this.errorHandler.handle(error.error);
              return of(new LoadEmployeesFail());
            })
          );
      }
    )
  );

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private employeeService: EmployeesService,
    private errorHandler: ErrorHandlingService
  ) {}
}
