<ng-select
  [items]="models$ | async"
  bindLabel="value"
  [formControl]="currentValue"
  [addTag]="true"
  [hideSelected]="true"
  [loading]="isModelsLoading"
  [typeahead]="modelTypeahead$"
  [placeholder]="placeholder | translate"
  (change)="changed($event)"
>
  <ng-template ng-typetosearch-tmp>
    <div class="ng-option disabled text-black-50">
      {{ "Start typing" | translate }}...
    </div>
  </ng-template>
  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
    <div class="ng-option disabled text-black-50">
      {{ "No address found" | translate }}
    </div>
  </ng-template>
  <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
    <div class="ng-option disabled text-black-50">
      {{ "Loading" | translate }}
    </div>
  </ng-template>
</ng-select>
