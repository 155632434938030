import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { isEqual } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  Subscription
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  pluck,
  scan,
  startWith,
  switchMap,
  tap
} from 'rxjs/operators';
import { Outlet } from '../../../outlets/models/outlet';
import { EmployeesService } from '../../services/employees/employees.service';

@Component({
  selector: 'app-employee-selector',
  templateUrl: './employee-selector.component.html',
  styleUrls: ['./employee-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmployeeSelectorComponent),
      multi: true
    }
  ]
})
export class EmployeeSelectorComponent
  implements OnDestroy, ControlValueAccessor {
  @Input() usePlaceholder = false;
  @Input() hasPhone = false;
  currentValue = new FormControl();
  modelTypeahead$ = new Subject<string>();
  isModelsLoading = false;
  models$: Observable<any>;
  modelsLoad$: Observable<any>;
  subscriptions = new Subscription();
  operators: string[] = [];
  countLoaded: number;
  nextPage = 0;
  fetchMore$ = new BehaviorSubject('');
  hasNextPage = true;
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(protected employeeService: EmployeesService) {
    this.modelsLoad$ = combineLatest(
      this.modelTypeahead$.pipe(
        startWith(''),
        tap(() => {
          this.nextPage = 0;
          this.hasNextPage = true;
        })
      ),
      this.fetchMore$.pipe(
        filter(_ => !this.isModelsLoading && this.hasNextPage)
      )
    ).pipe(
      debounceTime(100),
      tap(() => (this.isModelsLoading = true)),
      switchMap(([val, _]) => {
        this.nextPage++;
        const filter: any = { search: val, page: this.nextPage };
        if (this.hasPhone) {
          filter.has_phone = true;
        }
        return this.employeeService.filter(filter).pipe(
          tap(res => (this.hasNextPage = !!res.next)),
          pluck('results')
        );
      })
    );

    this.models$ = this.modelsLoad$.pipe(
      scan((previous, response) => {
        this.isModelsLoading = false;
        const result =
          this.nextPage <= 1 ? response : previous.concat(response);
        this.countLoaded = result.length;

        return result;
      }, [])
    );

    this.subscriptions.add(
      this.currentValue.valueChanges
        .pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
        .subscribe(v => this.onChanged(v))
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  writeValue(obj: any): void {
    this.currentValue.setValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.currentValue.disable();
    } else {
      this.currentValue.enable();
    }
  }

  onChanged(value: Outlet) {
    if (this.onChange) {
      this.onChange(value);
    }

    if (this.onTouched) {
      this.onTouched();
    }
  }

  fetchMore() {
    if (this.isModelsLoading) {
      return;
    }
    this.fetchMore$.next('');
  }

  scroll({ end }) {
    if (this.isModelsLoading) {
      return;
    }
    if (end + 10 >= this.countLoaded) {
      this.fetchMore$.next('');
    }
  }
}
