<div class="search-panel row"  [ngClass]="{'pt-4 px-3': customerId}">
  <app-employees-finder class="col-4 mr-3"></app-employees-finder>
  <app-date-selector class="col mr-3"></app-date-selector>
  <app-direction-selector class="col-2 mr-3"></app-direction-selector>
  <app-subject-selector class="col-2"></app-subject-selector>
</div>
<div class="panel">
  <div class="custom-table" *ngIf="1">
    <div class="table-header">
      <div class="column text-muted">{{'Time' | translate}}</div>
      <div class="column text-muted">{{'Operator' | translate}}</div>
      <div class="column text-muted">{{'Client' | translate}}</div>
      <div class="column text-muted">{{'Direction and subject' | translate}}</div>
      <div class="column text-muted text-center">{{'Comments' | translate}}</div>
      <div class="column audio text-muted text-center">{{'Record' | translate}}</div>
    </div>
    <ng-container *ngIf="!(loading$ | async) || (data$ | async).length > 0; else loadingBlock">
      <div class="table-body scroll-container" [class.mini-table]="customerId" perfectScrollbar
           #infiniteScrollContainer infiniteScroll [scrollWindow]='false' (scrolled)='onScroll()'>
        <div #infiniteScrollableElement *ngIf="(data$ | async).length; else noHistory">
          <div class="table-row" *ngFor="let history of (data$ | async); let i=index; trackBy: trackByFn">
            <div class="column text-muted">
              {{history.created | formatDatetime}}
            </div>
            <div class="column">
              <div class="employee-id text-muted">{{history.employee?.ext_id}}</div>
              <div class="employee-name">{{history.employee | employee}}</div>
            </div>
            <div class="column">
              {{history.customer | customer}}
            </div>
            <div class="column">
              <div class="direction">{{history.subjects[0]?.direction?.name}}</div>
              <div class="subject text-muted">{{history.subjects[0]?.name}}</div>
            </div>
            <div class="column center mini">
              <a class="btn-toolbar-action">
                <i class="material-icons" [ngClass]="{'no-comment': !history.comment}"
                   [popover]="history.comment | translate"
                   container="body" containerClass="values-popover-container"
                   triggers="mouseenter:mouseleave" [outsideClick]="true">
                  comment
                </i>
              </a>
            </div>
            <div class="column audio">
              <div class="audio-controls">
                <a *ngIf="canPlay(history)" class="btn-toolbar-action" (click)="play(history.id, history.file)">
                  <i class="material-icons">play_circle_outline</i>
                </a>
                <a *ngIf="canResume(history)" class="btn-toolbar-action mr-1" (click)="resume()">
                  <i class="material-icons">play_arrow</i>
                </a>
                <a *ngIf="canPause(history)" class="btn-toolbar-action mr-1" (click)="pause()">
                  <i class="material-icons">pause_circle_outline</i>
                </a>
                <a *ngIf="canStop(history)" class="btn-toolbar-action" (click)="stop()">
                  <i class="material-icons">stop</i>
                </a>

                <input class="ml-1" *ngIf="currentAudio?.audio && history.id === currentAudio.callId" type="range"
                  id="seek" [value]="currentAudio?.audio?.currentTime" max="{{currentAudio.duration}}"
                  (change)="changeSeek($event)"/>

              </div>
              <div class="time" *ngIf="history.duration">{{history.duration | formatSeconds}}</div>
            </div>
          </div>
        </div>

      </div>
    </ng-container>

  </div>
</div>

<ng-template #noHistory>
  <app-empty-list-placeholder [header]="'There are no histories calls' | translate"
                              [body]="'Try to change search conditions' | translate">
  </app-empty-list-placeholder>
</ng-template>

<ng-template #loadingBlock>
  <app-loader></app-loader>
</ng-template>

<ng-template #commentDialog>
  <app-comment-dialog [bsModalRef]="modalRef" [call]="currentCall"></app-comment-dialog>
</ng-template>
