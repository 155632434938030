import { Injectable } from '@angular/core';
import { Actions, Effect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { debounceTime, delay, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthenticationService } from '../../modules/shared/services/authentication/authentication.service';
import { SocketService } from '../../modules/shared/services/socket/socket.service';
import * as socketActions from '../actions/socket.action';
import { SendMessage, SendPing, SocketAction } from '../actions/socket.action';
import { AppState } from '../reducers';
import { SocketConnectionState } from '../reducers/socket.reducer';
import { getAutoRetryFlag, getConnectionState } from '../selectors/socket.selector';

@Injectable()
export class SocketEffect {
  static SOCKET_TIMEOUT = 60 * 1000;
  static PING_TIMEOUT = 20 * 1000;
  static RETRY_INTERVAL = 10 * 1000;

  constructor(
    private store: Store<AppState>,
    private socketService: SocketService,
    private actions$: Actions,
    private auth: AuthenticationService
  ) {
  }

  @Effect({dispatch: false})
  connect$ = this.actions$.pipe(
    ofType(socketActions.SOCKET_CONNECT),
    tap(() => this.socketService.connect())
  );

  @Effect({dispatch: false})
  disconnect$ = this.actions$.pipe(
    ofType(socketActions.SOCKET_DISCONNECT),
    tap(() => {
      this.socketService.disconnect();
    })
  );

  @Effect({dispatch: false})
  sendMessage$ = this.actions$.pipe(
    ofType(socketActions.SEND_MESSAGE),
    tap((action: SendMessage) => this.socketService.sendMessage(action.payload))
  );

  @Effect({dispatch: false})
  initSocket$ = this.actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    tap(() => this.socketService.init())
  );

  @Effect()
  checkConnection$ = this.actions$.pipe(
    ofType(socketActions.RECEIVE_MESSAGE),
    debounceTime(SocketEffect.SOCKET_TIMEOUT),
    withLatestFrom(this.auth.authStateChange),
    filter(([_, isAuth]) => isAuth),
    map(() => new socketActions.SocketConnect())
  );

  @Effect()
  reconnect$ = this.actions$.pipe(
    ofType(socketActions.SOCKET_DISCONNECT, socketActions.SET_RETRY_FLAG),
    debounceTime(SocketEffect.RETRY_INTERVAL),
    switchMap(() => combineLatest(this.store.pipe(select(getAutoRetryFlag)), this.auth.isAuthorized())),
    filter(([autoRetry, isAuthorized]) => autoRetry && isAuthorized),
    map(() => new socketActions.SocketConnect())
  );

  @Effect()
  sendPingAfterAppInit$ = this.actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    map(() => new SendPing())
  );

  @Effect()
  pingServer = this.actions$.pipe(
    ofType(socketActions.SEND_PING),
    delay(SocketEffect.PING_TIMEOUT),
    withLatestFrom(this.store.pipe(select(getConnectionState))),
    switchMap(([_, flag]: [SendPing, SocketConnectionState]) => {
      const actions: SocketAction[] = [new SendPing()];
      if (flag === SocketConnectionState.Connected) {
        actions.push(new SendMessage({type: 'ping'}));
      }
      return actions;
    })
  );
}
