import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import { Call, CallStates } from '../../models/calls.model';
import * as moment from 'moment';
import { Observable, interval, Subject, merge, of } from 'rxjs';
import { takeUntil, map, takeWhile, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-call-duration',
  templateUrl: './call-duration.component.html',
  styleUrls: ['./call-duration.component.scss']
})
export class CallDurationComponent implements OnChanges, OnDestroy {
  @Input() call: Call;
  @Input() sipStart: Date;

  duration$: Observable<any>;
  endTime$ = new Subject();
  stopTimer$ = new Subject();

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.call) {
      if (changes.call.currentValue) {
        const call: Call = changes.call.currentValue;

        if (changes.call.isFirstChange) {
          this.duration$ = merge(
            of(0),
            this.endTime$,
            interval(1000).pipe(takeWhile(() => !this.call.ended))
          ).pipe(
            map(() => {
              // if (this.call.ended && this.call.created) {
              //   const from = moment(this.call.ended);
              //   const duration = moment.duration(from.diff(this.call.created));
              //   return {
              //     h: duration.asHours(),
              //     m: duration.minutes(),
              //     s: duration.seconds()
              //   };
              // } else
              if (
                this.sipStart &&
                [
                  CallStates.ASSIGNED,
                  CallStates.ANSWERED,
                  CallStates.HOLD
                ].includes(call.state)
              ) {
                const from = moment();
                const duration = moment.duration(from.diff(this.sipStart));
                return {
                  h: duration.hours(),
                  m: duration.minutes(),
                  s: duration.seconds()
                };
              } else {
                return null;
              }
            }),
            takeUntil(this.stopTimer$)
          );
        }

        if (call.ended) {
          this.endTime$.next(call.ended);
        }
      }
    }
  }

  ngOnDestroy() {
    this.stopTimer$.next();
  }
}
