import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaymentsFilter, Payment } from '../../models/payment.model';
import { PageSettings } from 'src/app/modules/shared/helpers/calculate-page';
import { Observable } from 'rxjs';
import { PagebleEntity } from 'src/app/modules/shared/models/pageble-entity';
import { pickBy, isDate, isNil } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  basePath = 'payments/';
  constructor(private http: HttpClient) {}

  getList(
    params: PageSettings,
    filter: PaymentsFilter
  ): Observable<PagebleEntity<Payment>> {
    const notEmptyFilters = pickBy(
      filter,
      value => isDate(value) || !isNil(value)
    );
    const newParams = {
      ...params,
      ...notEmptyFilters
    };
    return this.http.get<PagebleEntity<Payment>>(this.basePath, {
      params: newParams as any
    });
  }

  get(id: string): Observable<Payment> {
    return this.http.get<Payment>(`${this.basePath}${id}/`);
  }

  refresh(id: string): Observable<Payment> {
    return this.http.post<Payment>(`${this.basePath}${id}/refresh/`, {});
  }
}
