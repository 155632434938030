import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import ymaps from 'ymaps';
import { AppState } from '../../../../../store/reducers';
import { SegmentProperty } from '../../../models/segment-property';

const mapDelta = 0.001;

@Component({
  selector: 'app-model-full-address-field',
  templateUrl: './model-full-address-field.component.html',
  styleUrls: ['./model-full-address-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModelFullAddressFieldComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ModelFullAddressFieldComponent),
      multi: true
    }
  ]
})
export class ModelFullAddressFieldComponent
  implements ControlValueAccessor, Validator, OnDestroy, OnChanges {

  get property(): SegmentProperty {
    return this._property;
  }

  @Input()
  set property(property: SegmentProperty) {
    this._property = property;
    const validators = [];
    if (property.is_required) {
      validators.push(Validators.required);
    }

    this.formGroup = this.fb.group({
      address: [{value: null, disabled: !property.is_editable}],
      latitude: null,
      longitude: null
    });

    this.wrapActionWithoutChanges(() => {
      if (!this.isChanging) {

        if (this.currentValue) {
          this.formGroup.patchValue(this.currentValue, {emitEvent: false});
          this.coordinates = [Number(this.currentValue.latitude), Number(this.currentValue.longitude)];
        }
      }
    });
  }

  private _property: SegmentProperty;
  @Input()
  hasRemoveButton = false;
  @Input()
  accordionOpened = false;
  @Output()
  onRemoveClick = new EventEmitter();

  formGroup: FormGroup;
  subscriptions = new Subject();
  disabled: boolean = false;
  currentValue;

  coordinates: number[] = [];
  mapView = true;
  map: any;
  ymaps: any;
  isYmapsLoading = false;

  isChanging: boolean;
  isFormChanged = false;
  isReverted = false;
  wrappedActionInProcess = false;
  skipChanges = false;

  onChange = _ => {
  };
  onTouched = () => {
  };

  constructor(private store: Store<AppState>, private fb: FormBuilder) {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.subscriptions))
      .subscribe(fn);
  }

  registerOnValidatorChange(fn: () => void): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.formGroup.get('address').disable();
    } else {
      this.formGroup.get('address').enable();
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.formGroup.errors;
  }

  writeValue(obj: any): void {
    this.currentValue = obj;
    if (this.currentValue && this.formGroup) {
      this.formGroup.patchValue(obj);
      this.coordinates = [Number(this.currentValue.latitude), Number(this.currentValue.longitude)];
    }

    if (!isEqual(obj, this.formGroup.value)) {
      this.isChanging = true;
      this.formGroup.setValue(obj);
      this.coordinates = [Number(this.currentValue.latitude), Number(this.currentValue.longitude)];
      this.isChanging = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
  }

  updateMap(rerender: boolean = false) {
    if ((this.ymaps || this.isYmapsLoading) && !rerender) {
      if (!this.isYmapsLoading) {
        this.updateCoordinates();
      }
    } else {
      if (this.map && rerender) {
        this.map.destroy();
        this.map = null;
      }
      this.isYmapsLoading = true;
      ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU').then(maps => {
        this.ymaps = maps;
        this.map = new maps.Map('map', {
          center: [56, 34],
          zoom: 4,
          type: 'yandex#map',
          controls: ['fullscreenControl']
        });

        this.map.events.add('click', (e) => {
          const coords = e.get('coords');
          if (coords && coords.length === 2) {
            this.coordinates = coords;
            this.formGroup.patchValue({
              latitude: this.coordinates[0].toFixed(8),
              longitude: this.coordinates[1].toFixed(8)
            });
            this.onChange(this.formGroup.getRawValue());
            this.updateCoordinates();
          }
        });
        this.isYmapsLoading = false;
        this.updateCoordinates();
      });
    }
  }

  updateCoordinates() {
    this.map.geoObjects.removeAll();
    const placeMark = new this.ymaps.Placemark(this.coordinates, {}, {});
    this.map.geoObjects.add(placeMark);
    this.map.setBounds(
      [
        [this.coordinates[0] - mapDelta, this.coordinates[1] - mapDelta],
        [this.coordinates[0] + mapDelta, this.coordinates[1] + mapDelta]
      ]
    );
  }

  switchToMap() {
    this.mapView = !this.mapView;
  }

  coordinateChanged(coordinates: number[]) {
    if (coordinates
      && coordinates.length === 2
      && Math.abs(coordinates[0]) > 0
      && Math.abs(coordinates[1]) > 0
    ) {
      this.coordinates = coordinates;
      this.formGroup.patchValue({
        latitude: coordinates[0].toFixed(8),
        longitude: coordinates[1].toFixed(8),
      });
      this.onChange(this.formGroup.getRawValue());
      this.updateCoordinates();
    }
  }

  wrapActionWithoutChanges(action: Function) {
    if (this.wrappedActionInProcess) {
      action();
      return;
    }
    this.wrappedActionInProcess = true;
    this.skipChanges = true;

    action();
    setTimeout(() => {
      this.skipChanges = false;
      this.wrappedActionInProcess = false;
    }, 150);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.accordionOpened && changes.accordionOpened.currentValue) {
      this.updateMap(true);
    }
  }
}
