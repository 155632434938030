import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isEqual, pick } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  take,
  takeUntil,
  tap,
  combineLatest as combineLatestPipe,
} from 'rxjs/operators';

import { AppState } from '../../../../store/reducers/index';
import { SelectItem } from '../../../shared/components/form/select/select.component';
import { FieldsError } from '../../../shared/models/http-error';
import { ErrorHandlingService } from '../../../shared/services/error-handling/error-handling.service';
import {
  FullSegment,
  SegmentFilter,
  SegmentForm,
  UpdatedSegmentFilter,
} from '../../models/full-segment';
import { SegmentGroup } from '../../models/segment-group';
import { SegmentModel } from '../../models/segment-model';
import {
  SEGMENT_PROPERTY_NAMES,
  SegmentProperty,
} from '../../models/segment-property';
import { SegmentFilterInit } from '../../store/actions/segments.action';
import { getSegmentModels } from '../../store/reducers/segment-models.reducer';
import { getSegmentGroups } from '../../store/selectors/segment-groups.selector';
import {
  getOutputSegmentModelsByName,
  getSegmentModelByName,
  getSegmentModelsList,
} from '../../store/selectors/segment-models.selector';
import {
  getSegmentPropertiesByModel,
  getSegmentPropertiesByOutputModel,
} from '../../store/selectors/segment-properties.selector';
import { segmentErrors } from '../../store/selectors/segments.selector';
import { SegmentFilterComponent } from '../segment-filters/segment-filter/segment-filter.component';

@Component({
  selector: 'app-segment-form',
  templateUrl: './segment-form.component.html',
  styleUrls: ['./segment-form.component.scss'],
})
export class SegmentFormComponent implements OnInit, OnDestroy, OnChanges {
  get newFilter(): FormControl {
    return this._newFilter;
  }

  set newFilter(value: FormControl) {
    if (value) {
      this.form.disable();
      value.enable();
    } else {
      this.form.enable();
    }
    this._newFilter = value;
  }

  get isRemoveFilter(): boolean {
    return this._isRemoveFilter;
  }

  set isRemoveFilter(value: boolean) {
    if (!this.isEdit || !this.form) {
      return;
    }
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    this._isRemoveFilter = value;
  }

  get currentSegmentModelLabel() {
    return this._currentSegmentModelLabel;
  }

  @Input()
  set currentSegmentModelLabel(value) {
    this._currentSegmentModelLabel = value;
    this.store
      .pipe(
        select(getSegmentModelByName(value)),
        filter((data) => !!data),
        take(1)
      )
      .subscribe((model: SegmentModel) => {
        this.currentSegmentModelId = model.id;
        if (this.form) {
          this.form.patchValue({ model: model.id });
        }
      });
  }

  get segment(): FullSegment {
    return this._segment;
  }

  private _currentSegmentModelLabel;
  private currentSegmentModelId;

  @Input()
  set segment(value: FullSegment) {
    this._segment = value;
    const selectedProperties = value.filters.map((it) => it.property.id);
    this.selectedProperties.next(selectedProperties);
  }

  get canEditFilters(): boolean {
    return this._canEditFilters;
  }

  @Input()
  set canEditFilters(value: boolean) {
    this._canEditFilters = value;
  }

  get filters(): FormArray {
    return this.form.get('filters') as FormArray;
  }

  @Input() isEdit: boolean;
  @Input() label;
  @Input() showFormButtons = true;
  private _canEditFilters = true;
  @Input() canAddFilter = true;
  @Output() nameUpdated = new EventEmitter<string>();
  @Output() filterUpdated = new EventEmitter<[string, UpdatedSegmentFilter]>();
  @Output() saved = new EventEmitter<SegmentForm>();
  @Output() filterAdded = new EventEmitter<UpdatedSegmentFilter>();
  @Output() filterRemoved = new EventEmitter<SegmentFilter>();
  @Output() cancelClick = new EventEmitter<void>();

  @ViewChild(NgSelectComponent) addFilterControlRef: NgSelectComponent;
  @ViewChildren(SegmentFilterComponent) filterArr: SegmentFilterComponent[];

  public get showConfirmationOnNavigation() {
    return (
      this.isChangingName ||
      (this.filterArr && this.filterArr.some((control) => control.hasChanges))
    );
  }

  initialSegment: FullSegment;
  form: FormGroup;
  segmentProperties$: Observable<SelectItem[]>;
  selectedModel$ = new BehaviorSubject<string>(null);
  outputModels$: Observable<SegmentModel[]>;
  unsubscribe = new Subject();
  filterUpdateSubscriptions: Subscription;
  addFilterControl: FormControl = new FormControl();
  selectedProperties = new BehaviorSubject<string[]>([]);
  formSubmitted = false;
  formSaved = false;
  currentAddSubscriptions: Subscription;
  formError: FieldsError;
  propertyNames = SEGMENT_PROPERTY_NAMES;

  private _segment: FullSegment;
  private isChangingFilter = {};
  private isChangingName = false;
  private _isRemoveFilter = false;
  private _newFilter: FormControl;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private errorHandler: ErrorHandlingService,
    private translateService: TranslateService
  ) {
    this.addFilterControl.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((id) => {
        if (!id) {
          return;
        }

        this.addFilter(id);
        this.addFilterControlRef.searchInput.nativeElement.blur();
        this.addFilterControl.setValue(null, { emitEvent: false });
      });

    this.store
      .pipe(select(segmentErrors), takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.formError = data;
        this.errorHandler.propogateErrorsInForm(data, this.form);
      });
  }

  buildSegments() {
    this.selectedModel$.next(this.currentSegmentModelId);

    this.segmentProperties$ = combineLatest(
      this.store.pipe(
        select(
          getSegmentPropertiesByOutputModel(this._currentSegmentModelLabel)
        ),
        map((data: SegmentProperty[]) =>
          data.filter(
            (it) => it.widget && it.is_segment_filter && it.widget.length > 0
          )
        )
      ),
      this.store.pipe(select(getSegmentGroups)),
      this.selectedProperties,
      this.selectedModel$
    ).pipe(
      map(
        ([data, groups, selectedProperties, selectedModel]: [
          SegmentProperty[],
          SegmentGroup[],
          string[],
          string
        ]) => {
          return data
            .filter(
              (it) =>
                !selectedProperties.find(
                  (propertyId) => it.id === propertyId
                ) && it.model === selectedModel
            )
            .map((it) => ({
              group: groups.find((group) => group.id === it.group) || null,
              model: it.model,
              name: this.translateService.instant(
                this.propertyNames[it.name]
                  ? this.propertyNames[it.name]
                  : it.name
              ),
              value: it.id,
              disabled: it.widget === 'enum' && it.property_values.length === 0,
            }));
        }
      )
    );

    this.outputModels$ = this.store.pipe(
      select(getOutputSegmentModelsByName(this._currentSegmentModelLabel))
    );
  }

  propertyGroupBy = (item) => (item.group ? item.group.id : null);

  propertyGroupValue = (_: string, children: any[]) => ({
    name: children[0].group ? children[0].group.name : null,
  });

  toggleFilter() {
    if (!this.form || !this.filters) {
      return;
    }
    if (!this.canEditFilters) {
      this.filters.disable();
    } else {
      this.filters.enable();
    }
  }

  addFilter(value: any) {
    const newFilter = new SegmentFilter({ property: value });
    if (this.isEdit) {
      this.store.dispatch(new SegmentFilterInit(newFilter));
    } else {
      this.filters.push(new FormControl(newFilter));
    }
  }

  removeFilter(item: FormControl) {
    const index = this.filters.controls.findIndex((it) => it === item);
    this.selectedProperties.next(
      this.selectedProperties
        .getValue()
        .filter((it) => it !== item.value.property)
    );
    this.filterRemoved.emit(item.value);
    this.filters.removeAt(index);
  }

  saveFilter(control: FormControl) {
    if (control && control.value && control.valid) {
      this.isChangingFilter[control.value._trackId] = false;
      this.updateFilter(control.value.id, control.value);
      control.markAsPristine();
    }
  }

  saveName() {
    const nameControl = this.form.get('name');
    if (nameControl && nameControl.value && nameControl.valid) {
      this.updateName(nameControl.value);
      nameControl.markAsPristine();
      this.isChangingName = false;
    }
  }

  isSaveNameDisabled(): boolean {
    const nameControl = this.form.get('name');
    return (
      !nameControl ||
      !nameControl.value ||
      nameControl.invalid ||
      nameControl.pristine
    );
  }

  createEmptyForm() {
    this.form = this.fb.group({
      name: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(255)]),
      ],
      filters: this.fb.array([], this.emptyArray),
      model: this.currentSegmentModelId,
    });
  }

  emptyArray(control: FormArray) {
    if (!control || !control.value) {
      return { required: true };
    }

    return control.controls.length > 0 ? null : { required: true };
  }

  createFormFromSegment() {
    this.form = this.fb.group({
      name: [
        this._segment.name,
        Validators.compose([Validators.required, Validators.maxLength(255)]),
      ],
      filters: this.fb.array(this._segment.filters),
      id: this._segment.id,
      company: this._segment.company,
      model: this._segment.model,
    });

    this.subscribeToChangeForm();
  }

  ngOnInit() {
    this.buildSegments();
    if (this.isEdit) {
      this.createFormFromSegment();
    } else {
      this.createEmptyForm();
    }
  }

  submit() {
    this.formSubmitted = true;
    if (this.form.invalid) {
      return;
    }
    const newSegment: SegmentForm = this.form.value;
    this.saved.emit(newSegment);
    this.formSaved = true;
  }

  updateName(value) {
    this.nameUpdated.emit(value);
  }

  updateFilter(id: string, data: any) {
    this.filterUpdated.emit([id, data]);
  }

  addFilterToFilters(data: SegmentFilter) {
    const newData: UpdatedSegmentFilter = pick(data, [
      'value',
      'operator',
      'property',
    ]);
    this.filterAdded.emit(newData);
  }

  unsubscribeFromChangeForm() {
    if (this.filterUpdateSubscriptions) {
      this.filterUpdateSubscriptions.unsubscribe();
    }
  }

  subscribeToChangeForm() {
    this.filterUpdateSubscriptions = this.form
      .get('name')
      .valueChanges.pipe(
        tap((data) => (this.isChangingName = true)),
        distinctUntilChanged((p, q) => isEqual(p, q))
      )
      .subscribe();
    this._segment.filters.forEach((it, i) => {
      const currentFilterControl = this.filters.at(i);
      this.filterUpdateSubscriptions.add(
        this.filters
          .at(i)
          .valueChanges.pipe(
            tap((data) => (this.isChangingFilter[data._trackId] = true)),
            distinctUntilChanged((p, q) => isEqual(p, q))
          )
          .subscribe()
      );
    });
  }

  updateForm(value: SegmentForm = null) {
    this.unsubscribeFromChangeForm();
    value.filters.forEach((updatedFilter) => {
      const filterControl = this.filters.controls.find(
        (f) => f.value._trackId === updatedFilter._trackId
      );
      if (filterControl) {
        if (!this.isChangingFilter[updatedFilter._trackId]) {
          filterControl.patchValue(updatedFilter, { emitEvent: false });
        }
      } else {
        this.filters.push(new FormControl(updatedFilter));
      }
    });
    if (!this.isChangingName) {
      this.form.get('name').patchValue(value.name, { emitEvent: false });
    }
    setTimeout(() => this.subscribeToChangeForm(), 0);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    if (this.filterUpdateSubscriptions) {
      this.filterUpdateSubscriptions.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const segmentField = 'segment';
    if (changes[segmentField] && !changes[segmentField].firstChange) {
      this.updateForm(
        pick(changes[segmentField].currentValue, [
          'id',
          'name',
          'model',
          'filters',
        ])
      );
    }
  }

  filterErrors(index) {
    if (!this.formError || !this.formError.filters) {
      return null;
    }
    return this.formError.filters[index];
  }

  onCancelClick(event) {
    event.preventDefault();
    this.cancelClick.emit();
  }

  trackByFn(index, f: FormControl) {
    const item: SegmentFilter = f.value;
    return item._trackId;
  }
}
