import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanDeactivate,
  Router,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppState } from '../../../../store/reducers';
import { Store, select } from '@ngrx/store';
import { LoadCall, SetActiveCall } from '../../store/call.actions';
import { getActiveCall } from '../../store/call.selector';
import { map } from 'rxjs/operators';
import { Call, CallStates } from '../../models/calls.model';
import { CallComponent } from '../../pages/call/call.component';
import { IncomingCallComponent } from '../../components/incoming-call/incoming-call.component';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';

@Injectable({
  providedIn: 'root'
})
export class IsCallFinishedGuard implements CanDeactivate<CallComponent> {
  constructor(private store: Store<AppState>, private router: Router) {}

  canDeactivate(
    component: CallComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.pipe(
      select(getActiveCall),
      map(call => {
        if (
          [CallStates.HANG_UP_CUSTOMER, CallStates.HANG_UP_EMPLOYEE].includes(
            call.state
          )
        ) {
          component.incomingCall.onFinishCall();
          return false;
        }
        if ([CallStates.ANSWERED, CallStates.HOLD].includes(call.state)) {
          component.incomingCall.onHangUpCall();
          return false;
        }
        return true;
      })
    );
  }
}
