import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../../store/reducers';
import { CallsHistorySetFilter } from '../../store/call.actions';

@Injectable({
  providedIn: 'root'
})
export class LoadCallsHistoryGuard implements CanActivate {
  constructor(private store: Store<AppState>) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(new CallsHistorySetFilter({}));
    return true;
  }
}
