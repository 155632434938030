<div class="modal-header">
  <h4 class="modal-title pull-left">{{'Comment' | translate}}</h4>
  <button type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
      <textarea
        [formControl]="comment"
        class="form-control"
        [placeholder]="('Add a comment' | translate) + '...'"
      ></textarea>
</div>
<div class="modal-footer">
  <button (click)="submit()"
          class="btn btn-primary ml-auto btn-block">{{'Save' | translate}}</button>
</div>
