import { createSelector } from '@ngrx/store';
import { isReload } from '../../../../shared/helpers/calculate-page';
import { getCourierModuletState } from '../selector';
import {
  ReturnDeliveryActions,
  ReturnDeliveryActionTypes,
} from './return-delivery.actions';
import { ReturnDelivery, ReturnDeliveryActionReason, ReturnDeliveryFilter } from '../../../models/return-delivery.model';

export interface ReturnDeliveryState {
  returnDeliveries: ReturnDelivery[];
  returnDeliveriesNextPage: string | null;
  returnDeliveriesOfOrder: ReturnDelivery[];
  returnDeliveriesOfOrderCount: number;
  returnDeliveriesFilter: ReturnDeliveryFilter;
  isLoadingReturnDeliveries: boolean;
  isLoadingReturnDeliveriesOfOrder: boolean;
  isReload: boolean;
  updating: boolean;
  actionReasons: ReturnDeliveryActionReason[];
  returnDelivery: ReturnDelivery | null;
  isLoadingReturnDelivery: boolean;
}

export const initialState: ReturnDeliveryState = {
  returnDeliveries: [],
  returnDeliveriesNextPage: null,
  returnDeliveriesOfOrder: [],
  returnDeliveriesOfOrderCount: 0,
  returnDeliveriesFilter: null,
  isReload: false,
  isLoadingReturnDeliveries: false,
  isLoadingReturnDeliveriesOfOrder: false,
  updating: false,
  actionReasons: [],
  isLoadingReturnDelivery: false,
  returnDelivery: null,
};

export function ReturnDeliveryReducer(
  state = initialState,
  action: ReturnDeliveryActions
): ReturnDeliveryState {
  switch (action.type) {
    case ReturnDeliveryActionTypes.LoadReturnDeliveries:
      return {
        ...state,
        isLoadingReturnDeliveries: true,
        isReload: isReload(action),
      };
    case ReturnDeliveryActionTypes.LoadReturnDeliveriesSuccess:
      return {
        ...state,
        returnDeliveries: action.payload.results,
        returnDeliveriesNextPage: action.payload.next,
        isLoadingReturnDeliveries: false,
        isReload: false,
      };
    case ReturnDeliveryActionTypes.LoadReturnDeliveriesFail:
      return {
        ...state,
        isLoadingReturnDeliveries: false,
        isReload: false,
      };
    case ReturnDeliveryActionTypes.SetReturnDeliveriesFilter:
      return {
        ...state,
        isLoadingReturnDeliveries: true,
        returnDeliveries: [],
        returnDeliveriesFilter: {
          ...(action.reset ? {} : state.returnDeliveriesFilter),
          ...action.payload,
        },
        returnDeliveriesNextPage: null,
      };
    case ReturnDeliveryActionTypes.LoadReturnDeliverySuccess:
      return {
        ...state,
        returnDeliveriesOfOrder: state.returnDeliveriesOfOrder.map((delivery) =>
          delivery.id === action.payload.id ? action.payload : delivery
        ),
        isLoadingReturnDeliveriesOfOrder: false,
        isLoadingReturnDelivery: false,
        returnDelivery: action.payload,
      };
    case ReturnDeliveryActionTypes.LoadReturnDelivery:
      return {
        ...state,
        returnDelivery: null,
        isLoadingReturnDelivery: true,
      };
    case ReturnDeliveryActionTypes.LoadReturnDeliveryFail:
      return {
        ...state,
        isLoadingReturnDelivery: false,
      };
    default:
      return state;
  }
}

export const getReturnDeliveryState = createSelector(
  getCourierModuletState,
  (data) => data.returnDeliveries
);
