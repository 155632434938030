import { Action, createSelector } from '@ngrx/store';
import { FieldsError } from '../../../shared/models/http-error';
import { isReload } from '../../../shared/helpers/calculate-page';
import {
  appendOrReplaceItem,
  upsertItem,
} from '../../../shared/helpers/append-or-set-collection';
import { DictionaryItem } from '../../models/dictionary-item';
import {
  DICTIONARY_ITEM_CREATE,
  DICTIONARY_ITEM_CREATE_FAIL,
  DICTIONARY_ITEM_CREATE_SUCCESS,
  DICTIONARY_ITEM_DELETE,
  DICTIONARY_ITEM_DELETE_FAIL,
  DICTIONARY_ITEM_DELETE_SUCCESS,
  DICTIONARY_ITEM_LOAD,
  DICTIONARY_ITEM_LOAD_FAIL,
  DICTIONARY_ITEM_LOAD_SUCCESS,
  DICTIONARY_ITEM_UPDATE,
  DICTIONARY_ITEM_UPDATE_FAIL,
  DICTIONARY_ITEM_UPDATE_SUCCESS,
  DICTIONARY_ITEMS_LOAD,
  DICTIONARY_ITEMS_LOAD_FAIL,
  DICTIONARY_ITEMS_LOAD_SUCCESS,
  DictionaryItemActions,
  DICTIONARY_ITEM_INIT,
} from './dictionary-item.actions';
import { getDictionaryModuleState } from '../selector';
import { cloneDeep } from 'lodash';

export interface DictionaryItemsState {
  dictionaryItems: DictionaryItem[];
  totalDictionariesCount: number;
  dictionaryItem: DictionaryItem;
  isLoadingDictionaryItems: boolean;
  isLoadingDictionaryItem: boolean;
  isReload: boolean;
  updating: boolean;
  dictionaryItemError: FieldsError;
}

export const initialState: DictionaryItemsState = {
  dictionaryItems: [],
  totalDictionariesCount: 0,
  dictionaryItem: null,
  isLoadingDictionaryItem: false,
  isLoadingDictionaryItems: false,
  isReload: false,
  updating: false,
  dictionaryItemError: null,
};

export function DictionaryItemsReducer(
  state = initialState,
  action: DictionaryItemActions
): DictionaryItemsState {
  switch (action.type) {
    case DICTIONARY_ITEMS_LOAD:
      return {
        ...state,
        isLoadingDictionaryItems: true,
        isReload: isReload(action),
      };
    case DICTIONARY_ITEMS_LOAD_SUCCESS:
      return {
        ...state,
        isLoadingDictionaryItems: false,
        dictionaryItems: [
          ...action.payload.map((item) => new DictionaryItem(item)),
          new DictionaryItem({}),
        ],
        isReload: false,
      };
    case DICTIONARY_ITEMS_LOAD_FAIL:
      return { ...state, isLoadingDictionaryItems: false, isReload: false };
    case DICTIONARY_ITEM_LOAD:
      return { ...state, isLoadingDictionaryItem: true };
    case DICTIONARY_ITEM_LOAD_SUCCESS:
      const newDictionaries = state.dictionaryItems
        .filter((it) => it.id !== action.payload.id)
        .concat(action.payload);
      return {
        ...state,
        dictionaryItems: newDictionaries,
        isLoadingDictionaryItem: false,
        dictionaryItem: action.payload,
      };
    case DICTIONARY_ITEM_LOAD_FAIL:
      return { ...state, isLoadingDictionaryItem: false };
    case DICTIONARY_ITEM_CREATE_SUCCESS:
      return {
        ...state,
        updating: false,
        dictionaryItems: state.dictionaryItems.map((item) =>
          item._trackId === action.trackId
            ? ({
                ...action.payload,
                _isLoading: false,
              } as DictionaryItem)
            : item
        ),
      };
    case DICTIONARY_ITEM_CREATE_FAIL:
      return { ...state, updating: false, dictionaryItemError: action.payload };
    case DICTIONARY_ITEM_INIT:
      return {
        ...state,
        dictionaryItems: [...state.dictionaryItems, new DictionaryItem({})],
      };
    case DICTIONARY_ITEM_CREATE:
    case DICTIONARY_ITEM_UPDATE:
      return {
        ...state,
        updating: true,
        dictionaryItems: state.dictionaryItems.map((item) =>
          item._trackId === action.payload._trackId
            ? ({
                ...action.payload,
                _isTouched: true,
                _isLoading: true,
              } as DictionaryItem)
            : item
        ),
      };
    case DICTIONARY_ITEM_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        dictionaryItems: state.dictionaryItems.map((item) =>
          item.id === action.payload.id
            ? ({
                ...item,
                _isLoading: false,
              } as DictionaryItem)
            : item
        ),
      };
    case DICTIONARY_ITEM_UPDATE_FAIL:
      return { ...state, updating: false, dictionaryItemError: action.payload };
    case DICTIONARY_ITEM_DELETE:
      return {
        ...state,
        updating: true,
        dictionaryItems: state.dictionaryItems.filter(
          (it) => it.id !== action.payload
        ),
      };
    case DICTIONARY_ITEM_DELETE_SUCCESS:
      return {
        ...state,
        dictionaryItems: state.dictionaryItems.filter(
          (it) => it.id !== action.payload
        ),
      };
    case DICTIONARY_ITEM_DELETE_FAIL:
      return { ...state, updating: false };
    default:
      return state;
  }
}

export const getDictionaryItemState = createSelector(
  getDictionaryModuleState,
  (data) => data.dictionaryItems
);
