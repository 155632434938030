import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DATE_FORMAT, SHOW_FORMAT } from '../../config/data-formats.config';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const date = moment(value, DATE_FORMAT);
    return date.isValid() ? date.format(SHOW_FORMAT) : '';
  }

}
