import { Action } from '@ngrx/store';
import { Segment } from '../../models/segment';
import {
  FullSegment,
  SegmentFilter,
  SegmentForm,
  UpdatedSegment,
  UpdatedSegmentFilter
} from '../../models/full-segment';
import {
  PagebleAction,
  PagebleActionPayload
} from '../../../shared/helpers/calculate-page';
import { PagebleEntity } from '../../../shared/models/pageble-entity';
import { FieldsError } from '../../../shared/models/http-error';
import { SegmentUpdatedMessage } from '../../models/messages/segment-updated.message';

export const SEGMENTS_LOAD = '[Segments] load';
export const SEGMENTS_LOAD_SUCCESS = '[Segments] load success';
export const SEGMENTS_LOAD_FAIL = '[Segments] load fail';

export const SEGMENTS_LOAD_ALL = '[Segments] load all';
export const SEGMENTS_LOAD_ALL_SUCCESS = '[Segments] load all success';

export const SEGMENTS_SET_FILTER = '[Segments] set filter';

export const SEGMENT_LOAD = '[Segments] segment load';
export const SEGMENT_LOAD_SUCCESS = '[Segments] segment load success';
export const SEGMENT_LOAD_FAIL = '[Segments] segment load fail';

export const SET_SEGMENT = '[Segments] set segment';
export const ADD_SEGMENT = '[Segments] add segment';

export const SEGMENT_CREATE = '[Segments] create';
export const SEGMENT_CREATE_SUCCESS = '[Segments] create success';
export const SEGMENT_CREATE_FAIL = '[Segments] create fail';

export const SEGMENT_UPDATE = '[Segments] update';
export const SEGMENT_UPDATE_SUCCESS = '[Segments] update success';
export const SEGMENT_UPDATE_FAIL = '[Segments] update fail';

export const SEGMENT_UPDATE_FROM_SOCKET = '[Segments] update from socket';

export const SEGMENT_FILTER_INIT = '[Segments] filter init';

export const SEGMENT_FILTER_ADD = '[Segments] filter add';
export const SEGMENT_FILTER_ADD_SUCCESS = '[Segments] filter add success';
export const SEGMENT_FILTER_ADD_FAIL = '[Segments] filter add fail';

export const SEGMENTS_FILTER_UPDATE = '[Segments] filter update';
export const SEGMENTS_FILTER_UPDATE_SUCCESS =
  '[Segments] filter update success';
export const SEGMENTS_FILTER_UPDATE_FAIL = '[Segments] filter update fail';

export const SEGMENTS_FILTER_DELETE = '[Segments] filter delete';
export const SEGMENTS_FILTER_DELETE_SUCCESS =
  '[Segments] filter delete success';
export const SEGMENTS_FILTER_DELETE_FAIL = '[Segments] filter delete fail';

export const SEGMENTS_FILTERS_BATCH_DELETE =
  '[Segments] delete batch of filters';
export const SEGMENTS_FILTERS_BATCH_DELETE_SUCCESS =
  '[Segments] delete batch of filters success';
export const SEGMENTS_FILTERS_BATCH_DELETE_FAIL =
  '[Segments] delete batch of filters fail';

export class SegmentsLoad implements Action, PagebleAction {
  readonly type = SEGMENTS_LOAD;
  constructor(public payload?: PagebleActionPayload) {}
}

export class SegmentsLoadSuccess implements Action {
  readonly type = SEGMENTS_LOAD_SUCCESS;
  constructor(public payload: PagebleEntity<FullSegment>) {}
}

export class SegmentsLoadFail implements Action {
  readonly type = SEGMENTS_LOAD_FAIL;
}

export class SegmentsLoadAll implements Action {
  readonly type = SEGMENTS_LOAD_ALL;
  constructor(public payload?: string) {}
}

export class SegmentsLoadAllSuccess implements Action {
  readonly type = SEGMENTS_LOAD_ALL_SUCCESS;
  constructor(public payload: PagebleEntity<FullSegment>) {}
}

export class SegmentsSetFilter implements Action {
  readonly type = SEGMENTS_SET_FILTER;
  constructor(public payload: any) {}
}

export class SegmentLoad implements Action {
  readonly type = SEGMENT_LOAD;
  constructor(public payload: string) {}
}

export class SegmentLoadSuccess implements Action {
  readonly type = SEGMENT_LOAD_SUCCESS;
  constructor(public payload: FullSegment) {}
}

export class SegmentLoadFail implements Action {
  readonly type = SEGMENT_LOAD_FAIL;
}

export class SegmentCreate implements Action {
  readonly type = SEGMENT_CREATE;
  constructor(public payload: SegmentForm) {}
}

export class SegmentCreateSuccess implements Action {
  readonly type = SEGMENT_CREATE_SUCCESS;
  constructor(public payload: FullSegment) {}
}

export class SegmentCreateFail implements Action {
  readonly type = SEGMENT_CREATE_FAIL;
  constructor(public payload: FieldsError) {}
}

export class SegmentUpdate implements Action {
  readonly type = SEGMENT_UPDATE;
  constructor(public payload: UpdatedSegment) {}
}

export class SegmentUpdateSuccess implements Action {
  readonly type = SEGMENT_UPDATE_SUCCESS;
  constructor(public payload: FullSegment) {}
}

export class SegmentUpdateFail implements Action {
  readonly type = SEGMENT_UPDATE_FAIL;
  constructor(public payload: FieldsError) {}
}

export class SegmentUpdateFromSocket implements Action {
  readonly type = SEGMENT_UPDATE_FROM_SOCKET;
  constructor(public payload: SegmentUpdatedMessage) {}
}

export class SegmentFilterInit implements Action {
  readonly type = SEGMENT_FILTER_INIT;
  constructor(public payload: SegmentFilter) {}
}

export class SegmentFilterAdd implements Action {
  readonly type = SEGMENT_FILTER_ADD;
  constructor(public payload: SegmentFilter, public trackId: string) {}
}

export class SegmentFilterAddSuccess implements Action {
  readonly type = SEGMENT_FILTER_ADD_SUCCESS;
  constructor(public payload: SegmentFilter, public trackId: string) {}
}

export class SegmentFilterAddFail implements Action {
  readonly type = SEGMENT_FILTER_ADD_FAIL;
  constructor(public payload: FieldsError, public trackId: string) {}
}

export class SetSegment implements Action {
  readonly type = SET_SEGMENT;
  constructor(public payload: FullSegment) {}
}

export class AddSegment implements Action {
  readonly type = ADD_SEGMENT;
  constructor(public payload: FullSegment) {}
}

export class SegmentsFilterUpdate implements Action {
  readonly type = SEGMENTS_FILTER_UPDATE;
  constructor(public payload: SegmentFilter, public trackId: string) {}
}

export class SegmentsFilterUpdateSuccess implements Action {
  readonly type = SEGMENTS_FILTER_UPDATE_SUCCESS;
  constructor(public payload: SegmentFilter, public trackId: string) {}
}

export class SegmentsFilterUpdateFail implements Action {
  readonly type = SEGMENTS_FILTER_UPDATE_FAIL;
  constructor(public payload: FieldsError, public trackId: string) {}
}

export class SegmentsFilterDelete implements Action {
  readonly type = SEGMENTS_FILTER_DELETE;
  constructor(public payload: string, public trackId: string) {}
}

export class SegmentsFilterDeleteSuccess implements Action {
  readonly type = SEGMENTS_FILTER_DELETE_SUCCESS;
  constructor(public trackId: string) {}
}

export class SegmentsFilterDeleteFail implements Action {
  readonly type = SEGMENTS_FILTER_DELETE_FAIL;
}

export class SegmentsFiltersBatchDelete implements Action {
  readonly type = SEGMENTS_FILTERS_BATCH_DELETE;
  constructor(public payload: string[]) {}
}

export class SegmentsFiltersBatchDeleteSuccess implements Action {
  readonly type = SEGMENTS_FILTERS_BATCH_DELETE_SUCCESS;
}

export class SegmentsFiltersBatchDeleteFail implements Action {
  readonly type = SEGMENTS_FILTERS_BATCH_DELETE_FAIL;
}

export type SegmmentsAction =
  | SegmentsLoad
  | SegmentsLoadSuccess
  | SegmentsLoadFail
  | SegmentsLoadAll
  | SegmentsLoadAllSuccess
  | SegmentsSetFilter
  | SegmentLoad
  | SegmentLoadSuccess
  | SegmentLoadFail
  | SegmentCreate
  | SegmentCreateFail
  | SegmentCreateSuccess
  | SegmentUpdate
  | SegmentUpdateSuccess
  | SegmentUpdateFail
  | SegmentUpdateFromSocket
  | SegmentsFilterUpdate
  | SegmentsFilterUpdateSuccess
  | SegmentsFilterUpdateFail
  | SegmentsFilterDelete
  | SegmentsFilterDeleteSuccess
  | SegmentsFilterDeleteFail
  | SegmentsFiltersBatchDelete
  | SegmentsFiltersBatchDeleteSuccess
  | SegmentsFiltersBatchDeleteFail
  | SetSegment
  | AddSegment
  | SegmentFilterInit
  | SegmentFilterAdd
  | SegmentFilterAddSuccess
  | SegmentFilterAddFail;
