import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppState } from 'src/app/store/reducers';
import { Store, select } from '@ngrx/store';
import { CreateCall } from '../../store/call.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { getCurrentUser } from 'src/app/modules/shared/store/selectors/user.selector';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EmployeesService } from 'src/app/modules/employees/services/employees/employees.service';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-waiting',
  templateUrl: './waiting.component.html',
  styleUrls: ['./waiting.component.scss']
})
export class WaitingComponent implements OnInit {
  @ViewChild('employeeStateChange', { static: true }) idleModal;
  modalRef: BsModalRef;
  customer = new FormControl();
  employee$: Observable<any>;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private modalService: BsModalService,
    private employeeService: EmployeesService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.employee$ = this.store.pipe(select(getCurrentUser));
  }

  createCall() {
    this.store.dispatch(
      new CreateCall({
        id: 'new',
        customer: this.customer.value,
        state: 'NEW'
      })
    );
  }

  becomeIdle() {
    this.modalRef = this.modalService.show(this.idleModal);
  }

  becomeOnline(employeeId) {
    console.log('hey, get back to work');
    this.employeeService
      .changeState(employeeId, { state: 'ONLINE' })
      .pipe(take(1))
      .subscribe(resp => {
        console.log('tried');
      });
  }

  goToCurrent() {
    this.router.navigate(['./'], {
      fragment: 'current-state',
      relativeTo: this.route
    });
  }

  goToCalls() {
    this.router.navigate(['./'], {
      fragment: 'calls-history',
      relativeTo: this.route
    });
  }
}
