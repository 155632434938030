import { SegmentModelsEffect } from './segment-models.effect';
import { SegmentPropertiesEffect } from './segment-properties.effect';
import { SegmentsEffect } from './segments.effect';
import {SegmentGroupsEffects} from './segment-groups.effects';

export const effects = [
  SegmentModelsEffect,
  SegmentPropertiesEffect,
  SegmentsEffect,
  SegmentGroupsEffects
];
