<div class="btn-group">
  <button
    class="btn btn-outline-secondary"
    type="button"
    *ngFor="let option of options"
    (click)="setValue(option.value)"
    [class.active]="option.value === currentValue.value"
  >
    {{ option.name | translate }}
  </button>
</div>
