import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { AppState } from '../../../../../store/reducers';
import {
  SEGMENT_PROPERTY_NAMES,
  SegmentProperty,
} from '../../../../segments/models/segment-property';

@Component({
  selector: 'app-model-scalar-field',
  templateUrl: './model-scalar-field.component.html',
  styleUrls: ['./model-scalar-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModelScalarFieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ModelScalarFieldComponent),
      multi: true,
    },
  ],
})
export class ModelScalarFieldComponent
  implements OnInit, ControlValueAccessor, Validator, OnDestroy {
  propertyNames = SEGMENT_PROPERTY_NAMES;

  @Input()
  submitted = false;

  get property(): SegmentProperty {
    return this._property;
  }

  @Input()
  set property(property: SegmentProperty) {
    this._property = property;
    const validators = [];
    if (property.is_required) {
      validators.push(Validators.required);
    }

    // const limits = property.limits;
    // if (limits) {
    //   const min = limits.min;
    //   if (!isNil(min)) {
    //     validators.push(Validators.min(min));
    //   }
    //
    //   const max = limits.max;
    //   if (!isNil(max)) {
    //     validators.push(Validators.max(max));
    //   }
    // }

    this.fieldValue = new FormControl(null, validators);
    if (!property.is_editable) {
      this.fieldValue.disable();
    } else {
      this.fieldValue.enable();
    }
    if (this.currentValue) {
      this.fieldValue.patchValue(this.currentValue);
      this.fieldValue.markAsTouched();
    }
    this.subscribeToForm();
  }

  private _property: SegmentProperty;
  @Input()
  hasRemoveButton = false;
  @Output()
  onRemoveClick = new EventEmitter();

  fieldValue: FormControl = new FormControl(null);
  subscriptions = new Subject();
  isChanging = true;
  disabled: boolean = false;
  currentValue;
  formSubscription;

  onChange = (_) => {};
  onTouched = () => {};

  constructor(private store: Store<AppState>) {}

  subscribeToForm() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    this.formSubscription = this.fieldValue.valueChanges
      .pipe(
        filter(() => this.fieldValue.valid)
        // tap(data => {

        // }),
        // filter(() => {
        //   return !this.isChanging;
        // })
      )
      .subscribe((data) => {
        // if (this.property.type === 'str' && this.property.widget === 'enum') {
        //   // if (this.property.is_many) {
        //   //   this.onChange(data.map((d) => d.ext_id));
        //   // } else {
        //   //   this.onChange(data.ext_id);
        //   // }
        // } else {
        this.onChange(data);
        // }
      });
  }

  ngOnInit() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.fieldValue.valueChanges
      .pipe(takeUntil(this.subscriptions))
      .subscribe(fn);
  }

  registerOnValidatorChange(fn: () => void): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.fieldValue.disable();
    } else {
      this.fieldValue.enable();
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.fieldValue.errors;
  }

  writeValue(obj: any): void {
    this.currentValue = obj;
    if (this.currentValue && this.fieldValue) {
      this.fieldValue.patchValue(obj);
    }

    if (!isEqual(obj, this.fieldValue.value)) {
      this.isChanging = true;
      this.fieldValue.setValue(obj);
      this.isChanging = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
  }

  remove() {
    this.onRemoveClick.emit();
  }

  compareValues(a: any, b: any): boolean {
    if (b && b.hasOwnProperty('id') && a && a.hasOwnProperty('id')) {
      return a.id === b.id;
    } else if (
      b &&
      b.hasOwnProperty('ext_id') &&
      a &&
      a.hasOwnProperty('ext_id')
    ) {
      return a.ext_id === b.ext_id;
    } else if (
      (b && b.hasOwnProperty('ext_id')) ||
      (a && a.hasOwnProperty('ext_id'))
    ) {
      return a.ext_id === b || a === b.ext_id;
    } else {
      return a === b;
    }
  }
}
