import { createSelector } from '@ngrx/store';
import { getUserState } from '../reducers/user.reducer';
import { CompanyRole } from '../../models/companies';

export const getCurrentUser = createSelector(
  getUserState,
  state => state.user
);

export const getCurrentCompany = createSelector(
  getUserState,
  state => {
    if (state.companies) {
      if (state.companies.length) {
        const idx = state.companies.findIndex(
          c => c.id === state.currentCompany
        );
        return state.companies[idx !== -1 ? idx : 0];
      } else {
        return undefined;
      }
    }
    return null;
  }
);

export const getCurrentCompanyId = createSelector(
  getUserState,
  state => {
    if (state.currentCompany) {
      return state.currentCompany;
    } else if (state.companies && state.companies.length) {
      return state.companies[0].id;
    } else {
      return null;
    }
  }
);

export const getUserCompanies = createSelector(
  getUserState,
  state => state.companies
);

export const userHasRole = (role: CompanyRole) =>
  createSelector(
    getCurrentCompany,
    company =>
      company && company.roles ? company.roles.some(r => r === role) : false
  );

export const getCompanyRoles = createSelector(
  getCurrentCompany,
  company => company.roles || []
);
