import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { Call } from '../../models/calls.model';
import { getActiveCall } from '../../store/call.selector';
import { Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { UpdateCall } from '../../store/call.actions';
import { withLatestFrom, filter, distinctUntilChanged } from 'rxjs/operators';
import { IncomingCallComponent } from '../../components/incoming-call/incoming-call.component';

@Component({
  selector: 'app-call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss']
})
export class CallComponent implements OnInit, OnDestroy {
  call$: Observable<Call>;
  findCustomer: FormControl = new FormControl();
  subscriptions = new Subscription();
  @ViewChild('incomingCall') incomingCall: IncomingCallComponent;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.call$ = this.store.pipe(select(getActiveCall));

    this.subscriptions.add(
      this.call$
        .pipe(distinctUntilChanged((a, b) => a && b && a.id === b.id))
        .subscribe(() => {
          this.findCustomer.reset();
        })
    );

    this.subscriptions.add(
      this.findCustomer.valueChanges
        .pipe(
          filter(value => value),
          withLatestFrom(this.call$)
        )
        .subscribe(([value, call]) => {
          this.store.dispatch(
            new UpdateCall({ id: call.id, customer: value.id })
          );
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
