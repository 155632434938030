import { Segment } from '../../models/segment';
import {
  ADD_SEGMENT,
  SEGMENT_CREATE,
  SEGMENT_CREATE_FAIL,
  SEGMENT_CREATE_SUCCESS,
  SEGMENT_FILTER_ADD,
  SEGMENT_FILTER_ADD_FAIL,
  SEGMENT_FILTER_ADD_SUCCESS,
  SEGMENT_LOAD,
  SEGMENT_LOAD_FAIL,
  SEGMENT_LOAD_SUCCESS,
  SEGMENT_UPDATE,
  SEGMENT_UPDATE_FAIL,
  SEGMENT_UPDATE_SUCCESS,
  SEGMENTS_FILTER_DELETE,
  SEGMENTS_FILTER_DELETE_FAIL,
  SEGMENTS_FILTER_DELETE_SUCCESS,
  SEGMENTS_FILTER_UPDATE,
  SEGMENTS_FILTER_UPDATE_FAIL,
  SEGMENTS_FILTER_UPDATE_SUCCESS,
  SEGMENTS_LOAD,
  SEGMENTS_LOAD_FAIL,
  SEGMENTS_LOAD_SUCCESS,
  SegmmentsAction,
  SET_SEGMENT,
  SEGMENTS_SET_FILTER,
  SEGMENTS_FILTERS_BATCH_DELETE,
  SEGMENTS_FILTERS_BATCH_DELETE_SUCCESS,
  SEGMENTS_FILTERS_BATCH_DELETE_FAIL,
  SEGMENT_FILTER_INIT,
  SEGMENTS_LOAD_ALL,
  SEGMENTS_LOAD_ALL_SUCCESS,
  SEGMENT_UPDATE_FROM_SOCKET,
} from '../actions/segments.action';
import { createSelector } from '@ngrx/store';
import { FullSegment } from '../../models/full-segment';
import { isReload } from '../../../shared/helpers/calculate-page';
import { FieldsError } from '../../../shared/models/http-error';
import { getSegmentsFeatureState } from '../selectors';
import { SegmentsFilter } from '../../models/segments-filter';
import { cloneDeep, pick, uniqBy } from 'lodash';

export interface SegmentsState {
  segments: FullSegment[];
  totalSegmentsCount: number;
  isLoading: boolean;
  isFirstLoading: boolean;
  updating: boolean;
  currentSegment: FullSegment;
  currentSegmentLoading: boolean;
  segmentErrors: FieldsError;
  filter: SegmentsFilter;
  byModel: boolean;
}

export const initialState: SegmentsState = {
  isLoading: false,
  isFirstLoading: false,
  segments: [],
  totalSegmentsCount: 0,
  updating: false,
  currentSegment: null,
  currentSegmentLoading: false,
  segmentErrors: null,
  filter: null,
  byModel: false,
};

export function SegmentsReducer(
  state = initialState,
  action: SegmmentsAction
): SegmentsState {
  switch (action.type) {
    case SEGMENTS_LOAD_ALL:
      return { ...state, isLoading: true };
    case SEGMENTS_LOAD_ALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFirstLoading: false,
        segments: action.payload.results,
        totalSegmentsCount: action.payload.count,
        byModel: false,
      };
    case SEGMENTS_LOAD:
      return { ...state, isLoading: true, isFirstLoading: isReload(action) };
    case SEGMENTS_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFirstLoading: false,
        segments: action.payload.results,
        totalSegmentsCount: action.payload.count,
        byModel: true,
      };
    case SEGMENTS_LOAD_FAIL:
      return { ...state, isLoading: false, isFirstLoading: false };
    case SEGMENT_LOAD:
      return { ...state, currentSegmentLoading: true };
    case SEGMENT_LOAD_SUCCESS:
      const currentSegment = new FullSegment(action.payload);
      return { ...state, currentSegmentLoading: false, currentSegment };
    case SEGMENT_LOAD_FAIL:
      return { ...state, currentSegmentLoading: false };
    case SEGMENT_CREATE:
    case SEGMENT_UPDATE:
    case SEGMENTS_FILTER_DELETE:
    case SEGMENTS_FILTERS_BATCH_DELETE:
      return { ...state, updating: true, segmentErrors: null };
    case SEGMENT_UPDATE_FAIL:
    case SEGMENT_CREATE_FAIL:
      return { ...state, segmentErrors: action.payload, updating: false };
    case SEGMENT_CREATE_SUCCESS:
    case SEGMENTS_FILTER_DELETE_FAIL:
    case SEGMENTS_FILTERS_BATCH_DELETE_SUCCESS:
    case SEGMENTS_FILTERS_BATCH_DELETE_FAIL:
      return { ...state, updating: false };
    case SEGMENT_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        currentSegment: state.currentSegment.updateAndClone(
          pick(action.payload, ['name', 'count'])
        ),
      };
    case SEGMENT_UPDATE_FROM_SOCKET:
      return {
        ...state,
        updating: false,
        currentSegment: state.currentSegment.updateAndClone(
          pick(action.payload.data, ['count'])
        )
      };
    case SEGMENT_FILTER_INIT:
      return {
        ...state,
        currentSegment: state.currentSegment.pushFilterAndClone(action.payload),
        updating: false,
      };
    case SEGMENT_FILTER_ADD:
    case SEGMENTS_FILTER_UPDATE:
      return {
        ...state,
        currentSegment: state.currentSegment.updateFilterAndClone(
          action.trackId,
          {
            ...pick(action.payload, [
              'id',
              'operator',
              'value',
              'property',
              'exclude',
              'count',
            ]),
            _isLoading: true,
            _isTouched: true,
          }
        ),
        updating: true,
      };
    case SEGMENT_FILTER_ADD_SUCCESS:
    case SEGMENTS_FILTER_UPDATE_SUCCESS:
      return {
        ...state,
        currentSegment: state.currentSegment
          .clone()
          .updateFilter(action.trackId, {
            ...pick(action.payload, [
              'id',
              'operator',
              'value',
              'property',
              'exclude',
              'count',
            ]),
            _isLoading: false,
          }),
        updating: false,
      };
    case SEGMENTS_FILTER_DELETE_SUCCESS:
      return {
        ...state,
        currentSegment: state.currentSegment.deleteFilterAndClone(
          action.trackId
        ),
        updating: false,
      };
    case SEGMENTS_FILTER_UPDATE_FAIL:
    case SEGMENT_FILTER_ADD_FAIL:
      return {
        ...state,
        currentSegment: state.currentSegment.updateFilterAndClone(
          action.trackId,
          {
            _isLoading: false,
          }
        ),
        segmentErrors: action.payload,
        updating: false,
      };
    case SET_SEGMENT:
      const segments = state.segments.map((it) =>
        it.id === action.payload.id ? action.payload : it
      );

      return {
        ...state,
        segments,
        currentSegment:
          state.currentSegment.id === action.payload.id
            ? state.currentSegment.updateAndClone({
                count: action.payload.count,
              })
            : state.currentSegment,
      };
    case SEGMENTS_SET_FILTER:
      return { ...state, filter: action.payload };
    case ADD_SEGMENT:
      return { ...state, segments: [...state.segments, action.payload] };
    default:
      return state;
  }
}

export const getSegmentsState = createSelector(
  getSegmentsFeatureState,
  (data) => data.segments
);
