import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SegmentModel } from '../../models/segment-model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SegmentModelsService {
  readonly basePath = 'segment-models/';
  constructor(private http: HttpClient) {}

  all(): Observable<SegmentModel[]> {
    return this.http.get<SegmentModel[]>(this.basePath);
  }

  one(id: string): Observable<SegmentModel> {
    return this.http.get<SegmentModel>(`${this.basePath}${id}`);
  }
}
