import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/reducers';
import { effects } from './store/effects';
import { CustomerCreateComponent } from './pages/customer-create/customer-create.component';
import { CustomerUpdateComponent } from './pages/customer-update/customer-update.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { CustomerFormComponent } from './components/customer-form/customer-form.component';
import { CustomersRoutingModule } from './routing/customers-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { TextMaskModule } from 'angular2-text-mask';
import { CustomersService } from './services/customers.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CustomersFilterComponent } from './components/customers-filter/customers-filter.component';
import { CustomerDisplayNamePipe } from './pipes/customer-display-name/customer-display-name.pipe';
import { CustomerEditComponent } from './components/customer-edit/customer-edit.component';
import { CustomersFinderComponent } from './components/customers-finder/customers-finder.component';
@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature(effects),
    StoreModule.forFeature('customers', reducers),
    CustomersRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    TextMaskModule,
    InfiniteScrollModule,
    PerfectScrollbarModule
  ],
  declarations: [
    CustomerCreateComponent,
    CustomerUpdateComponent,
    CustomersComponent,
    CustomerFormComponent,
    CustomersFilterComponent,
    CustomerDisplayNamePipe,
    CustomerEditComponent,
    CustomersFinderComponent
  ],
  providers: [CustomersService],
  exports: [
    CustomerDisplayNamePipe,
    CustomerEditComponent,
    CustomersFinderComponent
  ]
})
export class CustomersModule {}
