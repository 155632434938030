import { Component, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../store/reducers';
import { CallDirection } from '../../models/calls.model';
import { CallsHistorySetFilter } from '../../store/call.actions';
import { getDirections } from '../../store/call.selector';

@Component({
  selector: 'app-direction-selector',
  templateUrl: './direction-selector.component.html',
  styleUrls: ['./direction-selector.component.scss']
})
export class DirectionSelectorComponent implements OnDestroy {
  direction: FormControl = new FormControl();
  subscription = new Subject();
  directions: CallDirection[];

  constructor(private store: Store<AppState>) {
    this.store.pipe(select(getDirections)).subscribe(directions => this.directions = directions);

    this.direction.valueChanges.pipe(
      takeUntil(this.subscription),
      debounceTime(200)
    ).subscribe((f: string) => {
      this.store.dispatch(new CallsHistorySetFilter({
        subjects__direction: f,
      }));
    });
  }

  ngOnDestroy(): void {
    this.subscription.next();
    this.subscription.complete();
  }
}
