import { createSelector } from '@ngrx/store';
import {
  FORM_STATUS_LOAD,
  FORM_STATUS_LOAD_FAIL,
  FORM_STATUS_LOAD_SUCCESS,
  FORM_STATUS_LOAD_SUCCESS_WITHOUT_REVERT,
  FORM_STATUS_RESET,
  FormStatusActions
} from '../actions/form-status.actions';
import { getSharedState } from '../selectors';

export enum FormStatus {
  None = 0,
  Loading = 1,
  Success = 2,
  Fail = 3,
  SuccessWithoutRestore = 4
}

export interface FormStatusState {
  status: FormStatus;
}

export const initialState: FormStatusState = {
  status: FormStatus.None
};

export function FormStatusReducer(
  state: FormStatusState = initialState,
  action: FormStatusActions
): FormStatusState {
  switch (action.type) {
    case FORM_STATUS_LOAD:
      return {status: FormStatus.Loading};
    case FORM_STATUS_LOAD_SUCCESS:
      return {status: FormStatus.Success};
    case FORM_STATUS_LOAD_FAIL:
      return {status: FormStatus.Fail};
    case FORM_STATUS_RESET:
      return {status: FormStatus.None};
    case FORM_STATUS_LOAD_SUCCESS_WITHOUT_REVERT:
      return {status: FormStatus.SuccessWithoutRestore};
    default:
      return state;
  }
}

export const getFormStatusState = createSelector(getSharedState, data => data.formStatus);
