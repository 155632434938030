import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  ContactTipActionTypes,
  LoadContactTipsSuccess,
  LoadContactTipsFail
} from './contact-tip.actions';
import { ContactTipsService } from '../services/contact-tips/contact-tips.service';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ErrorHandlingService } from '../../shared/services/error-handling/error-handling.service';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs/internal/observable/of';

@Injectable()
export class ContactTipEffects {
  @Effect()
  loadTips$ = this.actions$.pipe(
    ofType(ContactTipActionTypes.LoadContactTips),
    switchMap(action => {
      return this.contactTipsService.all().pipe(
        map(tips => new LoadContactTipsSuccess(tips)),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handle(error.error);
          return of(new LoadContactTipsFail());
        })
      );
    })
  );

  constructor(
    private actions$: Actions,
    private contactTipsService: ContactTipsService,
    private errorHandler: ErrorHandlingService
  ) {}
}
