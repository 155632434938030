import { Component, OnInit, ViewChild } from '@angular/core';
import { SegmentEditComponent } from '../../components/segment-edit/segment-edit.component';

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss']
})
export class SegmentComponent implements OnInit {
  @ViewChild(SegmentEditComponent, { static: true }) editForm: SegmentEditComponent;

  public get showConfirmationOnNavigation() {
    return this.editForm && this.editForm.showConfirmationOnNavigation;
  }

  constructor() {}

  ngOnInit() {
  }

}
