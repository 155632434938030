import {
  DICTIONARIES_LOAD,
  DICTIONARIES_LOAD_FAIL,
  DICTIONARIES_LOAD_SUCCESS,
  DICTIONARY_CREATE,
  DICTIONARY_CREATE_FAIL,
  DICTIONARY_CREATE_SUCCESS,
  DICTIONARY_LOAD,
  DICTIONARY_LOAD_FAIL,
  DICTIONARY_LOAD_SUCCESS, DICTIONARY_UPDATE, DICTIONARY_UPDATE_FAIL, DICTIONARY_UPDATE_SUCCESS,
  DictionaryActions,
} from './dictionary.actions';
import {Dictionary} from '../../models/dictionary';
import {FieldsError} from '../../../shared/models/http-error';
import {createSelector} from '@ngrx/store';
import {getDictionaryModuleState} from '../selector';

export interface DictionaryState {
  dictionaries: Dictionary[];
  dictionary: Dictionary;
  loading: boolean;
  loadingDictionary: boolean;
  updating: boolean;
  formErrors: FieldsError;
}

export const initialState: DictionaryState = {
  dictionaries: [],
  dictionary: null,
  loading: false,
  updating: false,
  formErrors: null,
  loadingDictionary: false
};

export function DictionaryReducer(state = initialState, action: DictionaryActions): DictionaryState {
  switch (action.type) {
    case DICTIONARIES_LOAD:
      return { ...state, loading: true, dictionaries: [] };
    case DICTIONARIES_LOAD_SUCCESS:
      return { ...state, loading: false, dictionaries: action.payload };
    case DICTIONARIES_LOAD_FAIL:
      return { ...state, loading: false };
    case DICTIONARY_LOAD:
      return { ...state, loadingDictionary: true, dictionary: {id: action.payload, name: ''} };
    case DICTIONARY_LOAD_SUCCESS:
      return { ...state, loadingDictionary: false, dictionary: action.payload };
    case DICTIONARY_LOAD_FAIL:
      return { ...state, loadingDictionary: false };
    case DICTIONARY_CREATE:
      return { ...state, updating: true };
    case DICTIONARY_CREATE_SUCCESS:
      return { ...state, updating: false, dictionary: action.payload, dictionaries: state.dictionaries.concat(action.payload) };
    case DICTIONARY_CREATE_FAIL:
      return { ...state, updating: false, formErrors: action.payload };
    case DICTIONARY_UPDATE:
      return { ...state,  updating: true };
    case DICTIONARY_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        dictionary: action.payload,
        dictionaries: state.dictionaries.map(it => it.id === action.payload.id ? action.payload : it)
      };
    case DICTIONARY_UPDATE_FAIL:
      return { ...state, updating: false, formErrors: action.payload };

    default:
      return state;
  }
}

export const getDictionaryState = createSelector(getDictionaryModuleState, data => data.dictionaries);
