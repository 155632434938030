<ng-select [items]="models$ | async"
           [formControl]="currentValue"
           [virtualScroll]="true"
           [multiple]="false"
           [hideSelected]="true"
           [loading]="isModelsLoading"
           [typeahead]="modelTypeahead$"
           [placeholder]="usePlaceholder ? ('Employee' | translate) : ''"
           (scroll)="scroll($event)"
           (scrollToEnd)="fetchMore()">
  <ng-template ng-option-tmp
               let-item="item">
    {{item | employee}}
  </ng-template>
  <ng-template ng-label-tmp
               let-item="item"
               let-clear="clear">
    {{item | employee}}
  </ng-template>
</ng-select>
