import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  OnDestroy,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { isEqual, omit } from 'lodash';

import { AppState } from '../../../../../store/reducers/index';
import { getSegmentPropertyById } from '../../../store/selectors/segment-properties.selector';
import {
  SegmentProperty,
  SEGMENT_PROPERTY_NAMES,
} from '../../../models/segment-property';
import { SegmentFilter } from '../../../models/full-segment';
import { FieldsError } from '../../../../shared/models/http-error';
import { ConfirmationDialogService } from '../../../../shared/services/confirmation-dialog/confirmation-dialog.service';
import { getSegmentFilterByTrackId } from '../../../store/selectors/segments.selector';
import { _ } from '../../../../shared/helpers/translation-marker';

@Component({
  selector: 'app-filter',
  templateUrl: './segment-filter.component.html',
  styleUrls: ['./segment-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SegmentFilterComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SegmentFilterComponent),
      multi: true,
    },
  ],
})
export class SegmentFilterComponent
  implements ControlValueAccessor, Validator, OnDestroy {
  @Output() removed = new EventEmitter();
  @Output() saved = new EventEmitter();

  @Input() isEdit: boolean;
  @Input() formSubmitted: boolean;
  @Input() formError: FieldsError;

  property$: Observable<SegmentProperty>;
  filter$: Observable<SegmentFilter>;
  currentValue: SegmentFilter;
  propertyNames = SEGMENT_PROPERTY_NAMES;
  filter: FormControl;
  subscriptions = new Subscription();
  disabled = false;
  isChanging = true;
  isTyping = true;
  exclude;

  constructor(
    private store: Store<AppState>,
    private confirmationDialog: ConfirmationDialogService
  ) {
    this.filter = new FormControl();

    this.subscriptions.add(
      this.filter.valueChanges.subscribe((filter) =>
        this.onChange({ ...filter, exclude: this.exclude })
      )
    );
  }

  init() {
    this.filter$ = this.store.pipe(
      select(getSegmentFilterByTrackId(this.currentValue._trackId))
    );
  }

  onChange = (_) => {};
  onTouched = () => {};

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.subscriptions.add(this.filter.valueChanges.subscribe(fn));
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(c: AbstractControl): { [key: string]: any } {
    return this.filter.errors;
  }

  toggleExclude() {
    this.exclude = !this.exclude;
    this.filter.markAsDirty();
    this.onChange({ ...this.filter.value, exclude: this.exclude });
  }

  writeValue(obj: SegmentFilter): void {
    this.currentValue = obj;
    this.property$ = this.store.pipe(
      select(getSegmentPropertyById(this.currentValue.property))
    );
    if (!obj._isLoading) {
      this.filter.markAsPristine();
      this.filter.markAsUntouched();
    }
    if (
      !isEqual(
        omit(obj, ['_isLoading']),
        omit(this.filter.value, ['_isLoading'])
      )
    ) {
      this.isChanging = true;
      this.filter.setValue(obj);
      this.exclude = !!obj.exclude;
      this.isChanging = false;
    }
  }

  isSaveDisabled(): boolean {
    return this.disabled || this.filter.invalid || this.filter.pristine;
  }

  remove() {
    this.confirmationDialog
      .confirmV1(
        _('Do you really want to completely remove filter?'),
        _('Remove Filter'),
        _('Yes'),
        _('No'),
        true
      )
      .pipe(
        take(1),
        filter((accept) => !!accept)
      )
      .subscribe((val) => {
        this.removed.emit();
      });
  }

  save() {
    this.saved.emit();
  }

  get hasChanges() {
    return this.filter.dirty || this.currentValue._isLoading;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
