<div class="badges">
  <span
    *ngFor="let item of displayFilters"
    class="badge badge-pill"
    [class.clickable]="item.valueCollapsed"
    [class.exclude]="item.exclude"
    (click)="item.valueCollapsed && onClickItem($event, item)"
  >
    <span>{{ item.name | translate }}</span> {{ ' ' }}
    <span> {{ item.operator | translate }}</span>
    {{ ' ' }}
    <span>
      {{
        item.isCollapsed && item.valueCollapsed
          ? item.valueCollapsed
          : item.value
      }}</span
    >
    <ng-container *ngIf="item.isCollapsed && item.valueCollapsed">
      <span class="collapse show">
        {{ item.count ? '+' + item.count : '...' }}</span
      >
    </ng-container>
  </span>
</div>
