export const deliveryCreatedMessageType = 'ORDER_DELIVERY_CREATED';
export const deliveryUpdatedMessageType = 'ORDER_DELIVERY_UPDATED';

export interface OrderDeliveryUpdatedMessage {
  type: typeof deliveryCreatedMessageType | typeof deliveryCreatedMessageType;
  data: {
    model: string;
    id: string;
  };
}
