export const ITEMS_PER_PAGE = 40;

export const INITIAL_PAGE_SETTINGS = {
  page_size: ITEMS_PER_PAGE,
};

export interface PagebleActionPayload {
  isReload?: boolean;
  isRefresh?: boolean;
}

export class PagebleAction extends Object {
  payload?: PagebleActionPayload;
}

export interface PageSettings {
  page?: number;
  page_size: number;
}

export interface OutputPagebleAction<T extends PagebleAction> {
  originalAction: T;
  pageSettings: PageSettings;
}

export function isReload(action: PagebleAction) {
  return !action.payload || action.payload.isReload !== false;
}

export function isRefresh(action: PagebleAction) {
  return action.payload && action.payload.isRefresh;
}

export function calculatePage<T extends PagebleAction>(
  action: T,
  loadedCount: number
): OutputPagebleAction<T> {
  const reload = isReload(action); // true by default
  const refresh = isRefresh(action); // true by default
  let page, pages;

  if (refresh) {
    page = 1;
    pages = Math.ceil(loadedCount / ITEMS_PER_PAGE);
  } else {
    if (reload || loadedCount === 0) {
      page = 1;
      pages = 1;
    } else {
      page = Math.floor(loadedCount / ITEMS_PER_PAGE) + 1;
      pages = 1;
    }
  }

  return {
    originalAction: action,
    pageSettings: {
      page,
      page_size: pages * ITEMS_PER_PAGE
    }
  };
}

export function getPageSettingsFromUrl(url) {
  const parsedUrl = new URL(url);

  return {
    cursor: parsedUrl.searchParams.get('cursor'),
    page_size: +(parsedUrl.searchParams.get('page_size') || ITEMS_PER_PAGE),
  };
}
