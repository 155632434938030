import { ErrorHandler } from '@angular/core';
import { environment } from '../../environments/environment';
import * as Raven from 'raven-js';

Raven.config(environment.ravenUrl).install();

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    Raven.captureException(err.originalError || err);
  }
}
