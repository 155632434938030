import { isReload } from '../../shared/helpers/calculate-page';
import { Department, DepartmentsFilter } from '../models/department.model';
import { DepartmentActions, DepartmentActionTypes } from './department.actions';


export interface DepartmentState {
  departments: Department[];
  isLoadingDepartments: boolean;
  isReload: boolean;
  departmentsCount: number;
  departmentsFilter: DepartmentsFilter;
}

export const initialState: DepartmentState = {
  departments: [],
  isLoadingDepartments: false,
  isReload: false,
  departmentsCount: 0,
  departmentsFilter: {}
};

export function DepartmentReducer(state = initialState, action: DepartmentActions): DepartmentState {
  switch (action.type) {
    case DepartmentActionTypes.LoadDepartments:
      return {
        ...state,
        isLoadingDepartments: true,
        isReload: isReload(action)
      };
    case DepartmentActionTypes.LoadDepartmentsSuccess:
      return {
        ...state,
        isReload: false,
        isLoadingDepartments: false,
        departments: action.payload.results,
        departmentsCount: action.payload.count
      };
    case DepartmentActionTypes.LoadDepartmentsFail:
      return {
        ...state,
        isReload: false,
        isLoadingDepartments: false
      };
    case DepartmentActionTypes.SetFilterDepartments:
      return {
        ...state,
        departmentsFilter: {
          ...state.departmentsFilter,
          ...action.payload
        }
      };
    default:
      return state;
  }
}
