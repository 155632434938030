import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../../store/reducers';
import { PRODUCT_MODEL_NAME } from '../../../../segments/models/segment';
import { SegmentProperty } from '../../../../segments/models/segment-property';
import {
  getSegmentPropertiesByModel,
  getSegmentPropertyById,
} from '../../../../segments/store/selectors/segment-properties.selector';
import { ControlInputs, ControlType } from '../../../models/dynamic-control';
import { SegmentPropertiesService } from '../../../service/segment-properties/segment-properties.service';

@Component({
  selector: 'app-model-field',
  templateUrl: './model-field.component.html',
  styleUrls: ['./model-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModelFieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ModelFieldComponent),
      multi: true,
    },
  ],
})
export class ModelFieldComponent
  implements OnInit, ControlValueAccessor, Validator, OnDestroy
{
  @Input() modelName = PRODUCT_MODEL_NAME;

  @Input()
  set submitted(value: boolean) {
    this.dynamicInputs.submitted = !!value;
  }

  get parentId(): string {
    return this._parentId;
  }

  @Input()
  set parentId(value: string) {
    this._parentId = value;
    if (value) {
      combineLatest(
        this.store.pipe(select(getSegmentPropertyById(value))).pipe(take(1)),
        this.store.pipe(
          select(getSegmentPropertiesByModel(this.modelName)),
          map((properties) => properties.filter((p) => p.is_readable))
        )
      )
        .pipe(take(1))
        .subscribe(([segmentProperty, data]: [any, SegmentProperty[]]) => {
          this.dynamicInputs.property = cloneDeep(segmentProperty);
          this.allSegmentProperties = data;
          this.dynamicInputs.type = this.controlType();

          if (this.dynamicInputs.property) {
            const parent = data.find(
              (it) => it.id === this.dynamicInputs.property.parent
            );
            if (this.dynamicInputs.property.type === 'bool') {
              this.dynamicInputs.property.widget = 'bool';
            }
            if (
              this.dynamicInputs.property.path === 'data' ||
              this.dynamicInputs.property.is_many ||
              (parent && parent.type !== 'dict')
            ) {
              return;
            }

            const extIdProperty = data.find((it) => {
              return (
                it.parent === this.dynamicInputs.property.id &&
                it.path.endsWith('ext_id')
              );
            });

            if (!extIdProperty) {
              return;
            }

            this.dynamicInputs.property = {
              ...extIdProperty,
              path: this.dynamicInputs.property.path,
              widget: 'enum',
              name: this.dynamicInputs.property.name,
            };

            setTimeout(() => {
              this.onValidationChange();
            });
          }

          this.dynamicInputs.type = this.controlType();
        });
    }
  }

  private _parentId: string;
  formControl = new FormControl();
  subscription = new Subject();
  segmentPropertiesSub: Subscription;
  allSegmentProperties: SegmentProperty[];

  dynamicInputs: ControlInputs = {
    type: ControlType.Scalar,
    property: null,
    submitted: false,
    modelName: this.modelName,
  };

  onChange = (_) => {};

  onTouched = () => {};

  onValidationChange = () => {};

  constructor(private store: Store<AppState>) {
    this.formControl.valueChanges
      .pipe(
        takeUntil(this.subscription),
        filter(() => !!this.onChange)
      )
      .subscribe((data) => this.onChange(data));
  }

  ngOnInit() {
    this.dynamicInputs.modelName = this.modelName;
  }

  hasChildren(id: string) {
    return this.allSegmentProperties.some(
      (it) => it.parent === id && it.is_readable
    );
  }

  checkIfIsObject(): boolean {
    return (
      this.hasChildren(this.dynamicInputs.property.id) &&
      !this.dynamicInputs.property.is_many &&
      this.dynamicInputs.property.widget !== 'enum'
    );
  }

  checkIfIsScalar(): boolean {
    return (
      !this.hasChildren(this.dynamicInputs.property.id) ||
      this.dynamicInputs.property.widget === 'enum'
    );
  }

  checkIfIsArray(): boolean {
    return (
      this.hasChildren(this.dynamicInputs.property.id) &&
      this.dynamicInputs.property.is_many
    );
  }

  ngOnDestroy(): void {
    this.subscription.next();
    this.subscription.complete();
    if (this.segmentPropertiesSub) {
      this.segmentPropertiesSub.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.formControl.valueChanges
      .pipe(takeUntil(this.subscription))
      .subscribe(fn);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.formControl.errors;
  }

  writeValue(obj: any): void {
    this.formControl.patchValue(obj);
    setTimeout(() => {
      this.onValidationChange();
    });
  }

  controlType(): ControlType {
    if (this.checkIfIsArray()) {
      return ControlType.Array;
    } else if (this.checkIfIsScalar()) {
      return ControlType.Scalar;
    } else if (this.checkIfIsObject()) {
      return ControlType.Object;
    }
  }
}
