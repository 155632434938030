import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { DepartmentSelectorComponent } from './components/department-selector/department-selector.component';
import { DepartmentEffects } from './store/department.effects';
import { DepartmentReducer } from './store/department.reducer';

@NgModule({
  declarations: [DepartmentSelectorComponent],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature('department', DepartmentReducer),
    EffectsModule.forFeature([DepartmentEffects]),
    ReactiveFormsModule
  ],
  exports: [DepartmentSelectorComponent]
})
export class DepartmentsModule {
}
