import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../../modules/shared/services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AddAuthTokenInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skippedUrls = ['send_code/', 'login/', 'check_code/', 'verify_code/'];
    const isSkippedUrl = skippedUrls.filter(it => req.url.endsWith(it)).length > 0;
    if (isSkippedUrl) {
      return next.handle(req);
    }

    const newReq = req.clone({
      headers: req.headers.set('Authorization', `Token ${this.authService.getPlainAccessToken()}`)
    });
    return next.handle(newReq);
  }
}
