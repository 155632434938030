<form [formGroup]="form" (submit)="submit()">
  <div class="panel">
    <ng-container *ngIf="!(loading$ | async); else loading">
      <div class="form-header">
        <h3>{{title | translate}}</h3>
      </div>
      <fieldset class="form-fieldset">
        <div class="form-group row">
          <label for="name"
                 class="col-sm-2 col-form-label">{{'Name' | translate}}</label>
          <div class="col-sm-10">
            <input type="text"
                   class="form-control"
                   id="name"
                   formControlName="name">
            <div>
              <app-errors-component [control]="form.get('name')"
                                    [submitted]="submitted"></app-errors-component>
            </div>
          </div>
        </div>
      </fieldset>
    </ng-container>
  </div>
  <div *ngIf="!(loading$ | async)"
       class="mt-4 text-right">
    <button type="submit" class="btn btn-primary">{{ 'Save' | translate}}</button>
  </div>
</form>
<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
