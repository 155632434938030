import { Action } from '@ngrx/store';

export const FORM_STATUS_LOAD = '[Form status] load';
export const FORM_STATUS_LOAD_FAIL = '[Form status] load fail';
export const FORM_STATUS_LOAD_SUCCESS = '[Form status] load success';
export const FORM_STATUS_LOAD_SUCCESS_WITHOUT_REVERT = '[Form status] load success w/out revert';
export const FORM_STATUS_RESET = '[Form status] reset';


export class FormStatusLoad implements Action {
  readonly type = FORM_STATUS_LOAD;

  constructor() {
  }
}

export class FormStatusLoadSuccess implements Action {
  readonly type = FORM_STATUS_LOAD_SUCCESS;

  constructor() {
  }
}

export class FormStatusLoadFail implements Action {
  readonly type = FORM_STATUS_LOAD_FAIL;

  constructor() {
  }
}

export class FormStatusReset implements Action {
  readonly type = FORM_STATUS_RESET;

  constructor() {
  }
}

export class FormStatusLoadSuccessWithoutRestore implements Action {
  readonly type = FORM_STATUS_LOAD_SUCCESS_WITHOUT_REVERT;

  constructor() {
  }
}

export type FormStatusActions =
  | FormStatusLoad
  | FormStatusLoadSuccess
  | FormStatusLoadFail
  | FormStatusReset
  | FormStatusLoadSuccessWithoutRestore;
