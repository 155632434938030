import { Action } from '@ngrx/store';
import { PagebleActionPayload } from '../../shared/helpers/calculate-page';
import { PagebleEntity } from '../../shared/models/pageble-entity';
import {
  Call,
  CallActionReason,
  CallDirection,
  CallsFilter,
  CallSubject,
  PartialCall,
  RedirectCall
} from '../models/calls.model';
import { CallAudio } from '../models/call-audio';

export enum CallActionTypes {
  LoadCalls = '[Calls] load',
  LoadCallsHistory = '[Calls History] load',
  LoadCallsHistoryDebounced = '[Calls History] load debounced',
  LoadCallsHistorySuccess = '[Calls History] load success',
  LoadCallsHistoryFail = '[Calls History] load fail',
  ClearCallsHistory = '[Calls History] clear',
  LoadCall = '[Call] Load Call',
  NavigateToCall = '[Call] Navigate To Call',
  NavigateToCallSuccess = '[Call] Navigate To Call Success',
  SetCallsHistoryFilter = '[Calls History] set filter',
  SetActiveCall = '[Call] Set Active Call',
  LoadCallSuccess = '[Call] Load Call Success',
  LoadCallFail = '[Call] Load Call Fail',
  CreateCall = '[Call] Create Call',
  CreateCallFail = '[Call] Create Call Fail',
  UpdateCall = '[Call] Update Call',
  UpdateCallSuccess = '[Call] Update Call Success',
  UpdateCallFail = '[Call] Update Call Fail',
  HangUpCall = '[Call] Hang Up Call',
  FinishCall = '[Call] Finish Call',
  FinishCallSuccess = '[Call] Finish Call Success',
  FinishCallFail = '[Call] Finish Call Fail',
  ResumeCall = '[Call] Resume Call',
  HoldCall = '[Call] Hold Call',
  AnswerCall = '[Call] Answer Call',
  TransferCall = '[Call] transfer call',
  TransferCallSuccess = '[Call] transfer call success',
  TransferCallFail = '[Call] transfer call fail',
  LoadDirections = '[Call] Load Directions',
  LoadDirectionsSuccess = '[Call] Load Directions Success',
  LoadDirectionsFail = '[Call] Load Directions Fail',
  LoadSubjects = '[Call] Load Subjects',
  LoadSubjectsSuccess = '[Call] Load Subjects Success',
  LoadSubjectsFail = '[Call] Load Subjects Fail',
  LoadActionReasons = '[Call] Load Change Reasons',
  LoadActionReasonsSuccess = '[Call] Load Change Reasons Success',
  LoadActionReasonsFail = '[Call] Load Change Reasons Fail',
  LoadUnfinishedCalls = '[Call] Load Active Calls',
  LoadUnfinishedCallsSuccess = '[Call] Load Active Calls Success',
  LoadUnfinishedCallsFail = '[Call] Load Active Calls Fail',
  CallToCustomer = '[Call] call to customer',
  CallToCustomerSuccess = '[Call] call to customer success',
  CallToOrderRecipient = '[Call] call to order recipient',
  CallToOrderRecipientSuccess = '[Call] call to order recipient success',

  SetCurrentHistoryCallAudio = '[Call] set current history call audio',
  PlayHistoryCallAudio = '[Call] set and play current history call audio',
  StopHistoryCallAudio = '[Call] stop current history call audio',
  PauseHistoryCallAudio = '[Call] pause current history call audio',
  ResumeHistoryCallAudio = '[Call] resume current history call audio'
}

export class CreateCall implements Action {
  readonly type = CallActionTypes.CreateCall;
  constructor(public payload: Call) {}
}

export class NavigateToCall implements Action {
  readonly type = CallActionTypes.NavigateToCall;
  constructor(public id: string) {}
}

export class NavigateToCallSuccess implements Action {
  readonly type = CallActionTypes.NavigateToCallSuccess;
  constructor(public id: string) {}
}

export class CreateCallFail implements Action {
  readonly type = CallActionTypes.CreateCallFail;
}

export class UpdateCall implements Action {
  readonly type = CallActionTypes.UpdateCall;
  constructor(public payload: PartialCall) {}
}

export class UpdateCallSuccess implements Action {
  readonly type = CallActionTypes.UpdateCallSuccess;
  constructor(public payload: Call) {}
}

export class UpdateCallFail implements Action {
  readonly type = CallActionTypes.UpdateCallFail;
}

export class LoadUnfinishedCalls implements Action {
  readonly type = CallActionTypes.LoadUnfinishedCalls;
}

export class LoadUnfinishedCallsSuccess implements Action {
  readonly type = CallActionTypes.LoadUnfinishedCallsSuccess;
  constructor(public payload?: PagebleEntity<Call>) {}
}

export class LoadUnfinishedCallsFail implements Action {
  readonly type = CallActionTypes.LoadUnfinishedCallsFail;
}

export class LoadCalls implements Action {
  readonly type = CallActionTypes.LoadCalls;
}

export class LoadCallsHistoryDebounced implements Action {
  readonly type = CallActionTypes.LoadCallsHistoryDebounced;
  constructor(public payload?: PagebleActionPayload) {}
}

export class LoadCallsHistory implements Action {
  readonly type = CallActionTypes.LoadCallsHistory;
  constructor(public payload?: PagebleActionPayload) {}
}

export class LoadCallsHistorySuccess implements Action {
  readonly type = CallActionTypes.LoadCallsHistorySuccess;
  constructor(public payload?: PagebleEntity<Call>) {}
}

export class CallsHistorySetFilter implements Action {
  readonly type = CallActionTypes.SetCallsHistoryFilter;
  constructor(public payload: CallsFilter) {}
}

export class LoadCallsHistoryFail implements Action {
  readonly type = CallActionTypes.LoadCallsHistoryFail;
}

export class ClearCallsHistory implements Action {
  readonly type = CallActionTypes.ClearCallsHistory;
}

export class LoadCall implements Action {
  readonly type = CallActionTypes.LoadCall;
  constructor(public id: string) {}
}

export class SetActiveCall implements Action {
  readonly type = CallActionTypes.SetActiveCall;
  constructor(public id: string) {}
}

export class LoadCallSuccess implements Action {
  readonly type = CallActionTypes.LoadCallSuccess;
  constructor(public payload: Call) {}
}

export class LoadCallFail implements Action {
  readonly type = CallActionTypes.LoadCallFail;
}

export class HangUpCall implements Action {
  readonly type = CallActionTypes.HangUpCall;
  constructor(public id: string) {}
}

export class ResumeCall implements Action {
  readonly type = CallActionTypes.ResumeCall;
  constructor(public id: string) {}
}

export class FinishCall implements Action {
  readonly type = CallActionTypes.FinishCall;
  constructor(public id: string, public payload: any) {}
}

export class FinishCallSuccess implements Action {
  readonly type = CallActionTypes.FinishCallSuccess;
  constructor(public payload: Call) {}
}

export class FinishCallFail implements Action {
  readonly type = CallActionTypes.FinishCallFail;
}

export class HoldCall implements Action {
  readonly type = CallActionTypes.HoldCall;
  constructor(public id: string) {}
}

export class AnswerCall implements Action {
  readonly type = CallActionTypes.AnswerCall;
  constructor(public id: string) {}
}

export class TransferCall implements Action {
  readonly type = CallActionTypes.TransferCall;
  constructor(public id: string, public payload: RedirectCall) {}
}

export class TransferCallSuccess implements Action {
  readonly type = CallActionTypes.TransferCallSuccess;
}

export class TransferCallFail implements Action {
  readonly type = CallActionTypes.TransferCallFail;
}

export class LoadDirections implements Action {
  readonly type = CallActionTypes.LoadDirections;
}

export class LoadDirectionsSuccess implements Action {
  readonly type = CallActionTypes.LoadDirectionsSuccess;
  constructor(public payload: CallDirection[]) {}
}

export class LoadDirectionsFail implements Action {
  readonly type = CallActionTypes.LoadDirectionsFail;
}

export class LoadSubjects implements Action {
  readonly type = CallActionTypes.LoadSubjects;
}

export class LoadSubjectsSuccess implements Action {
  readonly type = CallActionTypes.LoadSubjectsSuccess;
  constructor(public payload: CallSubject[]) {}
}

export class LoadSubjectsFail implements Action {
  readonly type = CallActionTypes.LoadSubjectsFail;
}

export class LoadActionReasons implements Action {
  readonly type = CallActionTypes.LoadActionReasons;
}

export class LoadActionReasonsSuccess implements Action {
  readonly type = CallActionTypes.LoadActionReasonsSuccess;
  constructor(public payload: CallActionReason[]) {}
}

export class LoadActionReasonsFail implements Action {
  readonly type = CallActionTypes.LoadActionReasonsFail;
}

export class CallToCustomer implements Action {
  readonly type = CallActionTypes.CallToCustomer;
  constructor(public id: string, public purpose: string = null) {}
}

export class CallToCustomerSuccess implements Action {
  readonly type = CallActionTypes.CallToCustomerSuccess;
  constructor(public payload: Call, public purpose: string = null) {}
}

export class CallToOrderRecipient implements Action {
  readonly type = CallActionTypes.CallToOrderRecipient;
  constructor(public id: string, public purpose: string = null) {}
}

export class CallToOrderRecipientSuccess implements Action {
  readonly type = CallActionTypes.CallToOrderRecipientSuccess;
  constructor(public payload: Call, public purpose: string = null) {}
}

export class SetCurrentHistoryCallAudio implements Action {
  readonly type = CallActionTypes.SetCurrentHistoryCallAudio;
  constructor(public payload?: CallAudio) {}
}

export class PlayHistoryCallAudio implements Action {
  readonly type = CallActionTypes.PlayHistoryCallAudio;
  constructor(public payload?: CallAudio) {}
}

export class StopHistoryCallAudio implements Action {
  readonly type = CallActionTypes.StopHistoryCallAudio;
}

export class PauseHistoryCallAudio implements Action {
  readonly type = CallActionTypes.PauseHistoryCallAudio;
}

export class ResumeHistoryCallAudio implements Action {
  readonly type = CallActionTypes.ResumeHistoryCallAudio;
}

export type CallActions =
  | LoadCalls
  | LoadCall
  | NavigateToCall
  | NavigateToCallSuccess
  | SetActiveCall
  | UpdateCall
  | UpdateCallSuccess
  | UpdateCallFail
  | LoadCallSuccess
  | LoadCallFail
  | HangUpCall
  | FinishCall
  | FinishCallSuccess
  | FinishCallFail
  | ResumeCall
  | HoldCall
  | AnswerCall
  | TransferCall
  | LoadDirections
  | LoadDirectionsSuccess
  | LoadDirectionsFail
  | LoadSubjects
  | LoadSubjectsSuccess
  | LoadSubjectsFail
  | LoadActionReasons
  | LoadActionReasonsSuccess
  | LoadActionReasonsFail
  | LoadCallsHistory
  | LoadCallsHistoryDebounced
  | LoadCallsHistorySuccess
  | LoadCallsHistoryFail
  | ClearCallsHistory
  | CallsHistorySetFilter
  | LoadUnfinishedCalls
  | LoadUnfinishedCallsSuccess
  | LoadUnfinishedCallsFail
  | CallToCustomer
  | CallToCustomerSuccess
  | CallToOrderRecipient
  | CallToOrderRecipientSuccess
  | SetCurrentHistoryCallAudio
  | PlayHistoryCallAudio
  | StopHistoryCallAudio
  | PauseHistoryCallAudio
  | ResumeHistoryCallAudio;
