import { PagebleEntity } from '../models/pageble-entity';
import {
  isReload,
  OutputPagebleAction,
  PagebleAction,
  isRefresh,
} from './calculate-page';
import { uniqBy } from 'lodash';

export function appendOrSetCollection<T>(
  action: OutputPagebleAction<any>,
  data: PagebleEntity<T>,
  currentCollection: T[]
) {
  const reload =
    isReload(action.originalAction) || isRefresh(action.originalAction);

  data.results = reload
    ? data.results
    : uniqBy([...currentCollection, ...data.results], 'id');

  return data;
}

export function upsertItem<T extends { id: string }>(items: T[], item: T) {
  return items.filter((it) => it.id !== item.id).concat(item);
}

export function appendOrReplaceItem<T extends { id: string }>(
  item: T,
  currentCollection: T[]
) {
  const idx = currentCollection.findIndex((it) => it.id === item.id);
  if (idx !== -1) {
    return currentCollection.map((it, index) => (index === idx ? item : it));
  } else {
    return [...[item], ...currentCollection];
  }
}
