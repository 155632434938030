import { createFeatureSelector } from '@ngrx/store';
import * as socketAction from '../actions/socket.action';

export enum SocketConnectionState {
  Connecting,
  Connected,
  NotConnected
}

export interface SocketState {
  connectionState: SocketConnectionState;
  message: any;
  autoRetry: boolean;
}

export const initialState: SocketState = {
  connectionState: SocketConnectionState.NotConnected,
  message: null,
  autoRetry: true
};

export function SocketReducer(
  state: SocketState = initialState,
  action: socketAction.SocketAction
): SocketState {
  switch (action.type) {
    case socketAction.SOCKET_CONNECT:
      return { ...state, connectionState: SocketConnectionState.Connecting };
    case socketAction.SOCKET_CONNECT_SUCCESS:
      return { ...state, connectionState: SocketConnectionState.Connected };
    case socketAction.SOCKET_CONNECT_FAIL:
      return { ...state, connectionState: SocketConnectionState.NotConnected };
    case socketAction.SOCKET_DISCONNECT:
      return { ...state, connectionState: SocketConnectionState.NotConnected };
    case socketAction.SET_RETRY_FLAG:
      return { ...state, autoRetry: action.payload };
    default:
      return state;
  }
}

export const getSocketStore = createFeatureSelector('socket');
