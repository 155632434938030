import { Pipe, PipeTransform } from '@angular/core';
import { Employee } from '../../../employees/models/employee.model';

@Pipe({
  name: 'employee'
})
export class EmployeePipe implements PipeTransform {
  transform(value: Employee, args?: any): any {
    if (value) {
      const full_name =
        (value.last_name ? value.last_name + ' ' : '') +
        (value.first_name ? value.first_name + ' ' : '') +
        (value.middle_name ? value.middle_name : '');
      return full_name.trim().length ? full_name : 'Unknown Courier';
    } else {
      return '';
    }
  }
}
