<div class="wrap list-wrapper">
  <div class="row flex-shrink-0">
    <div class="col-6">
      <app-segments-search></app-segments-search>
    </div>
    <div class="col text-right">
      <a *ngIf="showCreateNewButton"
         [routerLink]="['/segments', currentSegmentModel, 'new']"
         class="btn btn-primary">
        {{'Create segment' | translate}}
      </a>
    </div>
  </div>
  <div class="panel" perfectScrollbar
       #infiniteScrollContainer infiniteScroll [scrollWindow]='false' (scrolled)='onScroll()'>
    <app-empty-list-placeholder *ngIf='segments.length === 0 && !isLoading; else segmentsTable;'
                                icon="icon-segment"
                                [header]="'No segments' | translate"
                                [body]="'There are no segments found' | translate"
                                [btnLabel]="'Create segment' | translate"
                                (btnClick)="createSegment()"></app-empty-list-placeholder>

    <app-loader *ngIf="isLoading"></app-loader>
  </div>
</div>

<ng-template #segmentsTable>
  <table *ngIf="!(isFirstLoading$ | async)" #infiniteScrollableElement
         class="table table-hover table-striped"
         [class.processing]="updating$ | async">
    <thead>
      <tr>
        <th class="name">{{'Name' | translate}}</th>
        <th class="filters">{{'Filters' | translate}}</th>
        <th class="text-right">{{'Count' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr class='clickable'
          *ngFor="let segment of segments"
          (click)='onRowClick(segment)'>
        <td>
          {{segment.name}}
        </td>
        <td>
          <app-filter-list [collection]='segment.filters'></app-filter-list>
        </td>
        <td class="text-right">{{segment.count}}</td>
      </tr>
    </tbody>
  </table>
</ng-template>
