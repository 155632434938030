import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  withLatestFrom,
  combineLatest,
  startWith,
  mergeMap,
  filter,
  concatMap,
  debounceTime,
} from 'rxjs/operators';
import { AppState } from '../../../../../store/reducers';
import { ErrorHandlingService } from '../../../../shared/services/error-handling/error-handling.service';
import {
  OrderDeliveryFilter,
  OrderDelivery,
} from '../../../models/order-delivery.model';
import { OrderDeliveriesService } from '../../../services/order-deliveries/order-deliveries.service';
import {
  ChangeCourierOrderDelivery,
  ChangeCourierOrderDeliveryFail,
  ChangeCourierOrderDeliverySuccess,
  ChangeDeliveryDateOrderDelivery,
  ChangeDeliveryDateOrderDeliveryFail,
  ChangeDeliveryDateOrderDeliverySuccess,
  LoadOrderDeliveriesFail,
  LoadOrderDeliveriesSuccess,
  OrderDeliveryActionTypes,
  UpdateOrderDelivery,
  UpdateOrderDeliveryFail,
  UpdateOrderDeliverySuccess,
  ConfirmOrderDelivery,
  ConfirmOrderDeliverySuccess,
  ConfirmOrderDeliveryFail,
  RescheduleOrderDelivery,
  RescheduleOrderDeliverySuccess,
  RescheduleOrderDeliveryFail,
  LoadOrderDeliveryActionReasons,
  LoadOrderDeliveryActionReasonsFail,
  LoadOrderDeliveryActionReasonsSuccess,
  LoadOrderDeliveriesOfOrder,
  LoadOrderDeliveriesOfOrderSuccess,
  LoadOrderDeliveriesOfOrderFail,
  CancelOrderDelivery,
  CancelOrderDeliverySuccess,
  CancelOrderDeliveryFail,
  LoadOrderDelivery,
  LoadOrderDeliverySuccess,
  LoadOrderDeliveryFail,
  RejectOrderDelivery,
  RejectOrderDeliverySuccess,
  RejectOrderDeliveryFail, CancelExternalOrderDelivery, CancelExternalOrderDeliverySuccess, CancelExternalOrderDeliveryFail,
} from './order-delivery.actions';
import {
  getOrderDeliveriesFilter,
  getOrderDeliveries,
  getOrderDeliveriesOfOrder,
  getOrderDeliveriesNextPage,
} from './order-delivery.selector';
import { ofMessage } from 'src/app/modules/shared/helpers/operators/ofMessage';
import {
  deliveryCreatedMessageType,
  deliveryUpdatedMessageType,
  OrderDeliveryUpdatedMessage,
} from '../../../models/delivery-soket-message.model';
import {
  orderUpdatedMessageType,
  OrderChangedMessage,
} from 'src/app/modules/orders/models/messages/order-changed';
import {
  calculatePage, getPageSettingsFromUrl, INITIAL_PAGE_SETTINGS,
  OutputPagebleAction,
} from 'src/app/modules/shared/helpers/calculate-page';
import { PagebleEntity } from 'src/app/modules/shared/models/pageble-entity';
import { appendOrSetCollection } from 'src/app/modules/shared/helpers/append-or-set-collection';

@Injectable()
export class OrderDeliveryEffects {
  @Effect()
  loadOrderDeliveries$ = this.actions$.pipe(
    ofType(OrderDeliveryActionTypes.LoadOrderDeliveries),
    combineLatest(
      this.store.pipe(select(getOrderDeliveriesFilter)),
      this.actions$.pipe(
        ofMessage(deliveryCreatedMessageType, deliveryUpdatedMessageType),
        startWith('null')
      ),
      this.actions$.pipe(
        ofMessage(orderUpdatedMessageType),
        withLatestFrom(this.store.pipe(select(getOrderDeliveries))),
        filter(
          ([message, deliveries]: [OrderChangedMessage, OrderDelivery[]]) =>
            deliveries.some((delivery) => delivery.order.id === message.data.id)
        ),
        map(
          ([message, deliveries]: [OrderChangedMessage, OrderDelivery[]]) =>
            message
        ),
        startWith('null')
      )
    ),
    debounceTime(20),
    map(([action, filter, deliveryMessage, orderMessage]) => [({ originalAction: action, pageSettings: {} }), filter]),
    withLatestFrom(this.store.pipe(select(getOrderDeliveriesNextPage))),
    switchMap(
      ([[action, filter], nextPage]: [
        [any, OrderDeliveryFilter],
        string | null
      ]) => {
        return this.orderDeliveriesService
          .all(nextPage ? getPageSettingsFromUrl(nextPage) : INITIAL_PAGE_SETTINGS, filter)
          .pipe(
            withLatestFrom(this.store.pipe(select(getOrderDeliveries))),
            map(
              ([data, currentOrderDeliviries]: [
                PagebleEntity<OrderDelivery>,
                OrderDelivery[]
              ]) => {
                return new LoadOrderDeliveriesSuccess(
                  appendOrSetCollection(action, data, currentOrderDeliviries)
                );
              }
            ),
            catchError((error: HttpErrorResponse) => {
              this.errorHandler.handle(error.error);
              return of(new LoadOrderDeliveriesFail());
            })
          );
      }
    )
  );

  @Effect()
  loadOrderDeliveriesOfOrder$ = this.actions$.pipe(
    ofType(OrderDeliveryActionTypes.LoadOrderDeliveriesOfOrder),
    withLatestFrom(this.store.pipe(select(getOrderDeliveriesOfOrder))),
    map(([action, deliveries]: [LoadOrderDeliveriesOfOrder, OrderDelivery[]]) =>
      calculatePage(action, deliveries.length)
    ),
    combineLatest(
      this.actions$.pipe(
        ofMessage(deliveryCreatedMessageType, deliveryUpdatedMessageType),
        startWith('null')
      )
    ),
    switchMap(
      ([action, message]: [
        OutputPagebleAction<LoadOrderDeliveriesOfOrder>,
        OrderDeliveryUpdatedMessage
      ]) => {
        return this.orderDeliveriesService
          .all(action.pageSettings, { order: action.originalAction.order })
          .pipe(
            withLatestFrom(this.store.pipe(select(getOrderDeliveriesOfOrder))),
            map(
              ([data, currentOrderDeliviries]: [
                PagebleEntity<OrderDelivery>,
                OrderDelivery[]
              ]) => {
                data.results.forEach((delivery) =>
                  this.store.dispatch(new LoadOrderDelivery(delivery.id))
                );
                return new LoadOrderDeliveriesOfOrderSuccess(
                  appendOrSetCollection(action, data, currentOrderDeliviries)
                );
              }
            ),
            catchError((error: HttpErrorResponse) => {
              this.errorHandler.handle(error.error);
              return of(new LoadOrderDeliveriesOfOrderFail());
            })
          );
      }
    )
  );

  @Effect()
  loadOrderDelivery$ = this.actions$.pipe(
    ofType(OrderDeliveryActionTypes.LoadOrderDelivery),
    concatMap((action: LoadOrderDelivery) =>
      this.orderDeliveriesService.one(action.id).pipe(
        map((data) => new LoadOrderDeliverySuccess(data)),
        catchError((error: HttpErrorResponse) =>
          of(new LoadOrderDeliveryFail())
        )
      )
    )
  );

  @Effect()
  updateOrderDelivery$ = this.actions$.pipe(
    ofType(OrderDeliveryActionTypes.UpdateOrderDelivery),
    switchMap((action: UpdateOrderDelivery) =>
      this.orderDeliveriesService
        .update(action.payload.id, action.payload)
        .pipe(
          map((data) => new UpdateOrderDeliverySuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(new UpdateOrderDeliveryFail())
          )
        )
    )
  );

  @Effect()
  changeDeliveryDate$ = this.actions$.pipe(
    ofType(OrderDeliveryActionTypes.ChangeDeliveryDateOrderDelivery),
    switchMap((action: ChangeDeliveryDateOrderDelivery) =>
      this.orderDeliveriesService
        .setDeliveryDate(action.payload.id, action.payload)
        .pipe(
          map((data) => new ChangeDeliveryDateOrderDeliverySuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(new ChangeDeliveryDateOrderDeliveryFail())
          )
        )
    )
  );

  @Effect()
  changeCourier$ = this.actions$.pipe(
    ofType(OrderDeliveryActionTypes.ChangeCourierOrderDelivery),
    mergeMap((action: ChangeCourierOrderDelivery) =>
      this.orderDeliveriesService
        .assignCourier(action.payload.id, action.payload)
        .pipe(
          map((data) => new ChangeCourierOrderDeliverySuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(new ChangeCourierOrderDeliveryFail())
          )
        )
    )
  );

  @Effect()
  confirmOrderDelivery$ = this.actions$.pipe(
    ofType(OrderDeliveryActionTypes.ConfirmOrderDelivery),
    switchMap((action: ConfirmOrderDelivery) => {
      return this.orderDeliveriesService
        .confirm(action.id, action.payload)
        .pipe(
          map((data) => new ConfirmOrderDeliverySuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(
              new ConfirmOrderDeliveryFail(
                this.errorHandler.handle(error.error)
              )
            )
          )
        );
    })
  );

  @Effect()
  cancelOrderDelivery$ = this.actions$.pipe(
    ofType(OrderDeliveryActionTypes.CancelOrderDelivery),
    switchMap((action: CancelOrderDelivery) => {
      return this.orderDeliveriesService.cancel(action.id, action.payload).pipe(
        map((data) => new CancelOrderDeliverySuccess(data)),
        catchError((error: HttpErrorResponse) =>
          of(new CancelOrderDeliveryFail(this.errorHandler.handle(error.error)))
        )
      );
    })
  );

  @Effect()
  cancelExternalOrderDelivery$ = this.actions$.pipe(
    ofType(OrderDeliveryActionTypes.CancelExternalOrderDelivery),
    switchMap((action: CancelExternalOrderDelivery) => {
      return this.orderDeliveriesService.refreshCDEKStatuses(action.id, true).pipe(
        map((data) => new CancelExternalOrderDeliverySuccess()),
        catchError((error: HttpErrorResponse) =>
          of(new CancelExternalOrderDeliveryFail(this.errorHandler.handle(error.error)))
        )
      );
    })
  );

  @Effect()
  rejectOrderDelivery$ = this.actions$.pipe(
    ofType(OrderDeliveryActionTypes.RejectOrderDelivery),
    switchMap((action: RejectOrderDelivery) => {
      return this.orderDeliveriesService.reject(action.id, action.payload).pipe(
        map((data) => new RejectOrderDeliverySuccess(data)),
        catchError((error: HttpErrorResponse) =>
          of(new RejectOrderDeliveryFail(this.errorHandler.handle(error.error)))
        )
      );
    })
  );

  @Effect()
  recheduleOrderDelivery$ = this.actions$.pipe(
    ofType(OrderDeliveryActionTypes.RescheduleOrderDelivery),
    switchMap((action: RescheduleOrderDelivery) => {
      return this.orderDeliveriesService
        .reschedule(action.id, action.payload)
        .pipe(
          map((data) => new RescheduleOrderDeliverySuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(
              new RescheduleOrderDeliveryFail(
                this.errorHandler.handle(error.error)
              )
            )
          )
        );
    })
  );

  @Effect()
  loadOrderDeliveryActionReasons$ = this.actions$.pipe(
    ofType(OrderDeliveryActionTypes.LoadOrderDeliveryActionReasons),
    switchMap((action: LoadOrderDeliveryActionReasons) => {
      return this.orderDeliveriesService.getActionReasons().pipe(
        map((data) => new LoadOrderDeliveryActionReasonsSuccess(data)),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handle(error.error);
          return of(new LoadOrderDeliveryActionReasonsFail());
        })
      );
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private errorHandler: ErrorHandlingService,
    private orderDeliveriesService: OrderDeliveriesService
  ) {}
}
