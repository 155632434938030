import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnChanges, OnInit, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DynamicControlComponent } from '../../components/model-form/dynamic-control/dynamic-control.component';
import { ControlInputs, DynamicControl } from '../../models/dynamic-control';

@Directive({
  selector: '[appDynamicControl]'
})
export class DynamicControlDirective implements DynamicControl, OnInit, OnChanges  {
  @Input()
  inputs: ControlInputs;

  @Input()
  control: FormControl;

  component: ComponentRef<DynamicControlComponent>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}

  ngOnChanges() {
    if (this.component) {
      this.component.instance.inputs = this.inputs;
      this.component.instance.control = this.control;
    }
  }

  ngOnInit() {
    const component = this.resolver.resolveComponentFactory<DynamicControlComponent>(DynamicControlComponent);
    this.component = this.container.createComponent(component);
    this.component.instance.inputs = this.inputs;
    this.component.instance.control = this.control;
  }

}
