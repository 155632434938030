import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator
} from '@angular/forms';
import { forwardRef, Input, OnDestroy, Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/reducers';
import { ErrorHandlingService } from '../../../shared/services/error-handling/error-handling.service';
import { SegmentFilter } from '../../models/full-segment';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { SegmentProperty } from '../../models/segment-property';

@Directive()
export abstract class FilterValueAccessor
  implements ControlValueAccessor, Validator, OnDestroy {
  @Input() property: SegmentProperty;

  /* Value */
  _filter: SegmentFilter = null;
  get filter(): SegmentFilter {
    return this._filter;
  }
  set filter(v: SegmentFilter) {
    if (v !== this._filter) {
      this._filter = v;
      this.onChange(v);
    }
  }

  /* Properties */
  form: FormGroup;
  protected subscriptions = new Subscription();

  constructor(
    protected fb: FormBuilder,
    protected store: Store<AppState>,
    protected errorHandler: ErrorHandlingService
  ) {}

  writeValue(filter: SegmentFilter) {
    this._filter = filter;
    this.onWrite(this.filter);
  }

  pushValue(value: SegmentFilter) {
    this._filter = value;
    this.onChange(new SegmentFilter(value));
    this.onTouched();
  }

  abstract onWrite(value: SegmentFilter);

  onChange = _ => {};
  onTouched = () => {};
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validate(c: AbstractControl): { [key: string]: any } {
    if (!this.form) {
      return null;
    }

    const validationErrors = Object.keys(this.form.controls).reduce(
      (errors: any, name: string) => {
        const controlErrors = this.form.get(name).errors;
        if (controlErrors) {
          errors = { ...controlErrors };
        }
        return errors;
      },
      { ...this.form.errors }
    );

    return isEmpty(validationErrors) ? null : validationErrors;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

export function MakeProvider(type: any) {
  return [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => type),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => type),
      multi: true
    }
  ];
}
