import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject, zip } from 'rxjs';
import {
  debounceTime,
  filter,
  pluck,
  scan,
  startWith,
  switchMap,
  takeUntil,
  take,
  tap,
} from 'rxjs/operators';
import { AppState } from '../../../../store/reducers';
import { SegmentRemoteModel } from '../../../segments/models/segment-model';
import { SegmentProperty } from '../../../segments/models/segment-property';
import { SegmentRemoteModelService } from '../../../segments/service/segment-remote-model/segment-remote-model.service';

@Component({
  selector: 'app-remote-model-selector',
  templateUrl: './remote-model-selector.component.html',
  styleUrls: ['./remote-model-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RemoteModelSelectorComponent),
      multi: true,
    },
  ],
})
export class RemoteModelSelectorComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() noItemsFoundMessage = 'No items found';

  modelTypeahead$ = new Subject<string>();
  isModelsLoading = false;
  models$: Observable<SegmentRemoteModel[]>;
  modelsLoad$: Observable<SegmentRemoteModel[]>;
  subscriptions = new Subject();
  countLoaded: number;
  nextPage = 0;
  hasNextPage;
  fetchMore$ = new BehaviorSubject('');
  currentValue: FormControl = new FormControl({});
  @Input() property: SegmentProperty;
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private remoteModelService: SegmentRemoteModelService
  ) {
    this.modelsLoad$ = combineLatest(
      this.modelTypeahead$.pipe(
        startWith(''),
        tap(() => {
          this.nextPage = 0;
          this.hasNextPage = true;
        })
      ),
      this.fetchMore$.pipe(
        filter((_) => !this.isModelsLoading && this.hasNextPage)
      )
    ).pipe(
      debounceTime(100),
      filter(() => !this.isModelsLoading),
      tap(() => (this.isModelsLoading = true)),
      switchMap(([val, _]) => {
        this.nextPage++;
        return this.remoteModelService
          .filter({
            model: this.property.remote_model,
            page: this.nextPage,
            search: val,
          })
          .pipe(
            tap((res) => (this.hasNextPage = !!res.next)),
            pluck('results')
          );
      })
    );

    this.models$ = this.modelsLoad$.pipe(
      scan((previous, response) => {
        this.isModelsLoading = false;
        const result =
          this.nextPage <= 1 ? response : previous.concat(response);
        this.countLoaded = result.length;

        return result;
      }, [])
    );

    this.currentValue.valueChanges
      .pipe(takeUntil(this.subscriptions))
      .subscribe((value) => {
        this.onChanged(value);
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChanged(value: SegmentRemoteModel) {
    this.onChange(value);

    if (this.onTouched) {
      this.onTouched();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.currentValue.disable();
    } else {
      this.currentValue.enable();
    }
  }

  writeValue(obj: any): void {
    if (obj) {
      if (obj.hasOwnProperty('display_name') || obj.hasOwnProperty('name')) {
        this.currentValue.setValue(obj, { emitEvent: false });
      } else if (typeof obj === 'string') {
        this.remoteModelService
          .read(obj, this.property.remote_model)
          .pipe(take(1))
          .subscribe((item) =>
            this.currentValue.setValue(item, { emitEvent: false })
          );
      } else if (
        obj.length &&
        obj.every((o) => o.hasOwnProperty('display_name'))
      ) {
        this.currentValue.setValue(obj, { emitEvent: false });
      } else if (obj.length && obj.every((o) => typeof o === 'string')) {
        zip(
          ...obj.map((o) =>
            this.remoteModelService
              .read(o, this.property.remote_model)
              .pipe(take(1))
          )
        )
          // .pipe(takeUntil(this.subscriptions))
          .subscribe((objs) => {
            this.currentValue.setValue(objs, { emitEvent: false });
          });
      } else {
        console.error('Wrong value for remote model', obj);
      }
    }
  }

  fetchMore() {
    if (this.isModelsLoading) {
      return;
    }
    this.fetchMore$.next('');
  }

  scroll({ end }) {
    if (this.isModelsLoading) {
      return;
    }
    if (end + 10 >= this.countLoaded) {
      this.fetchMore$.next('');
    }
  }
}
