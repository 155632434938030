<a href="" class="user-account" *ngIf="user$ | async as user">
  <div class="details">
    <div>
      <a class="name text-capitalize" routerLink="/profile">{{
        user.first_name || user.last_name
          ? user.first_name + ' ' + user.last_name
          : user.username
      }}</a>
    </div>
  </div>
</a>
<ng-container *ngIf="company$ | async as company">
  <span dropdown class="user-company">
    <a
      href
      class="dropdown-toggle"
      dropdownToggle
      (click)="(false)"
      aria-controls="basic-link-dropdown"
      >{{ company.name }}</a
    >
    <ul
      *dropdownMenu
      id="basic-link-dropdown"
      class="dropdown-menu dropdown-menu-right"
      role="menu"
      aria-labelledby="basic-link"
    >
      <li *ngFor="let item of companies$ | async">
        <a
          class="dropdown-item"
          href="#"
          [class.active]="company.id === item.id"
          (click)="selectCompany(item.id)"
          >{{ item.name }}</a
        >
      </li>
    </ul>
  </span>
</ng-container>
<!-- <a href=""
   class="icon-link">
  <i class="material-icons">notifications</i>
</a> -->
<a class="icon-link" (click)="logout()">
  <i class="material-icons">exit_to_app</i>
</a>
