import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PopoverModule} from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ContactTipsModule } from '../contact-tips/contact-tips.module';
import { CustomersModule } from '../customers/customers.module';
import { DepartmentsModule } from '../departments/departments.module';
import { EmployeesModule } from '../employees/employees.module';
import { SharedModule } from '../shared/shared.module';
import { CallDurationComponent } from './components/call-duration/call-duration.component';
import { CallsHistoryComponent } from './components/calls-history/calls-history.component';
import { CommentDialogComponent } from './components/comment-dialog/comment-dialog.component';
import { CustomerCardComponent } from './components/customer-card/customer-card.component';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
import { DirectionSelectorComponent } from './components/direction-selector/direction-selector.component';
import { EmployeesFinderComponent } from './components/employees-finder/employees-finder.component';
import { FinishCallComponent } from './components/finish-call/finish-call.component';
import { HangupCallComponent } from './components/hangup-call/hangup-call.component';
import { IncomingCallComponent } from './components/incoming-call/incoming-call.component';
import { IncomingComponent } from './components/incoming/incoming.component';
import { RedirectCallComponent } from './components/redirect-call/redirect-call.component';
import { SubjectSelectorComponent } from './components/subject-selector/subject-selector.component';
import { CallComponent } from './pages/call/call.component';
import { CallsComponent } from './pages/calls/calls.component';
import { HistoryComponent } from './pages/history/history.component';
import { WaitingComponent } from './pages/waiting/waiting.component';
import { CallsRoutingModule } from './routing/calls-routing.module';
import { CallEffects } from './store/call.effects';
import * as fromCall from './store/call.reducer';

@NgModule({
  imports: [
    CommonModule,
    CallsRoutingModule,
    InfiniteScrollModule,
    PerfectScrollbarModule,
    NgSelectModule,
    SharedModule,
    CustomersModule,
    EmployeesModule,
    ReactiveFormsModule,
    ContactTipsModule,
    StoreModule.forFeature('call', fromCall.reducer),
    EffectsModule.forFeature([CallEffects]),
    PopoverModule,
    TabsModule,
    DepartmentsModule
  ],
  declarations: [
    IncomingCallComponent,
    IncomingComponent,
    CallComponent,
    WaitingComponent,
    CustomerCardComponent,
    HangupCallComponent,
    CallsHistoryComponent,
    CommentDialogComponent,
    EmployeesFinderComponent,
    DirectionSelectorComponent,
    SubjectSelectorComponent,
    DateSelectorComponent,
    HistoryComponent,
    FinishCallComponent,
    CallsComponent,
    CallDurationComponent,
    RedirectCallComponent
  ],
  exports: [IncomingComponent, CallsHistoryComponent]
})
export class CallsModule {
}
