import { Action } from '@ngrx/store';

export const SEGMENT_MODELS_LOAD = '[Segment models] load';
export const SEGMENT_MODELS_LOAD_SUCCESS = '[Segment models] load success';
export const SEGMENT_MODELS_LOAD_FAIL = '[Segment models] load fail';

export const SET_SEGMENT_MODEL = '[Segment model] set model';

export class SegmentModelsLoad implements Action {
    readonly type = SEGMENT_MODELS_LOAD;
}

export class SegmentModelsLoadSuccess implements Action {
    readonly type = SEGMENT_MODELS_LOAD_SUCCESS;
    constructor(public payload: any) {}
}

export class SegmentModelsLoadFail implements Action {
    readonly type = SEGMENT_MODELS_LOAD_FAIL;
}

export class SetSegmentModel implements Action {
    readonly type = SET_SEGMENT_MODEL;
    constructor(public payload: string) {}
}

export type SegmentModelsAction =
  | SegmentModelsLoad
  | SegmentModelsLoadSuccess
  | SegmentModelsLoadFail
  | SetSegmentModel
;
