import { _ } from '../../shared/helpers/translation-marker';

export class Segment {
  id?: string;
  model: string;
  name: string;
  count?: number;
}

export const CUSTOMER_MODEL_NAME = 'customer';
export const PRODUCT_MODEL_NAME = 'product';
export const OUTLET_MODEL_NAME = 'outlet';
export const EMPLOYEE_MODEL_NAME = 'employee';
export const SKU_MODEL_NAME = 'sku';
export const BALANCES_MODEL_NAME = 'balance';
export const FCMDEVICES_MODEL_NAME = 'fcmdevice';
export const COMPANY_MODEL_NAME = 'company';
export const SERVICE_MODEL_NAME = 'service';
export const SERVICE_OPTION_MODEL_NAME = 'serviceoption';
export const PRICE_MODEL_NAME = 'price';

export const SegmentModelsName = {
  [CUSTOMER_MODEL_NAME]: _('Customer segments'),
  [PRODUCT_MODEL_NAME]: _('Product segments'),
  [OUTLET_MODEL_NAME]: _('Outlet segments'),
  [SKU_MODEL_NAME]: _('SKU segments'),
  [BALANCES_MODEL_NAME]: _('Balances segments'),
  [FCMDEVICES_MODEL_NAME]: _('FCM clients segements'),
  [COMPANY_MODEL_NAME]: _('Company segments'),
  [SERVICE_MODEL_NAME]: _('Service segments'),
  [SERVICE_OPTION_MODEL_NAME]: _('Service options segments'),
  [EMPLOYEE_MODEL_NAME]: _('Employee segments'),
  [PRICE_MODEL_NAME]: _('Price segments'),
};

export const SegmentModelMenuItemNames = {
  [CUSTOMER_MODEL_NAME]: _('Customer segments menu'),
  [PRODUCT_MODEL_NAME]: _('Product segments menu'),
  [OUTLET_MODEL_NAME]: _('Outlet segments menu'),
  [SKU_MODEL_NAME]: _('SKU segments menu'),
  [BALANCES_MODEL_NAME]: _('Balances segments menu'),
  [FCMDEVICES_MODEL_NAME]: _('FCM clients segements menu'),
  [COMPANY_MODEL_NAME]: _('Company segments menu'),
  [SERVICE_MODEL_NAME]: _('Service segments menu'),
  [SERVICE_OPTION_MODEL_NAME]: _('Service options segments menu'),
  [EMPLOYEE_MODEL_NAME]: _('Employee segments menu'),
  [PRICE_MODEL_NAME]: _('Price segments menu'),
};
