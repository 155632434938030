import { createSelector, createFeatureSelector } from '@ngrx/store';
import { CallsState } from './call.reducer';

export const getCallsState = createFeatureSelector('call');

export const getActiveCall = createSelector(
  getCallsState,
  (state: CallsState) =>
    state.activeCall ? state.calls[state.activeCall] : null
);

export const getCallPurpose = createSelector(
  getCallsState,
  (state: CallsState) => state.callPurpose
);

export const getDirections = createSelector(
  getCallsState,
  (state: CallsState) => state.directions
);

export const getDirectionsWithSubjects = createSelector(
  getCallsState,
  (state: CallsState) =>
    state.directions.map(direction => ({
      ...direction,
      subjects: state.subjects.filter(
        subject => subject.direction === direction.id
      )
    }))
);

export const getSubjects = createSelector(
  getCallsState,
  (state: CallsState) => state.subjects
);

export const getSubjectsByDirection = direction =>
  createSelector(
    getCallsState,
    (state: CallsState) =>
      state.subjects.filter(subject => subject.direction === direction)
  );

export const getHangUpReasons = createSelector(
  getCallsState,
  (state: CallsState) => state.actionReasons
);

export const hasUnfinishedCalls = createSelector(
  getCallsState,
  (state: CallsState) => !!state.unfinishedCalls.length
);
export const isLoadingUnfinishedCalls = createSelector(
  getCallsState,
  (state: CallsState) => state.isLoadingUnfinishedCalls
);
export const getLastUnfinishedCall = createSelector(
  getCallsState,
  (state: CallsState) =>
    state.unfinishedCalls.length ? state.unfinishedCalls[0] : null
);

export const getCallsHistory = createSelector(
  getCallsState,
  (state: CallsState) => state.callsHistory
);
export const getCallsHistoryCount = createSelector(
  getCallsState,
  (state: CallsState) => state.callsHistoryCount
);

export const getCallsHistoryData = createSelector(
  getCallsState,
  (state: CallsState) => ({
    data: state.callsHistory,
    totalCount: state.callsHistoryCount
  })
);

export const isLoadingCallsHistory = createSelector(
  getCallsState,
  (state: CallsState) => state.isLoadingCallsHistory
);
export const isReloadCallsHistory = createSelector(
  getCallsState,
  (state: CallsState) => state.isReload
);
export const getCallsHistoryFilter = createSelector(
  getCallsState,
  (state: CallsState) => state.callsHistoryFilter
);
export const getCurrentAudio = createSelector(
  getCallsState,
  (state: CallsState) => state.currentHistoryCallAudio
);
