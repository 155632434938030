import { Action } from '@ngrx/store';
import { LegalEntity } from '../../models/legal-entity.model';

export enum LegalEntityActionTypes {
  LoadEntities = '[Payment] Load Legal Entities',
  LoadEntitiesSuccess = '[Payment] Load Legal Entities Success',
  LoadEntitiesFail = '[Payment] Load Legal Entities Fail'
}

export class LoadEntities implements Action {
  readonly type = LegalEntityActionTypes.LoadEntities;
}

export class LoadEntitiesSuccess implements Action {
  readonly type = LegalEntityActionTypes.LoadEntitiesSuccess;
  constructor(public payload: LegalEntity[]) {}

}

export class LoadEntitiesFail implements Action {
  readonly type = LegalEntityActionTypes.LoadEntitiesFail;
}

export type LegalEntityActions =
  | LoadEntities
  | LoadEntitiesSuccess
  | LoadEntitiesFail;
