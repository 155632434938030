<div class="form-group row" *ngIf="property">
  <label class="col-sm-2 col-form-label">
    {{
      (propertyNames[property.name]
        ? propertyNames[property.name]
        : property.name
      ) | translate
    }}
    <span class="text-danger" *ngIf="property.is_required">*</span>
  </label>
  <div class="col flex-grow-1">
    <ng-container [ngSwitch]="property.widget">
      <input
        type="number"
        class="form-control"
        [formControl]="fieldValue"
        *ngSwitchCase="'number'"
      />
      <ng-select
        class="ng-select-autowidth"
        *ngSwitchCase="'enum'"
        [compareWith]="compareValues"
        bindLabel="name"
        [formControl]="fieldValue"
        [items]="property.property_values"
        [hideSelected]="true"
        [bindValue]="property.type === 'str' ? 'ext_id' : null"
        [multiple]="property.is_many"
      ></ng-select>
      <input
        class="checkbox-input"
        *ngSwitchCase="'bool'"
        type="checkbox"
        [formControl]="fieldValue"
      />
      <app-dadata-address
        *ngSwitchCase="'address'"
        [formControl]="fieldValue"
      ></app-dadata-address>
      <app-number-input
        *ngSwitchCase="'amount'"
        [formControl]="fieldValue"
      ></app-number-input>
      <textarea
        *ngSwitchCase="'textarea'"
        type="text"
        class="form-control"
        [formControl]="fieldValue"
      ></textarea>
      <app-remote-model-selector
        *ngSwitchCase="'remote_model'"
        [property]="property"
        [formControl]="fieldValue"
      ></app-remote-model-selector>
      <app-model-string-field
        [formControl]="fieldValue"
        [property]="property"
        *ngSwitchDefault
      ></app-model-string-field>
      <app-errors-component
        [control]="fieldValue"
        [submitted]="submitted"
      ></app-errors-component>
    </ng-container>
    <div *ngIf="hasRemoveButton">
      <a class="icon-link" (click)="remove()">
        <i class="icon icon-trash"></i>
      </a>
    </div>
  </div>
</div>
