<div class="input-group">
  <div class="input-group-prepend" *ngIf="showArrows">
    <span class="input-group-text" (click)="decrease()">
      <i class="material-icons">chevron_left</i>
    </span>
  </div>
  <input
    [formControl]="dateControl"
    type="text"
    class="form-control"
    [ngClass]="additionalClass"
    [placeholder]="placeholder"
    bsDatepicker
    [bsConfig]="datePickerConfig"
    [maxDate]="maxDate"
    [minDate]="minDate"
    (blur)="onTouched && onTouched()"
    [placement]="placement"
  />
  <div class="input-group-append" *ngIf="showIcon">
    <span
      class="input-group-text close"
      *ngIf="dateControl.value"
      (click)="clear()"
      ><i class="material-icons">clear</i></span
    >
    <span class="input-group-text" *ngIf="!dateControl.value"
      ><i class="material-icons">date_range</i></span
    >
  </div>
  <div class="input-group-append" *ngIf="showArrows">
    <span class="input-group-text" (click)="increase()">
      <i class="material-icons">chevron_right</i>
    </span>
  </div>
</div>
