import { Action } from '@ngrx/store';
import { Courier } from '../../../models/courier.model';
import { Order } from '../../../../orders/models/order';
import { CourierOrder } from '../../../models/order.model';
import { PagebleEntity } from '../../../../shared/models/pageble-entity';
import { OrderDelivery } from '../../../models/order-delivery.model';
import { DeliveryRegion } from '../../../models/delivery-region.model';
import { Outlet } from 'src/app/modules/outlets/models/outlet';

export enum CourierActionTypes {
  LoadCouriers = '[Courier] Load Couriers',
  LoadCouriersSuccess = '[Courier] Load Couriers Success',
  LoadCouriersFail = '[Courier] Load Couriers Fail',
  LoadCourierOrders = '[Courier] Load Delivery Orders',
  LoadCourierOrdersSuccess = '[Courier] Load Delivery Orders Success',
  LoadCourierOrdersFail = '[Courier] Load Delivery Orders Fail',
  LoadDeliveryRegions = '[Courier] Load Delivery Regions',
  LoadDeliveryRegionsSuccess = '[Courier] Load Delivery Regions Success',
  LoadDeliveryRegionsFail = '[Courier] Load Delivery Regions Fail',
  LoadRegionOutlets = '[Courier] Load Region Outlets',
  LoadRegionOutletsSuccess = '[Courier] Load Region Outlets Success',
  LoadRegionOutletsFail = '[Courier] Load Region Outlets Fail'
}

export class LoadCouriers implements Action {
  readonly type = CourierActionTypes.LoadCouriers;
  constructor(public filter: { region: string }) {}
}

export class LoadCouriersSuccess implements Action {
  readonly type = CourierActionTypes.LoadCouriersSuccess;
  constructor(public payload: PagebleEntity<Courier>) {}
}

export class LoadCouriersFail implements Action {
  readonly type = CourierActionTypes.LoadCouriersFail;
}

export class LoadCourierOrders implements Action {
  readonly type = CourierActionTypes.LoadCourierOrders;
}

export class LoadCourierOrdersSuccess implements Action {
  readonly type = CourierActionTypes.LoadCourierOrdersSuccess;
  constructor(public payload: PagebleEntity<CourierOrder>) {}
}

export class LoadCourierOrdersFail implements Action {
  readonly type = CourierActionTypes.LoadCourierOrdersFail;
}

export class LoadDeliveryRegions implements Action {
  readonly type = CourierActionTypes.LoadDeliveryRegions;
  constructor(public params: any) {}
}

export class LoadDeliveryRegionsSuccess implements Action {
  readonly type = CourierActionTypes.LoadDeliveryRegionsSuccess;
  constructor(public payload: DeliveryRegion[]) {}
}

export class LoadDeliveryRegionsFail implements Action {
  readonly type = CourierActionTypes.LoadDeliveryRegionsFail;
}

export class LoadRegionOutlets implements Action {
  readonly type = CourierActionTypes.LoadRegionOutlets;
  constructor(public region: string) {}
}

export class LoadRegionOutletsSuccess implements Action {
  readonly type = CourierActionTypes.LoadRegionOutletsSuccess;
  constructor(public payload: Outlet[]) {}
}

export class LoadRegionOutletsFail implements Action {
  readonly type = CourierActionTypes.LoadRegionOutletsFail;
}

export type CourierActions =
  | LoadCouriers
  | LoadCouriersSuccess
  | LoadCouriersFail
  | LoadCourierOrders
  | LoadCourierOrdersSuccess
  | LoadCourierOrdersFail
  | LoadDeliveryRegions
  | LoadDeliveryRegionsSuccess
  | LoadDeliveryRegionsFail
  | LoadRegionOutlets
  | LoadRegionOutletsSuccess
  | LoadRegionOutletsFail;
