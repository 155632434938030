<ng-select
  [items]="models$ | async"
  bindLabel="display_name"
  [formControl]="currentValue"
  [multiple]="false"
  [hideSelected]="true"
  [loading]="isModelsLoading"
  [typeahead]="modelTypeahead$"
  (scroll)="scroll($event)"
  (scrollToEnd)="fetchMore()"
  [placeholder]="'Operator' | translate"
>
  <ng-template ng-label-tmp let-item="item">
    {{ item | employee }}
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <div>{{ item | employee }}</div>
  </ng-template>
  <ng-template ng-typetosearch-tmp>
    <div class="ng-option disabled text-black-50">
      {{ 'Start typing' | translate }}...
    </div>
  </ng-template>
  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
    <div class="ng-option disabled text-black-50">
      {{ 'No employee found' | translate }}
    </div>
  </ng-template>
  <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
    <div class="ng-option disabled text-black-50">
      {{ 'Loading' | translate }}
    </div>
  </ng-template>
</ng-select>
