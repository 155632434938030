import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { _ } from '../../../shared/helpers/translation-marker';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Outlet } from '../../../outlets/models/outlet';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/reducers';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  skipWhile,
  takeUntil,
  tap
} from 'rxjs/operators';
import { isEqual } from 'lodash';
import { CreateCustomerData, Customer } from '../../models/customer';
import {
  isLoadingCustomer,
  isUpdatingCustomer
} from '../../store/customer/customer.selectors';
import * as moment from 'moment';
import { DATE_FORMAT } from '../../../shared/config/data-formats.config';
import { cloneDeep, isEmpty } from 'lodash';
import { conformToMask } from 'angular2-text-mask';
import { FormatPhoneService } from '../../../shared/services/format-phone/format-phone.service';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit, OnDestroy {
  @Input()
  title: string = _('Add customer');
  @Input()
  isEdit = false;

  @Output()
  onSumbitted = new EventEmitter<CreateCustomerData>();
  @Output()
  changed = new EventEmitter<CreateCustomerData>();
  @Output()
  onRegistered = new EventEmitter<void>();

  form: FormGroup;
  updating$: Observable<boolean>;
  isChanging = false;
  isUpdating = false;
  wrappedActionInProcess = false;
  isFormChanged = false;
  readonly gender = [
    { name: _('M'), value: _('MALE') },
    { name: _('F'), value: _('FEMALE') }
  ];
  subscriptions = new Subject();
  submitted = false;
  maxDate = new Date();
  loading$: Observable<boolean>;

  @Input()
  set customer(customer: Customer) {
    if (!customer) {
      return;
    }
    if (!this.isChanging) {
      this.isUpdating = true;
      this.form.patchValue(customer);
    }
  }

  constructor(private fb: FormBuilder, private store: Store<AppState>) {
    this.form = fb.group({
      first_name: [null, Validators.compose([Validators.maxLength(255)])],
      middle_name: [null, Validators.compose([Validators.maxLength(255)])],
      last_name: [null, Validators.compose([Validators.maxLength(255)])],
      phone: [null, Validators.required],
      email: [
        null,
        Validators.compose([Validators.maxLength(254), Validators.email])
      ],
      gender: null,
      birthday: null
    });

    this.updating$ = this.store.pipe(select(isUpdatingCustomer));
    this.loading$ = this.store.pipe(select(isLoadingCustomer));
  }

  prepareData(date: CreateCustomerData) {
    date.birthday = date.birthday
      ? moment(date.birthday).format(DATE_FORMAT)
      : null;
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
  }

  submit() {
    if (!this.form.valid) {
      return;
    }

    const data = this.form.value;
    this.prepareData(data);
    this.onSumbitted.emit(data);
  }
}
