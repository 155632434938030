import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { SipService } from '../../services/sip/sip.service';
import { HangUpCall } from '../../store/call.actions';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CallActionReason } from '../../models/calls.model';
import { Observable } from 'rxjs';
import { getHangUpReasons } from '../../store/call.selector';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CallService } from '../../services/call/call.service';
import { ErrorHandlingService } from 'src/app/modules/shared/services/error-handling/error-handling.service';

@Component({
  selector: 'app-hangup-call',
  templateUrl: './hangup-call.component.html',
  styleUrls: ['./hangup-call.component.scss']
})
export class HangupCallComponent implements OnInit {
  @Input() call: string;
  @Input() modalRef: BsModalRef;
  confirmed = false;
  reasons$: Observable<CallActionReason[]>;
  form: FormGroup;
  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private sipService: SipService,
    private callService: CallService,
    private errorHandler: ErrorHandlingService
  ) {
    this.form = this.fb.group({
      reason: [null, Validators.required],
      state: ['HANG_UP_EMPLOYEE', Validators.required],
      comment: ['']
    });
  }

  ngOnInit() {
    this.reasons$ = this.store.pipe(select(getHangUpReasons));
  }

  confirm() {
    this.store.dispatch(new HangUpCall(this.call));
    this.sipService.bye();
    this.confirmed = true;
    this.modalRef.hide();
  }

  decline() {
    this.modalRef.hide();
  }
}
