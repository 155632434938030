<div class="modal-header">
  <h4 class="modal-title pull-left">{{ 'How was the call?' | translate }}</h4>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <ul class="reasons" *ngIf="(reasons$ | async) as reasons">
      <li *ngFor="let reason of reasons">
        <div class="form-check">
          <input
            type="radio"
            class="form-check-input"
            formControlName="reason"
            [id]="reason.id"
            [value]="reason.id"
          />
          <label class="form-check-label" [for]="reason.id">{{
            reason.name
          }}</label>
        </div>
      </li>
    </ul>
    <app-errors-component [control]="form.get('reason')"></app-errors-component>
    <div class="comment">
      <textarea
        formControlName="comment"
        class="form-control mb-3"
        [placeholder]="('Comment' | translate) + '...'"
      ></textarea>
      <app-errors-component
        [control]="form.get('comment')"
      ></app-errors-component>
    </div>
    <button
      class="btn btn-primary btn-block"
      (click)="submit()"
      [disabled]="!form.valid"
    >
      {{ 'Submit' | translate }}
    </button>
  </form>
</div>
