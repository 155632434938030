<form novalidate [formGroup]="form" (ngSubmit)="submit()">
  <div class="panel">
    <div class="form-header d-flex">
      <h3>
        {{ label }}&nbsp;&nbsp;
        <span class="text-black-50">{{ segment.count }}</span>
      </h3>
      <app-loader class="sm" *ngIf=""></app-loader>
    </div>
    <fieldset class="form-fieldset">

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{ 'Name' | translate }}</label>
        <div class="col name-col">
          <input type="text" formControlName="name" class="form-control" />
          <app-errors-component [control]="form.get('name')" [submitted]="formSubmitted"></app-errors-component>
        </div>
        <div class="save-col" *ngIf="!showFormButtons">
          <a class="icon-link" [class.disabled]="isSaveNameDisabled()" (click)="saveName()">
            <i class="material-icons">done_outline</i>
          </a>
        </div>
      </div>

      <ng-container formArrayName="filters">
        <ng-container *ngFor="let item of filters.controls; trackBy: trackByFn; let i = index">
          <app-filter [formControlName]="i" [formError]="filterErrors(i)" [isEdit]="isEdit"
                      (removed)="removeFilter(item)" (saved)="saveFilter(item)">
          </app-filter>
        </ng-container>
      </ng-container>

      <div class="form-group row" *ngIf="canAddFilter && canEditFilters">
        <label class="col-sm-3 col-form-label"></label>
        <div class="col property-col" *ngIf="(segmentProperties$ | async) as segmentProperties">
          <ng-select [formControl]="addFilterControl" [items]="segmentProperties"
                bindLabel="name" bindValue="value" [groupBy]="propertyGroupBy" [groupValue]="propertyGroupValue"
                [placeholder]="'Add filter' | translate" [clearable]="false">
            <ng-template ng-header-tmp>
              <ng-container *ngIf="outputModels$ | async as outputModels">
                <ng-container *ngIf="outputModels.length">
                  <a class="switch-model"
                    [class.active]="(selectedModel$ | async) === m.id"
                    *ngFor="let m of outputModels"
                    (click)="selectedModel$.next(m.id)">{{ m.name | translate }}</a>
                  </ng-container>
              </ng-container>
            </ng-template>
            <ng-template ng-optgroup-tmp let-item="item">
              {{ item?.name || 'Unnamed group' | translate }}
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="form-group row justify-content-end">
        <div class="col-9">
          <app-errors-component [control]="filters" [submitted]="formSubmitted"></app-errors-component>
        </div>
      </div>
    </fieldset>
  </div>
  <div class="mt-4 text-right" *ngIf="showFormButtons">
    <button class="btn btn-primary" type="submit" [class.disabled]="formSaved">
      {{ isEdit ? 'Save segment' : ('Create segment' | translate) }}
    </button>
  </div>
</form>
