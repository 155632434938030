import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isDate, isEmpty, isNil, isNumber, pickBy } from 'lodash';
import { Observable } from 'rxjs';
import { OrderFilter } from 'src/app/modules/orders/models/order-filter';
import { OrdersService } from 'src/app/modules/orders/services/orders/orders.service';
import { PageSettings } from 'src/app/modules/shared/helpers/calculate-page';
import { PagebleEntity } from 'src/app/modules/shared/models/pageble-entity';
import { EmployeesService } from '../../../employees/services/employees/employees.service';
import { Courier } from '../../models/courier.model';
import { CourierOrder } from '../../models/order.model';
import { DeliveryRegion } from '../../models/delivery-region.model';
import { Outlet } from 'src/app/modules/outlets/models/outlet';

@Injectable({
  providedIn: 'root'
})
export class CourierService {
  constructor(
    private ordersService: OrdersService,
    private http: HttpClient,
    private employeeService: EmployeesService
  ) {}

  getOrders(
    params: PageSettings,
    filter: OrderFilter
  ): Observable<PagebleEntity<CourierOrder>> {
    return this.ordersService.all(params, {
      ...filter,
      delivery_type: 'DELIVERY'
    });
  }

  getCouriers(
    params: PageSettings,
    filter: any
  ): Observable<PagebleEntity<Courier>> {
    const notEmptyFilters = pickBy(
      filter,
      value => isDate(value) || !isNil(value)
    );
    const newParams = { ...params, ...notEmptyFilters, roles: 'courier' };
    return this.http.get<PagebleEntity<Courier>>(`employees/`, {
      params: newParams as any
    });
  }

  getDeliveryRegions(params): Observable<DeliveryRegion[]> {
    return this.http.get<DeliveryRegion[]>(`order-deliveries/regions/`, {
      params
    });
  }

  getRegionOutlets(region: string): Observable<PagebleEntity<Outlet>> {
    return this.http.get<PagebleEntity<Outlet>>(`outlets`, {
      params: {
        region,
        page_size: '999'
      }
    });
  }

  filter(filter): Observable<PagebleEntity<Courier>> {
    const notEmptyFilters = pickBy(
      filter,
      value => !isEmpty(value) || isNumber(value)
    );
    const params = {
      ...notEmptyFilters,
      roles: 'courier'
    };
    return this.employeeService.filter(params);
  }
}
