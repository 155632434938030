import { CallState } from '../calls.model';

export const callUpdateMessageType = 'CALL_UPDATED';

export interface MailBatchChangedMessage {
  type: typeof callUpdateMessageType;
  data: {
    id: string;
    state: CallState;
  };
}
