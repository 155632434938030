<app-sidebar>
  <ul class="nav nav-primary">
    <li class="nav-item">
      <a class="nav-link"
          routerLink="/customers">
        <i class="icon icon-people"></i>
        {{ "Customers" | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link"
          routerLink="/orders">
        <i class="icon icon-catalog"></i>
        {{ "Orders" | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" (click)="toggle('COURIERS')">
        <i class="icon icon-people"></i>
        {{ "Courier delivery" | translate }}
        <i class="expand-icon material-icons">
          {{ !expand["COURIERS"] ? "expand_more" : "expand_less" }}
        </i>
      </a>
      <ul class="nav" *ngIf="expand['COURIERS']">
        <li class="nav-item">
          <a
            class="nav-link"
            routerLink="/deliveries/courier"
            routerLinkActive="active"
          >
            {{ "Courier delivery" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            routerLink="/deliveries/external"
            routerLinkActive="active"
          >
            {{ "CDEK" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            routerLink="/deliveries/return"
            routerLinkActive="active"
          >
            {{ "Return deliveries" | translate }}
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-item">
      <a class="nav-link"
         routerLink="/reclamations">
        <i class="material-icons">keyboard_return</i>
        {{ "Reclamations" | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/receipts">
        <i class="material-icons">receipt</i>
        {{ "Receipts" | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/payments">
        <i class="material-icons">credit_card</i>
        {{ "Payments" | translate }}
      </a>
    </li>
  </ul>
</app-sidebar>
<div class="workspace">
  <app-page-header></app-page-header>
  <router-outlet></router-outlet>
</div>
