import { createSelector } from '@ngrx/store';
import {
  getOrderDeliveryState,
  OrderDeliveryState,
} from './order-delivery.reducer';
import { OrderDeliveryStatus } from '../../../models/order-delivery.model';

export const getOrderDeliveries = createSelector(
  getOrderDeliveryState,
  (state: OrderDeliveryState) => state.orderDeliveries
);

export const getCurrentOrderDelivery = createSelector(
  getOrderDeliveryState,
  (state: OrderDeliveryState) => state.orderDelivery
);

export const getOrderDeliveriesNextPage = createSelector(
  getOrderDeliveryState,
  (state: OrderDeliveryState) => state.orderDeliveriesNextPage
);

export const getOrderDeliveriesFilter = createSelector(
  getOrderDeliveryState,
  (state: OrderDeliveryState) => state.orderDeliveriesFilter
);
export const isLoadingOrderDeliveries = createSelector(
  getOrderDeliveryState,
  (state: OrderDeliveryState) => state.isLoadingOrderDeliveries
);
export const getOrderDeliveriesOfOrder = createSelector(
  getOrderDeliveryState,
  (state: OrderDeliveryState) => state.orderDeliveriesOfOrder
);

export const getOrderDeliveriesOfOrderCount = createSelector(
  getOrderDeliveryState,
  (state: OrderDeliveryState) => state.orderDeliveriesOfOrderCount
);

export const isLoadingOrderDeliveriesOfOrder = createSelector(
  getOrderDeliveryState,
  (state: OrderDeliveryState) => state.isLoadingOrderDeliveriesOfOrder
);
export const isLoadingOrderDelivery = createSelector(
  getOrderDeliveryState,
  (state: OrderDeliveryState) => state.isLoadingOrderDelivery
);
export const isUpdatingOrderDelivery = createSelector(
  getOrderDeliveryState,
  (state: OrderDeliveryState) => state.updating
);
export const isReloadOrderDeliveries = createSelector(
  getOrderDeliveryState,
  (state: OrderDeliveryState) => state.isReload
);

export const getOrderDeliveryActionReasonsFor = (status: OrderDeliveryStatus) =>
  createSelector(getOrderDeliveryState, (state: OrderDeliveryState) => {
    return state.actionReasons.filter((reason) => reason.state === status);
  });
