import { createSelector } from '@ngrx/store';
import { isReload } from '../../../../shared/helpers/calculate-page';
import {
  OrderDelivery,
  OrderDeliveryFilter,
  OrderDeliveryActionReason,
} from '../../../models/order-delivery.model';
import { getCourierModuletState } from '../selector';
import {
  OrderDeliveryActions,
  OrderDeliveryActionTypes,
} from './order-delivery.actions';

export interface OrderDeliveryState {
  orderDeliveries: OrderDelivery[];
  orderDeliveriesNextPage: string | null;
  orderDeliveriesOfOrder: OrderDelivery[];
  orderDeliveriesOfOrderCount: number;
  orderDeliveriesFilter: OrderDeliveryFilter;
  isLoadingOrderDeliveries: boolean;
  isLoadingOrderDeliveriesOfOrder: boolean;
  isReload: boolean;
  updating: boolean;
  actionReasons: OrderDeliveryActionReason[];
  orderDelivery: OrderDelivery | null;
  isLoadingOrderDelivery: boolean;
}

export const initialState: OrderDeliveryState = {
  orderDeliveries: [],
  orderDeliveriesNextPage: null,
  orderDeliveriesOfOrder: [],
  orderDeliveriesOfOrderCount: 0,
  orderDeliveriesFilter: null,
  isReload: false,
  isLoadingOrderDeliveries: false,
  isLoadingOrderDeliveriesOfOrder: false,
  updating: false,
  actionReasons: [],
  isLoadingOrderDelivery: false,
  orderDelivery: null,
};

export function OrderDeliveryReducer(
  state = initialState,
  action: OrderDeliveryActions
): OrderDeliveryState {
  switch (action.type) {
    case OrderDeliveryActionTypes.LoadOrderDeliveries:
      return {
        ...state,
        isLoadingOrderDeliveries: true,
        isReload: isReload(action),
      };
    case OrderDeliveryActionTypes.LoadOrderDeliveriesSuccess:
      return {
        ...state,
        orderDeliveries: action.payload.results,
        orderDeliveriesNextPage: action.payload.next,
        isLoadingOrderDeliveries: false,
        isReload: false,
      };
    case OrderDeliveryActionTypes.LoadOrderDeliveriesOfOrder:
      return {
        ...state,
        orderDeliveriesOfOrder: isReload(action)
          ? []
          : state.orderDeliveriesOfOrder,
        isLoadingOrderDeliveriesOfOrder: true,
      };
    case OrderDeliveryActionTypes.LoadOrderDeliveriesOfOrderSuccess:
      return {
        ...state,
        orderDeliveriesOfOrder: action.payload.results,
        orderDeliveriesOfOrderCount: action.payload.count,
        isLoadingOrderDeliveriesOfOrder: false,
      };
    case OrderDeliveryActionTypes.LoadOrderDeliveriesFail:
      return {
        ...state,
        isLoadingOrderDeliveries: false,
        isReload: false,
      };
    case OrderDeliveryActionTypes.UpdateOrderDelivery:
    case OrderDeliveryActionTypes.ChangeDeliveryDateOrderDelivery:
    case OrderDeliveryActionTypes.ChangeCourierOrderDelivery:
    case OrderDeliveryActionTypes.ConfirmOrderDelivery:
    case OrderDeliveryActionTypes.RescheduleOrderDelivery:
      return {
        ...state,
        updating: true,
      };
    case OrderDeliveryActionTypes.UpdateOrderDeliverySuccess:
    case OrderDeliveryActionTypes.ChangeDeliveryDateOrderDeliverySuccess:
    case OrderDeliveryActionTypes.ChangeCourierOrderDeliverySuccess:
    case OrderDeliveryActionTypes.ConfirmOrderDeliverySuccess:
    case OrderDeliveryActionTypes.RescheduleOrderDeliverySuccess:
      return {
        ...state,
        updating: false,
      };
    case OrderDeliveryActionTypes.UpdateOrderDeliveryFail:
    case OrderDeliveryActionTypes.ChangeDeliveryDateOrderDeliveryFail:
    case OrderDeliveryActionTypes.ChangeCourierOrderDeliveryFail:
    case OrderDeliveryActionTypes.ConfirmOrderDeliveryFail:
    case OrderDeliveryActionTypes.RescheduleOrderDeliveryFail:
      return {
        ...state,
        updating: false,
      };
    case OrderDeliveryActionTypes.SetOrderDeliveriesFilter:
      return {
        ...state,
        isLoadingOrderDeliveries: true,
        orderDeliveries: [],
        orderDeliveriesFilter: {
          ...(action.reset ? {} : state.orderDeliveriesFilter),
          ...action.payload,
        },
        orderDeliveriesNextPage: null,
      };
    case OrderDeliveryActionTypes.LoadOrderDeliveryActionReasons:
      return {
        ...state,
      };
    case OrderDeliveryActionTypes.LoadOrderDeliveryActionReasonsSuccess:
      return {
        ...state,
        actionReasons: action.payload,
      };
    case OrderDeliveryActionTypes.LoadOrderDeliverySuccess:
      return {
        ...state,
        orderDeliveriesOfOrder: state.orderDeliveriesOfOrder.map((delivery) =>
          delivery.id === action.payload.id ? action.payload : delivery
        ),
        isLoadingOrderDeliveriesOfOrder: false,
        isLoadingOrderDelivery: false,
        orderDelivery: action.payload,
      };
    case OrderDeliveryActionTypes.LoadOrderDelivery:
      return {
        ...state,
        orderDelivery: null,
        isLoadingOrderDelivery: true,
      };
    case OrderDeliveryActionTypes.LoadOrderDeliveryFail:
      return {
        ...state,
        isLoadingOrderDelivery: false,
      };
    default:
      return state;
  }
}

export const getOrderDeliveryState = createSelector(
  getCourierModuletState,
  (data) => data.orderDeliveries
);
