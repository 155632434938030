import { Injectable } from '@angular/core';
import { NotificationsService } from '../notifications/notifications.service';
import { CommonError, FieldsError } from '../../models/http-error';
import { isString, values, isArray } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { toPairs } from 'lodash';
import { _ } from '../../helpers/translation-marker';
export const backendErrors = 'backendErrors';
import { groupBy } from 'lodash';

export interface BackendError {
  message: string;
  field: string | null;
  code: string;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  constructor(
    private notificationService: NotificationsService,
    private translateService: TranslateService
  ) {}

  private isCommonError(data): data is CommonError {
    return data && data.detail && isString(data.detail);
  }

  private isFieldsError(data): data is FieldsError {
    return values(data).reduce((acc, it) => acc && isArray(it), true);
  }

  private isBackendError(data): data is BackendError[] {
    if (!isArray(data)) {
      return false;
    }

    return data.reduce((acc, error) => {
      const possibleFields = ['message', 'field', 'code'];
      return (
        acc &&
        Object.keys(error).filter((it) => !possibleFields.includes(it))
          .length === 0
      );
    }, true);
  }

  isNonFieldError(fieldName) {
    return !fieldName || fieldName === 'non_field_errors';
  }

  private convertToFieldsError(errors: BackendError[]): FieldsError {
    return errors.reduce((acc, error) => {
      if (this.isNonFieldError(error.field)) {
        return acc;
      }
      if (!acc[error.field]) {
        acc[error.field] = [error.message];
      } else {
        acc[error.field].push(error.message);
      }
      return acc;
    }, {});
  }

  handle(error: any): FieldsError {
    if (!error || !this.isBackendError(error)) {
      this.notificationService.error(
        this.translateService.instant(_('Internal server error'))
      );
      return null;
    }

    const commonErrors = error
      .filter((it) => this.isNonFieldError(it.field))
      .map((it) => it.message);
    if (commonErrors.length > 0) {
      this.notificationService.error(commonErrors);
    }

    return this.convertToFieldsError(error);
  }

  propogateErrorsInForm(error: FieldsError, form: FormGroup) {
    if (!form) {
      return;
    }
    toPairs(form.controls).forEach(
      ([key, control]: [string, AbstractControl]) => {
        const fieldError = error && error[key];
        let errors = control.errors || {};
        if (fieldError) {
          control.markAsTouched({ onlySelf: true });
          errors[backendErrors] = fieldError;
        } else {
          delete errors[backendErrors];
        }

        if (Object.keys(errors).length === 0) {
          errors = null;
        }

        control.setErrors(errors);
      }
    );
  }
}
