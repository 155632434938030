import { Component, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FilterValueAccessor, MakeProvider } from '../filter-value-accessor';
import { SelectItem } from '../../../../shared/components/form/select/select.component';
import {
  SegmentOperator,
  SegmentOperatorLabels
} from '../../../models/segment-property';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-segment-filter-string',
  templateUrl: './segment-filter-string.component.html',
  styleUrls: ['./segment-filter-string.component.scss'],
  providers: [...MakeProvider(SegmentFilterStringComponent)]
})
export class SegmentFilterStringComponent extends FilterValueAccessor {
  operators: string[] = [];
  @ViewChild('selectElement') selectElement;
  private previousOperator;

  addTag = (value) => {
    const values = this.form.get('value').value || [];
    const merged = [...values, ...(this.isPhones(value) ? value.split(' ') : [value])];
    this.form.patchValue({value: merged});
    this.selectElement.searchInput.nativeElement.value = '';
    return null;
  }

  isPhones(value) {
    return value?.match(/^[\d\n\-\+\(\) ]+$/);
  }

  ngOnInit() {
    this.initForm();
    this.initSubscriptions();
    this.updateOperators();
  }

  initForm() {
    this.form = this.fb.group({
      operator: [ null, Validators.required ],
      value: [ null, this.notEmptyValidator ],
    });
  }

  notEmptyValidator(control: FormControl) {
    return control.value && control.value.length > 0 ? null : { required: true };
  }

  initSubscriptions() {
    this.subscriptions.add(
      this.form.valueChanges.subscribe(() => {
        if (!this.resetValueAfterChangeOperator()) {
          this.pushValue({
            ...this.filter,
            ...this.serialize(),
          });
        }

        this.previousOperator = this.form.get('operator').value;
      }),
    );
  }

  updateOperators() {
    if (this.property && this.property.operators) {
      this.operators = this.property.operators;
    }
  }

  serialize() {
    const values = this.form.value;
    return values;
  }

  resetValueAfterChangeOperator() {
    if (!this.previousOperator) {
      return false;
    }

    const currentOperator = this.form.get('operator').value;
    const changed = this.isArrayValue(currentOperator) !== this.isArrayValue(this.previousOperator);
    if (changed) {
      this.form.patchValue({
        value: null
      }, { emitEvent: false });
    }

    return changed;
  }

  onWrite(values) {
    const formValues = {
      ...values,
      operator: values.operator || this.property.operators[0],
      value: values.value
    };
    this.form.patchValue(formValues, { emitEvent: false });

    this.resetValueAfterChangeOperator();
    this.previousOperator = formValues.operator;
  }

  isArrayValue(item) {
    return [SegmentOperator.IN, SegmentOperator.NOT_IN].includes(item);
  }

  isMultiSelect() {
    return this.isArrayValue(this.form.get('operator').value);
  }
}
