import { Omit } from '../../shared/helpers/omit';

export class DictionaryItem {
  id: string;
  name: string;
  description: string;
  dictionary: string;
  data: any;
  segments: any[];
  actions?: string[];
  _trackId?: string;
  _isTouched?: boolean;
  _isLoading?: boolean;

  constructor(data) {
    Object.assign(this, data);
    this.prepare();
  }

  protected prepare() {
    if (!this._trackId) {
      this._trackId = Math.round(Math.random() * 9999999999).toString(16);
    }
  }
}

export type UpdateDictionaryItemData = Omit<DictionaryItem, 'id'>;

export const DICTIONARY_ITEM_KEY = 'DICTIONARY_ITEM_KEY';
