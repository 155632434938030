import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Company } from '../../models/companies';
import { PagebleEntity } from '../../models/pageble-entity';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUser(): Observable<any> {
    return this.http.get('auth/user/');
  }

  getCompanies(): Observable<Company[]> {
    return this.http.get<PagebleEntity<Company>>('companies/').pipe(map((it: PagebleEntity<Company>) => it.results));
  }
}
