import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/reducers';
import { Call } from '../../models/calls.model';
import { UpdateCall } from '../../store/call.actions';

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent implements OnInit {
  customerId: string;
  comment: FormControl = new FormControl();

  @Input() bsModalRef;

  @Input() set call(call: Call) {
    this.customerId = call.id ? call.id : '';
    if (call && call.comment) {
      this.comment.setValue(call.comment);
    }
  }

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>) {
  }

  ngOnInit() {
  }

  submit() {

    const comment = this.comment.value;
    this.store.dispatch(new UpdateCall({
      id: this.customerId,
      comment: comment
    }));
    this.bsModalRef.hide();
  }

  close() {
    this.bsModalRef.hide();
  }
}
