import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { isString } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private toastService: ToastrService) {}

  error(notification: string | string[]) {
    if (isString(notification)) {
      this.toastService.error(notification, undefined, {
        closeButton: true,
        timeOut: 20000,
      });
    } else {
      notification.forEach((it) => this.error(it));
    }
  }

  success(notification: string) {
    this.toastService.success(notification);
  }
}
