<ng-container *ngIf="!(isLoading$ | async) && segment; else loading">
  <div class="wrap">
    <app-segment-form [label]="segment.name"
                      [segment]="segment$ | async"
                      (nameUpdated)="updateName($event)"
                      (filterUpdated)="saveFilter($event)"
                      (filterRemoved)="removeFilter($event)"
                      (cancelClick)="cancelClick()"
                      [canEditFilters]="true"
                      [canAddFilter]="true"
                      [showFormButtons]="false"
                      [currentSegmentModelLabel]="currentSegmentModel$ | async"
                      [isEdit]="true"></app-segment-form>
  </div>
</ng-container>
<ng-template #loading></ng-template>
