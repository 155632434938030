import {
  Component,
  OnInit,
  Input,
  HostListener,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import {
  SegmentProperty,
  SegmentOperator,
  SegmentOperatorLabels,
  SegmentOperatorLabelsExt,
} from '../../models/segment-property';
import { getSegmentPropertiesMap } from '../../store/selectors/segment-properties.selector';
import { take, filter } from 'rxjs/operators';
import { getSegmentModelsList } from '../../store/selectors/segment-models.selector';
import { SegmentModel } from '../../models/segment-model';
import { keyBy } from 'lodash';
@Component({
  selector: 'app-segment-details',
  templateUrl: './segment-details.component.html',
  styleUrls: ['./segment-details.component.scss'],
})
export class SegmentDetailsComponent implements OnInit, OnDestroy {
  @Input() segment;
  @Input() isLoading;
  @Output() destroyed = new EventEmitter<void>();
  @Output() hide = new EventEmitter<void>();
  models: { [key: string]: SegmentModel };
  properties: { [key: string]: SegmentProperty };
  operator = SegmentOperator;
  operatorLabel = SegmentOperatorLabels;
  operatorLabelExt = SegmentOperatorLabelsExt;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.store
      .pipe(
        select(getSegmentPropertiesMap),
        filter((p) => !!p),
        take(1)
      )
      .subscribe((properties) => (this.properties = properties));

    this.store
      .pipe(select(getSegmentModelsList, take(1)))
      .subscribe((models) => {
        this.models = keyBy(models, 'id');
      });
  }

  ngOnDestroy() {
    this.destroyed.emit();
  }

  interpolateValue(filter) {
    if (this.properties) {
      const property = this.properties[filter.property.id || filter.property];

      let value = '';
      switch (property.widget) {
        case 'enum':
          value = filter.value
            .map(
              (v) =>
                property.property_values.find(
                  (p) => String(p.ext_id) === String(v)
                ) //@TODO: fix types on back (currently property_values: [{ ext_id: 585, ... )] value: ['585']
            )
            .map((v) => v.name)
            .join(', ');
          break;
        case 'date':
        case 'number':
          if (filter.operator === this.operator.RANGE) {
            value = `${filter.value[0]} — ${filter.value[1]}`;
          } else {
            value = filter.value;
          }
          break;
        case 'string':
          if (
            [this.operator.IN, this.operator.NOT_IN].includes(filter.operator)
          ) {
            value = filter.value.join(', ');
          } else {
            value = filter.value;
          }
          break;
        case 'remote_model':
          if (
            [this.operator.IN, this.operator.NOT_IN].includes(filter.operator)
          ) {
            value = filter.value.map((v) => v.name).join(', ');
          } else {
            value = filter.value.name;
          }
          break;
        default:
          value = Array.isArray(filter.value)
            ? filter.value.join(', ')
            : filter.value;
      }

      return value;
    }
  }
}
