import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { omit, map as _map } from 'lodash';
import { of, GroupedObservable } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  withLatestFrom,
  tap,
  filter,
  groupBy,
  mergeMap,
  debounceTime,
  takeUntil,
  take,
  combineLatest
} from 'rxjs/operators';
import { AppState } from '../../../../store/reducers';
import { appendOrSetCollection } from '../../../shared/helpers/append-or-set-collection';
import {
  calculatePage,
  OutputPagebleAction
} from '../../../shared/helpers/calculate-page';
import { PagebleEntity } from '../../../shared/models/pageble-entity';

import { ErrorHandlingService } from '../../../shared/services/error-handling/error-handling.service';
import {
  FormStatusLoad,
  FormStatusLoadFail,
  FormStatusLoadSuccess
} from '../../../shared/store/actions/form-status.actions';
import { DictionaryItem } from '../../models/dictionary-item';
import { DictionaryItemsService } from '../../services/dictionary-items/dictionary-items.service';
import { getDictionary } from '../dictionary/dictionary.selectors';
import {
  DICTIONARY_ITEM_CREATE,
  DICTIONARY_ITEM_CREATE_SUCCESS,
  DICTIONARY_ITEM_DELETE,
  DICTIONARY_ITEM_LOAD,
  DICTIONARY_ITEM_UPDATE,
  DICTIONARY_ITEMS_LOAD,
  DictionaryItemCreate,
  DictionaryItemCreateFail,
  DictionaryItemCreateSuccess,
  DictionaryItemDelete,
  DictionaryItemDeleteFail,
  DictionaryItemDeleteSuccess,
  DictionaryItemLoad,
  DictionaryItemLoadFail,
  DictionaryItemLoadSuccess,
  DictionaryItemsLoad,
  DictionaryItemsLoadFail,
  DictionaryItemsLoadSuccess,
  DictionaryItemUpdate,
  DictionaryItemUpdateFail,
  DictionaryItemUpdateSuccess,
  DictionaryItemInit
} from './dictionary-item.actions';
import {
  getDictionaryItems,
  getDictionaryItemByTrackId
} from './dictionary-item.selectors';
import { Dictionary } from '../../models/dictionary';

@Injectable()
export class DictionaryItemEffects {
  private readonly handleError = (error: HttpErrorResponse) => {
    this.errorHandler.handle(error.error);
    return of(new DictionaryItemLoadFail());
  }

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private dictionaryItemsService: DictionaryItemsService,
    private errorHandler: ErrorHandlingService,
    private router: Router
  ) {}

  @Effect()
  loadDictionaries = this.actions$.pipe(
    ofType(DICTIONARY_ITEMS_LOAD),
    withLatestFrom(this.store.pipe(select(getDictionary))),
    switchMap(([action, dictionary]: [DictionaryItemsLoad, Dictionary]) => {
      return this.dictionaryItemsService.all(dictionary.id).pipe(
        map((data: DictionaryItem[]) => {
          return new DictionaryItemsLoadSuccess(data);
        }),
        catchError(this.handleError)
      );
    })
  );

  @Effect()
  loadDictionaryItem$ = this.actions$.pipe(
    ofType(DICTIONARY_ITEM_LOAD),
    switchMap((action: DictionaryItemLoad) => {
      return this.dictionaryItemsService.one(action.payload).pipe(
        map(data => new DictionaryItemLoadSuccess(data)),
        catchError(this.handleError)
      );
    })
  );

  @Effect()
  createDictionaryItem$ = this.actions$.pipe(
    ofType(DICTIONARY_ITEM_CREATE),
    withLatestFrom(this.store.pipe(select(getDictionary))),
    mergeMap(([action, dictionary]: [DictionaryItemCreate, Dictionary]) => {
      return this.dictionaryItemsService
        .create({
          ...action.payload,
          dictionary: dictionary.id,
          segments: _map(action.payload.segments, 'id')
        })
        .pipe(
          withLatestFrom(
            this.store.pipe(
              select(getDictionaryItemByTrackId(action.payload._trackId))
            )
          ),
          map(([data, latest]) => {
            const combined = { ...latest, actions: data.actions, id: data.id };
            return new DictionaryItemCreateSuccess(
              combined as DictionaryItem,
              action.payload._trackId
            );
          }),
          catchError(this.handleError)
        );
    })
  );

  @Effect({ dispatch: false })
  afterCreateDictionaryItem$ = this.actions$.pipe(
    ofType(DICTIONARY_ITEM_CREATE_SUCCESS),
    withLatestFrom(this.store.pipe(select(getDictionaryItems)))
  );

  @Effect()
  updateDictionaryItem$ = this.actions$.pipe(
    ofType(DICTIONARY_ITEM_UPDATE),
    tap((action: DictionaryItemUpdate) => {
      this.store.dispatch({
        type: 'CANCEL_DICTIONARY_ITEM_UPDATE_REQUEST',
        trackId: action.payload._trackId
      });
    }),
    groupBy((action: DictionaryItemUpdate) => action.payload._trackId),
    mergeMap((action: GroupedObservable<any, any>) => {
      return action.pipe(
        debounceTime(1000),
        combineLatest(
          action.pipe(
            switchMap(_action =>
              this.store.pipe(
                select(getDictionaryItemByTrackId(_action.payload._trackId)),
                filter(item => !!item.id),
                take(1),
                map(item => item.id)
              )
            )
          )
        ),
        switchMap(([action, itemId]: [DictionaryItemUpdate, string]) => {
          this.store.dispatch(new FormStatusLoad());
          const cancel$ = this.actions$.pipe(
            ofType('CANCEL_DICTIONARY_ITEM_UPDATE_REQUEST'),
            filter(
              (a: { type: string; trackId: string }) =>
                a.trackId === action.payload._trackId
            )
          );
          return this.dictionaryItemsService
            .update(itemId, {
              ...omit(action.payload, 'id'),
              segments: _map(action.payload.segments, 'id')
            })
            .pipe(
              map(data => {
                this.store.dispatch(new FormStatusLoadSuccess());
                return new DictionaryItemUpdateSuccess(data);
              }),
              catchError((error: HttpErrorResponse) => {
                this.store.dispatch(new FormStatusLoadFail());
                return of(
                  new DictionaryItemUpdateFail(
                    this.errorHandler.handle(error.error)
                  )
                );
              }),
              takeUntil(cancel$)
            );
        })
      );
    })
  );

  @Effect()
  deleteDictionaryItem$ = this.actions$.pipe(
    ofType(DICTIONARY_ITEM_DELETE),
    mergeMap((action: DictionaryItemDelete) => {
      this.store.dispatch(new FormStatusLoad());
      return this.dictionaryItemsService.delete(action.payload).pipe(
        map(() => {
          this.store.dispatch(new FormStatusLoadSuccess());
          return new DictionaryItemDeleteSuccess(action.payload);
        }),
        catchError((error: HttpErrorResponse) => {
          this.store.dispatch(new FormStatusLoadFail());
          this.errorHandler.handle(error.error);
          return of(new DictionaryItemDeleteFail());
        })
      );
    })
  );
}
