<ng-container *ngIf='control?.invalid && (control?.touched || control?.dirty || submitted) && control?.errors as errors'>
  <div class='small text-danger' *ngIf='errors.required'>
    {{'Required' | translate}}
  </div>
  <div class='small text-danger' *ngIf='errors.max'>
    {{'Maximuim value is' | translate}} {{ errors?.max?.max || errors?.max?.max === 0 ? errors?.max?.max : errors.max}}
  </div>
  <div class='small text-danger' *ngIf='errors.min'>
    {{'Minimum value is' | translate}} {{ errors?.min?.min || errors?.min?.min === 0 ? errors?.min?.min : errors.min }}
  </div>
  <div class='small text-danger' *ngIf='errors.maxlength'>
    {{'Maximuim length is' | translate}} {{errors.maxlength.requiredLength}}{{' '}}{{'symbols' | translate}}
  </div>
  <div class='small text-danger' *ngIf='errors.minlength'>
    {{'Minimum length is' | translate}} {{errors.minlength.requiredLength}}{{' '}}{{'symbols' | translate}}
  </div>
  <div class='small text-danger' *ngIf='errors.appMinDate'>
    {{'Date cannot be earlier than' | translate}} {{formDate(errors.value)}}
  </div>
  <div class='small text-danger' *ngIf='errors.appMaxDate'>
    {{'Date cannot be later than' | translate}} {{formDate(errors.value)}}
  </div>
  <div *ngIf='errors.rangeInvalid' class='small text-danger'>
    {{ 'Invalid range' | translate }}
  </div>
  <div *ngIf='errors.dateTimeRangeInvalid' class='small text-danger'>
    {{ 'Invalid date time range' | translate }}
  </div>
  <div *ngIf='errors.invalidPhone' class='small text-danger'>
    {{ 'Phone number is invalid' | translate }}
  </div>
  <div class='small text-danger' *ngIf="errors.validatePasswordConfirmation">
    {{'Passwords don\'t match'}}
  </div>
  <div class='small text-danger' *ngIf="errors.email">
    {{'Email has wrong format'}}
  </div>
  <div *ngIf='errors.pattern && isPhonePattern()' class='small text-danger'>
    {{ 'Invalid phone number' | translate }}
  </div>
  <div *ngIf='errors.pattern && !isPhonePattern()' class='small text-danger'>
    {{ 'Value doesn\'t match pattern' | translate }}: "{{errors.pattern.requiredPattern}}"
  </div>
</ng-container>

<ng-container *ngIf='control?.errors && control?.errors[backendErrors]'>
  <div class='small text-danger'
       *ngFor='let item of control.errors[backendErrors]'>
    {{item | translate}}
  </div>
</ng-container>
