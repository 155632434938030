import { cloneDeep } from 'lodash';
import { Segment } from './segment';
import { SegmentOperator, SegmentProperty } from './segment-property';

export class SegmentFilter {
  id?: string;
  value: any | any[] = null;
  operator: SegmentOperator = null;
  property: SegmentProperty;
  count?: string;
  data?: any = {};
  segment?: FullSegment;
  widget?: string;
  exclude?: boolean;
  _trackId?: string;
  _isTouched?: boolean;
  _isLoading?: boolean;

  constructor(data) {
    Object.assign(this, data);
    this.prepare();
  }

  protected prepare() {
    if (!this._trackId) {
      this._trackId = Math.round(Math.random() * 9999999999).toString(16);
    }
  }
}

export type UpdatedSegmentFilter = Pick<
  SegmentFilter,
  'value' | 'operator' | 'property'
>;

export class FullSegment extends Segment {
  company: string;
  model: string;
  name: string;
  filters: SegmentFilter[] = [];

  constructor(data = {}) {
    super();
    Object.assign(this, data);
    this.filters = this.filters.map((filter) => new SegmentFilter(filter));
  }

  filterIdx(trackId) {
    return this.filters.findIndex((filter) => filter._trackId === trackId);
  }

  updateAndClone(data) {
    if (data.filters) {
      const updFilters: SegmentFilter[] = data.filters.map((filter) => {
        const existIdx = this.filters.findIndex((f) => f.id === filter.id);
        if (existIdx !== -1) {
          return new SegmentFilter({
            ...this.filters[existIdx],
            ...filter,
          });
        } else {
          return new SegmentFilter({
            ...filter,
          });
        }
      });

      const newFilters: SegmentFilter[] = this.filters.filter((f) => !f.id);

      data.filters = updFilters.concat(newFilters);
    }
    Object.assign(this, data);

    return cloneDeep<FullSegment>(this);
  }

  deleteFilterAndClone(trackId) {
    this.filters.splice(this.filterIdx(trackId), 1);
    return cloneDeep<FullSegment>(this);
  }

  updateFilterAndClone(trackId, data): FullSegment {
    this.filters[this.filterIdx(trackId)] = new SegmentFilter({
      ...this.filters[this.filterIdx(trackId)],
      ...data,
    });
    return cloneDeep<FullSegment>(this);
  }

  clone() {
    return cloneDeep<FullSegment>(this);
  }

  updateFilter(trackId, data): FullSegment {
    this.filters[this.filterIdx(trackId)] = new SegmentFilter({
      ...this.filters[this.filterIdx(trackId)],
      ...data,
    });
    return this;
  }

  update(data) {
    if (data.filters) {
      const updFilters: SegmentFilter[] = data.filters.map((filter) => {
        const existIdx = this.filters.findIndex((f) => f.id === filter.id);
        if (existIdx !== -1) {
          return new SegmentFilter({
            ...this.filters[existIdx],
            ...filter,
          });
        } else {
          return new SegmentFilter({
            ...filter,
          });
        }
      });

      const newFilters: SegmentFilter[] = this.filters.filter((f) => !f.id);

      data.filters = updFilters.concat(newFilters);
    }
    Object.assign(this, data);

    return this;
  }

  pushFilterAndClone(newFilter: SegmentFilter): FullSegment {
    this.filters = [...this.filters, newFilter];
    return cloneDeep<FullSegment>(this);
  }
}

export type SegmentForm = Pick<FullSegment, 'name' | 'filters' | 'model'>;
export type UpdatedSegment = Pick<FullSegment, 'name'>;
