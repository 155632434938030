import { _ } from '../../shared/helpers/translation-marker';

export type SegmentPropertyType =
  | 'uuid'
  | 'int'
  | 'float'
  | 'str'
  | 'date'
  | 'datetime'
  | 'bool'
  | 'dict'
  | 'full_address';

export type SegmentWidget =
  | 'enum'
  | 'date'
  | 'number'
  | 'string'
  | 'remote_model'
  | string;

export enum SegmentOperator {
  IN = 'in',
  NOT_EXACT = 'not_exact',
  I_STARTS_WITH = 'istartswith',
  DAY = 'day',
  GTE = 'gte',
  LT = 'lt',
  I_ENDS_WITH = 'iendswith',
  CONTAINS = 'contains',
  ENDS_WITH = 'endswith',
  I_EXACT = 'iexact',
  GT = 'gt',
  SEARCH = 'search',
  IS_NULL = 'isnull',
  I_CONTAINS = 'icontains',
  NOT_IN = 'not_in',
  YEAR = 'year',
  EXACT = 'exact',
  RANGE = 'range',
  MONTH = 'month',
  I_REGEX = 'iregex',
  STARTS_WITH = 'startswith',
  WEEK_DAY = 'week_day',
  REGEX = 'regex',
  LTE = 'lte',
  NOT_RANGE = 'not_range',
}

export const SegmentOperatorLabels: { [key: string]: string } = {
  [SegmentOperator.IN]: _('In'),
  [SegmentOperator.NOT_EXACT]: _('Not exact'),
  [SegmentOperator.DAY]: _('Day'),
  [SegmentOperator.GTE]: _('Greater than or equal'),
  [SegmentOperator.LT]: _('Lower than'),
  [SegmentOperator.CONTAINS]: _('Contains'),
  [SegmentOperator.I_CONTAINS]: _('Contains'),
  [SegmentOperator.ENDS_WITH]: _('Ends with'),
  [SegmentOperator.I_ENDS_WITH]: _('Ends with'),
  [SegmentOperator.GT]: _('Greater than'),
  [SegmentOperator.SEARCH]: _('Search'),
  [SegmentOperator.IS_NULL]: _('Is null'),
  [SegmentOperator.NOT_IN]: _('Not in'),
  [SegmentOperator.YEAR]: _('Year'),
  [SegmentOperator.EXACT]: _('Exact'),
  [SegmentOperator.I_EXACT]: _('Exact'),
  [SegmentOperator.RANGE]: _('Range'),
  [SegmentOperator.NOT_RANGE]: _('Not in range'),
  [SegmentOperator.MONTH]: _('Month'),
  [SegmentOperator.STARTS_WITH]: _('Starts with'),
  [SegmentOperator.I_STARTS_WITH]: _('Starts with'),
  [SegmentOperator.WEEK_DAY]: _('Week day'),
  [SegmentOperator.REGEX]: _('Regular expression'),
  [SegmentOperator.I_REGEX]: _('Regular expression'),
  [SegmentOperator.LTE]: _('Lower than or equal'),
};

export const SegmentOperatorLabelsExt: { [key: string]: string } = {
  [SegmentOperator.CONTAINS]: _('Case sensitive'),
  [SegmentOperator.I_CONTAINS]: _('Case insensitive'),
  [SegmentOperator.ENDS_WITH]: _('Case sensitive'),
  [SegmentOperator.I_ENDS_WITH]: _('Case insensitive'),
  [SegmentOperator.EXACT]: _('Case sensitive'),
  [SegmentOperator.I_EXACT]: _('Case insensitive'),
  [SegmentOperator.STARTS_WITH]: _('Case sensitive'),
  [SegmentOperator.I_STARTS_WITH]: _('Case insensitive'),
  [SegmentOperator.REGEX]: _('Case sensitive'),
  [SegmentOperator.I_REGEX]: _('Case insensitive'),
};

export const SegmentOperatorInterpolationTypeSpecific: {
  [key: string]: { [key: string]: string };
} = {
  datetime: {
    [SegmentOperator.LTE]: _('until'),
    [SegmentOperator.GTE]: _('from'),
  },
  date: { [SegmentOperator.LTE]: _('until'), [SegmentOperator.GTE]: _('from') },
};

export const SegmentOperatorInterpolation: { [key: string]: string } = {
  [SegmentOperator.IN]: _('is'),
  [SegmentOperator.EXACT]: _('is'),
  [SegmentOperator.NOT_IN]: _('is not'),
  [SegmentOperator.RANGE]: null,
  [SegmentOperator.NOT_RANGE]: _('is not'),
};

export interface SegmentPropertyValue {
  ext_id: string;
  name: string;
}

export interface SegmentProperty {
  id: string;
  model: string;
  name: string;
  is_enum: boolean;
  is_editable: boolean;
  is_filter: boolean;
  is_readable: boolean;
  is_segment_filter: boolean;
  is_many: boolean;
  parent: string;
  is_required: boolean;
  type: SegmentPropertyType;
  values?: any[];
  property_values?: SegmentPropertyValue[];
  operators?: string[];
  widget: SegmentWidget;
  remote_model?: string;
  group: string;
  limits?: {
    min: number;
    max: number;
  };
  path: string;
}

export const SEGMENT_PROPERTY_NAMES = {
  ['activity']: _('Activity'),
  ['address']: _('Address'),
  ['amount']: _('Amount'),
  ['article']: _('Article'),
  ['balance']: _('Balance'),
  ['bank']: _('Bank'),
  ['bic']: _('Bic'),
  ['birthday']: _('Birthday'),
  ['brand']: _('Brand'),
  ['branch']: _('Branch'),
  ['card']: _('Card'),
  ['check']: _('Check'),
  ['checking account']: _('Checking account'),
  ['corrected by']: _('Corrected by'),
  ['correspondent account']: _('Correspondent account'),
  ['cost price']: _('Cost price'),
  ['count']: _('Count'),
  ['customer']: _('Customer'),
  ['data']: _('Data'),
  ['date']: _('Date'),
  ['date_created']: _('Created on'),
  ['description']: _('Description'),
  ['device']: _('Device'),
  ['dictionary']: _('Dictionary'),
  ['division']: _('Division'),
  ['email']: _('Email'),
  ['email state']: _('Email state'),
  ['employee']: _('Employee'),
  ['entity']: _('Entity'),
  ['ext_id']: _('External ID'),
  ['firing_date']: _('Firing date'),
  ['first name']: _('First name'),
  ['first_name']: _('First name'),
  ['format']: _('Format'),
  ['gender']: _('Gender'),
  ['group']: _('Group'),
  ['hiring_date']: _('Hiring date'),
  ['image']: _('Image'),
  ['issued']: _('Issued'),
  ['issued by']: _('Issued by'),
  ['items']: _('Items'),
  ['last name']: _('Last name'),
  ['last_name']: _('Last name'),
  ['latitude']: _('Latitude'),
  ['legal address']: _('Legal address'),
  ['location']: _('Location'),
  ['longitude']: _('Longitude'),
  ['mall']: _('Mall'),
  ['middle name']: _('Middle name'),
  ['middle_name']: _('Middle name'),
  ['name']: _('Name'),
  ['number']: _('Number'),
  ['old price']: _('Old price'),
  ['outlet']: _('Outlet'),
  ['parent']: _('Parent'),
  ['phone']: _('Phone'),
  ['phone state']: _('Phone state'),
  ['price']: _('Price'),
  ['product']: _('Product'),
  ['products']: _('Products'),
  ['psrn']: _('PSRN'),
  ['region']: _('Region'),
  ['registration_date']: _('Registration date'),
  ['rental address']: _('Rental address'),
  ['sku']: _('SKU'),
  ['sort']: _('Sort'),
  ['state']: _('State'),
  ['timezone']: _('Timezone'),
  ['tin']: _('Tin'),
  ['type']: _('Type'),
  ['units']: _('Units'),
  ['username']: _('Username'),
  ['working time']: _('Working time'),
  ['materials fineness']: _('Materials fineness'),
  ['materials material']: _('Materials material'),
  ['materials weight']: _('Materials weight'),
  ['gems clarity and color']: _('Gems clarity and color'),
  ['gems count']: _('Gems count'),
  ['gems cut']: _('Gems cut'),
  ['gems diameter']: _('Gems diameter'),
  ['gems gem']: _('Gems gem'),
  ['gems weight']: _('Gems weight'),
  ['price per weight']: _('Price per weight'),
  ['weight']: _('Weight'),
  ['color']: _('Color'),
  ['style']: _('Style'),
  ['Design']: _('Design'),
  ['kind']: _('Kind'),
  ['Margin groups']: _('Margin groups'),
  ['product group']: _('Product group'),
  ['Product line']: _('Product line'),
  ['Female']: _('Female'),
  ['Male']: _('Male'),
  ['Structure']: _('Structure'),
};
