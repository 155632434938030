import { createSelector, createFeatureSelector } from '@ngrx/store';
import { CourierState, getCourierState } from './courier.reducer';

export const getCourierOrders = createSelector(
  getCourierState,
  (state: CourierState) => state.orders
);

export const getCourierOrdersFilter = createSelector(
  getCourierState,
  (state: CourierState) => state.ordersFilter
);

export const getCouriers = createSelector(
  getCourierState,
  (state: CourierState) => state.couriers
);

export const getDeliveryRegions = createSelector(
  getCourierState,
  (state: CourierState) => state.regions
);

export const isLoadingDeliveryRegions = createSelector(
  getCourierState,
  (state: CourierState) => state.isLoadingRegions
);

export const getCouriersMap = createSelector(
  getCourierState,
  (state: CourierState) =>
    state.couriers.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {})
);

export const getRegionOutletsMap = createSelector(
  getCourierState,
  (state: CourierState) =>
    state.outlets.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {})
);
