import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/reducers';
import {
  DictionaryItem,
  UpdateDictionaryItemData,
} from '../../models/dictionary-item';
import { map as _map } from 'lodash';

@Component({
  selector: '[app-dictionary-item-row-form]',
  templateUrl: './dictionary-item-row-form.component.html',
  styleUrls: ['./dictionary-item-row-form.component.scss'],
})
export class DictionaryItemRowFormComponent implements OnInit {
  @Input() set dictionaryItem(value: DictionaryItem) {
    this._dictionaryItem = value;
    if (!value) {
      return;
    }
    this.form.patchValue(value, { emitEvent: false });
  }
  get dictionaryItem() {
    return this._dictionaryItem;
  }

  @Output() onSubmit = new EventEmitter<UpdateDictionaryItemData>();
  @Output() changeItem = new EventEmitter<UpdateDictionaryItemData>();
  @Output() removeItem = new EventEmitter<UpdateDictionaryItemData>();
  _dictionaryItem: DictionaryItem;
  form: FormGroup;

  constructor(private store: Store<AppState>, private fb: FormBuilder) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      description: '',
      sort: [0, Validators.required],
      segments: null,
    });
  }

  ngOnInit() {}

  save() {
    if (this.form.valid) {
      this.changeItem.emit({ ...this.dictionaryItem, ...this.form.value });
      this.form.markAsPristine();
    }
  }

  remove() {
    this.removeItem.emit({
      ...this.dictionaryItem,
      ...this.form.getRawValue(),
    });
  }
}
