import { Action } from '@ngrx/store';

import { PagebleEntity } from '../../../../shared/models/pageble-entity';
import { FieldsError } from '../../../../shared/models/http-error';
import { PagebleActionPayload } from '../../../../shared/helpers/calculate-page';
import {
  OrderDelivery,
  OrderDeliveryChangeDeliveryDate,
  OrderDeliveryChangeCourier,
  OrderDeliveryFilter,
  RescheduleOrderDeliveryPayload,
  OrderDeliveryActionReason,
} from '../../../models/order-delivery.model';

export enum OrderDeliveryActionTypes {
  LoadOrderDeliveries = '[Order Deliveries] load',
  LoadOrderDeliveriesSuccess = '[Order Deliveries] load success',
  LoadOrderDeliveriesFail = '[Order Deliveries] load fail',
  //
  LoadOrderDelivery = '[Order Delivery] load',
  LoadOrderDeliverySuccess = '[Order Delivery] load success',
  LoadOrderDeliveryFail = '[Order Delivery] load fail',
  //
  LoadOrderDeliveriesOfOrder = '[Order Deliveries] Load Order Deliveries Of Order',
  LoadOrderDeliveriesOfOrderSuccess = '[Order Deliveries] Load Order Deliveries Of Order Success',
  LoadOrderDeliveriesOfOrderFail = '[Order Deliveries] Load Order Deliveries Of Order Fail',
  //
  SetOrderDeliveriesFilter = '[Order Deliveries] set filter',
  //
  UpdateOrderDelivery = '[Order Delivery] update',
  UpdateOrderDeliverySuccess = '[Order Delivery] update success',
  UpdateOrderDeliveryFail = '[Order Delivery] update fail',
  //
  ChangeCourierOrderDelivery = '[Order Delivery] change Courier',
  ChangeCourierOrderDeliverySuccess = '[Order Delivery] change Courier success',
  ChangeCourierOrderDeliveryFail = '[Order Delivery] change Courier fail',
  //
  ChangeDeliveryDateOrderDelivery = '[Order Delivery] change DeliveryDate',
  ChangeDeliveryDateOrderDeliverySuccess = '[Order Delivery] change Delivery Date success',
  ChangeDeliveryDateOrderDeliveryFail = '[Order Delivery] change Delivery Date fail',
  //
  ConfirmOrderDelivery = '[Order Delivery] Confirm Order Delivery',
  ConfirmOrderDeliveryFail = '[Order Delivery] Confirm Order Delivery Fail',
  ConfirmOrderDeliverySuccess = '[Order Delivery] Confirm Order Delivery Success',
  //
  CancelOrderDelivery = '[Order Delivery] Cancel Order Delivery',
  CancelOrderDeliveryFail = '[Order Delivery] Cancel Order Delivery Fail',
  CancelOrderDeliverySuccess = '[Order Delivery] Cancel Order Delivery Success',
  //
  CancelExternalOrderDelivery = '[Order Delivery] Cancel External Order Delivery',
  CancelExternalOrderDeliveryFail = '[Order Delivery] Cancel External Order Delivery Fail',
  CancelExternalOrderDeliverySuccess = '[Order Delivery] Cancel External Order Delivery Success',
  //
  RejectOrderDelivery = '[Order Delivery] Reject Order Delivery',
  RejectOrderDeliveryFail = '[Order Delivery] Reject Order Delivery Fail',
  RejectOrderDeliverySuccess = '[Order Delivery] Reject Order Delivery Success',
  //
  RescheduleOrderDelivery = '[Order Delivery] Reschedule Order Delivery',
  RescheduleOrderDeliveryFail = '[Order Delivery] Reschedule Order Delivery Fail',
  RescheduleOrderDeliverySuccess = '[Order Delivery] Reschedule Order Delivery Success',
  //
  LoadOrderDeliveryActionReasons = '[Order Delivery] Load Order Delivery Action Reasons',
  LoadOrderDeliveryActionReasonsSuccess = '[Order Delivery] Load Order Delivery Action Reasons Success',
  LoadOrderDeliveryActionReasonsFail = '[Order Delivery] Load Order Delivery Action Reasons Fail',
}

export class LoadOrderDeliveries implements Action {
  readonly type = OrderDeliveryActionTypes.LoadOrderDeliveries;
  constructor(public payload?: PagebleActionPayload) {}
}

export class LoadOrderDeliveriesSuccess implements Action {
  readonly type = OrderDeliveryActionTypes.LoadOrderDeliveriesSuccess;
  constructor(public payload?: PagebleEntity<OrderDelivery>) {}
}

export class LoadOrderDeliveriesFail implements Action {
  readonly type = OrderDeliveryActionTypes.LoadOrderDeliveriesFail;
}

export class LoadOrderDelivery implements Action {
  readonly type = OrderDeliveryActionTypes.LoadOrderDelivery;
  constructor(public id: string) {}
}

export class LoadOrderDeliverySuccess implements Action {
  readonly type = OrderDeliveryActionTypes.LoadOrderDeliverySuccess;
  constructor(public payload?: OrderDelivery) {}
}

export class LoadOrderDeliveryFail implements Action {
  readonly type = OrderDeliveryActionTypes.LoadOrderDeliveryFail;
}

export class LoadOrderDeliveriesOfOrder implements Action {
  readonly type = OrderDeliveryActionTypes.LoadOrderDeliveriesOfOrder;
  constructor(public order: string, public payload?: PagebleActionPayload) {}
}

export class LoadOrderDeliveriesOfOrderSuccess implements Action {
  readonly type = OrderDeliveryActionTypes.LoadOrderDeliveriesOfOrderSuccess;
  constructor(public payload?: PagebleEntity<OrderDelivery>) {}
}

export class LoadOrderDeliveriesOfOrderFail implements Action {
  readonly type = OrderDeliveryActionTypes.LoadOrderDeliveriesOfOrderFail;
}

export class SetOrderDeliveriesFilter implements Action {
  readonly type = OrderDeliveryActionTypes.SetOrderDeliveriesFilter;
  constructor(public payload: OrderDeliveryFilter, public reset = false) {}
}

export class UpdateOrderDelivery implements Action {
  readonly type = OrderDeliveryActionTypes.UpdateOrderDelivery;

  constructor(public payload: Partial<OrderDelivery>) {}
}

export class UpdateOrderDeliverySuccess implements Action {
  readonly type = OrderDeliveryActionTypes.UpdateOrderDeliverySuccess;

  constructor(public payload: OrderDelivery) {}
}

export class UpdateOrderDeliveryFail implements Action {
  readonly type = OrderDeliveryActionTypes.UpdateOrderDeliveryFail;

  constructor() {}
}

export class ConfirmOrderDelivery implements Action {
  readonly type = OrderDeliveryActionTypes.ConfirmOrderDelivery;
  constructor(
    public id: string,
    public payload?: RescheduleOrderDeliveryPayload
  ) {}
}

export class ConfirmOrderDeliveryFail implements Action {
  readonly type = OrderDeliveryActionTypes.ConfirmOrderDeliveryFail;
  constructor(public payload: FieldsError) {}
}
export class ConfirmOrderDeliverySuccess implements Action {
  readonly type = OrderDeliveryActionTypes.ConfirmOrderDeliverySuccess;
  constructor(public payload: OrderDelivery) {}
}

export class CancelOrderDelivery implements Action {
  readonly type = OrderDeliveryActionTypes.CancelOrderDelivery;
  constructor(
    public id: string,
    public payload?: RescheduleOrderDeliveryPayload
  ) {}
}

export class CancelOrderDeliveryFail implements Action {
  readonly type = OrderDeliveryActionTypes.CancelOrderDeliveryFail;
  constructor(public payload: FieldsError) {}
}
export class CancelOrderDeliverySuccess implements Action {
  readonly type = OrderDeliveryActionTypes.CancelOrderDeliverySuccess;
  constructor(public payload: OrderDelivery) {}
}

export class CancelExternalOrderDelivery implements Action {
  readonly type = OrderDeliveryActionTypes.CancelExternalOrderDelivery;
  constructor(public id: string) {}
}

export class CancelExternalOrderDeliveryFail implements Action {
  readonly type = OrderDeliveryActionTypes.CancelExternalOrderDeliveryFail;
  constructor(public payload: FieldsError) {}
}
export class CancelExternalOrderDeliverySuccess implements Action {
  readonly type = OrderDeliveryActionTypes.CancelExternalOrderDeliverySuccess;
}

export class RejectOrderDelivery implements Action {
  readonly type = OrderDeliveryActionTypes.RejectOrderDelivery;
  constructor(
    public id: string,
    public payload?: RescheduleOrderDeliveryPayload
  ) {}
}

export class RejectOrderDeliveryFail implements Action {
  readonly type = OrderDeliveryActionTypes.RejectOrderDeliveryFail;
  constructor(public payload: FieldsError) {}
}
export class RejectOrderDeliverySuccess implements Action {
  readonly type = OrderDeliveryActionTypes.RejectOrderDeliverySuccess;
  constructor(public payload: OrderDelivery) {}
}

export class RescheduleOrderDelivery implements Action {
  readonly type = OrderDeliveryActionTypes.RescheduleOrderDelivery;
  constructor(
    public id: string,
    public payload: RescheduleOrderDeliveryPayload
  ) {}
}

export class RescheduleOrderDeliveryFail implements Action {
  readonly type = OrderDeliveryActionTypes.RescheduleOrderDeliveryFail;
  constructor(public payload: FieldsError) {}
}
export class RescheduleOrderDeliverySuccess implements Action {
  readonly type = OrderDeliveryActionTypes.RescheduleOrderDeliverySuccess;
  constructor(public payload: OrderDelivery) {}
}

export class ChangeCourierOrderDelivery implements Action {
  readonly type = OrderDeliveryActionTypes.ChangeCourierOrderDelivery;
  constructor(public payload: OrderDeliveryChangeCourier) {}
}

export class ChangeCourierOrderDeliverySuccess implements Action {
  readonly type = OrderDeliveryActionTypes.ChangeCourierOrderDeliverySuccess;
  constructor(public payload: OrderDelivery) {}
}

export class ChangeCourierOrderDeliveryFail implements Action {
  readonly type = OrderDeliveryActionTypes.ChangeCourierOrderDeliveryFail;
}

export class ChangeDeliveryDateOrderDelivery implements Action {
  readonly type = OrderDeliveryActionTypes.ChangeDeliveryDateOrderDelivery;
  constructor(public payload: OrderDeliveryChangeDeliveryDate) {}
}

export class ChangeDeliveryDateOrderDeliverySuccess implements Action {
  readonly type =
    OrderDeliveryActionTypes.ChangeDeliveryDateOrderDeliverySuccess;
  constructor(public payload: OrderDelivery) {}
}

export class ChangeDeliveryDateOrderDeliveryFail implements Action {
  readonly type = OrderDeliveryActionTypes.ChangeDeliveryDateOrderDeliveryFail;
}

export class LoadOrderDeliveryActionReasons implements Action {
  readonly type = OrderDeliveryActionTypes.LoadOrderDeliveryActionReasons;
}

export class LoadOrderDeliveryActionReasonsSuccess implements Action {
  readonly type =
    OrderDeliveryActionTypes.LoadOrderDeliveryActionReasonsSuccess;
  constructor(public payload: OrderDeliveryActionReason[]) {}
}

export class LoadOrderDeliveryActionReasonsFail implements Action {
  readonly type = OrderDeliveryActionTypes.LoadOrderDeliveryActionReasonsFail;
}

export type OrderDeliveryActions =
  | LoadOrderDeliveries
  | LoadOrderDeliveriesSuccess
  | LoadOrderDeliveriesFail
  //
  | LoadOrderDelivery
  | LoadOrderDeliverySuccess
  | LoadOrderDeliveryFail
  //
  | LoadOrderDeliveriesOfOrder
  | LoadOrderDeliveriesOfOrderSuccess
  | LoadOrderDeliveriesOfOrderFail
  //
  | SetOrderDeliveriesFilter
  //
  | UpdateOrderDelivery
  | UpdateOrderDeliverySuccess
  | UpdateOrderDeliveryFail
  //
  | ChangeCourierOrderDelivery
  | ChangeCourierOrderDeliverySuccess
  | ChangeCourierOrderDeliveryFail
  //
  | ChangeDeliveryDateOrderDelivery
  | ChangeDeliveryDateOrderDeliverySuccess
  | ChangeDeliveryDateOrderDeliveryFail
  //
  | ConfirmOrderDelivery
  | ConfirmOrderDeliverySuccess
  | ConfirmOrderDeliveryFail
  //
  | CancelOrderDelivery
  | CancelOrderDeliverySuccess
  | CancelOrderDeliveryFail
  //
  | RejectOrderDelivery
  | RejectOrderDeliverySuccess
  | RejectOrderDeliveryFail
  //
  | RescheduleOrderDelivery
  | RescheduleOrderDeliverySuccess
  | RescheduleOrderDeliveryFail
  //
  | LoadOrderDeliveryActionReasons
  | LoadOrderDeliveryActionReasonsSuccess
  | LoadOrderDeliveryActionReasonsFail;
