import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isDate, isNil, pickBy } from 'lodash';
import { Observable } from 'rxjs';
import { PagebleEntity } from 'src/app/modules/shared/models/pageble-entity';
import { PageSettings } from 'src/app/modules/shared/helpers/calculate-page';
import { ReturnDelivery, ReturnDeliveryFilter } from '../../models/return-delivery.model';

@Injectable({
  providedIn: 'root',
})
export class ReturnDeliveriesService {
  basePath = 'return-deliveries/';

  constructor(private http: HttpClient) {}

  all(
    params: PageSettings,
    filter: ReturnDeliveryFilter
  ): Observable<PagebleEntity<ReturnDelivery>> {
    const notEmptyFilters = pickBy(
      filter,
      (value) => isDate(value) || !isNil(value)
    );
    const newParams = { ...params, ...notEmptyFilters };
    return this.http.get<PagebleEntity<ReturnDelivery>>(this.basePath, {
      params: newParams as any,
    });
  }

  one(id: string): Observable<ReturnDelivery> {
    return this.http.get<ReturnDelivery>(`${this.basePath}${id}/`);
  }
}
