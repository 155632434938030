import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'ngx-auth';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements AuthService {
  static ACCESS_TOKEN_NAME = 'accessToken';
  static REFRESH_TOKEN_NAME = 'refreshToken';
  get authStateChange(): Observable<boolean> {
    return this._authStateChange.asObservable();
  }

  private _authStateChange: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  constructor(private http: HttpClient, private router: Router) {
    this.isAuthorized().subscribe(state => this._authStateChange.next(state));
  }

  public isAuthorized(): Observable<boolean> {
    const isAuthorized: boolean = !!localStorage.getItem(
      AuthenticationService.ACCESS_TOKEN_NAME
    );

    return of(isAuthorized);
  }

  public getPlainAccessToken(): string {
    return localStorage.getItem(AuthenticationService.ACCESS_TOKEN_NAME);
  }

  public getAccessToken(): Observable<string> {
    return of(this.getPlainAccessToken());
  }

  public refreshToken(): Observable<any> {
    const refreshToken: string = localStorage.getItem(
      AuthenticationService.REFRESH_TOKEN_NAME
    );

    // TODO: Add url to refresh token
    localStorage.clear();
    this.logout();
    return throwError('Not implemented');
  }

  public refreshShouldHappen(response: HttpErrorResponse): boolean {
    return response.status === 401;
    // return false;
  }

  public verifyTokenRequest(url: string): boolean {
    return url.endsWith('refresh-token');
  }

  private saveAccessData(accessToken) {
    localStorage.setItem(AuthenticationService.ACCESS_TOKEN_NAME, accessToken);
  }

  public login(accessToken) {
    localStorage.setItem(AuthenticationService.ACCESS_TOKEN_NAME, accessToken.key);
    this.router.navigate(['/']);
    this._authStateChange.next(true);
  }

  public loginByUsernameAndPassword(
    username: string,
    password: string
  ): Observable<any> {
    return this.http.post('auth/login/', { username, password }).pipe(
      tap(token => {
        this.saveAccessData(token.key);
        this._authStateChange.next(true);
        console.log('###should navigate');
        this.router.navigate(['/']);
      })
    );
  }

  public logout(): void {
    if (!this._authStateChange.getValue()) {
      return;
    }
    localStorage.removeItem(AuthenticationService.ACCESS_TOKEN_NAME);
    this._authStateChange.next(false);
    this.router.navigate(['/auth/login']);
  }

  getHeaders?(token: string): { [name: string]: string | string[] } {
    return { Authorization: `Token ${this.getPlainAccessToken()}` };
  }
}
