import { createSelector } from '@ngrx/store';
import {
  CUSTOMER_CREATE,
  CUSTOMER_CREATE_FAIL,
  CUSTOMER_CREATE_SUCCESS,
  CUSTOMER_DELETE,
  CUSTOMER_DELETE_FAIL,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_LOAD,
  CUSTOMER_LOAD_FAIL,
  CUSTOMER_LOAD_SUCCESS,
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_FAIL,
  CUSTOMER_UPDATE_SUCCESS,
  CustomerActions,
  CUSTOMERS_CLEAR,
  CUSTOMERS_LOAD,
  CUSTOMERS_LOAD_FAIL,
  CUSTOMERS_LOAD_SUCCESS,
  CUSTOMERS_SET_FILTER
} from './customer.actions';
import { Customer, CustomersFilter } from '../../models/customer';
import { FieldsError } from '../../../shared/models/http-error';
import { isReload } from '../../../shared/helpers/calculate-page';
import { upsertItem } from '../../../shared/helpers/append-or-set-collection';
import { getCustomerModuleState } from '../selector';

export interface CustomerState {
  customers: Customer[];
  totalCustomersCount: number;
  customer: Customer;
  isLoadingCustomers: boolean;
  isLoadingCustomer: boolean;
  isReload: boolean;
  updating: boolean;
  customerError: FieldsError;
  filter: CustomersFilter;
}

export const initialState: CustomerState = {
  customers: [],
  totalCustomersCount: 0,
  customer: null,
  isLoadingCustomer: false,
  isLoadingCustomers: false,
  isReload: false,
  updating: false,
  customerError: null,
  filter: {}
};

export function CustomerReducer(
  state = initialState,
  action: CustomerActions
): CustomerState {
  switch (action.type) {
    case CUSTOMERS_LOAD:
      return {
        ...state,
        customers: isReload(action) ? [] : state.customers,
        isLoadingCustomers: true,
        isReload: isReload(action)
      };
    case CUSTOMERS_LOAD_SUCCESS:
      return {
        ...state,
        isLoadingCustomers: false,
        customers: action.payload.results,
        totalCustomersCount: action.payload.count,
        isReload: false
      };
    case CUSTOMERS_LOAD_FAIL:
      return { ...state, isLoadingCustomers: false, isReload: false };
    case CUSTOMERS_CLEAR:
      return { ...state, isLoadingCustomers: false, customers: [] };
    case CUSTOMER_LOAD:
      return { ...state, customer: null, isLoadingCustomer: true };
    case CUSTOMER_LOAD_SUCCESS:
      const newCustomers = state.customers
        .filter(it => it.id !== action.payload.id)
        .concat(action.payload);
      return {
        ...state,
        customers: newCustomers,
        isLoadingCustomer: false,
        customer: action.payload
      };
    case CUSTOMER_LOAD_FAIL:
      return { ...state, isLoadingCustomer: false };
    case CUSTOMER_CREATE:
      return { ...state, updating: true };
    case CUSTOMER_CREATE_SUCCESS:
      return { ...state, updating: false, customer: action.payload };
    case CUSTOMER_CREATE_FAIL:
      return { ...state, updating: false, customerError: action.payload };
    case CUSTOMER_UPDATE:
      return { ...state, updating: true };
    case CUSTOMER_UPDATE_SUCCESS:
      const customers = upsertItem(state.customers, action.payload);
      return { ...state, updating: false, customer: action.payload, customers };
    case CUSTOMER_UPDATE_FAIL:
      return { ...state, updating: false, customerError: action.payload };
    case CUSTOMER_DELETE:
      return { ...state, updating: true };
    case CUSTOMER_DELETE_SUCCESS:
    case CUSTOMER_DELETE_FAIL:
      return { ...state, updating: false };
    case CUSTOMERS_SET_FILTER:
      return { ...state, filter: action.payload };
    default:
      return state;
  }
}

export const getCustomerState = createSelector(
  getCustomerModuleState,
  data => data.customers
);
