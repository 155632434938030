import { Action } from '@ngrx/store';
import { PagebleActionPayload } from '../../shared/helpers/calculate-page';
import { PagebleEntity } from '../../shared/models/pageble-entity';
import { EmployeeActionType } from '../models/employee-action-types.model';
import { Employee, EmployeesFilter } from '../models/employee.model';

export enum EmployeeActionTypes {
  LoadEmployees = '[Employee] Load Employees',
  LoadEmployeesDebounced = '[Employee] Load Employees if not loading',
  LoadEmployeesSuccess = '[Employee] Load Employees Success',
  LoadEmployeesFail = '[Employee] Load Employees Fail',
  EmployeesSetFilter = '[Employee] set filter',
  LoadEmployeeActionTypes = '[Employee] Load Employee Action Types',
  LoadEmployeeActionTypesSuccess = '[Employee] Load Employee Action Types Success',
  LoadEmployeeActionTypesFail = '[Employee] Load Employee Action Types Fail'
}

export class LoadEmployees implements Action {
  readonly type = EmployeeActionTypes.LoadEmployees;
  constructor(public payload?: PagebleActionPayload) {}
}

export class LoadEmployeesDebounced implements Action {
  readonly type = EmployeeActionTypes.LoadEmployeesDebounced;
  constructor(public payload?: PagebleActionPayload) {}
}

export class LoadEmployeesSuccess implements Action {
  readonly type = EmployeeActionTypes.LoadEmployeesSuccess;
  constructor(public payload?: PagebleEntity<Employee>) {}
}

export class LoadEmployeesFail implements Action {
  readonly type = EmployeeActionTypes.LoadEmployeesFail;
}

export class EmployeesSetFilter implements Action {
  readonly type = EmployeeActionTypes.EmployeesSetFilter;
  constructor(public payload: EmployeesFilter) {}
}

export class LoadEmployeeActionTypes implements Action {
  readonly type = EmployeeActionTypes.LoadEmployeeActionTypes;
}

export class LoadEmployeeActionTypesSuccess implements Action {
  readonly type = EmployeeActionTypes.LoadEmployeeActionTypesSuccess;
  constructor(public payload: EmployeeActionType[]) {}
}

export class LoadEmployeeActionTypesFail implements Action {
  readonly type = EmployeeActionTypes.LoadEmployeeActionTypesFail;
}

export type EmployeeActions =
  | LoadEmployees
  | LoadEmployeesSuccess
  | LoadEmployeesFail
  | EmployeesSetFilter
  | LoadEmployeeActionTypes
  | LoadEmployeeActionTypesSuccess
  | LoadEmployeeActionTypesFail;
