import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import * as SIP from 'sip.js';
import { SipService } from '../../services/sip/sip.service';

export interface IncomingCall {
  sipSession: SIP.Session;
}

@Component({
  selector: 'app-incoming',
  templateUrl: './incoming.component.html',
  styleUrls: ['./incoming.component.scss']
})
export class IncomingComponent implements OnInit {
  // user: UserData = null;
  user = null;
  private sipSession: SIP.Session | null = null;
  private sipUa: SIP.UA;
  isConnected = false;
  incomingCalls: IncomingCall[] = [];

  @ViewChild('myAudio', { static: true })
  myAudio: ElementRef;
  @ViewChild('hisAudio', { static: true })
  hisAudio: ElementRef;
  call;

  private sipInviteOptions: SIP.InviteOptions;

  constructor(private sipService: SipService) {}

  ngOnInit() {
    this.sipService.setInviteOptions({
      remoteAudio: this.hisAudio.nativeElement,
      localAudio: this.myAudio.nativeElement
    });
  }

  // ngOnDestroy() {
  //   this.incomingCalls.map(incomingCall => {
  //     incomingCall.sipSession.bye();
  //     incomingCall.sipSession.cancel();
  //   });
  //   this.sipService.sipUa.removeAllListeners(); // typings bug
  // }

  // onHangUp(incomingCall: IncomingCall) {
  //   const index = this.getCallIndex(incomingCall);
  //   this.incomingCalls.splice(index, 1);
  //   this.stopPlay();
  // }

  // private getCallIndex(incomingCall: IncomingCall): number {
  //   return this.incomingCalls.findIndex(item => {
  //     return item === incomingCall;
  //   });
  // }

  // private subscribeOn(
  //   events: string,
  //   session: { on: Function },
  //   handler: Function
  // ) {
  //   events.split(' ').map(eventName => {
  //     session.on(eventName, event => {
  //       handler(event, eventName);
  //     });
  //   });
  // }

  // private subscribeLogger(
  //   prefix: string,
  //   events: string,
  //   session: { on: Function }
  // ) {
  //   events.split(' ').map(eventName => {
  //     session.on(eventName, () => {
  //       // console.log('event', prefix, eventName);
  //     });
  //   });
  // }
}
