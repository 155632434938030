import { Component, OnInit } from '@angular/core';
import {UpdateDictionaryData} from '../../models/dictionary';
import {AppState} from '../../../../store/reducers';
import {Store} from '@ngrx/store';
import {DictionaryCreate} from '../../store/dictionary/dictionary.actions';

@Component({
  selector: 'app-dictionary-create',
  templateUrl: './dictionary-create.component.html',
  styleUrls: ['./dictionary-create.component.scss']
})
export class DictionaryCreateComponent implements OnInit {

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
  }

  create(value: UpdateDictionaryData) {
    this.store.dispatch(new DictionaryCreate(value));
  }

}
