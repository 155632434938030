<div class="wrap list-wrapper">
  <div class="text-right">
    <div class="form-group row">
      <div class="col-sm-5 left">
        <input class="form-control value" [placeholder]="'Search' | translate" [(ngModel)]="nameFilter" />
      </div>
      <div class="col-sm-7 right">
        <a class="btn btn-primary" routerLink="/dictionaries/new">{{'Add dictionary'| translate}}</a>
      </div>
    </div>
  </div>
  <div class="panel"
       perfectScrollbar
       *ngIf='dictionaries.length > 0 || loading; else noDictionaries;'
  >
    <table class="table table-hover table-striped">
      <thead>
      <tr>
        <th>{{'Name' | translate}}</th>
        <th>{{'Auto' | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let dictionary of filteredDictionaries" class="clickable" (click)="goToDictionary(dictionary)">
        <td>{{dictionary.name}}</td>
        <td>{{(dictionary.auto ? 'Yes' : 'No') | translate}}</td>
      </tr>
      </tbody>
    </table>
    <app-loader *ngIf="loading"></app-loader>
  </div>
</div>
<ng-template #noDictionaries>
  <app-empty-list-placeholder [header]="'There are no dictionaries' | translate"></app-empty-list-placeholder>
</ng-template>
