import { FormControl } from '@angular/forms';
import { SegmentProperty } from '../../segments/models/segment-property';

export enum ControlType {
  Object = 0,
  Scalar = 1,
  Array = 2
}

export interface ControlInputs {
  type: ControlType;
  property: SegmentProperty;
  submitted: boolean;
  modelName: string;
}

export interface DynamicControl {
  inputs: ControlInputs;
  control: FormControl;
}
