import { Action } from '@ngrx/store';
import {
  PagebleAction,
  PagebleActionPayload
} from '../../../shared/helpers/calculate-page';
import { PagebleEntity } from '../../../shared/models/pageble-entity';
import { FieldsError } from '../../../shared/models/http-error';
import {
  DictionaryItem,
  UpdateDictionaryItemData
} from '../../models/dictionary-item';
import { UpdateDictionaryData } from '../../models/dictionary';

export const DICTIONARY_ITEMS_LOAD = '[Dictionary items] load';
export const DICTIONARY_ITEMS_LOAD_SUCCESS = '[Dictionary items] load success';
export const DICTIONARY_ITEMS_LOAD_FAIL = '[Dictionary items] load fail';

export const DICTIONARY_ITEM_LOAD = '[Dictionary item] dictionary_item load';
export const DICTIONARY_ITEM_LOAD_SUCCESS =
  '[Dictionary item] dictionary_item load success';
export const DICTIONARY_ITEM_LOAD_FAIL =
  '[Dictionary item] dictionary_item load fail';

export const DICTIONARY_ITEM_INIT = '[Dictionary item] dictionary_item init';

export const DICTIONARY_ITEM_CREATE =
  '[Dictionary item] dictionary_item create';
export const DICTIONARY_ITEM_CREATE_SUCCESS =
  '[Dictionary item] dictionary_item create success';
export const DICTIONARY_ITEM_CREATE_FAIL =
  '[Dictionary item] dictionary_item create fail';

export const DICTIONARY_ITEM_UPDATE =
  '[Dictionary item] dictionary_item update';
export const DICTIONARY_ITEM_UPDATE_SUCCESS =
  '[Dictionary item] dictionary_item update success';
export const DICTIONARY_ITEM_UPDATE_FAIL =
  '[Dictionary item] dictionary_item update fail';

export const DICTIONARY_ITEM_DELETE =
  '[Dictionary item] dictionary_item delete';
export const DICTIONARY_ITEM_DELETE_SUCCESS =
  '[Dictionary item] dictionary_item delete success';
export const DICTIONARY_ITEM_DELETE_FAIL =
  '[Dictionary item] dictionary_item delete fail';

export const DICTIONARY_ITEM_ADD = '[Dictionary item] dictionary_item add';
export const DICTIONARY_ITEM_ADD_SUCCESS =
  '[Dictionary item] dictionary_item add success';
export const DICTIONARY_ITEM_ADD_FAIL =
  '[Dictionary item] dictionary_item add fail';

export class DictionaryItemsLoad implements Action, PagebleAction {
  readonly type = DICTIONARY_ITEMS_LOAD;
  constructor(public dictionary?: PagebleActionPayload) {}
}

export class DictionaryItemsLoadSuccess implements Action {
  readonly type = DICTIONARY_ITEMS_LOAD_SUCCESS;
  constructor(public payload?: DictionaryItem[]) {}
}

export class DictionaryItemsLoadFail implements Action {
  readonly type = DICTIONARY_ITEMS_LOAD_FAIL;
}

export class DictionaryItemLoad implements Action {
  readonly type = DICTIONARY_ITEM_LOAD;
  constructor(public payload: string) {}
}

export class DictionaryItemLoadSuccess implements Action {
  readonly type = DICTIONARY_ITEM_LOAD_SUCCESS;
  constructor(public payload: DictionaryItem) {}
}

export class DictionaryItemLoadFail implements Action {
  readonly type = DICTIONARY_ITEM_LOAD_FAIL;
}

export class DictionaryItemInit implements Action {
  readonly type = DICTIONARY_ITEM_INIT;
}

export class DictionaryItemCreate implements Action {
  readonly type = DICTIONARY_ITEM_CREATE;
  constructor(public payload: UpdateDictionaryItemData) {}
}

export class DictionaryItemCreateSuccess implements Action {
  readonly type = DICTIONARY_ITEM_CREATE_SUCCESS;
  constructor(public payload: DictionaryItem, public trackId: string) {}
}

export class DictionaryItemCreateFail implements Action {
  readonly type = DICTIONARY_ITEM_CREATE_FAIL;
  constructor(public payload: FieldsError) {}
}

export class DictionaryItemUpdate implements Action {
  readonly type = DICTIONARY_ITEM_UPDATE;
  constructor(public payload: DictionaryItem) {}
}

export class DictionaryItemUpdateSuccess implements Action {
  readonly type = DICTIONARY_ITEM_UPDATE_SUCCESS;
  constructor(public payload: DictionaryItem) {}
}

export class DictionaryItemUpdateFail implements Action {
  readonly type = DICTIONARY_ITEM_UPDATE_FAIL;
  constructor(public payload: FieldsError) {}
}

export class DictionaryItemDelete implements Action {
  readonly type = DICTIONARY_ITEM_DELETE;
  constructor(public payload: string) {}
}

export class DictionaryItemDeleteSuccess implements Action {
  readonly type = DICTIONARY_ITEM_DELETE_SUCCESS;
  constructor(public payload: string) {}
}

export class DictionaryItemDeleteFail implements Action {
  readonly type = DICTIONARY_ITEM_DELETE_FAIL;
}

export class DictionaryFilterAdd implements Action {
  readonly type = DICTIONARY_ITEM_ADD;
  constructor(public payload: DictionaryItem, public trackId: string) {}
}

export class DictionaryFilterSuccess implements Action {
  readonly type = DICTIONARY_ITEM_ADD_SUCCESS;
  constructor(public payload: DictionaryItem, public trackId: string) {}
}

export class DictionaryFilterAddFail implements Action {
  readonly type = DICTIONARY_ITEM_ADD;
}

export type DictionaryItemActions =
  | DictionaryItemsLoad
  | DictionaryItemsLoadSuccess
  | DictionaryItemsLoadFail
  | DictionaryItemLoad
  | DictionaryItemLoadSuccess
  | DictionaryItemLoadFail
  | DictionaryItemInit
  | DictionaryItemCreate
  | DictionaryItemCreateSuccess
  | DictionaryItemCreateFail
  | DictionaryItemUpdate
  | DictionaryItemUpdateSuccess
  | DictionaryItemUpdateFail
  | DictionaryItemDelete
  | DictionaryItemDeleteSuccess
  | DictionaryItemDeleteFail;
