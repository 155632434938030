import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TipsFloatingComponent } from './components/tips-floating/tips-floating.component';
import { StoreModule } from '@ngrx/store';
import * as fromContactTip from './store/contact-tip.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ContactTipEffects } from './store/contact-tip.effects';
import { TipsPanelContentComponent } from './components/tips-panel-content/tips-panel-content.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [TipsFloatingComponent, TipsPanelContentComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature('contactTip', fromContactTip.reducer),
    EffectsModule.forFeature([ContactTipEffects])
  ],
  exports: [TipsFloatingComponent]
})
export class ContactTipsModule {}
