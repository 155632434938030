import { Action } from '@ngrx/store';
import { PagebleEntity } from '../../../../shared/models/pageble-entity';
import { PagebleActionPayload } from '../../../../shared/helpers/calculate-page';
import { ReturnDelivery, ReturnDeliveryFilter } from '../../../models/return-delivery.model';

export enum ReturnDeliveryActionTypes {
  LoadReturnDeliveries = '[Return Deliveries] load',
  LoadReturnDeliveriesSuccess = '[Return Deliveries] load success',
  LoadReturnDeliveriesFail = '[Return Deliveries] load fail',

  LoadReturnDelivery = '[Return Delivery] load',
  LoadReturnDeliverySuccess = '[Return Delivery] load success',
  LoadReturnDeliveryFail = '[Return Delivery] load fail',

  SetReturnDeliveriesFilter = '[Return Deliveries] set filter',
}

export class LoadReturnDeliveries implements Action {
  readonly type = ReturnDeliveryActionTypes.LoadReturnDeliveries;
  constructor(public payload?: PagebleActionPayload) {}
}

export class LoadReturnDeliveriesSuccess implements Action {
  readonly type = ReturnDeliveryActionTypes.LoadReturnDeliveriesSuccess;
  constructor(public payload?: PagebleEntity<ReturnDelivery>) {}
}

export class LoadReturnDeliveriesFail implements Action {
  readonly type = ReturnDeliveryActionTypes.LoadReturnDeliveriesFail;
}

export class LoadReturnDelivery implements Action {
  readonly type = ReturnDeliveryActionTypes.LoadReturnDelivery;
  constructor(public id: string) {}
}

export class LoadReturnDeliverySuccess implements Action {
  readonly type = ReturnDeliveryActionTypes.LoadReturnDeliverySuccess;
  constructor(public payload?: ReturnDelivery) {}
}

export class LoadReturnDeliveryFail implements Action {
  readonly type = ReturnDeliveryActionTypes.LoadReturnDeliveryFail;
}

export class SetReturnDeliveriesFilter implements Action {
  readonly type = ReturnDeliveryActionTypes.SetReturnDeliveriesFilter;
  constructor(public payload: ReturnDeliveryFilter, public reset = false) {}
}

export type ReturnDeliveryActions =
  | LoadReturnDeliveries
  | LoadReturnDeliveriesSuccess
  | LoadReturnDeliveriesFail

  | LoadReturnDelivery
  | LoadReturnDeliverySuccess
  | LoadReturnDeliveryFail

  | SetReturnDeliveriesFilter;
