<div class="modal-header">
  <h4 class="modal-title pull-left">{{ 'Redirect call' | translate }}</h4>
</div>
<div class="modal-body" [formGroup]="redirectForm">
  <div class="header">
    {{ 'To employee' | translate }}
  </div>
  <div class="panel my-3">
    <app-employee-selector
      [hasPhone]="true"
      formControlName="employee"
    ></app-employee-selector>
  </div>
  <!--<tabset type="qa-tabs" class="redirect-call-tabs">-->
  <!--<tab (selectTab)="goToDepartment()">-->
  <!---->
  <!--</tab>-->
  <!--<tab (selectTab)="goToEmployee()">-->
  <!--<ng-template tabHeading>-->
  <!--{{ 'To department' | translate }}-->
  <!--</ng-template>-->
  <!--<div class="panel my-3">-->
  <!--<app-department-selector formControlName="department"></app-department-selector>-->
  <!--</div>-->
  <!--</tab>-->
  <!--</tabset>-->

  <label for="comment">{{ 'Comment' | translate }}</label>
  <textarea
    class="form-control mb-3"
    name="comment"
    id="comment"
    formControlName="comment"
  ></textarea>

  <button
    class="btn btn-primary btn-block"
    (click)="redirect()"
    [disabled]="!(redirectForm.value.employee || redirectForm.value.department)"
  >
    {{ 'Redirect' | translate }}
  </button>
</div>
