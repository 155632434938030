import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, combineLatest, merge, Subscription } from 'rxjs';
import {
  CallDirection,
  CallSubject
} from 'src/app/modules/calls/models/calls.model';
import { AppState } from 'src/app/store/reducers';
import { Store, select } from '@ngrx/store';
import {
  getDirections,
  getDirectionsWithSubjects
} from 'src/app/modules/calls/store/call.selector';
import {
  getContactTipsBySubject,
  getContactTipsByTerm
} from '../../store/contact-tip.selector';
import { ContactTip } from '../../models/contact-tip.model';
import { FormControl } from '@angular/forms';
import {
  switchMap,
  tap,
  filter,
  map,
  withLatestFrom,
  debounceTime
} from 'rxjs/operators';

type ScreenName = 'SEARCH' | 'DIRECTIONS' | 'SUBJECT' | 'TIP';

@Component({
  selector: 'app-tips-floating',
  templateUrl: './tips-floating.component.html',
  styleUrls: ['./tips-floating.component.scss']
})
export class TipsFloatingComponent implements OnInit, OnDestroy {
  screen: ScreenName = 'DIRECTIONS';
  prevScreen: ScreenName;
  isPanelVisible: boolean;
  directions$: Observable<CallDirection[]>;
  tipsBySubject: ContactTip[];
  tipsFound: ContactTip[];
  tips: ContactTip[];
  subject$ = new Subject<CallSubject>();
  directionExpanded: { [key: string]: boolean } = {};
  currentSubject: CallSubject;
  currentTip: ContactTip;
  searchTerm: string = null;
  searchTermControl = new FormControl();
  isSearching: boolean;
  subscriptions = new Subscription();

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.directions$ = this.store.pipe(select(getDirectionsWithSubjects));

    this.subscriptions.add(
      this.searchTermControl.valueChanges
        .pipe(
          tap(term => {
            const isEmptyTerm = !term || !term.length;
            this.isSearching = true;
            if (this.screen !== 'SEARCH') {
              if (!isEmptyTerm) {
                this.setScreen('SEARCH');
              }
            } else {
              if (isEmptyTerm) {
                this.back();
              }
            }
          }),
          debounceTime(300),
          switchMap(term => {
            return this.store.pipe(select(getContactTipsByTerm(term)));
          })
        )
        .subscribe(tips => {
          this.isSearching = false;
          this.tipsFound = tips;
        })
    );

    this.subscriptions.add(
      this.subject$
        .pipe(
          tap(() => {
            this.setScreen('SUBJECT');
          }),
          switchMap(subject => {
            return this.store.pipe(select(getContactTipsBySubject(subject.id)));
          })
        )
        .subscribe(tips => (this.tipsBySubject = tips))
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setScreen(screen: ScreenName) {
    if (screen !== this.screen) {
      this.prevScreen = this.screen;
      this.screen = screen;
    }
  }

  back() {
    if (this.screen === 'SEARCH') {
      if (this.currentSubject) {
        this.setScreen('SUBJECT');
      } else {
        this.setScreen('DIRECTIONS');
      }
    } else {
      if (this.screen === 'TIP') {
        this.setScreen(this.prevScreen);
      } else if (this.screen === 'SUBJECT') {
        this.setScreen('DIRECTIONS');
      }
    }
  }

  goToSubject(subject: CallSubject) {
    this.currentSubject = subject;
    this.subject$.next(subject);
  }

  goToTip(tip: ContactTip) {
    this.setScreen('TIP');
    this.currentTip = tip;
  }

  toggleDirection(id: string) {
    this.directionExpanded[id] = !this.directionExpanded[id];
  }

  togglePanel() {
    this.isPanelVisible = !this.isPanelVisible;
  }

  clearSearch() {
    this.searchTermControl.setValue(null);
  }
}
