<div class="panel">
  <div class="wrap">
    <div class="d-flex">
      <div class="client">
        <div class="name" *ngIf="call.customer">
          {{ call.customer?.last_name }} {{ call.customer?.first_name }}
          {{ call.customer?.middle_name }}
        </div>
        <div class="name" *ngIf="!call.customer">
          {{ 'Unknown customer' | translate }}
        </div>
        <div class="location">г. Москва</div>
      </div>
      <div class="call-direction ml-auto">
        <div class="from mr-1">{{ call.from_number | formatPhone }}</div>
        <div class="to">{{ call.to_number | formatPhone }}</div>
      </div>
    </div>
    <form [formGroup]="callForm">
      <div class="tags mt-2">
        <div class="tag" *ngFor="let subject of call.subjects">
          {{ subject.direction?.name }} / {{ subject.name }}
          <a
            (click)="removeSubject(subject.id)"
            class="remove"
            [class.disabled]="call.state === 'FINISHED'"
            >&times;</a
          >
        </div>
      </div>
      <div class="d-flex align-items-end mt-1">
        <div class="subject-selector">
          <div class="direction">
            <ng-select
              [items]="directions$ | async"
              class="ng-select-prepend"
              bindValue="id"
              bindLabel="name"
              formControlName="direction"
              [placeholder]="'Select direction' | translate"
            ></ng-select>
          </div>
          <div class="theme">
            <ng-select
              [items]="subjects$ | async"
              bindValue="id"
              class="ng-select-middle"
              bindLabel="name"
              formControlName="subject"
              [placeholder]="'Select theme' | translate"
            ></ng-select>
          </div>
          <button
            class="btn btn-primary"
            (click)="addSubject()"
            [disabled]="
              !callForm.get('subject').value || call.state === 'FINISHED'
            "
          >
            +
          </button>
        </div>

        <div class="controls">
          <app-call-duration
            *ngIf="(isTalking$ | async)"
            [call]="call"
            [sipStart]="sessionStart$ | async"
            class="text-muted align-self-center mr-2"
          ></app-call-duration>
          <button
            type="button"
            class="btn btn-icon btn-primary ml-2"
            (click)="showComment = !showComment"
          >
            <i class="material-icons">comment</i>
          </button>
          <ng-container *ngIf="(isCalling$ | async)">
            <button
              type="button"
              class="btn btn-icon btn-success ml-2"
              (click)="onAccept()"
            >
              <i class="material-icons">phone</i>
            </button>
            <button
              type="button"
              class="btn btn-icon btn-danger ml-2"
              (click)="onDecline()"
            >
              <i class="material-icons">call_end</i>
            </button>
          </ng-container>
          <ng-container *ngIf="(isTalking$ | async)">
            <button
              type="button"
              *ngIf="!(isHold$ | async)"
              class="btn btn-icon btn-primary ml-2"
              (click)="onHold()"
            >
              <i class="material-icons">pause</i>
            </button>
            <button
              type="button"
              *ngIf="(isHold$ | async)"
              class="btn btn-icon btn-primary ml-2"
              (click)="onResume()"
            >
              <i class="material-icons">play_arrow</i>
            </button>
            <button
              type="button"
              class="btn btn-icon btn-primary ml-2"
              (click)="openRedirectDialog()"
            >
              <i class="material-icons">forward</i>
            </button>
            <button
              type="button"
              class="btn btn-icon btn-danger ml-2"
              (click)="onHangUpCall()"
            >
              <i class="material-icons">call_end</i>
            </button>
          </ng-container>
          <ng-container
            *ngIf="
              !(isCalling$ | async) &&
              !(isTalking$ | async) &&
              call.state !== 'FINISHED'
            "
          >
            <button
              type="button"
              class="btn btn-outline-primary ml-2"
              (click)="onFinishCall()"
            >
              <i class="material-icons">check</i> {{ 'Finish' | translate }}
            </button>
          </ng-container>
          <ng-container *ngIf="call.state === 'FINISHED'">
            <div class="align-self-center ml-3 text-muted">
              {{ 'This call is finished' | translate }}
            </div>
          </ng-container>
        </div>
      </div>
    </form>
    <div *ngIf="showComment" class="mt-3">
      <textarea
        [formControl]="comment"
        class="form-control"
        [placeholder]="('Add a comment' | translate) + '...'"
      ></textarea>
    </div>
  </div>
</div>
<ng-template #hangUpModal>
  <app-hangup-call
    [call]="call.id"
    [modalRef]="hangUpModalRef"
  ></app-hangup-call>
</ng-template>
<ng-template #finishModal>
  <app-finish-call
    [call]="call.id"
    [modalRef]="finishModalRef"
  ></app-finish-call>
</ng-template>
<ng-template #redirectModal>
  <app-redirect-call
    [call]="call.id"
    [modalRef]="redirectModalRef"
  ></app-redirect-call>
</ng-template>
