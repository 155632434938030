<div [formGroup]="form">
  <div class="form-check mt-2">
    <input
      type="checkbox"
      class="form-check-input"
      formControlName="value"
      id="value"
    />
    <label class="form-check-label" for="value">{{ "Yes" | translate }}</label>
  </div>
</div>
