import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedModule } from '../shared/shared.module';
import { EmployeeSelectorComponent } from './components/employee-selector/employee-selector.component';
import { EmployeeStateChangeComponent } from './components/employee-state-change/employee-state-change.component';
import { EmployeesFinderComponent } from './components/employees-finder/employees-finder.component';
import { EmployeesStateSelectorComponent } from './components/employees-state-selector/employees-state-selector.component';
import { EmployeesStatusListComponent } from './components/employees-status-list/employees-status-list.component';

import { EmployeeEffects } from './store/employee.effects';
import * as fromEmployee from './store/employee.reducer';
import { EmployeeRolesComponent } from './components/employee-roles/employee-roles.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature('employee', fromEmployee.reducer),
    EffectsModule.forFeature([EmployeeEffects]),
    InfiniteScrollModule,
    PerfectScrollbarModule,
    ReactiveFormsModule
  ],
  declarations: [
    EmployeesStatusListComponent,
    EmployeeStateChangeComponent,
    EmployeesFinderComponent,
    EmployeesStateSelectorComponent,
    EmployeeSelectorComponent,
    EmployeeRolesComponent,
  ],
  exports: [EmployeeStateChangeComponent, EmployeeSelectorComponent, EmployeeRolesComponent]
})
export class EmployeesModule {
}
