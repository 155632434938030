import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../store/reducers';
import { CallSubject } from '../../models/calls.model';
import { CallsHistorySetFilter } from '../../store/call.actions';
import { getSubjects } from '../../store/call.selector';

@Component({
  selector: 'app-subject-selector',
  templateUrl: './subject-selector.component.html',
  styleUrls: ['./subject-selector.component.scss']
})
export class SubjectSelectorComponent {
  subject: FormControl = new FormControl();
  subscription = new Subject();
  subjects: CallSubject[];

  constructor(private store: Store<AppState>) {
    this.store.pipe(select(getSubjects)).subscribe(subjects => this.subjects = subjects);

    this.subject.valueChanges.pipe(
      takeUntil(this.subscription),
      debounceTime(1000)
    ).subscribe((f: string) => {
      this.store.dispatch(new CallsHistorySetFilter({
        subjects: f,
      }));
    });
  }

}
