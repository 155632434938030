import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tips-panel-content',
  templateUrl: './tips-panel-content.component.html',
  styleUrls: ['./tips-panel-content.component.scss']
})
export class TipsPanelContentComponent implements OnInit {
  @Input() header;
  @Input() hasBack: boolean = false;
  @Output() back = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();
  constructor() {}

  ngOnInit() {}

  onBack() {
    this.back.emit();
  }

  onClose() {
    this.close.emit();
  }
}
