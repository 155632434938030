import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/reducers';
import { filter, mapTo, tap } from 'rxjs/operators';
import { HandleGuardRequestService } from '../../../../modules/shared/services/handle-guard-request/handle-guard-request.service';
import {
  LoadEmployeeActionTypes,
  EmployeeActionTypes
} from '../../../../modules/employees/store/employee.actions';
import { getEmployeeActionTypes } from '../../../../modules/employees/store/employee.selector';

@Injectable({
  providedIn: 'root'
})
export class LoadEmployeeActionTypesGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private handler: HandleGuardRequestService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(new LoadEmployeeActionTypes());
    return this.handler.handle(
      this.store.select(getEmployeeActionTypes).pipe(
        filter(types => {
          return !!types;
        }),
        mapTo(true)
      ),
      EmployeeActionTypes.LoadEmployeeActionTypesFail
    );
  }
}
