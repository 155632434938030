import { createSelector } from '@ngrx/store';
import { Courier } from '../../../models/courier.model';
import { CourierOrder } from '../../../models/order.model';
import { getCourierModuletState } from '../selector';
import { CourierActions, CourierActionTypes } from './courier.actions';
import { DeliveryRegion } from '../../../models/delivery-region.model';
import { Outlet } from 'src/app/modules/outlets/models/outlet';

export interface CourierState {
  couriers: Courier[];
  regions: DeliveryRegion[];
  isLoadingRegions: boolean;
  orders: CourierOrder[];
  ordersFilter: any;
  outlets: Outlet[];
  isLoadingOutlets: boolean;
}

export const initialState: CourierState = {
  couriers: [],
  regions: [],
  isLoadingRegions: false,
  orders: [],
  ordersFilter: null,
  outlets: [],
  isLoadingOutlets: false
};

export function CourierReducer(
  state = initialState,
  action: CourierActions
): CourierState {
  switch (action.type) {
    case CourierActionTypes.LoadCouriers:
      return state;

    case CourierActionTypes.LoadCouriersSuccess:
      return { ...state, couriers: action.payload.results };

    case CourierActionTypes.LoadCourierOrdersSuccess:
      return { ...state, orders: action.payload.results };

    case CourierActionTypes.LoadDeliveryRegions:
      return { ...state, isLoadingRegions: true };

    case CourierActionTypes.LoadDeliveryRegionsSuccess:
      return { ...state, regions: action.payload, isLoadingRegions: false };

    case CourierActionTypes.LoadDeliveryRegionsFail:
      return { ...state, isLoadingRegions: false };

    case CourierActionTypes.LoadRegionOutlets:
      return { ...state, isLoadingOutlets: true };

    case CourierActionTypes.LoadRegionOutletsSuccess:
      return { ...state, outlets: action.payload, isLoadingOutlets: false };

    case CourierActionTypes.LoadRegionOutletsFail:
      return { ...state, isLoadingOutlets: false };

    default:
      return state;
  }
}

export const getCourierState = createSelector(
  getCourierModuletState,
  data => data.couriers
);
