import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FilterValueAccessor, MakeProvider } from '../filter-value-accessor';
import { SegmentOperator } from '../../../models/segment-property';
import { filter } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { SelectItem } from '../../../../shared/components/form/select/select.component';
import { NO_EMIT_EVENT } from '../../../../shared/helpers/forms';
import { _ } from '../../../../shared/helpers/translation-marker';
import { rangeValidator } from '../../../../shared/validators/range.validator';
import { createNumberMask } from 'text-mask-addons/dist/textMaskAddons';

enum numberOperators {
  RANGE = 'range',
  NOT_RANGE = 'not_range',
  EXACT = 'exact',
  NOT_EXACT = 'not_exact',
}

@Component({
  selector: 'app-segment-filter-number',
  templateUrl: './segment-filter-number.component.html',
  styleUrls: ['./segment-filter-number.component.scss'],
  providers: [...MakeProvider(SegmentFilterNumberComponent)],
})
export class SegmentFilterNumberComponent extends FilterValueAccessor
  implements OnInit {
  operators: string[] = [numberOperators.RANGE, numberOperators.NOT_RANGE];

  mask = {
    mask: createNumberMask({
      prefix: '',
      suffix: '',
      allowDecimal: true,
      allowNegative: false,
      thousandsSeparatorSymbol: ' ',
    }),
  };

  ngOnInit() {
    this.initForm();
    this.initSubscriptions();
  }

  initForm() {
    this.form = this.fb.group(
      {
        numberOperator: [null, Validators.required],
        start: [null],
        end: [null],
      },
      { validator: rangeValidator('start', 'end') }
    );
  }

  initSubscriptions() {
    this.subscriptions.add(
      this.form.valueChanges.subscribe(() => {
        this.pushValue({
          ...this.filter,
          ...this.serialize(),
        } as any);
      })
    );
  }

  onWrite(values) {
    this.form.patchValue(this.valuesToForm(values), NO_EMIT_EVENT);
  }

  onClearStart() {
    this.form.get('start').setValue(null);
  }

  onClearEnd() {
    this.form.get('end').setValue(null);
  }

  valuesToForm(values): any {
    const { operator, value } = values;

    const currentLimits = [];
    const propertyLimits = this.property.limits;
    if (propertyLimits) {
      if (propertyLimits.min) {
        currentLimits.push(Validators.min(propertyLimits.min));
      }

      if (propertyLimits.max) {
        currentLimits.push(Validators.max(propertyLimits.max));
      }
    }
    const limitValidator = Validators.compose(currentLimits);
    this.form.get('start').setValidators(limitValidator);
    this.form.get('end').setValidators(limitValidator);

    if (!value || !operator) {
      return {
        numberOperator: numberOperators.RANGE,
        start: null,
        end: null,
      };
    }

    switch (operator) {
      case SegmentOperator.RANGE:
        return {
          numberOperator: numberOperators.RANGE,
          start: value[0],
          end: value[1],
        };
      case SegmentOperator.NOT_RANGE:
        return {
          numberOperator: numberOperators.NOT_RANGE,
          start: value[0],
          end: value[1],
        };
      case SegmentOperator.GTE:
        return {
          numberOperator: numberOperators.RANGE,
          start: value,
          end: null,
        };
      case SegmentOperator.LTE:
        return {
          numberOperator: numberOperators.RANGE,
          start: null,
          end: value,
        };
      default:
        console.warn('warn: operator not implemented: ', operator);
        return {
          numberOperator: numberOperators.RANGE,
          start: value,
          end: null,
        };
    }
  }

  serialize() {
    const { start, end, numberOperator } = this.form.value;

    let operator;
    let value;

    // TODO @dkchv: remove true after implement other options
    if (numberOperator === numberOperators.RANGE) {
      if (start && end) {
        operator = SegmentOperator.RANGE;
        value = [start, end];
      } else if (!start) {
        operator = SegmentOperator.LTE;
        value = end;
      } else if (!end) {
        operator = SegmentOperator.GTE;
        value = start;
      }
    }

    if (numberOperator === numberOperators.NOT_RANGE) {
      operator = SegmentOperator.NOT_RANGE;
      const propertyLimits = this.property.limits;
      if (start && end) {
        value = [start, end];
      } else if (!start) {
        value = [propertyLimits.min, end];
      } else if (!end) {
        value = [start, propertyLimits.max];
      }
    }

    return {
      operator,
      value,
    };
  }
}
