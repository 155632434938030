import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { BowserModule, BowserService } from 'ngx-bowser';

import { ContactCenterComponent } from './contact-center.component';
import { SharedModule } from '../../modules/shared/shared.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { reducers } from '../../store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { effects } from '../../store/effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { TranslatePoHttpLoader } from '@dry7/ngx-translate-po-http-loader';
import { PrivateRootComponent } from './pages/private-root/private-root.component';
import { ProxyToBackendInterceptorService } from '../../services/proxy-to-backend-interceptor/proxy-to-backend-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../../../environments/environment';
import { RavenErrorHandler } from '../../config/raven.config';
import { AddAuthTokenInterceptorService } from '../../services/add-auth-token-interceptor/add-auth-token-interceptor.service';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { APP_PERFECT_SCROLLBAR_CONFIG } from '../../config/perfect-scrollbar.config';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { CustomSerializer } from '../../store/reducers/router.reducer';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ruLocale, enGbLocale } from 'ngx-bootstrap/locale';
import 'moment/locale/en-au';
import 'moment/locale/en-gb';
import 'moment/locale/en-ca';
import 'moment/locale/ru';
import { SegmentsModule } from '../../modules/segments/segments.module';
import { AuthenticationService } from '../../modules/shared/services/authentication/authentication.service';
import { AUTH_SERVICE } from 'ngx-auth';
import { AddCompanyIdInterceptorService } from '../../services/add-company-id-interceptor/add-company-id-interceptor.service';
import { TreeModule } from '@circlon/angular-tree-component';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { CallsModule } from '../../modules/calls/calls.module';
import { DictionariesModule } from '../../modules/dictionaries/dictionaries.module';
import { ContactCenterRoutingModule } from './routing/contact-center-routing.module';
import { EmployeesModule } from 'src/app/modules/employees/employees.module';
import { CouriersSharedModule } from 'src/app/modules/couriers/shared/shared.module';
import { ReportsSharedModule } from 'src/app/modules/reports/shared/reports-shared.module';
import { ReclamationsSharedModule } from 'src/app/modules/reclamations/shared/reclamations-shared.module';

registerLocaleData(localeRu, 'ru');

export function createTranslateLoader(http: HttpClient) {
  return new TranslatePoHttpLoader(http, 'assets/i18n', '.po');
}

defineLocale('ru', ruLocale);
defineLocale('en', enGbLocale);

export function factory(authenticationService: AuthenticationService) {
  return authenticationService;
}

@NgModule({
  declarations: [ContactCenterComponent, PrivateRootComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ContactCenterRoutingModule,
    EmployeesModule,
    SharedModule.forRoot(),
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
      },
    }),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25, //  Retains last 25 states
    }),
    TreeModule,
    EffectsModule.forRoot(effects),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    SegmentsModule,
    PopoverModule.forRoot(),
    SortableModule.forRoot(),
    AccordionModule.forRoot(),
    CallsModule,
    DictionariesModule,
    BowserModule,
    CouriersSharedModule,
    ReportsSharedModule,
    ReclamationsSharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProxyToBackendInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAuthTokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddCompanyIdInterceptorService,
      multi: true,
    },
    {
      provide: AUTH_SERVICE,
      deps: [AuthenticationService],
      useFactory: factory,
    },
    {
      provide: ErrorHandler,
      useClass: environment.production ? RavenErrorHandler : ErrorHandler,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: APP_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    BowserService,
  ],
  bootstrap: [ContactCenterComponent],
})
export class ContactCenterModule {}
