import { Component, OnInit, Input } from '@angular/core';
import {
  SegmentOperator,
  SegmentOperatorLabels,
  SegmentOperatorLabelsExt
} from '../../../models/segment-property';

@Component({
  selector: 'app-operator-option',
  templateUrl: './operator-option.component.html',
  styleUrls: ['./operator-option.component.scss']
})
export class OperatorOptionComponent implements OnInit {
  @Input() item;

  labels = SegmentOperatorLabels;
  extensions = SegmentOperatorLabelsExt;
  constructor() {}

  ngOnInit() {}
}
