import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { AppState } from '../../../../store/reducers';
import { Store } from '@ngrx/store';
import { CustomersSetFilter } from '../../store/customer/customer.actions';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-customers-filter',
  templateUrl: './customers-filter.component.html',
  styleUrls: ['./customers-filter.component.scss']
})
export class CustomersFilterComponent implements OnInit, OnDestroy {
  form: FormGroup;
  subscription = new Subject();
  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.form = this.fb.group({
      phone: this.route.snapshot.queryParams.phone || '',
      email: this.route.snapshot.queryParams.email || '',
      search: this.route.snapshot.queryParams.search || ''
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscription.next();
    this.subscription.complete();
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    const values = this.form.value;

    const filters = {
      phone: values.phone || undefined,
      email: values.email || undefined,
      search: values.search || undefined,
    };

    this.store.dispatch(new CustomersSetFilter({ ...filters }));

    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { ...filters },
      queryParamsHandling: 'merge',
    });
  }
}
