import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { merge, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Action } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class HandleGuardRequestService {
  constructor(private actions$: Actions) {}

  handle<T>(
    observable: Observable<T>,
    ...errorActions: string[]
  ): Observable<boolean> {
    return merge(observable, this.actions$.pipe(ofType(...errorActions))).pipe(
      map(res => {
        return (!res || errorActions.indexOf((res as Action).type)) === -1
          ? res
          : false;
      }),
      catchError(() => of(false))
    ) as Observable<boolean>;
  }
}
