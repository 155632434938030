<ng-select
  [items]="models$ | async"
  bindLabel="display_name"
  [formControl]="currentValue"
  [multiple]="false"
  [hideSelected]="true"
  [loading]="isModelsLoading"
  [typeahead]="modelTypeahead$"
  (scroll)="scroll($event)"
  (scrollToEnd)="fetchMore()"
>
  <ng-template ng-label-tmp let-item="item">
    {{ item | customerDisplayName }}
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <div>{{ item | customerDisplayName }}</div>
    <div class="count text-black-50 small" *ngIf="item.email">
      {{ 'Email' | translate }}: {{ item.email }}
    </div>
    <div class="count text-black-50 small" *ngIf="item.phone">
      {{ 'Phone' | translate }}: {{ item.phone | formatPhone }}
    </div>
  </ng-template>
  <ng-template ng-typetosearch-tmp>
    <div class="ng-option disabled text-black-50">
      {{ 'Start typing' | translate }}...
    </div>
  </ng-template>
  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
    <div class="ng-option disabled text-black-50">
      {{ 'No users found' | translate }}
    </div>
  </ng-template>
  <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
    <div class="ng-option disabled text-black-50">
      {{ 'Loading' | translate }}
    </div>
  </ng-template>
</ng-select>
