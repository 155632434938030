import { ActionReducerMap } from '@ngrx/store';
import { FormStatusReducer, FormStatusState } from './form-status.reducer';
import { UserState, UserReducer } from './user.reducer';
import { LegalEntityState, legalEntityReducer } from './legal-entity.reducer';

export interface SharedState {
  user: UserState;
  formStatus: FormStatusState;
  legalEntity: LegalEntityState;
}

export const reducers: ActionReducerMap<SharedState> = {
  user: UserReducer,
  formStatus: FormStatusReducer,
  legalEntity: legalEntityReducer
};
