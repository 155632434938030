<input
  type='text'
  [textMask]='mask'
  class='form-control'
  [ngClass]="hostClass+' '+customClass"
  [placeholder]='placeholder'
  [formControl]="currentValue"
  (blur)="onBlur()"
>
<span *ngIf="clearable && currentValue.value" (click)="clear()" class="clear-wrapper"><span class="clear">×</span></span>
