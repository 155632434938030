<form [formGroup]="form" (submit)="submit()">
  <ng-container *ngIf="!(loading$ | async); else: loading">
    <fieldset class="form-fieldset">
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label for="last_name">{{ 'Last name' | translate }}</label>
            <input
              type="text"
              class="form-control"
              id="last_name"
              formControlName="last_name"
            />
            <div>
              <app-errors-component
                [control]="form.get('last_name')"
                [submitted]="submitted"
              ></app-errors-component>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="first_name">{{ 'First name' | translate }}</label>
            <input
              type="text"
              class="form-control"
              id="first_name"
              formControlName="first_name"
            />
            <div>
              <app-errors-component
                [control]="form.get('first_name')"
                [submitted]="submitted"
              ></app-errors-component>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="middle_name">{{ 'Middle name' | translate }}</label>
            <input
              type="text"
              class="form-control"
              id="middle_name"
              formControlName="middle_name"
            />
            <div>
              <app-errors-component
                [control]="form.get('middle_name')"
                [submitted]="submitted"
              ></app-errors-component>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="form-group">
            <label>{{ 'Gender' | translate }}</label>
            <app-button-group
              formControlName="gender"
              class="full-width"
              [options]="gender"
            ></app-button-group>
            <!--
              <ng-select

                [searchable]="false"
                [clearable]="false"
              >
                <ng-option *ngFor="let it of gender" [value]="it.value">{{
                  it.name | translate
                }}</ng-option>
              </ng-select>
            -->
            <div>
              <app-errors-component
                [control]="form.get('gender')"
                [submitted]="submitted"
              ></app-errors-component>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label for="birthday">{{ 'Birthday' | translate }}</label>
            <app-datepicker
              id="birthday"
              [maxDate]="maxDate"
              formControlName="birthday"
              additionalClass="start form-control"
              placeholder=""
              placement="bottom left"
            ></app-datepicker>
            <div>
              <app-errors-component
                [control]="form.get('birthday')"
                [submitted]="submitted"
              ></app-errors-component>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="phone">{{ 'Phone' | translate }}</label>
            <app-phone-input formControlName="phone" [readonly]="isEdit"></app-phone-input>
            <div>
              <app-errors-component
                [control]="form.get('phone')"
                [submitted]="submitted"
              ></app-errors-component>
            </div>
          </div>
        </div>
        <div class="col col-email">
          <div class="form-group">
            <label for="email">{{ 'Email' | translate }}</label>
            <input
              type="text"
              class="form-control"
              id="email"
              formControlName="email"
              [readonly]="isEdit"
            />
            <div>
              <app-errors-component
                [control]="form.get('email')"
                [submitted]="submitted"
              ></app-errors-component>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="mr-4 pb-4 text-right">
      <button type="submit" [disabled]="!form.valid" class="btn btn-primary">
        {{ 'Save' | translate }}
      </button>
    </div>
  </ng-container>
</form>
<ng-template #loading> <app-loader></app-loader> </ng-template>
