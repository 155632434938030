import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as pathToRegexp from 'path-to-regexp';
import { AppState } from '../../../../store/reducers';
import {
  getRouterState,
  RouterStateUrl
} from '../../../../store/reducers/router.reducer';
import { RouterReducerState } from '@ngrx/router-store';

export function forRoute(
  store: Store<AppState>,
  ...routes: (string | RegExp)[]
) {
  return (source: Observable<any>) =>
    source.pipe(
      withLatestFrom(store.pipe(select(getRouterState))),
      filter(
        ([effect, routerState]: [any, RouterReducerState<RouterStateUrl>]) => {
          if (!routerState) {
            return false;
          }
          return routes.reduce((acc, route) => {
            const regexp = pathToRegexp(route);
            return acc || !!routerState.state.url.match(regexp);
          }, false);
        }
      ),
      map(data => data[0])
    );
}
