import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/reducers';


@Injectable()
export class FormStatusEffects {

  constructor(private actions$: Actions,
              private store: Store<AppState>) {
  }
}
