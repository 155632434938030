import { Action } from '@ngrx/store';

export enum ContactCenterActionTypes {
  UpdateTaskCountSuccess = '[Contact Center] Update Task Count Success'
}

export class UpdateTaskCountSuccess implements Action {
  readonly type = ContactCenterActionTypes.UpdateTaskCountSuccess;
  constructor(public payload?: any) {}
}

export type ContactCenterActions =
  | UpdateTaskCountSuccess;
