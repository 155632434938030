import { Action } from '@ngrx/store';
import { SegmentProperty } from '../../models/segment-property';

export const SEGMENT_PROPERTIES_LOAD = '[Segment properties] load';
export const SEGMENT_PROPERTIES_LOAD_SUCCESS = '[Segment properties] load success';
export const SEGMENT_PROPERTIES_LOAD_FAIL = '[Segment properties] load fail';

export class SegmentPropertiesLoad implements Action {
    readonly type = SEGMENT_PROPERTIES_LOAD;
}

export class SegmentPropertiesLoadSuccess implements Action {
    readonly type = SEGMENT_PROPERTIES_LOAD_SUCCESS;
    constructor(public payload: SegmentProperty[]) {}
}

export class SegmentPropertiesLoadFail implements Action {
    readonly type = SEGMENT_PROPERTIES_LOAD_FAIL;
}

export type SegmentPropertiesAction =
  | SegmentPropertiesLoad
  | SegmentPropertiesLoadSuccess
  | SegmentPropertiesLoadFail
;
