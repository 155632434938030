import {
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { isNumber, round } from 'lodash';
import { Subscription } from 'rxjs';
import { createNumberMask } from 'text-mask-addons/dist/textMaskAddons';
import { NumberDemaskService } from '../../services/number-demask/number-demask.service';

const NUMBERS_MASK = {
  mask: createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    allowNegative: false,
    thousandsSeparatorSymbol: ' ',
  }),
};

@Component({
  selector:
    'app-number-input[formControlName],app-number-input[ngModel],app-number-input[formControl]',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
  ],
})
export class NumberInputComponent
  implements OnInit, ControlValueAccessor, OnDestroy {
  hostClass: string;
  @Input()
  placeholder: string = '';
  @Input()
  customClass: string = '';
  @Input()
  mask: any;
  @Input() clearable = false;

  currentValue: FormControl = new FormControl();
  currentValueSubscription: Subscription;

  statusChangeSubscription: Subscription;
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    private demaskService: NumberDemaskService,
    private element: ElementRef,
    private injector: Injector
  ) {
    this.currentValueSubscription = this.currentValue.valueChanges.subscribe(
      (v) => this.onChanged(v)
    );
  }

  ngOnInit() {
    setTimeout(() => {
      this.statusChangeSubscription = this.injector
        .get(NgControl)
        .control.statusChanges.subscribe(() => {
          this.updateClasses();
        });
    });

    this.mask = this.mask || NUMBERS_MASK;
  }

  writeValue(obj: any): void {
    this.currentValue.setValue(isNumber(obj) ? round(obj, 2) : obj);
  }

  private updateClasses() {
    setTimeout(() => {
      this.hostClass = this.element.nativeElement.className;
    }, 0);
  }

  onBlur() {
    if (this.onTouched) {
      this.onTouched();
      this.updateClasses();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.currentValue.disable();
    } else {
      this.currentValue.enable();
    }
    // throw new Error('Method not implemented.');
  }

  onChanged(value: string) {
    if (this.onChange) {
      this.onChange(this.demaskService.demask(value || ''));
    }

    if (this.onTouched) {
      this.onTouched();
    }
  }

  clear() {
    this.writeValue(null);
  }

  ngOnDestroy(): void {
    if (this.statusChangeSubscription) {
      this.statusChangeSubscription.unsubscribe();
    }

    this.currentValueSubscription.unsubscribe();
  }
}
