import { Pipe, PipeTransform } from '@angular/core';
import { Customer } from '../../models/customer';

@Pipe({
  name: 'customerDisplayName'
})
export class CustomerDisplayNamePipe implements PipeTransform {

  transform(value: Customer, args?: any): any {
    if (!value) {
      return '';
    }
    return [value.last_name, value.first_name, value.middle_name].filter(it => !!it).join(' ');
  }

}
