import { ActionReducerMap } from '@ngrx/store';
import { SocketReducer, SocketState } from './socket.reducer';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import {
  RouterMetaState,
  RouterMetaReducer,
  RouterStateUrl
} from './router.reducer';
import { ContactCenterState, contactCenterReducer } from 'src/app/bootstrap-modules/contact-center/store/contact-center.reducer';

export interface AppState {
  router: RouterReducerState<RouterStateUrl>;
  routerMeta: RouterMetaState;
  socket: SocketState;
  contactCenter: ContactCenterState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  routerMeta: RouterMetaReducer,
  socket: SocketReducer,
  contactCenter: contactCenterReducer
};
