<div class="title" *ngIf="breadcrumbs$ | async as breadcrumbs">
  <ul class="breadcrumb">
    <ng-container *ngFor="let crumb of breadcrumbs; let last = last;">
      <li class="breadcrumb-item">
        <a [routerLink]="crumb.url">{{ crumb.name | translate }}</a>
      </li>
    </ng-container>
  </ul>
  <div>
    <h1>{{ title$ | async | translate }}</h1>
    <ng-content></ng-content>
  </div>
</div>
<app-user-bar></app-user-bar>
