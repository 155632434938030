<div class="outdated-message" *ngIf="isOutdated">
  <div class="outdated-message-inner">
    <h1>{{'You are using an outdated browser' | translate}}</h1>
    <p class="lead">{{'Please follow one of this links to update your browser' | translate}}</p>
    <p class="mt-5">
      <a class="browser-link" title="{{'Download Google Chrome' | translate}}"
         href="https://www.google.com/chrome/" target="_blank"><img src="/assets/browser-logos/chrome_128x128.png" width="96" height="96" alt=""></a>
      <a class="browser-link" title="{{'Download Mozilla Firefox' | translate}}"
         href="https://www.mozilla.org/ru/firefox/new/" target="_blank"><img src="/assets/browser-logos/firefox_128x128.png" width="96" height="96" alt=""></a>
    </p>
  </div>
</div>
