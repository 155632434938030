<div class="status-bar">
  <div class="sticky filled">
    <div class="actions"><ng-content></ng-content></div>
    <div class="statuses" *ngIf="showStatus">
      <ng-container [ngSwitch]="status$ | async">
        <div class="form-status" *ngSwitchCase="formStatuses.Loading">
          <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          {{ 'In process' | translate }}
        </div>
        <div class="form-status text-danger" *ngSwitchCase="formStatuses.Fail">
          <i class="material-icons mr-1">error_outline</i
          >{{ 'Error while loading' | translate }}
        </div>
        <div
          class="form-status no-changes-text"
          *ngSwitchCase="formStatuses.None"
        >
          {{ 'There are no changes' | translate }}
        </div>
        <div class="form-status" *ngSwitchCase="formStatuses.Success">
          <i class="material-icons mr-1">check</i
          >{{ 'Changes saved' | translate }}
          <a
            *ngIf="haveRevert"
            class="btn-toolbar-action ml-3"
            (click)="revertForm.emit()"
          >
            <i class="material-icons">restore</i>
          </a>
        </div>
        <div class="form-status" *ngSwitchCase="formStatuses.SuccessWithoutRestore">
          <i class="material-icons mr-1">check</i>{{ 'Changes saved' | translate }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
