import { Pipe, PipeTransform } from '@angular/core';
import { Customer } from '../../../customers/models/customer';

@Pipe({
  name: 'customer'
})
export class CustomerPipe implements PipeTransform {

  transform(value: Customer, args?: any): any {
    if (value) {
      return (value.last_name ? value.last_name + ' ' : '')
           + (value.first_name ? value.first_name + ' ' : '')
           + (value.middle_name ? value.middle_name : '');
    } else {
      return '';
    }
  }

}
