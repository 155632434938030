import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PageSettings } from '../../shared/helpers/calculate-page';
import { PagebleEntity } from '../../shared/models/pageble-entity';
import {
  CreateCustomerData,
  Customer,
  CustomersFilter
} from '../models/customer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isDate, isEmpty, pickBy, isNumber } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  basePath = 'customers/';
  constructor(private http: HttpClient) {}

  all(
    params: PageSettings,
    filter: CustomersFilter
  ): Observable<PagebleEntity<Customer>> {
    const notEmptyFilters = pickBy(filter, value => !isEmpty(value));
    const newParams = {
      ...params,
      ...notEmptyFilters
    };
    return this.http.get<PagebleEntity<Customer>>(this.basePath, {
      params: newParams as any
    });
  }

  filter(filter): Observable<PagebleEntity<Customer>> {
    const notEmptyFilters = pickBy(
      filter,
      value => !isEmpty(value) || isNumber(value)
    );
    return this.http.get<PagebleEntity<Customer>>(this.basePath, {
      params: notEmptyFilters
    });
  }

  one(id: string): Observable<Customer> {
    return this.http.get<Customer>(`${this.basePath}${id}/`);
  }

  create(data: CreateCustomerData): Observable<Customer> {
    return this.http.post<Customer>(this.basePath, data);
  }

  update(id: string, data: CreateCustomerData): Observable<Customer> {
    return this.http.patch<Customer>(`${this.basePath}${id}/`, data);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${this.basePath}${id}/`);
  }
}
