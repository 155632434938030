import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-dialog-v1',
  templateUrl: './confirmation-dialog-v1.component.html',
  styleUrls: ['./confirmation-dialog-v1.component.scss']
})
export class ConfirmationDialogV1Component implements OnInit {
  get closed(): Observable<boolean> {
    return this._closed.asObservable();
  }
  @Input() message: string;
  @Input() title = 'Attention';
  @Input() confirmButtonText = 'Yes';
  @Input() declineButtonText = 'No';
  @Input() hideCloseIcon = false;


  private _closed: Subject<boolean> = new Subject<boolean>();

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
  }

  accept() {
    this.bsModalRef.hide();
    this._closed.next(true);
  }

  decline() {
    this.bsModalRef.hide();
    this._closed.next(false);
  }

}
