import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-empty-list-placeholder',
  templateUrl: './empty-list-placeholder.component.html',
  styleUrls: ['./empty-list-placeholder.component.scss']
})
export class EmptyListPlaceholderComponent implements OnInit {
  @Input() header: string;
  @Input() body: string;
  @Input() btnLabel: string;
  @Input() icon: string = '';
  @Output('btnClick') btnClick = new EventEmitter<void>();
  constructor() {}

  ngOnInit() {}

  actionClick() {
    this.btnClick.emit();
  }
}
