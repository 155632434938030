import { isReload } from '../../shared/helpers/calculate-page';
import {
  Call,
  CallActionReason,
  CallDirection,
  CallsFilter,
  CallSubject
} from '../models/calls.model';
import { CallActions, CallActionTypes } from './call.actions';
import { CallAudio } from '../models/call-audio';

export interface CallsState {
  calls: { [key: string]: Call }; // @TODO: refactor to a single object
  activeCall: string;
  callPurpose: string;
  isLoadingUnfinishedCalls: boolean;
  unfinishedCalls: Call[];
  directions: CallDirection[];
  subjects: CallSubject[];
  actionReasons: CallActionReason[];
  callsHistory: Call[];
  isLoadingCallsHistory: boolean;
  isReload: boolean;
  callsHistoryCount: number;
  callsHistoryFilter: CallsFilter;
  currentHistoryCallAudio: CallAudio;
}

export const initialState: CallsState = {
  calls: {},
  activeCall: null,
  callPurpose: null,
  isLoadingUnfinishedCalls: false,
  unfinishedCalls: [],
  directions: [],
  subjects: [],
  actionReasons: [],
  callsHistory: [],
  callsHistoryCount: 0,
  isLoadingCallsHistory: false,
  isReload: false,
  callsHistoryFilter: {
    state: 'FINISHED'
  },
  currentHistoryCallAudio: undefined
};

export function reducer(state = initialState, action: CallActions): CallsState {
  switch (action.type) {
    case CallActionTypes.SetActiveCall:
      return { ...state, activeCall: action.id };
    case CallActionTypes.LoadCalls:
      return state;
    case CallActionTypes.LoadCallSuccess:
    case CallActionTypes.UpdateCallSuccess:
      return {
        ...state,
        calls: { ...state.calls, [action.payload.id]: action.payload },
        callsHistory: state.callsHistory.map(call =>
          call.id === action.payload.id ? action.payload : call
        )
      };
    case CallActionTypes.FinishCallSuccess:
      return {
        ...state,
        calls: { ...state.calls, [action.payload.id]: action.payload },
        callsHistory: state.callsHistory.map(call =>
          call.id === action.payload.id ? action.payload : call
        ),
        unfinishedCalls: state.unfinishedCalls.filter(
          call => call.id !== action.payload.id
        )
      };
    case CallActionTypes.UpdateCallSuccess:
      return {
        ...state,
        calls: {
          ...state.calls,
          [state.activeCall]: {
            ...state.calls[state.activeCall],
            ...action.payload
          }
        }
      };
    case CallActionTypes.LoadDirectionsSuccess:
      return { ...state, directions: action.payload };
    case CallActionTypes.LoadSubjectsSuccess:
      return { ...state, subjects: action.payload };
    case CallActionTypes.LoadActionReasonsSuccess:
      return { ...state, actionReasons: action.payload };
    case CallActionTypes.LoadUnfinishedCalls:
      return {
        ...state,
        isLoadingUnfinishedCalls: true
      };
    case CallActionTypes.LoadUnfinishedCallsSuccess:
      return {
        ...state,
        isLoadingUnfinishedCalls: false,
        unfinishedCalls: action.payload.results
      };
    case CallActionTypes.LoadUnfinishedCallsFail:
      return {
        ...state,
        isLoadingUnfinishedCalls: false,
        unfinishedCalls: []
      };
    case CallActionTypes.LoadCallsHistory:
      return {
        ...state,
        isLoadingCallsHistory: true,
        isReload: isReload(action)
      };
    case CallActionTypes.LoadCallsHistorySuccess:
      return {
        ...state,
        isLoadingCallsHistory: false,
        callsHistory: action.payload.results,
        callsHistoryCount: action.payload.count,
        isReload: false
      };
    case CallActionTypes.LoadCallsHistoryFail:
      return { ...state, isLoadingCallsHistory: false, isReload: false };
    case CallActionTypes.ClearCallsHistory:
      return { ...state, isLoadingCallsHistory: false, callsHistory: [] };
    case CallActionTypes.SetCallsHistoryFilter:
      return {
        ...state,
        callsHistoryFilter: { ...state.callsHistoryFilter, ...action.payload },
        isLoadingCallsHistory: true
      };
    case CallActionTypes.CallToCustomerSuccess:
    case CallActionTypes.CallToOrderRecipientSuccess:
      return {
        ...state,
        activeCall: action.payload.id,
        callPurpose: action.purpose,
        calls: {
          ...state.calls,
          [action.payload.id]: action.payload
        }
      };
    case CallActionTypes.SetCurrentHistoryCallAudio :
    case CallActionTypes.PlayHistoryCallAudio :
      return {
        ...state,
        currentHistoryCallAudio: action.payload
      };
    case CallActionTypes.StopHistoryCallAudio :
    case CallActionTypes.PauseHistoryCallAudio :
    case CallActionTypes.ResumeHistoryCallAudio :
        return {
          ...state,
          ...state.currentHistoryCallAudio
        };
    default:
      return state;
  }
}
