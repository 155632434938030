import { Component, EventEmitter, Input, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../../store/reducers';
import { FormStatus } from '../../store/reducers/form-status.reducer';
import { getCurrentFormStatus } from '../../store/selectors/form-status.selector';

@Component({
  selector: 'app-form-status',
  templateUrl: './form-status.component.html',
  styleUrls: ['./form-status.component.scss']
})
export class FormStatusComponent {
  @Input() haveRevert = true;
  @Input() showStatus = true;
  @Output() revertForm: EventEmitter<void> = new EventEmitter();
  status$: Observable<FormStatus>;
  formStatuses: typeof FormStatus = FormStatus;

  constructor(private store: Store<AppState>) {
    this.status$ = this.store.pipe(select(getCurrentFormStatus));
  }
}
