import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { flatMap, take } from 'rxjs/operators';
import { getCurrentCompanyId } from '../../modules/shared/store/selectors/user.selector';
import { AppState } from '../../store/reducers';

@Injectable({
  providedIn: 'root'
})
export class AddCompanyIdInterceptorService implements HttpInterceptor {
  constructor(private store: Store<AppState>) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store.pipe(select(getCurrentCompanyId)).pipe(
      take(1),
      flatMap((company: string) => {
        const newReq = req.clone({
          setHeaders: {
            'X-QA-Company': company || ''
          }
        });

        return next.handle(newReq);
      })
    );
  }
}
