import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { DictionariesRoutingModule } from './routing/dictionaries-routing.module';
import { DictionariesService } from './services/dictionaries/dictionaries.service';
import { SharedModule } from '../shared/shared.module';
import { reducers } from './store/reducers';
import { effects } from './store/effects';
import { DictionariesComponent } from './pages/dictionaries/dictionaries.component';
import { DictionaryFormComponent } from './components/dictionary-form/dictionary-form.component';
import { DictionaryCreateComponent } from './pages/dictionary-create/dictionary-create.component';
import { DictionaryItemsComponent } from './pages/dictionary-items/dictionary-items.component';
import { DictionaryItemRowFormComponent } from './components/dictionary-item-row-form/dictionary-item-row-form.component';
import { FinderComponent } from './components/finder/finder.component';

@NgModule({
  imports: [
    CommonModule,
    DictionariesRoutingModule,
    StoreModule.forFeature('dictionaries', reducers),
    EffectsModule.forFeature(effects),
    SharedModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
  ],
  declarations: [
    DictionariesComponent,
    DictionaryFormComponent,
    DictionaryCreateComponent,
    DictionaryItemsComponent,
    DictionaryItemRowFormComponent,
    FinderComponent
  ],
  providers: [
    DictionariesService
  ]
})
export class DictionariesModule {
}
