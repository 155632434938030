import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ContactTip } from '../../models/contact-tip.model';

@Injectable({
  providedIn: 'root'
})
export class ContactTipsService {
  constructor(private http: HttpClient) {}

  all(): Observable<ContactTip[]> {
    return this.http.get<ContactTip[]>('contact-tips/');
  }
}
