import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { tap, scan, filter, map } from 'rxjs/operators';

@Injectable()
export class RouterEffect {
  constructor(private actions$: Actions) {}

  @Effect()
  connect$ = this.actions$.pipe(
    ofType('ROUTER_NAVIGATION', 'ROUTER_CANCEL'),
    scan((acc: any[], action: any) => {
      acc.push(action);
      return acc.slice(-3);
    }, []),
    filter(
      (actions: any[]) => actions[actions.length - 1].type === 'ROUTER_CANCEL'
    ),
    map((actions: any[]) => ({ ...actions[0] }))
  );
}
