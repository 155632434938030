import {SegmentGroup} from '../../models/segment-group';
import {
  SegmentGroupsAction,
  SEGMENTS_GROUPS_LOAD,
  SEGMENTS_GROUPS_LOAD_FAIL,
  SEGMENTS_GROUPS_LOAD_SUCCESS
} from '../actions/segment-groups.action';
import {createSelector} from '@ngrx/store';
import {getSegmentsFeatureState} from '../selectors';

export interface SegmentGroupsState {
  groups: SegmentGroup[];
  loading: boolean;
}

export const initialState: SegmentGroupsState = {
  groups: [],
  loading: false
}

export function SegmentGroupsReducer(state: SegmentGroupsState = initialState, action: SegmentGroupsAction): SegmentGroupsState {
  switch (action.type) {
    case SEGMENTS_GROUPS_LOAD:
      return { ...state, loading: true };
    case SEGMENTS_GROUPS_LOAD_SUCCESS:
      return { ...state, groups: action.payload, loading: false };
    case SEGMENTS_GROUPS_LOAD_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export const getSegmentGroupsState = createSelector(getSegmentsFeatureState, data => data.segmentGroups);
