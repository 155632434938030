import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import {
  SHOW_DATETIME_FORMAT,
  SHOW_FORMAT
} from '../../../shared/config/data-formats.config';
import { _ } from '../../../shared/helpers/translation-marker';
import {
  COLLAPSE_ARRAY_LIMIT,
  COLLAPSE_STRING_LIMIT,
  Collapsible
} from '../../../shared/models/collapsible.model';
import { SegmentFilter } from '../../models/full-segment';
import {
  SegmentOperator,
  SegmentOperatorInterpolation,
  SegmentOperatorInterpolationTypeSpecific,
  SegmentOperatorLabels,
  SegmentPropertyValue,
  SegmentWidget
} from '../../models/segment-property';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { getSegmentPropertiesMap } from '../../store/selectors/segment-properties.selector';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent implements OnInit {
  get collection(): Collapsible<SegmentFilter>[] {
    return this._collection;
  }
  displayFilters: {
    value: string;
    valueCollapsed: string;
    count: number;
    operator: string;
    name: string;
    isCollapsed: boolean;
    exclude?: boolean;
  }[];
  properties;

  @Input() set collection(segmentFilters: Collapsible<SegmentFilter>[]) {
    this.store
      .pipe(
        select(getSegmentPropertiesMap),
        filter(p => !!p),
        take(1)
      )
      .subscribe(properties => {
        this.properties = properties;

        this.displayFilters = segmentFilters.map(filter => {
          let { operator, value, property, exclude } = filter;
          const prop = properties[String(property)];

          if (prop.type === 'datetime') {
            if ([SegmentOperator.GTE, SegmentOperator.LTE].includes(operator)) {
              value = moment(value).format(SHOW_DATETIME_FORMAT);
            } else if (Array.isArray(value)) {
              value = [
                moment(value[0]).format(SHOW_DATETIME_FORMAT),
                moment(value[1]).format(SHOW_DATETIME_FORMAT),
              ];
            }
          }

          if (prop.type === 'date') {
            if ([SegmentOperator.GTE, SegmentOperator.LTE].includes(operator)) {
              value = moment(value).format(SHOW_FORMAT);
            } else {
              value = [
                moment(value[0]).format(SHOW_FORMAT),
                moment(value[1]).format(SHOW_FORMAT),
              ];
            }
          }

          const isCollapsibleArray =
            Array.isArray(value) && value.length > COLLAPSE_ARRAY_LIMIT;
          const isCollapsibleString =
            typeof value === 'string' && value.length > COLLAPSE_STRING_LIMIT;

          return {
            value: this.interpolateValue(
              value,
              operator,
              prop.widget,
              prop.property_values,
              false
            ),
            valueCollapsed:
              isCollapsibleArray || isCollapsibleString
                ? this.interpolateValue(
                    value,
                    operator,
                    prop.widget,
                    prop.property_values,
                    true
                  )
                : null,
            count: Array.isArray(value) ? value.length : 0,
            operator: this.interpolateOperator(operator, prop.widget),
            name: prop.name,
            isCollapsed: true,
            exclude,
          };
        });

        this._collection = segmentFilters;
      });
  }
  private _collection: Collapsible<SegmentFilter>[] = [];

  constructor(
    private translateService: TranslateService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  onClickItem(e, item) {
    e.stopPropagation();
    item.isCollapsed = !item.isCollapsed;
  }

  interpolateOperator(operator, type?) {
    return type &&
      SegmentOperatorInterpolationTypeSpecific[type] &&
      SegmentOperatorInterpolationTypeSpecific[type][operator] !== undefined
      ? SegmentOperatorInterpolationTypeSpecific[type][operator]
      : SegmentOperatorInterpolation[operator] !== undefined
      ? SegmentOperatorInterpolation[operator]
      : SegmentOperatorLabels[operator];
  }

  interpolateProperty(
    values: any[],
    props: SegmentPropertyValue[],
    isCollapsed: boolean,
    widgetType: SegmentWidget
  ) {
    let filtered = values;

    if (widgetType === 'remote_model') {
      filtered = values.map(v => v.display_name);
    } else if (widgetType !== 'string') {
      filtered = values.map(it => {
        const prop = props.find(prop => String(prop.ext_id) === String(it));
        return prop ? prop.name : 'not found';
      });

      if (filtered.length === 0) {
        return _('not found');
      }
    }

    if (filtered.length === 1) {
      return filtered[0];
    }

    return isCollapsed
      ? filtered.slice(0, COLLAPSE_ARRAY_LIMIT).join(', ') + '...'
      : filtered.slice(0, -1).join(', ') +
          ' ' +
          this.translateService.instant(_('or')) +
          ' ' +
          filtered[filtered.length - 1];
  }

  interpolateValue(
    value,
    operator,
    widget,
    property_values,
    isCollapsed: boolean
  ) {
    if (!value) {
      return _('empty string');
    }

    if (!Array.isArray(value)) {
      return isCollapsed
        ? `${value.substring(0, COLLAPSE_STRING_LIMIT)}`
        : `${value}`;
    }

    if (Array.isArray(value)) {
      switch (operator) {
        case SegmentOperator.RANGE:
        case SegmentOperator.NOT_RANGE:
          return (
            this.translateService.instant(_('from')) +
            ' ' +
            value[0] +
            ' ' +
            this.translateService.instant(_('to')) +
            ' ' +
            value[1]
          );
        case SegmentOperator.NOT_IN:
        case SegmentOperator.IN:
          return this.interpolateProperty(
            value,
            property_values,
            isCollapsed,
            widget
          );
        default:
          console.warn('warn: not implemented for: ', operator);
          return JSON.stringify(value);
      }
    }

    console.warn('warn: not implemented for: ', value);
    return JSON.stringify(value);
  }
}
