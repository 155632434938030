import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SegmentGroup } from '../../models/segment-group';

@Injectable({
  providedIn: 'root'
})
export class SegmentGroupsService {
  private basePath = 'segment-property-groups/';
  constructor(private http: HttpClient) {}

  all(): Observable<SegmentGroup[]> {
    return this.http.get<SegmentGroup[]>(this.basePath);
  }
}
