import { Component, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-finder',
  templateUrl: './finder.component.html',
})
export class FinderComponent implements OnDestroy {
  @Output() filter = new EventEmitter();
  search = new FormControl();
  subscription = new Subject();

  constructor() {
    this.search.valueChanges.pipe(
      takeUntil(this.subscription),
      debounceTime(500)
    ).subscribe((f) => {
      this.filter.emit(f ? f : undefined);
    });
  }

  ngOnDestroy(): void {
    this.subscription.next();
    this.subscription.complete();
  }
}
