import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DepartmentState } from './department.reducer';

export const getDeparmentState = createFeatureSelector('department');

export const getDepartments = createSelector(getDeparmentState, (state: DepartmentState) => state.departments);
export const isLoadingDepartments = createSelector(getDeparmentState, (state: DepartmentState) => state.isLoadingDepartments);
export const isReloadDepartments = createSelector(getDeparmentState, (state: DepartmentState) => state.isReload);
export const getDepartmentsCount = createSelector(getDeparmentState, (state: DepartmentState) => state.departmentsCount);
export const getDepartmentsFilter = createSelector(getDeparmentState, (state: DepartmentState) => state.departmentsFilter);
