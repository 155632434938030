import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '../../../../store/reducers';
import { Store } from '@ngrx/store';
import { getCurrentSegmentModel, getCurrentSegmentModelLabel } from '../../store/selectors/segment-models.selector';
import { take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { SEGMENT_CREATE_SUCCESS, SegmentCreateSuccess } from '../../store/actions/segments.action';
import { SegmentModel } from '../../models/segment-model';

@Component({
  selector: 'app-segment-create-page',
  templateUrl: './segment-create-page.component.html',
  styleUrls: ['./segment-create-page.component.scss']
})
export class SegmentCreatePageComponent implements OnInit, OnDestroy {
  currentSegmentModel$: Observable<string>;
  subscription: Subscription;
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private actions$: Actions
  ) {
    this.currentSegmentModel$ = this.store.select(getCurrentSegmentModelLabel);
    this.subscription = this.actions$.pipe(
      ofType(SEGMENT_CREATE_SUCCESS),
      take(1),
      withLatestFrom(this.store.select(getCurrentSegmentModel)),
    ).subscribe(([action, segmentModel]: [SegmentCreateSuccess, SegmentModel]) => {
      this.router.navigate(['segments', segmentModel.path, action.payload.id]);
    });
  }

  ngOnInit() {
  }

  cancelClick() {
    this.currentSegmentModel$.pipe(take(1)).subscribe((model) => {
      this.router.navigate(['/segments', model]);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
