import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  forwardRef,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  Subscription,
  Observable,
  merge,
  combineLatest,
  BehaviorSubject,
  Subject,
} from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { getSegmentsByModelName } from 'src/app/modules/segments/store/selectors/segments.selector';
import { Segment } from 'src/app/modules/segments/models/segment';
import { _ } from '../../helpers/translation-marker';
import { SegmentCreateComponent } from 'src/app/modules/segments/components/segment-create/segment-create.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  SEGMENT_CREATE_SUCCESS,
  AddSegment,
  SegmentCreateSuccess,
} from 'src/app/modules/segments/store/actions/segments.action';
import { Actions, ofType } from '@ngrx/effects';
import {
  take,
  startWith,
  tap,
  debounceTime,
  filter,
  switchMap,
  pluck,
  scan,
  map,
} from 'rxjs/operators';
import { SegmentModel } from 'src/app/modules/segments/models/segment-model';
import {
  getSegmentModelsByNames,
  getSegmentModelByName,
} from 'src/app/modules/segments/store/selectors/segment-models.selector';
import { SegmentsService } from 'src/app/modules/segments/service/segments/segments.service';
import { SegmentDetailsComponent } from 'src/app/modules/segments/components/segment-details/segment-details.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { isArray } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'app-segment-select',
  templateUrl: './segment-select.component.html',
  styleUrls: ['./segment-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SegmentSelectComponent),
      multi: true,
    },
  ],
})
export class SegmentSelectComponent
  implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  models: string[];
  @Input()
  multiple = false;
  // currentValue: string[];
  currentValue: FormControl = new FormControl();
  currentValueSubscription: Subscription;
  hostClass: string;
  statusChangeSubscription: Subscription;
  onChange: any = () => {};
  onTouched: any = () => {};
  segmentTypeahead$ = new Subject<string>();
  isSegmentsLoading = false;
  segmentsLoad$: Observable<Segment[]>;
  countLoaded: number;
  segments$: Observable<Segment[]>;
  models$: Observable<SegmentModel[]>;
  model$ = new BehaviorSubject<string>(null);
  modalRef;
  nextPage = 0;
  fetchMore$ = new BehaviorSubject('');
  hasNextPage = true;
  keepOpen;
  @ViewChild(NgSelectComponent, { static: true }) select: NgSelectComponent;

  constructor(
    private store: Store<AppState>,
    private segmentService: SegmentsService,
    private modalService: BsModalService,
    private actions$: Actions
  ) {
    this.segmentsLoad$ = combineLatest(
      this.segmentTypeahead$.pipe(
        startWith(''),
        tap(() => {
          this.nextPage = 0;
          this.hasNextPage = true;
        })
      ),
      this.model$.pipe(
        tap(() => {
          this.nextPage = 0;
          this.hasNextPage = true;
        }),
        switchMap((name) =>
          this.store.pipe(select(getSegmentModelByName(name)))
        )
      ),
      this.fetchMore$.pipe(
        filter((_) => !this.isSegmentsLoading && this.hasNextPage)
      )
    ).pipe(
      debounceTime(100),
      tap(() => (this.isSegmentsLoading = true)),
      switchMap(([val, model, _]) => {
        this.nextPage++;
        return this.segmentService
          .byModel(
            model.id,
            { page: this.nextPage, page_size: 20 },
            { search: val }
          )
          .pipe(
            tap((res) => (this.hasNextPage = !!res.next)),
            pluck('results')
          );
      })
    );

    this.segments$ = combineLatest(
      this.currentValue.valueChanges.pipe(startWith([])),
      this.segmentsLoad$.pipe(
        scan((previous, response) => {
          this.isSegmentsLoading = false;
          const result =
            this.nextPage <= 1 ? response : previous.concat(response);
          this.countLoaded = result.length;

          return result;
        }, [])
      )
    ).pipe(
      map(([_, list]) => {
        const currentValue = this.currentValue.value;
        if (!currentValue) {
          return list;
        }
        return list.filter((value) =>
          isArray(currentValue)
            ? currentValue.every((v: any) => v.id !== value.id)
            : currentValue.id !== value.id
        );
      })
    );
  }

  showAsyncInfo(e: Event, segment) {
    const ref = this.showInfo(e, null, true);

    this.segmentService
      .one(segment.id)
      .pipe(take(1))
      .subscribe((result) => {
        ref.content.segment = result;
        ref.content.isLoading = false;
      });
  }

  showInfo(e: Event, segment, isLoading = false) {
    e.preventDefault();
    e.stopPropagation();
    // this.select.isOpen = true;
    // Ng-select hack to keep it open when closing modal
    this.select.ngOnChanges({
      isOpen: {
        currentValue: true,
        previousValue: undefined,
        firstChange: undefined,
        isFirstChange: () => undefined,
      },
    });
    const ref = this.modalService.show(SegmentDetailsComponent, {
      initialState: { segment, isLoading },
      class: 'large-modal',
    });

    ref.content.hide.pipe(take(1)).subscribe(() => {
      ref.hide();
    });
    // Ng-select hack to restore normal behaviour
    ref.content.destroyed.pipe(take(1)).subscribe(() => {
      // this.select.isOpen = true;
      this.select.ngOnChanges({
        isOpen: {
          currentValue: undefined,
          previousValue: undefined,
          firstChange: undefined,
          isFirstChange: () => undefined,
        },
      });
    });

    return ref;
  }

  ngOnInit() {
    this.model$.next(this.models[0]);

    this.currentValueSubscription = this.currentValue.valueChanges.subscribe(
      (v) => this.onChanged(v)
    );
    // this.segments$ = this.store.pipe(
    //   select(getSegmentsByModelName(this.model))
    // );

    this.models$ = this.store.pipe(
      select(getSegmentModelsByNames(this.models))
    );
  }

  onBlur() {
    if (this.onTouched) {
      this.onTouched();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.currentValue.disable();
    } else {
      this.currentValue.enable();
    }
  }

  onChanged(value: string) {
    if (this.onChange) {
      this.onChange(value);
    }

    if (this.onTouched) {
      this.onTouched();
    }
  }

  ngOnDestroy(): void {
    if (this.statusChangeSubscription) {
      this.statusChangeSubscription.unsubscribe();
    }

    this.currentValueSubscription.unsubscribe();
  }

  private showAddSegmentDialog(segmentModel: string, title: string) {
    const initialState = {
      segmentModel,
      title,
    };
    this.modalRef = this.modalService.show(SegmentCreateComponent, {
      initialState,
      class: 'large-modal',
    });
    merge(
      this.modalService.onHide,
      this.actions$.pipe(ofType(SEGMENT_CREATE_SUCCESS))
    )
      .pipe(take(1))
      .subscribe((action) => {
        if (action instanceof SegmentCreateSuccess) {
          this.modalRef.hide();
          this.store.dispatch(new AddSegment(action.payload));
          if (this.multiple) {
            const values = [...(this.currentValue.value || []), action.payload];
            this.currentValue.patchValue(values);
          } else {
            this.currentValue.patchValue(action.payload);
          }
        }
      });
  }

  showCreateDialog() {
    this.model$.pipe(take(1)).subscribe((name) => {
      this.showAddSegmentDialog(name, _('Create segment'));
    });
  }

  compareValues(a: any, b: any): boolean {
    return a.id == b.id;
  }

  writeValue(obj: string | string[]): void {
    this.currentValue.setValue(obj, { emitEvent: false });
  }

  fetchMore() {
    if (this.isSegmentsLoading) {
      return;
    }
    this.fetchMore$.next('');
  }

  scroll({ end }) {
    if (this.isSegmentsLoading) {
      return;
    }
    if (end + 10 >= this.countLoaded) {
      this.fetchMore$.next('');
    }
  }
}
