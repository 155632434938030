import { Action } from '@ngrx/store';
import { CrumbDetail } from '../reducers/router.reducer';
import { RouterStateSnapshot } from '@angular/router';
export const SET_CRUMB = '[Router] Set crumb key';
export const SET_PAGE_TITLE = '[Router] Set page title';
export const ROUTER_REVERT = '[Router] Revert state';

export class SetCrumb implements Action {
  readonly type = SET_CRUMB;
  constructor(public key: string, public value: CrumbDetail) {}
}

export class SetPageTitle implements Action {
  readonly type = SET_PAGE_TITLE;
  constructor(public key: string, public value: string) {}
}

export class RouterRevert implements Action {
  readonly type = ROUTER_REVERT;
  constructor(public payload: RouterStateSnapshot) {}
}

export type RouterAction = SetCrumb | SetPageTitle | RouterRevert;
