import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { AppState } from '../../../../store/reducers';
import { Store } from '@ngrx/store';
import { SegmentLoad } from '../../store/actions/segments.action';
import { getCurrentSegment, isCurrentSegmentLoading } from '../../store/selectors/segments.selector';
import { filter, take } from 'rxjs/operators';
import { SetPageTitle } from '../../../../store/actions/router.action';
import { _ } from '../../../shared/helpers/translation-marker';

@Injectable({
  providedIn: 'root'
})
export class LoadSegmentGuard implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(new SegmentLoad(next.params.id));

    this.store.dispatch(new SetPageTitle('SEGMENT_DETAILED', '...'));

    combineLatest(
      this.store.select(getCurrentSegment),
      this.store.select(isCurrentSegmentLoading)
    ).pipe(
      filter(([segment, isLoading]) => !!segment && !isLoading),
      take(1)
    ).subscribe(([segment, isLoading]) => this.store.dispatch(new SetPageTitle('SEGMENT_DETAILED', segment.name)));

    return true;
  }
}
