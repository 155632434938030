import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DATE_FORMAT, SHOW_DATETIME_FORMAT, SHOW_DATETIME_FORMAT_FULL_YEAR } from '../../config/data-formats.config';

@Pipe({
  name: 'formatDatetime'
})
export class FormatDatetimePipe implements PipeTransform {

  transform(value: any, full_year?: boolean): any {
    let date = moment(value);
    if (!date.isValid()) {
      date = moment(value, DATE_FORMAT);
    }

    return date.isValid() ? date.format(full_year ? SHOW_DATETIME_FORMAT_FULL_YEAR : SHOW_DATETIME_FORMAT) : '';
  }

}
