import { createSelector } from '@ngrx/store';
import { getDictionaryItemState } from './dictionary-item.reducer';

export const getDictionaryItems = createSelector(
  getDictionaryItemState,
  data => data.dictionaryItems
);
export const getTotalDictionaryItemCount = createSelector(
  getDictionaryItemState,
  data => data.totalDictionariesCount
);
export const getCurrentDictionaryItem = createSelector(
  getDictionaryItemState,
  data => data.dictionaryItem
);
export const getDictionaryItemByTrackId = trackId => {
  return createSelector(
    getDictionaryItemState,
    data => data.dictionaryItems.find(item => item._trackId === trackId)
  );
};
export const isLoadingDictionaryItems = createSelector(
  getDictionaryItemState,
  data => data.isLoadingDictionaryItems
);
export const isReloadDictionaryItems = createSelector(
  getDictionaryItemState,
  data => data.isReload
);
export const isLoadingDictionaryItem = createSelector(
  getDictionaryItemState,
  data => data.isLoadingDictionaryItem
);
export const isUpdatingDictionaryItem = createSelector(
  getDictionaryItemState,
  data => data.updating
);
export const isDictionaryItemsReload = createSelector(
  getDictionaryItemState,
  data => data.isReload
);
export const getDictionaryItemError = createSelector(
  getDictionaryItemState,
  data => data.dictionaryItemError
);
