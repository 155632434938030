import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DaDataService {
  basePath = 'address/';

  constructor(private http: HttpClient) {}

  getAddress(query: string): Observable<AddressSuggestion[]> {
    return this.http.get<AddressSuggestion[]>(`${this.basePath}suggest/`, {
      params: { query: query },
    });
  }

  getFullAddress(query: string): Observable<AddressSuggestion[]> {
    return this.http.get<AddressSuggestion[]>(`${this.basePath}suggest/`, {
      params: { query: query, count: 1 } as any,
    });
  }
}

export class AddressSuggestion {
  value: string = '';
  unrestricted_value: string = '';
  qc_geo: boolean = false;
  city_with_type: string = null;
  area_with_type: string = null;
  settlement_with_type: string = null;
  street_with_type: string = null;
  house: string = null;
  geo_lon: number;
  geo_lat: number;
  constructor(options: any = {}) {
    Object.assign(this, options);
  }
}
export type NameSuggestion = {
  suggestions: any[];
};
