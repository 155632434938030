import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  FormStatusLoad,
  FormStatusLoadFail,
  FormStatusLoadSuccess,
  FormStatusReset
} from '../../../shared/store/actions/form-status.actions';
import { DictionariesService } from '../../services/dictionaries/dictionaries.service';
import { ErrorHandlingService } from '../../../shared/services/error-handling/error-handling.service';
import {
  DICTIONARIES_LOAD,
  DICTIONARIES_LOAD_SUCCESS,
  DictionariesLoadFail,
  DictionariesLoadSuccess,
  DICTIONARY_CREATE, DICTIONARY_CREATE_SUCCESS,
  DICTIONARY_LOAD, DICTIONARY_UPDATE,
  DictionaryCreate,
  DictionaryCreateFail,
  DictionaryCreateSuccess,
  DictionaryLoad,
  DictionaryLoadFail,
  DictionaryLoadSucccess, DictionaryUpdate, DictionaryUpdateFail, DictionaryUpdateSuccess
} from './dictionary.actions';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { AppState } from '../../../../store/reducers';
import { select, Store } from '@ngrx/store';
import { getDictionary } from './dictionary.selectors';
import { Dictionary } from '../../models/dictionary';
import { Router } from '@angular/router';

@Injectable()
export class DictionaryEffects {
  constructor(
    private actions$: Actions,
    private dictionariesService: DictionariesService,
    private errorHandler: ErrorHandlingService,
    private store: Store<AppState>,
    private router: Router
  ) {
  }

  @Effect()
  all$ = this.actions$.pipe(
    ofType(DICTIONARIES_LOAD),
    switchMap(() => {
      return this.dictionariesService.all().pipe(
        map((data) => new DictionariesLoadSuccess(data)),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handle(error.error);
          return of(new DictionariesLoadFail());
        })
      );
    })
  );

  @Effect()
  one$ = this.actions$.pipe(
    ofType(DICTIONARY_LOAD),
    switchMap((action: DictionaryLoad) => {
      return this.dictionariesService.one(action.payload).pipe(
        map((data) => new DictionaryLoadSucccess(data)),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handle(error.error);
          return of(new DictionaryLoadFail());
        })
      );
    })
  );

  @Effect()
  create$ = this.actions$.pipe(
    ofType(DICTIONARY_CREATE),
    switchMap((action: DictionaryCreate) => {
      return this.dictionariesService.create(action.payload).pipe(
        map((data) => new DictionaryCreateSuccess(data)),
        catchError((error: HttpErrorResponse) => {
          return of(new DictionaryCreateFail(this.errorHandler.handle(error.error)));
        })
      );
    })
  );

  @Effect({dispatch: false})
  afterCreate$ = this.actions$.pipe(
    ofType(DICTIONARY_CREATE_SUCCESS),
    tap((data: DictionaryCreateSuccess) => this.router.navigate(['/dictionaries', data.payload.id, 'items']))
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(DICTIONARY_UPDATE),
    withLatestFrom(this.store.pipe(select(getDictionary))),
    switchMap(([action, dictionary]: [DictionaryUpdate, Dictionary]) => {
      this.store.dispatch(new FormStatusLoad());
      return this.dictionariesService.update(dictionary.id, action.payload).pipe(
        map((data) => {
          this.store.dispatch(new FormStatusLoadSuccess());
          if (action.isRevert) {
            this.store.dispatch(new FormStatusReset());
          }
          return new DictionaryUpdateSuccess(data);
        }),
        catchError((error: HttpErrorResponse) => {
          this.store.dispatch(new FormStatusLoadFail());
          return of(new DictionaryUpdateFail(this.errorHandler.handle(error.error)));
        })
      );
    })
  );
}
