<!--
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'Hang up call' | translate }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="decline()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
-->
<div class="modal-body text-center">
  <p>{{ 'Are you sure you want to hang up' | translate }}?</p>
  <button type="button" class="btn btn-primary" (click)="confirm()">
    {{ 'Yes' | translate }}
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary ml-3"
    (click)="decline()"
  >
    {{ 'No' | translate }}
  </button>
</div>
