<div [formGroup]="form">
  <div class="row">
    <div class="col col-3">
      <ng-select
        class="ng-select-autowidth ng-select-prepend select-filter-operator"
        [items]="operators"
        formControlName="numberOperator"
        bindValue="value"
        [clearable]="false"
        [searchable]="false"
        [placeholder]="'select' | translate"
      >
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
          <app-operator-option [item]="item"></app-operator-option>
        </ng-template>
      </ng-select>
      <app-errors-component
        class="mt-1 ml-2"
        [control]="form.get('numberOperator')"
      ></app-errors-component>
    </div>
    <div class="col">
      <div class="position-relative">
        <app-number-input
          formControlName="start"
          customClass="start form-control"
          [mask]="mask"
          [placeholder]="'from' | translate"
        ></app-number-input>
        <a
          *ngIf="form.get('start').value"
          class="input-clear icon-link"
          (click)="onClearStart()"
        >
          <i class="material-icons">close</i>
        </a>
      </div>
      <app-errors-component
        class="mt-1 ml-2"
        [control]="form.get('start')"
      ></app-errors-component>
    </div>
    <div class="col">
      <div class="position-relative">
        <app-number-input
          formControlName="end"
          type="text"
          customClass="end form-control"
          [mask]="mask"
          [placeholder]="'to' | translate"
        ></app-number-input>
        <a
          *ngIf="form.get('end').value"
          class="input-clear icon-link"
          (click)="onClearEnd()"
        >
          <i class="material-icons">close</i>
        </a>
      </div>
      <app-errors-component
        class="mt-1 ml-2"
        [control]="form.get('end')"
      ></app-errors-component>
    </div>
  </div>
  <div class="row" *ngIf="form.errors">
    <div class="col-12">
      <app-errors-component
        class="mt-1 ml-2"
        [control]="form"
      ></app-errors-component>
    </div>
  </div>
</div>
