<div [formGroup]="form">
  <div class="row">
    <div class="col col-3">
      <ng-select
        class="ng-select-autowidth ng-select-prepend select-filter-operator"
        [items]="operators"
        formControlName="operator"
        bindValue="value"
        [clearable]="false"
        [searchable]="false"
        [placeholder]="'select' | translate"
      >
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
          <app-operator-option [item]="item"></app-operator-option>
        </ng-template>
      </ng-select>
      <app-errors-component
        class="mt-1 ml-2"
        [control]="form.get('operator')"
      ></app-errors-component>
    </div>
    <div class="col">
      <ng-select
        [items]="models$ | async"
        bindLabel="display_name"
        [virtualScroll]="true"
        [multiple]="true"
        [loading]="isModelsLoading"
        [typeahead]="modelTypeahead$"
        formControlName="value"
        (scroll)="scroll($event)"
        (scrollToEnd)="fetchMore()"
        [compareWith]="compareValues"
      >
        <ng-template ng-option-tmp let-item="item" let-selected="item.selected">
          <div [class.selected]="item.selected">
            {{
              item.display_name +
                (item.display_description
                  ? ' - ' + item.display_description
                  : '')
            }}
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="row">
    <div class="col offset-3">
      <app-errors-component
        class="mt-1 ml-2"
        [control]="form.get('value')"
      ></app-errors-component>
    </div>
  </div>
</div>
