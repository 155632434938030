import { LegalEntityActions, LegalEntityActionTypes } from '../actions/legal-entity.actions';
import { LegalEntity } from '../../models/legal-entity.model';
import { createSelector } from '@ngrx/store';
import { getSharedState } from '../selectors';

export interface LegalEntityState {
  legalEntities: LegalEntity[];
}

export const initialState: LegalEntityState = {
  legalEntities: []
};

export function legalEntityReducer(
  state = initialState,
  action: LegalEntityActions
): LegalEntityState {
  switch (action.type) {

    case LegalEntityActionTypes.LoadEntitiesSuccess:
      return {
        ...state,
        legalEntities: action.payload
      };

    case LegalEntityActionTypes.LoadEntitiesFail:
      return {
        ...state,
        legalEntities: []
      };

    default:
      return state;
  }
}

export const getLegalEntityState = createSelector(getSharedState, data => data.legalEntity);
