import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty, isNumber, pickBy, isBoolean } from 'lodash';
import { Observable } from 'rxjs';
import { PagebleEntity } from 'src/app/modules/shared/models/pageble-entity';
import { PageSettings } from '../../../shared/helpers/calculate-page';
import { EmployeeActionType } from '../../models/employee-action-types.model';
import {
  Employee,
  EmployeesFilter,
  PartialEmployee,
} from '../../models/employee.model';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  basePath = 'employees/';

  constructor(private http: HttpClient) {}

  allActionTypes(): Observable<PagebleEntity<EmployeeActionType>> {
    return this.http.get<PagebleEntity<EmployeeActionType>>(
      `employee-state-action-types/`
    );
  }

  all(
    filter: EmployeesFilter,
    params: PageSettings
  ): Observable<PagebleEntity<Employee>> {
    const notEmptyFilters = pickBy(
      filter,
      (value) => !isEmpty(value) || isNumber(value)
    );
    const paramsAndFilter = {
      ...params,
      ...notEmptyFilters,
    };
    return this.http.get<PagebleEntity<Employee>>(this.basePath, {
      params: paramsAndFilter as any,
    });
  }

  changeState(id: string, params) {
    return this.http.post(`${this.basePath}${id}/change_state/`, params);
  }

  get(id: string): Observable<Employee> {
    return this.http.get<Employee>(`${this.basePath}${id}/`);
  }

  create(payload: PartialEmployee): Observable<Employee> {
    return this.http.post<Employee>(this.basePath, payload);
  }

  update(payload: PartialEmployee): Observable<Employee> {
    return this.http.patch<Employee>(`${this.basePath}${payload.id}/`, payload);
  }

  filter(filter): Observable<PagebleEntity<Employee>> {
    const notEmptyFilters = pickBy(
      filter,
      (value) => !isEmpty(value) || isNumber(value) || isBoolean(value)
    );
    return this.http.get<PagebleEntity<Employee>>(this.basePath, {
      params: notEmptyFilters,
    });
  }
}
