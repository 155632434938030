import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  switchMap,
  map,
  catchError,
} from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlingService } from '../../../shared/services/error-handling/error-handling.service';
import { of } from 'rxjs';
import {
  LegalEntityActionTypes,
  LoadEntities,
  LoadEntitiesSuccess,
  LoadEntitiesFail
} from '../actions/legal-entity.actions';
import { LegalEntityService } from '../../services/legal-entity/legal-entity.service';

@Injectable()
export class LegalEntityEffects {

  @Effect()
  loadEntities$ = this.actions$.pipe(
    ofType(LegalEntityActionTypes.LoadEntities),
    switchMap((action: LoadEntities) => {
      return this.legalEntityService.getEntities().pipe(
        map(data => new LoadEntitiesSuccess(data)),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handle(error.error);
          return of(new LoadEntitiesFail());
        })
      );
    })
  );

  constructor(
    private actions$: Actions,
    private legalEntityService: LegalEntityService,
    private errorHandler: ErrorHandlingService
  ) {}
}
