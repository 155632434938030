import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SegmentsComponent } from '../pages/segments/segments.component';
import { LoadSegmentsGuard } from './guards/load-segments.guard';
import { SegmentComponent } from '../pages/segment/segment.component';
import { LoadSegmentGuard } from './guards/load-segment.guard';
import { SegmentRootComponent } from '../pages/segment-root/segment-root.component';
import { SegmentCreatePageComponent } from '../pages/segment-create-page/segment-create-page.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { _ } from '../../shared/helpers/translation-marker';
import { SetSegmentModelGuard } from './guards/set-segment-model.guard';
import { SegmentLeaveGuard } from './guards/segment-leave.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: ':model',
        canActivate: [
          SetSegmentModelGuard
        ],
        data: {
          page_id: 'SEGMENTS_MODEL'
        },
        children: [
          {
            path: '',
            canActivate: [LoadSegmentsGuard],
            component: SegmentsComponent,
          },
          {
            path: 'new',
            component: SegmentCreatePageComponent,
            data: {
              crumb: {name: _('Create segment')}
            }
          },
          {
            path: ':id',
            canActivate: [ LoadSegmentGuard ],
            canDeactivate: [ SegmentLeaveGuard ],
            component: SegmentComponent,
            data: {
              hideTitle: true
            }
          }
        ]
      },
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  declarations: [],
  exports: [RouterModule]
})
export class SegmentsRoutingModule {}
