import { ActionReducerMap } from '@ngrx/store';
import { CourierReducer, CourierState } from './courier/courier.reducer';
import { OrderDeliveryReducer, OrderDeliveryState } from './order-delivery/order-delivery.reducer';
import { ReturnDeliveryReducer, ReturnDeliveryState } from './return-delivery/return-delivery.reducer';

export interface CourierModuleState {
  couriers: CourierState;
  orderDeliveries: OrderDeliveryState;
  returnDeliveries: ReturnDeliveryState;
}

export const reducers: ActionReducerMap<CourierModuleState> = {
  couriers: CourierReducer,
  orderDeliveries: OrderDeliveryReducer,
  returnDeliveries: ReturnDeliveryReducer,
};

