import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {
  DATE_FORMAT,
  dataFormatsConfig,
  SHOW_FORMAT
} from '../../../config/data-formats.config';
import { isDate, isNil } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ]
})
export class DatepickerComponent
  implements OnInit, ControlValueAccessor, OnDestroy {
  @Input()
  set viewDateForm(value: string) {
    this._viewDateForm = value;
    this.datePickerConfig.dateInputFormat = value;
  }
  @Input() showIcon = false;
  @Input() showArrows = false;
  @Input() inputDateFormat: string = DATE_FORMAT;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() placeholder: string;
  @Input() additionalClass: string;
  @Input() placement = 'bottom';

  private readonly oneDay = 1000 * 60 * 60 * 24; // one day in ms
  private _viewDateForm: string = SHOW_FORMAT;
  datePickerConfig = { ...dataFormatsConfig, dateInputFormat: SHOW_FORMAT };
  dateControl: FormControl = new FormControl();

  skip = false;
  private subscriptions = new Subject();

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    private localeService: BsLocaleService,
    private translateService: TranslateService
  ) {
    this.dateControl.valueChanges
      .pipe(
        takeUntil(this.subscriptions),
        skipWhile(() => this.skip)
      )
      .subscribe(v => {
        if (this.dateControl.valid && this.onChange) {
          this.onChange(v);
        }
      });

    this.localeService.use(this.translateService.currentLang);
    this.translateService.onLangChange
      .pipe(takeUntil(this.subscriptions))
      .subscribe(v => this.localeService.use(v.lang));
  }

  ngOnInit() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.dateControl.disable({ emitEvent: false });
    } else {
      this.dateControl.enable({ emitEvent: false });
    }
  }

  writeValue(obj: any): void {
    const value = isDate(obj)
      ? obj
      : isNil(obj)
      ? null
      : moment(obj, this.inputDateFormat).toDate();
    const curValue = this.dateControl.value;
    if (
      ((value === null || curValue === null) && value !== curValue) ||
      (value && curValue && value.getTime() !== curValue.getTime())
    ) {
      this.dateControl.patchValue(value, { emitEvent: false });
    }
  }

  increase() {
    const value: Date = this.dateControl.value;
    const newValue = new Date(value.getTime() + this.oneDay);
    this.dateControl.setValue(newValue);
  }

  decrease() {
    const value: Date = this.dateControl.value;
    const newValue = new Date(value.getTime() - this.oneDay);
    this.dateControl.setValue(newValue);
  }

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
  }

  clear() {
    this.dateControl.reset();
  }
}
