import { Action } from '@ngrx/store';
import { PagebleActionPayload } from '../../shared/helpers/calculate-page';
import { PagebleEntity } from '../../shared/models/pageble-entity';
import { Department, DepartmentsFilter } from '../models/department.model';

export enum DepartmentActionTypes {
  LoadDepartments = '[Department] Load Departments',
  LoadDepartmentsSuccess = '[Department] Load Departments Success',
  LoadDepartmentsFail = '[Department] Load Departments Fail',
  SetFilterDepartments = '[Department] set filter'
}

export class LoadDepartments implements Action {
  readonly type = DepartmentActionTypes.LoadDepartments;

  constructor(public payload?: PagebleActionPayload) {
  }
}

export class LoadDepartmentsSuccess implements Action {
  readonly type = DepartmentActionTypes.LoadDepartmentsSuccess;

  constructor(public payload?: PagebleEntity<Department>) {
  }
}

export class LoadDepartmentFail implements Action {
  readonly type = DepartmentActionTypes.LoadDepartmentsFail;
}

export class DepartmentsSetFilter implements Action {
  readonly type = DepartmentActionTypes.SetFilterDepartments;

  constructor(public payload: DepartmentsFilter) {

  }
}

export type DepartmentActions = LoadDepartments
  | LoadDepartmentsSuccess
  | LoadDepartmentFail
  | DepartmentsSetFilter;
