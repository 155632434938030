import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../store/reducers';
import { Dictionary } from '../../models/dictionary';
import {
  getDictionaries,
  isLoadingDictionaries,
} from '../../store/dictionary/dictionary.selectors';

@Component({
  selector: 'app-dictionaries',
  templateUrl: './dictionaries.component.html',
  styleUrls: ['./dictionaries.component.scss'],
})
export class DictionariesComponent implements OnInit, OnDestroy {
  loading: boolean;
  dictionaries: Dictionary[];
  subscriptions = new Subject();
  nameFilter: string;

  constructor(private store: Store<AppState>, private router: Router) {
    this.store
      .pipe(select(getDictionaries), takeUntil(this.subscriptions))
      .subscribe((data) => {
        this.dictionaries = data;
      });
    this.store
      .pipe(select(isLoadingDictionaries), takeUntil(this.subscriptions))
      .subscribe((data) => (this.loading = data));
  }

  get filteredDictionaries(): Dictionary[] {
    const query = this.nameFilter && this.nameFilter.toLowerCase();
    if (!query) return this.dictionaries;
    return this.dictionaries.filter((el) =>
      el.name.toLowerCase().includes(query)
    );
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
  }

  goToDictionary(dictionary: Dictionary) {
    this.router.navigate(['/dictionaries', dictionary.id, 'items']);
  }
}
