import { Action } from '@ngrx/store';
import { HttpErrorsList } from '../../../shared/models/http-error';

export const LOAD_USER = '[User] Load user';
export const LOAD_USER_SUCCESS = '[User] Load user success';
export const LOAD_USER_FAIL = '[User] Load user fail';

export const LOAD_COMPANIES = '[User] Load user companies';
export const LOAD_COMPANIES_SUCCESS = '[User] Load user companies success';
export const LOAD_COMPANIES_FAIL = '[User] Load user companies fail';
export const SET_CURRENT_COMPANY = '[User] Set current company';

export const NOOP = '[No Operation]';

export class NoopAction implements Action {
  readonly type = NOOP;
}

export class LoadUser implements Action {
  readonly type = LOAD_USER;
  constructor() {}
}

export class LoadUserSuccess implements Action {
  readonly type = LOAD_USER_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadUserFail implements Action {
  readonly type = LOAD_USER_FAIL;
  constructor(public payload: HttpErrorsList) {}
}

export class LoadCompanies implements Action {
  readonly type = LOAD_COMPANIES;
  constructor() {}
}

export class LoadCompaniesSuccess implements Action {
  readonly type = LOAD_COMPANIES_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadCompaniesFail implements Action {
  readonly type = LOAD_COMPANIES_FAIL;
  constructor(public payload: HttpErrorsList) {}
}

export class SetCurrentCompany implements Action {
  readonly type = SET_CURRENT_COMPANY;
  constructor(public id: string, public reload = true) {}
}

export type UserAction =
  | LoadUser
  | LoadUserSuccess
  | LoadUserFail
  | LoadCompanies
  | LoadCompaniesSuccess
  | LoadCompaniesFail
  | SetCurrentCompany;
