import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppState } from '../../../../store/reducers';
import { Store } from '@ngrx/store';
import { SetSegmentModel } from '../../store/actions/segment-models.action';
import { getCurrentSegmentModel } from '../../store/selectors/segment-models.selector';
import { take, filter } from 'rxjs/operators';
import { SetPageTitle, SetCrumb } from '../../../../store/actions/router.action';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '../../../shared/helpers/translation-marker';
import { SegmentModelsName } from '../../models/segment';

@Injectable({
  providedIn: 'root'
})
export class SetSegmentModelGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(new SetSegmentModel(next.params.model));
    this.store.select(getCurrentSegmentModel).pipe(
      filter(model => !!model),
      take(1),
    )
      .subscribe(model => {
        this.store.dispatch(new SetCrumb('SEGMENTS_MODEL', { name: this.translateService.instant(SegmentModelsName[model.path])}));
        this.store.dispatch(
          new SetPageTitle(
            'SEGMENTS_MODEL',
            this.translateService.instant(SegmentModelsName[model.path])
          )
        );
      });

    return true;
  }
}
