import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../store/reducers';
import { TransferCall } from '../../store/call.actions';

@Component({
  selector: 'app-redirect-call',
  templateUrl: './redirect-call.component.html',
  styleUrls: ['./redirect-call.component.scss']
})
export class RedirectCallComponent implements OnInit, OnDestroy {
  @Input() call: string;
  @Input() modalRef: BsModalRef;

  fragment = 'employee';
  private subscriptions = new Subject();

  redirectForm: FormGroup;

  constructor(private route: ActivatedRoute,
              private fb: FormBuilder,
              private store: Store<AppState>,
              private router: Router) {
    this.route.fragment
      .pipe(takeUntil(this.subscriptions))
      .subscribe(fragment => (this.fragment = fragment || 'employee'));
  }

  ngOnInit() {
    this.redirectForm = this.fb.group({
      employee: null,
      department: null,
      comment: ''
    });
  }

  goToDepartment() {
    this.redirectForm.patchValue({department: null});
    this.router.navigate(['./'], {
      fragment: 'department',
      relativeTo: this.route
    });
  }

  goToEmployee() {
    this.redirectForm.patchValue({employee: null});
    this.router.navigate(['./'], {
      fragment: 'employee',
      relativeTo: this.route
    });
  }

  redirect() {
    const value = this.redirectForm.getRawValue();
    this.store.dispatch(new TransferCall(this.call, {
      comment: value.comment,
      employee: value.employee.id
    }));
    this.modalRef.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
  }
}
