import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isEqual } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DaDataService } from '../../services/dadata/dadata.service';

@Component({
  selector: 'app-dadata-address',
  templateUrl: './dadata-address.component.html',
  styleUrls: ['./dadata-address.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DadataAddressComponent),
      multi: true
    }
  ]
})
export class DadataAddressComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() placeholder = 'Address';
  currentValue = new FormControl();
  modelTypeahead$ = new Subject<string>();
  isModelsLoading = false;
  models$: Observable<any>;
  subscriptions = new Subject();
  operators: string[] = [];
  @Output() coordinateChanged: EventEmitter<number[]> = new EventEmitter<number[]>();

  onChange: any = () => {
  }

  onTouched: any = () => {
  }

  constructor(private daDataService: DaDataService) {
    // this.mode
    this.models$ = this.modelTypeahead$.pipe(startWith(''),
      takeUntil(this.subscriptions),
      debounceTime(100),
      filter(val => !!val),
      tap(() => (this.isModelsLoading = true)),
      switchMap((val) => this.daDataService.getAddress(val)),
      tap(() => (this.isModelsLoading = false)));

    this.currentValue.valueChanges
      .pipe(takeUntil(this.subscriptions),
        distinctUntilChanged((a, b) => isEqual(a, b)))
      .subscribe(v => this.onChanged(v));
  }

  ngOnInit(): any {
  }

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    if (obj) {
      this.currentValue.setValue(obj, {emitEvent: false});
    }
  }

  onChanged(value: any) {
    if (this.onChange) {
      this.onChange(value ? value.value : '');
    }

    if (this.onTouched) {
      this.onTouched();
    }
  }

  changed(val: any) {
    if (val && val.unrestricted_value) {
      this.daDataService.getFullAddress(val.unrestricted_value).subscribe(fullAddress => {
        if (fullAddress && fullAddress[0] && fullAddress[0].geo_lat && fullAddress[0].geo_lon) {
          this.coordinateChanged.emit([Number(fullAddress[0].geo_lat), Number(fullAddress[0].geo_lon)]);
        }
      });
    }
  }
}
