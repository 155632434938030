import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProxyToBackendInterceptorService implements HttpInterceptor {
  skipRoutes = ['websocket', 'assets'];

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url;
    const isSkipRoute = this.skipRoutes.filter(it => url.startsWith(it)).length !== 0;
    if (!isSkipRoute) {
      const newRequest = req.clone({
        url: `/api/${url}`
      });
      return next.handle(newRequest);
    }

    return next.handle(req);
  }
}
