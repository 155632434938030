import { Component } from '@angular/core';
import { FilterValueAccessor, MakeProvider } from '../filter-value-accessor';
import { SegmentOperator } from '../../../models/segment-property';
import { filter } from 'rxjs/operators';
import * as moment from 'moment';
import { dataFormatsConfig } from '../../../../shared/config/data-formats.config';
import { Validators } from '@angular/forms';
import { SelectItem } from '../../../../shared/components/form/select/select.component';
import { NO_EMIT_EVENT } from '../../../../shared/helpers/forms';
import { _ } from '../../../../shared/helpers/translation-marker';

enum dateOperators {
  RANGE = 'range',
  EXACT_DATES = 'exact_dates',
  EXCLUDE_DATES = 'exclude_dates'
}

@Component({
  selector: 'app-segment-filter-date',
  templateUrl: './segment-filter-date.component.html',
  styleUrls: ['./segment-filter-date.component.scss'],
  providers: [...MakeProvider(SegmentFilterDateComponent)]
})
export class SegmentFilterDateComponent extends FilterValueAccessor {
  operators: string[] = [dateOperators.RANGE];

  datePickerConfig = dataFormatsConfig;

  ngOnInit() {
    this.initForm();
    this.initSubscriptions();
  }

  initForm() {
    this.form = this.fb.group({
      dateOperator: [null, Validators.required],
      start: [null],
      end: [null]
    });
  }

  initSubscriptions() {
    this.subscriptions.add(
      this.form.valueChanges
        .pipe(
          filter(values => {
            return this.form.valid && (values.start || values.end);
          })
        )
        .subscribe(() => {
          this.pushValue({
            ...this.filter,
            ...this.serialize()
          } as any);
        })
    );
  }

  onWrite(values) {
    this.form.patchValue(this.valuesToForm(values), NO_EMIT_EVENT);
  }

  onClearStart() {
    this.form.get('start').setValue(null);
  }

  onClearEnd() {
    this.form.get('end').setValue(null);
  }

  valuesToForm(values): any {
    const { operator, value } = values;
    if (!value || !operator) {
      return {
        dateOperator: dateOperators.RANGE,
        start: null,
        end: null
      };
    }

    const dateValue = moment(
      value,
      this.datePickerConfig.dateInputFormat
    ).toDate();

    switch (operator) {
      case SegmentOperator.RANGE:
        const start = moment(
          value[0],
          this.datePickerConfig.dateInputFormat
        ).toDate();
        const end = moment(
          value[1],
          this.datePickerConfig.dateInputFormat
        ).toDate();
        return {
          dateOperator: dateOperators.RANGE,
          start,
          end
        };
      case SegmentOperator.GTE:
        return {
          dateOperator: dateOperators.RANGE,
          start: dateValue,
          end: null
        };
      case SegmentOperator.LTE:
        return {
          dateOperator: dateOperators.RANGE,
          start: null,
          end: dateValue
        };
      default:
        console.warn('warn: operator not implemented: ', operator);
        return {
          dateOperator: dateOperators.RANGE,
          start: dateValue,
          end: null
        };
    }
  }

  serialize() {
    const { start, end, dateOperator } = this.form.value;
    const format = this.datePickerConfig.dateInputFormat;
    const startValue = start ? moment(start).format(format) : null;
    const endValue = end ? moment(end).format(format) : null;

    let operator;
    let value;

    // TODO @dkchv: remove true after implement other options
    if (dateOperator === dateOperators.RANGE || true) {
      if (startValue && endValue) {
        operator = SegmentOperator.RANGE;
        value = [startValue, endValue];
      } else if (!startValue) {
        operator = SegmentOperator.LTE;
        value = endValue;
      } else if (!endValue) {
        operator = SegmentOperator.GTE;
        value = startValue;
      }
    }

    return {
      operator,
      value
    };
  }
}
