<div class="modal-header"
     *ngIf="title">
  <h4 class="modal-title pull-left">{{title | translate}}</h4>
  <button type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="decline()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-center">
  <p>{{message | translate}}</p>
  <button type="button"
          class="btn btn-primary"
          (click)="accept()">{{'Yes' | translate}}</button>
  <button type="button"
          class="btn btn-outline-secondary ml-3"
          (click)="decline()">{{'No' | translate}}</button>
</div>
