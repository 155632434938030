import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatSeconds'
})
export class FormatSecondsPipe implements PipeTransform {

  transform(value: number, args?: any): string {
    if (value && value > 0) {
      const minutes = Math.floor(value / 60);
      const seconds = value - minutes * 60;
      return `${minutes ? minutes : '00'}:${seconds}`;
    } else {
      return '00:00';
    }
  }

}
