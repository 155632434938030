// import createNumberMask from 'text-mask-addons/dist/createNumberMask';
// import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

// export const NUMBERS_MASK = {
//   mask: createNumberMask({
//     prefix: '',
//     suffix: '',
//     allowDecimal: false,
//     allowNegative: false,
//     thousandsSeparatorSymbol: ' ',
//   })
// };

// export const DECIMAL_MASK = {
//   mask: createNumberMask({
//     prefix: '',
//     suffix: '',
//     allowDecimal: true,
//     allowNegative: false,
//     thousandsSeparatorSymbol: ' ',
//   })
// }

export const FL_MASK = {
  mask: [/\d/, /\d/, /\d/]
};

export const SM_MASK = {
  mask: [/[0-2]/, /\d/, /\d/, /\d/]
};

export const NO_MASK = {
  mask: false
};

export const TIME_MASK = {
  mask: [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
  keepCharPositions: true
}

// export const DATE_MASK = {
//   mask: [/\d/, /\d/, '-', /[0-1]/, /\d/],
//   keepCharPositions: true,
//   pipe: createAutoCorrectedDatePipe('dd-mm')
// }
