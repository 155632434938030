import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ng-select-option-with-count',
  templateUrl: './ng-select-option-with-count.component.html',
  styleUrls: ['./ng-select-option-with-count.component.scss']
})
export class NgSelectOptionWithCountComponent implements OnInit {
  @Input() name: string;
  @Input() count: number;
  @Input() selected: boolean;
  constructor() {}

  ngOnInit() {}
}
