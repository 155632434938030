import { createSelector } from '@ngrx/store';
import { getSegmentsState } from '../reducers/segments.reducer';
import { getSegmentModelsList } from './segment-models.selector';

export const getSegments = createSelector(
  getSegmentsState,
  data => data.segments
);
export const getSegmentsByModelName = modelName => {
  return createSelector(
    getSegments,
    getSegmentModelsList,
    (segments, segmentModels) => {
      const model = segmentModels.find(it => it.path === modelName);
      return segments.filter(it => it.model === model.id);
    }
  );
};
export const isLoadingSegments = createSelector(
  getSegmentsState,
  data => data.isLoading
);
export const isUpdating = createSelector(
  getSegmentsState,
  data => data.updating
);
export const getCurrentSegment = createSelector(
  getSegmentsState,
  data => data.currentSegment
);
export const isCurrentSegmentLoading = createSelector(
  getSegmentsState,
  data => data.currentSegmentLoading
);
export const getTotalSegmentsCount = createSelector(
  getSegmentsState,
  data => data.totalSegmentsCount
);
export const isFirstLoading = createSelector(
  getSegmentsState,
  data => data.isFirstLoading
);
export const segmentErrors = createSelector(
  getSegmentsState,
  data => data.segmentErrors
);
export const getSegmentsFilter = createSelector(
  getSegmentsState,
  data => data.filter
);
export const isSegmentsByModel = createSelector(
  getSegmentsState,
  data => data.byModel
);

export const getSegmentFilterById = id => {
  return createSelector(
    getSegmentsState,
    data => {
      return data.currentSegment.filters.find(filter => filter.id === id);
    }
  );
};

export const getSegmentFilterByTrackId = id => {
  return createSelector(
    getSegmentsState,
    data => {
      return data.currentSegment.filters.find(filter => filter._trackId === id);
    }
  );
};
