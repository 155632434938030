import { Action } from '@ngrx/store';
import { ContactTip } from '../models/contact-tip.model';

export enum ContactTipActionTypes {
  LoadContactTips = '[ContactTip] Load ContactTips',
  LoadContactTipsSuccess = '[ContactTip] Load ContactTips Success',
  LoadContactTipsFail = '[ContactTip] Load ContactTips Fail'
}

export class LoadContactTips implements Action {
  readonly type = ContactTipActionTypes.LoadContactTips;
}

export class LoadContactTipsSuccess implements Action {
  readonly type = ContactTipActionTypes.LoadContactTipsSuccess;
  constructor(public payload: ContactTip[]) {}
}

export class LoadContactTipsFail implements Action {
  readonly type = ContactTipActionTypes.LoadContactTipsFail;
}

export type ContactTipActions =
  | LoadContactTips
  | LoadContactTipsSuccess
  | LoadContactTipsFail;
