import { createSelector } from '@ngrx/store';
import { getSegmentPropertiesState } from '../reducers/segment-properties.reducer';
import {
  getCurrentSegmentModel,
  getOutputSegmentModelsByName,
  getSegmentModelByName,
} from './segment-models.selector';
import { keyBy } from 'lodash';

const findSegmentsByModel = (segmentModel, data) => {
  return data.segmentProperties.filter((it) => it.model === segmentModel.id);
};

const findSegmentsByOutputModel = (segmentModels, data) => {
  return data.segmentProperties.filter((it) =>
    segmentModels.some((m) => m.id === it.model)
  );
};

export const getSegmentPropertiesByModel = (modelPath: string) =>
  createSelector(
    getSegmentModelByName(modelPath),
    getSegmentPropertiesState,
    findSegmentsByModel
  );

export const getSegmentPropertiesByOutputModel = (modelPath: string) =>
  createSelector(
    getOutputSegmentModelsByName(modelPath),
    getSegmentPropertiesState,
    findSegmentsByOutputModel
  );

export const getCurrentSegmentProperties = createSelector(
  getCurrentSegmentModel,
  getSegmentPropertiesState,
  findSegmentsByModel
);

export const getSegmentPropertyById = (id) =>
  createSelector(getSegmentPropertiesState, (data) =>
    data.segmentProperties.find((it) => it.id === id)
  );

export const getSegmentPropertiesMap = createSelector(
  getSegmentPropertiesState,
  (data) =>
    data.segmentProperties && data.segmentProperties.length
      ? keyBy(data.segmentProperties, 'id')
      : null
);

export const getSegmentPropertyByPath = (path) =>
  createSelector(getSegmentPropertiesState, (data) =>
    data.segmentProperties.find((it) => it.path === path)
  );

export const getSegmentPropertiesByParentId = (id) =>
  createSelector(getSegmentPropertiesState, (data) =>
    data.segmentProperties.filter((it) => it.parent === id)
  );

export const isLoadingSegmentProperties = createSelector(
  getSegmentPropertiesState,
  (data) => data.isLoading
);
