<div class="wrap">
  <app-form-status (revertForm)="revertChanges()"></app-form-status>
  <div class="panel mt-0">
    <div class="form-header">
      <h3>{{ 'Edit user' | translate }}</h3>
      <a class="link-icon btn-toolbar-action phone"
         (click)="callToCustomer()">
        <i class="material-icons">phone</i>
      </a>
    </div>
    <app-customer-form
      [isEdit]="true"
      [customer]="customer$ | async"
      (onSumbitted)="save($event)"
    ></app-customer-form>
  </div>
  <router-outlet></router-outlet>
</div>
