import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Dictionary, UpdateDictionaryData} from '../../models/dictionary';

@Injectable({
  providedIn: 'root'
})
export class DictionariesService {
  private basePath = 'dictionaries/';

  constructor(private http: HttpClient) { }

  all(): Observable<Dictionary[]> {
    return this.http.get<Dictionary[]>(this.basePath);
  }

  one(id: string): Observable<Dictionary> {
    return this.http.get<Dictionary>(this.basePath + id + '/');
  }

  create(data: UpdateDictionaryData): Observable<Dictionary> {
    return this.http.post<Dictionary>(this.basePath, data);
  }

  update(id: string , data: UpdateDictionaryData) {
    return this.http.patch<Dictionary>(this.basePath + id + '/', data);
  }
}
