import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isDate, isEmpty, pickBy } from 'lodash';
import { Observable } from 'rxjs';
import { PageSettings } from '../../../shared/helpers/calculate-page';
import { PagebleEntity } from '../../../shared/models/pageble-entity';
import {
  Call,
  CallActionReason,
  CallDirection,
  CallsFilter,
  CallSubject,
  PartialCall
} from '../../models/calls.model';

@Injectable({
  providedIn: 'root'
})
export class CallService {
  basePath = 'calls/';

  constructor(private http: HttpClient) {}

  one(id: string): Observable<Call> {
    return this.http.get<Call>(`${this.basePath}${id}/`);
  }

  all(
    filter: CallsFilter,
    params: PageSettings
  ): Observable<PagebleEntity<Call>> {
    const notEmptyFilters = pickBy(
      filter,
      value => isDate(value) || !isEmpty(value)
    );
    const paramsAndFilter = {
      ...params,
      ...notEmptyFilters
    };
    return this.http.get<PagebleEntity<Call>>(this.basePath, {
      params: paramsAndFilter as any
    });
  }

  update(id: string, params: PartialCall): Observable<Call> {
    return this.http.patch<Call>(`${this.basePath}${id}/`, params);
  }

  finishCall(id: string, params): Observable<Call> {
    return this.http.post<Call>(`${this.basePath}${id}/finish/`, params);
  }

  holdCall(id: string): Observable<Call> {
    return this.http.post<Call>(`${this.basePath}${id}/hold/`, {});
  }

  resumeCall(id: string): Observable<Call> {
    return this.http.post<Call>(`${this.basePath}${id}/resume/`, {});
  }

  transferCall(id: string, params): Observable<Call> {
    return this.http.post<Call>(`${this.basePath}${id}/transfer/`, params);
  }

  getSubjects(): Observable<CallDirection[]> {
    return this.http.get<CallDirection[]>(`contact-subjects/`);
  }

  getDirections(): Observable<CallSubject[]> {
    return this.http.get<CallSubject[]>(`contact-directions/`);
  }

  getActionReasons(): Observable<CallActionReason[]> {
    return this.http.get<CallActionReason[]>(`call-action-reasons/`);
  }

  setActionReason(id: string, params): Observable<any> {
    return this.http.post(`${this.basePath}${id}/call_reason/`, params);
  }

  callToCustomer(customerId: string): Observable<any> {
    return this.http.post(`${this.basePath}call_customer/`, {
      customer: customerId
    });
  }

  callToOrderRecipient(orderId: string): Observable<any> {
    return this.http.post(`${this.basePath}call_order_by_sip/`, {
      order: orderId
    });
  }
}
