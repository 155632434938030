import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  SEGMENT_MODELS_LOAD,
  SegmentModelsLoadFail,
  SegmentModelsLoadSuccess
} from '../actions/segment-models.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SegmentModelsService } from '../../service/segment-models/segment-models.service';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class SegmentModelsEffect {
  constructor(
    private action$: Actions,
    private segmentModelsService: SegmentModelsService
  ) {}
  @Effect()
  loadSegmentModels$ = this.action$.pipe(
    ofType(SEGMENT_MODELS_LOAD),
    switchMap(() => {
      return this.segmentModelsService.all().pipe(
        map(data => new SegmentModelsLoadSuccess(data)),
        catchError(() => of(new SegmentModelsLoadFail()))
      );
    })
  );
}
