import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmployeeState } from './employee.reducer';

export const getEmployeeState = createFeatureSelector('employee');

export const getEmployeeActionTypes = createSelector(
  getEmployeeState,
  (state: EmployeeState) => state.actionTypes
);

export const getEmployees = createSelector(getEmployeeState, (state: EmployeeState) => state.employees);
export const getEmployeesData = createSelector(getEmployeeState, (state: EmployeeState) => ({
  data: state.employees,
  totalCount: state.employeesCount
}));
export const isLoadingEmployees = createSelector(getEmployeeState, (state: EmployeeState) => state.isLoadingEmployees);
export const isReloadingEmployees = createSelector(getEmployeeState, (state: EmployeeState) => state.isReload);
export const getEmployeesCount = createSelector(getEmployeeState, (state: EmployeeState) => state.employeesCount);
export const getEmployeesFilter = createSelector(getEmployeeState, (state: EmployeeState) => state.employeesFilter);

