<app-model-sub-object-value *ngIf="inputs.type === ControlTypes.Object"
                          [property]="inputs.property"
                          [formControl]="control"
                          [submitted]="inputs.submitted"
                          [modelName]="inputs.modelName"></app-model-sub-object-value>
<app-model-scalar-field *ngIf="inputs.type === ControlTypes.Scalar"
                          [property]="inputs.property"
                          [formControl]="control"
                          [submitted]="inputs.submitted"></app-model-scalar-field>
<app-model-array-value *ngIf="inputs.type === ControlTypes.Array"
                         [property]="inputs.property"
                         [formControl]="control"
                         [submitted]="inputs.submitted"
                         [modelName]="inputs.modelName"></app-model-array-value>
