import { Observable } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { IncomingSocketMessage, RECEIVE_MESSAGE, ReceiveMessage } from '../../../../store/actions/socket.action';
import { filter, map } from 'rxjs/operators';

export function ofMessage(...types: string[]) {
  return (source: Observable<any>) => source.pipe(
    ofType(RECEIVE_MESSAGE),
    filter((action: ReceiveMessage<IncomingSocketMessage>) => types.includes(action.payload.type)),
    map((action: ReceiveMessage) => action.payload)
  );
}
