import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppState } from '../../../../store/reducers/index';
import { Store, select } from '@ngrx/store';
import {
  catchError,
  map,
  mapTo,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {
  calculatePage,
  OutputPagebleAction
} from '../../../shared/helpers/calculate-page';
import { PagebleEntity } from '../../../shared/models/pageble-entity';
import { appendOrSetCollection } from '../../../shared/helpers/append-or-set-collection';
import { of } from 'rxjs';
import { FormStatusLoad, FormStatusLoadFail, FormStatusLoadSuccess } from '../../../shared/store/actions/form-status.actions';
import { CustomersService } from '../../services/customers.service';
import {
  CUSTOMERS_LOAD,
  CustomersLoad,
  CustomersLoadFail,
  CustomersLoadSuccess,
  CUSTOMER_LOAD,
  CustomerLoad,
  CustomerLoadSuccess,
  CustomerLoadFail,
  CUSTOMER_CREATE,
  CustomerCreate,
  CustomerCreateSuccess,
  CustomerCreateFail,
  CUSTOMER_CREATE_SUCCESS,
  CUSTOMER_UPDATE,
  CustomerUpdate,
  CustomerUpdateSuccess,
  CustomerUpdateFail,
  CUSTOMER_DELETE,
  CustomerDelete,
  CustomerDeleteSuccess,
  CustomerDeleteFail,
  CUSTOMERS_SET_FILTER,
  CUSTOMERS_LOAD_DEBOUNCED,
  CustomersLoadDebounced
} from './customer.actions';
import { getCustomers, getCustomersFilter, isLoadingCustomers } from './customer.selectors';
import { Customer, CustomersFilter } from '../../models/customer';
import { ErrorHandlingService } from '../../../shared/services/error-handling/error-handling.service';
import { HttpErrorResponse } from '@angular/common/http';
import { omit } from 'lodash';
import { Router } from '@angular/router';
import { NoopAction } from 'src/app/modules/shared/store/actions/user.action';

@Injectable()
export class CustomerEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private customersService: CustomersService,
    private errorHandler: ErrorHandlingService,
    private router: Router
  ) {}

  @Effect()
  loadCustomers$ = this.actions$.pipe(
    ofType(CUSTOMERS_LOAD),
    withLatestFrom(this.store.pipe(select(getCustomers))),
    map(([action, customers]: [CustomersLoad, Customer[]]) =>
      calculatePage(action, customers.length)
    ),
    withLatestFrom(this.store.pipe(select(getCustomersFilter))),
    switchMap(
      ([action, filter]: [
        OutputPagebleAction<CustomersLoad>,
        CustomersFilter
      ]) => {
        return this.customersService.all(action.pageSettings, filter).pipe(
          withLatestFrom(this.store.pipe(select(getCustomers))),
          map(
            ([data, currentCustomers]: [
              PagebleEntity<Customer>,
              Customer[]
            ]) => {
              return new CustomersLoadSuccess(
                appendOrSetCollection(action, data, currentCustomers)
              );
            }
          ),
          catchError((error: HttpErrorResponse) => {
            this.errorHandler.handle(error.error);
            return of(new CustomersLoadFail());
          })
        );
      }
    )
  );

  @Effect()
  loadCustomerDebounced$ = this.actions$.pipe(
    ofType(CUSTOMERS_LOAD_DEBOUNCED),
    withLatestFrom(this.store.pipe(select(isLoadingCustomers))),
    map(([action, isLoading]: [CustomersLoadDebounced, boolean]) => {
      if (isLoading) {
        return new NoopAction();
      }
      return new CustomersLoad(action.payload);
    })
  );

  @Effect()
  loadCustomer$ = this.actions$.pipe(
    ofType(CUSTOMER_LOAD),
    switchMap((action: CustomerLoad) => {
      return this.customersService.one(action.payload).pipe(
        map(data => new CustomerLoadSuccess(data)),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handle(error.error);
          return of(new CustomerLoadFail());
        })
      );
    })
  );

  @Effect()
  createCustomer$ = this.actions$.pipe(
    ofType(CUSTOMER_CREATE),
    switchMap((action: CustomerCreate) => {
      return this.customersService.create(action.payload).pipe(
        map(data => new CustomerCreateSuccess(data)),
        catchError((error: HttpErrorResponse) => {
          return of(
            new CustomerCreateFail(this.errorHandler.handle(error.error))
          );
        })
      );
    })
  );

  @Effect({ dispatch: false })
  afterCreateCustomer$ = this.actions$.pipe(
    ofType(CUSTOMER_CREATE_SUCCESS),
    tap((action: CustomerCreateSuccess) =>
      this.router.navigate(['customers', action.payload.id])
    )
  );

  @Effect()
  updateCustomer$ = this.actions$.pipe(
    ofType(CUSTOMER_UPDATE),
    switchMap((action: CustomerUpdate) => {
      this.store.dispatch(new FormStatusLoad());
      return this.customersService
        .update(action.payload.id, omit(action.payload, 'id'))
        .pipe(
          map(data => {this.store.dispatch(new FormStatusLoadSuccess()); return new CustomerUpdateSuccess(data); }),
          catchError((error: HttpErrorResponse) => {
            this.store.dispatch(new FormStatusLoadFail());
            return of(
              new CustomerUpdateFail(this.errorHandler.handle(error.error))
            );
          })
        );
    })
  );

  @Effect()
  deleteCustomer$ = this.actions$.pipe(
    ofType(CUSTOMER_DELETE),
    switchMap((action: CustomerDelete) => {
      return this.customersService.delete(action.payload).pipe(
        switchMap(data => [new CustomerDeleteSuccess()]),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handle(error.error);
          return of(new CustomerDeleteFail());
        })
      );
    })
  );

  @Effect()
  setFilter = this.actions$.pipe(
    ofType(CUSTOMERS_SET_FILTER),
    mapTo(new CustomersLoad({ isReload: true }))
  );
}
