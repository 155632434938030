import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorHandlingService } from '../../../shared/services/error-handling/error-handling.service';
import { SegmentGroupsService } from '../../service/segment-groups/segment-groups.service';
import { SEGMENTS_GROUPS_LOAD, SegmentsGroupsLoadFail, SegmentsGroupsLoadSuccess } from '../actions/segment-groups.action';


@Injectable()
export class SegmentGroupsEffects {

  constructor(
    private actions$: Actions,
    private segmentGroupsService: SegmentGroupsService,
    private errorHandlingService: ErrorHandlingService
  ) {
  }

  @Effect()
  loadAll$ = this.actions$.pipe(
    ofType(SEGMENTS_GROUPS_LOAD),
    switchMap(() => {
      return this.segmentGroupsService.all().pipe(
        map(data => new SegmentsGroupsLoadSuccess(data)),
        catchError((error: HttpErrorResponse) => {
          // this.errorHandlingService.handle(error.error);
          return of(new SegmentsGroupsLoadFail());
        })
      );
    })
  );
}
