import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isDate, isNil, pickBy } from 'lodash';
import { Observable } from 'rxjs';
import { PageSettings } from '../../../shared/helpers/calculate-page';
import { PagebleEntity } from '../../../shared/models/pageble-entity';
import { FullOrder, PartialOrder } from '../../models/full-order';
import { Order } from '../../models/order';
import { OrderFilter } from '../../models/order-filter';
import {
  OrderActionReason,
  OrderActionPayload,
} from '../../models/order-action-reason.model';
import { StateAction } from '../../models/state-action';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  basePath = 'orders/';

  constructor(private http: HttpClient) {}

  all(
    params: PageSettings,
    filter: OrderFilter
  ): Observable<PagebleEntity<Order>> {
    const notEmptyFilters = pickBy(
      filter,
      (value) => isDate(value) || !isNil(value)
    );
    const newParams = {
      ...params,
      ...notEmptyFilters,
    };
    return this.http.get<PagebleEntity<Order>>(this.basePath, {
      params: newParams as any,
    });
  }

  oneFiltered(
    filter: OrderFilter,
    id: string
  ): Observable<PagebleEntity<Order>> {
    const notEmptyFilters = pickBy(
      filter,
      (value) => isDate(value) || !isNil(value)
    );
    const newParams = {
      ...notEmptyFilters,
      id,
    };
    return this.http.get<PagebleEntity<Order>>(this.basePath, {
      params: newParams as any,
    });
  }

  one(id: string): Observable<FullOrder> {
    return this.http.get<FullOrder>(`${this.basePath}${id}/`);
  }

  deliver(id: string): Observable<FullOrder> {
    return this.http.post<FullOrder>(`${this.basePath}${id}/deliver/`, [{}]);
  }

  pay(id: string): Observable<FullOrder> {
    return this.http.post<FullOrder>(`${this.basePath}${id}/pay/`, [{}]);
  }

  cancel(id: string, payload: OrderActionPayload): Observable<FullOrder> {
    return this.http.post<FullOrder>(`${this.basePath}${id}/cancel/`, payload);
  }

  addComment(id: string, payload: OrderActionPayload): Observable<FullOrder> {
    return this.http.post<FullOrder>(`${this.basePath}${id}/comment/`, payload);
  }

  update(id: string, order: PartialOrder): Observable<FullOrder> {
    return this.http.patch<FullOrder>(`${this.basePath}${id}/`, order);
  }

  getActionReasons(): Observable<OrderActionReason[]> {
    return this.http.get<OrderActionReason[]>(`order-action-reasons/`);
  }

  returnOrderItem(id: string, params?: { barcode?: string }): Observable<any> {
    return this.http.post(`order-items/${id}/return/`, params);
  }

  getReclamationFormSuggestions(orderItem): Observable<any> {
    return this.http.get(`order-items/${orderItem}/reclamation-form/`);
  }

  getBanks(query): Observable<any> {
    return this.http.get('bank/suggest/', { params: { query } });
  }

  stateActions(
    id: string,
    params: PageSettings,
    filter: OrderFilter
  ): Observable<PagebleEntity<StateAction>> {
    const notEmptyFilters = pickBy(
      filter,
      (value) => isDate(value) || !isNil(value)
    );
    const newParams = {
      ...params,
      ...notEmptyFilters,
    };
    return this.http.get<PagebleEntity<StateAction>>(`${this.basePath}${id}/state_actions/`, {
      params: newParams as any,
    });
  }
}
