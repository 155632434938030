import { SegmentsReducer, SegmentsState } from './segments.reducer';
import { SegmentModelsReducer, SegmentModelsState } from './segment-models.reducer';
import { SegmentPropertiesReducer, SegmentPropertiesState } from './segment-properties.reducer';
import { ActionReducerMap } from '@ngrx/store';
import {SegmentGroup} from '../../models/segment-group';
import {SegmentGroupsReducer, SegmentGroupsState} from './segment-groups.reducer';

export interface SegmentsFeatureState {
  segmentModels: SegmentModelsState;
  segmentProperties: SegmentPropertiesState;
  segments: SegmentsState;
  segmentGroups: SegmentGroupsState;
}

export const reducers: ActionReducerMap<SegmentsFeatureState> = {
  segmentModels: SegmentModelsReducer,
  segmentProperties: SegmentPropertiesReducer,
  segments: SegmentsReducer,
  segmentGroups: SegmentGroupsReducer
};
