<div class="wrap">
  <!--
    <div class="d-flex">
      <app-customers-finder [formControl]="customer"></app-customers-finder>
      <button
        type="button"
        class="btn btn-primary"
        (click)="createCall()"
        [disabled]="!this.customer.value"
      >
        {{ 'Create' | translate }}
      </button>
    </div>
  -->
  <div class="operator-state" *ngIf="(employee$ | async) as employee">
    <ng-container *ngIf="employee.state === 'OFFLINE'">
      <h3>
        <span
          class="status status-danger status-lg d-inline-flex align-middle"
        ></span>
        {{ 'You are offline' | translate }}
      </h3>
    </ng-container>
    <ng-container *ngIf="employee.state === 'ONLINE'">
      <h3>
        <span
          class="status status-success status-lg d-inline-flex align-middle"
        ></span>
        {{ 'You are ready to accept calls' | translate }}
      </h3>

      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="becomeIdle()"
      >
        {{ 'Take a break' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="employee.state === 'IDLE'">
      <h3>
        <span
          class="status status-progress status-lg d-inline-flex align-middle"
        ></span>
        {{ 'You are on a break' | translate }}
      </h3>

      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="becomeOnline(employee.id)"
      >
        {{ 'Get back to work' | translate }}
      </button>
    </ng-container>
  </div>
</div>

<ng-template #employeeStateChange>
  <app-employee-state-change
    [employeeId]="(employee$ | async)?.id"
    [modalRef]="modalRef"
  ></app-employee-state-change>
</ng-template>
