import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { _ } from '../../shared/helpers/translation-marker';
import { CustomersComponent } from '../pages/customers/customers.component';
import { LoadCustomersGuard } from './guards/load-customers.guard';
import { CustomerUpdateComponent } from '../pages/customer-update/customer-update.component';
import { LoadCustomerGuard } from './guards/load-customer.guard';

export const routes: Routes = [
  {
    path: '',
    data: {
      crumb: {
        name: _('Customers')
      }
    },
    children: [
      {
        path: '',
        component: CustomersComponent,
        canActivate: [LoadCustomersGuard],
      },
      {
        path: ':id',
        component: CustomerUpdateComponent,
        canActivate: [LoadCustomerGuard],
        loadChildren:
          () => import('../modules/customers-extra/customers-extra.module').then(m => m.CustomersExtraModule)
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  declarations: [],
  exports: [RouterModule]
})
export class CustomersRoutingModule {}
