import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentStatePipe } from './pipes/payment-state/payment-state.pipe';
import { PaymentMethodPipe } from './pipes/payment-method/payment-method.pipe';
import { PaymentHistoryComponent } from './components/payment-history/payment-history.component';
import { PaymentDetailsComponent } from './components/payment-details/payment-details.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PaymentEffects } from './store/payment.effects';
import * as fromPayment from './store/payment.reducer';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    PaymentStatePipe,
    // PaymentTypePipe, // moved to SharedModule
    PaymentMethodPipe,
    PaymentHistoryComponent,
    PaymentDetailsComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    StoreModule.forFeature('payment', fromPayment.paymentReducer),
    EffectsModule.forFeature([PaymentEffects]),
  ],
  exports: [
    PaymentStatePipe,
    // PaymentTypePipe, // moved to SharedModule
    PaymentMethodPipe,
    PaymentHistoryComponent,
    PaymentDetailsComponent,
  ],
})
export class PaymentsSharedModule {}
