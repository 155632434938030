import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../store/reducers';
import { DATE_FORMAT } from '../../../shared/config/data-formats.config';
import { CallsHistorySetFilter } from '../../store/call.actions';
import * as moment from 'moment';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss']
})
export class DateSelectorComponent {
  maxDate = new Date();
  callDate: FormControl = new FormControl();
  subscription = new Subject();

  constructor(private store: Store<AppState>) {

    this.callDate.valueChanges.pipe(
      takeUntil(this.subscription),
      debounceTime(1000)
    ).subscribe((f) => {
      this.store.dispatch(new CallsHistorySetFilter({
        created_date: f ? moment(f).format(DATE_FORMAT).toString() : undefined,
      }));
    });
  }

}
