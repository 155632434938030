import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { CustomersClear, CustomersSetFilter } from '../../store/customer/customer.actions';
import { AppState } from '../../../../store/reducers';
import { isEmpty } from 'lodash';
import { map } from 'rxjs/operators';
import { getCustomersFilter } from '../../store/customer/customer.selectors';

@Injectable({
  providedIn: 'root'
})
export class LoadCustomersGuard implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (isEmpty(next.queryParams)) {
      this.store.dispatch(new CustomersClear());
      return true;
    }

    return this.store.pipe(
      select(getCustomersFilter),
      map(filters => {
        const newFilters = { ...filters, ...next.queryParams };

        this.store.dispatch(
          new CustomersSetFilter(
            { ...newFilters },
          )
        );

        next.queryParams = { ...newFilters };
        return true;
      })
    );
  }
}
