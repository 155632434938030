export const segmentCreatedMessageType = 'SEGMENT_CREATED';
export const segmentUpdatedMessageType = 'SEGMENT_UPDATED';

export interface SegmentUpdatedMessage {
  type: typeof segmentCreatedMessageType | typeof segmentUpdatedMessageType;
  data: {
    model: string,
    id: string,
    count?: number
  };
}
