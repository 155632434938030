import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { EmployeeActionType } from '../../models/employee-action-types.model';
import { Observable } from 'rxjs';
import { getEmployeeActionTypes } from '../../store/employee.selector';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmployeesService } from '../../services/employees/employees.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-employee-state-change',
  templateUrl: './employee-state-change.component.html',
  styleUrls: ['./employee-state-change.component.scss']
})
export class EmployeeStateChangeComponent implements OnInit {
  @Input() employeeId: string;
  @Input() modalRef: BsModalRef;
  form: FormGroup;
  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private employeeService: EmployeesService
  ) {
    this.form = this.fb.group({
      action_type: [null, Validators.required],
      state: ['IDLE', Validators.required],
      comment: ['']
    });
  }
  actionTypes$: Observable<EmployeeActionType[]>;

  ngOnInit() {
    this.actionTypes$ = this.store.pipe(select(getEmployeeActionTypes));
  }

  submit() {
    if (this.form.valid) {
      this.employeeService
        .changeState(this.employeeId, this.form.value)
        .pipe(take(1))
        .subscribe(resp => {
          this.modalRef.hide();
        });
    }
  }

  cancel() {
    this.modalRef.hide();
  }
}
