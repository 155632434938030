import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PageSettings } from '../../../shared/helpers/calculate-page';
import { Observable } from 'rxjs';
import { PagebleEntity } from '../../../shared/models/pageble-entity';
import {
  DictionaryItem,
  UpdateDictionaryItemData
} from '../../models/dictionary-item';

@Injectable({
  providedIn: 'root'
})
export class DictionaryItemsService {
  basePath = 'dictionary-items/';
  constructor(private http: HttpClient) {}

  all(dictionary: string): Observable<DictionaryItem[]> {
    const newParams: any = {
      dictionary
    };
    return this.http.get<DictionaryItem[]>(this.basePath, {
      params: newParams
    });
  }

  one(id: string): Observable<DictionaryItem> {
    return this.http.get<DictionaryItem>(`${this.basePath}${id}/`);
  }

  create(data: UpdateDictionaryItemData): Observable<DictionaryItem> {
    return this.http.post<DictionaryItem>(this.basePath, data);
  }

  update(
    id: string,
    data: UpdateDictionaryItemData
  ): Observable<DictionaryItem> {
    return this.http.patch<DictionaryItem>(`${this.basePath}${id}/`, data);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${this.basePath}${id}/`);
  }
}
