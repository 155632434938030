import { Action } from '@ngrx/store';
import {
  ContactTipActions,
  ContactTipActionTypes
} from './contact-tip.actions';
import { ContactTip } from '../models/contact-tip.model';

export interface ContactTipState {
  contactTips: ContactTip[];
}

export const initialState: ContactTipState = { contactTips: [] };

export function reducer(
  state = initialState,
  action: ContactTipActions
): ContactTipState {
  switch (action.type) {
    case ContactTipActionTypes.LoadContactTipsSuccess:
      return { ...state, contactTips: action.payload };
    default:
      return state;
  }
}
