import { SegmentModel } from '../../models/segment-model';
import {
  SEGMENT_MODELS_LOAD,
  SEGMENT_MODELS_LOAD_FAIL,
  SEGMENT_MODELS_LOAD_SUCCESS,
  SegmentModelsAction, SET_SEGMENT_MODEL
} from '../actions/segment-models.action';
import { createSelector } from '@ngrx/store';
import { getSegmentsFeatureState } from '../selectors';

export interface SegmentModelsState {
  segmentModels: SegmentModel[];
  segmentModelLabel: string;
  isLoading: boolean;
}

export const initialState: SegmentModelsState = {
  isLoading: false,
  segmentModels: [],
  segmentModelLabel: null
};

export function SegmentModelsReducer(state = initialState, action: SegmentModelsAction): SegmentModelsState {
  switch (action.type) {
    case SEGMENT_MODELS_LOAD:
      return { ...state, isLoading: true };
    case SEGMENT_MODELS_LOAD_SUCCESS:
      // @TODO: replace with backend generated 'path'
      const segmentModels = action.payload.map(model => ({...model, path: model.model_name.toLowerCase()}));
      return { ...state, isLoading: false, segmentModels };
    case SEGMENT_MODELS_LOAD_FAIL:
      return { ...state, isLoading: false };
    case SET_SEGMENT_MODEL:
      return { ...state, segmentModelLabel: action.payload };
    default:
      return state;
  }
}

export const getSegmentModels = createSelector(getSegmentsFeatureState, data => data.segmentModels);
