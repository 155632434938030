import { ConfirmDeliveryDialogComponent } from './components/confirm-delivery-dialog/confirm-delivery-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveriesListComponent } from './components/deliveries-list/deliveries-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store/reducers';
import { effects } from './store/effects';
import { RescheduleDialogComponent } from './components/reschedule-dialog/reschedule-dialog.component';
import { SharedModule } from '../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { OrderDeliveryShortComponent } from './components/order-delivery-short/order-delivery-short.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CancelDeliveryDialogComponent } from './components/cancel-delivery-dialog/cancel-delivery-dialog.component';
import { RejectDeliveryDialogComponent } from './components/reject-delivery-dialog/reject-delivery-dialog.component';
import { StateHistoryDialogComponent } from './components/state-history-dialog/state-history-dialog.component';
import { RouterModule } from '@angular/router';
import { DeliveryTrackingComponent } from './components/delivery-tracking/delivery-tracking.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { OrderItemStatePipe } from './pipes/order-item-state/order-item-state.pipe';

@NgModule({
  declarations: [
    DeliveriesListComponent,
    RescheduleDialogComponent,
    ConfirmDeliveryDialogComponent,
    CancelDeliveryDialogComponent,
    RejectDeliveryDialogComponent,
    OrderDeliveryShortComponent,
    StateHistoryDialogComponent,
    DeliveryTrackingComponent,
    OrderItemStatePipe,
  ],
  imports: [
    RouterModule,
    StoreModule.forFeature('couriers', reducers),
    EffectsModule.forFeature(effects),
    CommonModule,
    TranslateModule,
    SharedModule,
    ReactiveFormsModule,
    TooltipModule,
    InfiniteScrollModule,
    PerfectScrollbarModule,
    PopoverModule,
    BsDropdownModule.forRoot(),
  ],
  exports: [
    DeliveriesListComponent,
    RescheduleDialogComponent,
    ConfirmDeliveryDialogComponent,
    CancelDeliveryDialogComponent,
    RejectDeliveryDialogComponent,
    OrderDeliveryShortComponent,
    StateHistoryDialogComponent,
    DeliveryTrackingComponent,
    OrderItemStatePipe,
  ],
})
export class CouriersSharedModule {}
