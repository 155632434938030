import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  Injector,
  forwardRef,
  Input
} from '@angular/core';
import { PHONE_MASK } from '../../config/data-formats.config';
import {
  ControlValueAccessor,
  NgControl,
  FormControl,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  AbstractControl
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { _ } from '../../helpers/translation-marker';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true
    }
  ]
})
export class PhoneInputComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  @Input()
  placeholder = '';
  @Input()
  customClass = '';
  @Input() readonly = false;
  phoneMask = PHONE_MASK;
  currentValue: FormControl = new FormControl();
  currentValueSubscription: Subscription;
  hostClass: string;
  statusChangeSubscription: Subscription;
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private element: ElementRef, private injector: Injector) {
    this.currentValueSubscription = this.currentValue.valueChanges.subscribe(
      v => this.onChanged(v)
    );
  }

  ngOnInit() {
    // setTimeout(() => {
    //   this.statusChangeSubscription = this.injector
    //     .get(NgControl)
    //     .control.statusChanges.subscribe(() => {
    //       this.updateClasses();
    //     });
    // });
  }

  writeValue(obj: string): void {
    this.currentValue.setValue(obj ? obj.substring(1) : '');
  }

  private updateClasses() {
    setTimeout(() => {
      this.hostClass = this.element.nativeElement.className;
    }, 0);
  }

  onBlur() {
    if (this.onTouched) {
      this.onTouched();
      // this.updateClasses();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  onChanged(value: string) {
    if (this.onChange) {
      const clean = value.replace(/\D+/g, '');
      this.onChange(clean.length ? '7' + clean : '');
    }

    if (this.onTouched) {
      this.onTouched();
    }
  }

  ngOnDestroy(): void {
    if (this.statusChangeSubscription) {
      this.statusChangeSubscription.unsubscribe();
    }

    this.currentValueSubscription.unsubscribe();
  }

  validate(control: AbstractControl) {
    if (control.value.length === 0) {
      return;
    }

    if (control.value.length < 11 || !/^\d+$/.test(control.value)) {
      console.log(control.value);
      return { invalidPhone: _('Invalid phone number') };
    }
  }
}
