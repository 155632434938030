import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/reducers';
import {
  getSegments, getSegmentsByModelName,
  getTotalSegmentsCount,
  isFirstLoading,
  isLoadingSegments,
  isUpdating
} from '../../store/selectors/segments.selector';
import { getCurrentSegmentModel, getCurrentSegmentModelLabel } from '../../store/selectors/segment-models.selector';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { skip, startWith, switchMap, take } from 'rxjs/operators';
import {
  SetCrumb,
  SetPageTitle
} from '../../../../store/actions/router.action';
import { SegmentsLoad } from '../../store/actions/segments.action';
import { FullSegment } from '../../models/full-segment';
import { _ } from '../../../shared/helpers/translation-marker';
import { TranslateService } from '@ngx-translate/core';
import { SegmentModelsName } from '../../models/segment';
import { SegmentModel } from '../../models/segment-model';

@Component({
  selector: 'app-segments',
  templateUrl: './segments.component.html',
  styleUrls: ['./segments.component.scss']
})
export class SegmentsComponent implements OnInit, OnDestroy {
  @ViewChild('infiniteScrollContainer', { static: true })
  public scrollContainer: ElementRef;
  private _scrollableElement: ElementRef;
  @ViewChild('infiniteScrollableElement')
  public set scrollableElement(element: ElementRef) {
    this._scrollableElement = element;
    this.getMoreDataIfNeeded();
  }
  public get scrollableElement(): ElementRef {
    return this._scrollableElement;
  }

  isFirstLoading$: Observable<boolean>;
  updating$: Observable<boolean>;
  isLoading: boolean;
  currentSegmentModel: string;
  totalCount: number;
  segments: FullSegment[] = [];

  subscriptions: Subscription;
  showCreateNewButton = true;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.isFirstLoading$ = this.store.select(isFirstLoading);
    this.updating$ = this.store.select(isUpdating);
      this.store
        .pipe(select(getCurrentSegmentModel))
        .subscribe((model) => {
          this.currentSegmentModel = model.path;
          this.store.dispatch(new SetCrumb('SEGMENTS_MODEL', { name: this.translateService.instant(SegmentModelsName[model.path])}));
          this.store.dispatch(
            new SetPageTitle(
              'SEGMENTS_MODEL',
              this.translateService.instant(SegmentModelsName[model.path])
            )
          );
        });

    this.subscriptions = route.url.subscribe(() => {
        if (route.firstChild) {
          route.firstChild.url
            .pipe(take(1))
            .subscribe((value: UrlSegment[]) => {
              this.showCreateNewButton = !(value[0] && value[0].path);
            });
        } else {
          this.showCreateNewButton = true;
        }
      });

    this.subscriptions.add(
      this.route.url.pipe(skip(1)).subscribe(() => {
        this.store.dispatch(new SegmentsLoad());
      })
    );

    this.subscriptions.add(
      this.store
        .select(isLoadingSegments)
        .subscribe(data => (this.isLoading = data))
    );

    this.subscriptions.add(
      this.store
        .select(getTotalSegmentsCount)
        .subscribe(data => (this.totalCount = data))
    );

    this.subscriptions.add(
      this.store.pipe(
        select(getCurrentSegmentModelLabel),
        switchMap((model) => this.store.pipe(select(getSegmentsByModelName(model))))
      )
      .subscribe(data => {
        this.segments = data;
      })
    );
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getMoreDataIfNeeded() {
    if (this.scrollContainer !== undefined && this.scrollableElement !== undefined &&
      this.scrollContainer.nativeElement.clientHeight >= this.scrollableElement.nativeElement.scrollHeight) {
      this.onScroll();
    }
  }

  onScroll() {
    if (this.isLoading || !this.segments) {
      return;
    }

    if (this.totalCount > this.segments.length) {
      this.isLoading = true;
      this.store.dispatch(new SegmentsLoad({ isReload: false }));
    }
  }

  onRowClick(segment: FullSegment) {
    this.router.navigate(['/segments', this.currentSegmentModel, segment.id]);
  }

  createSegment() {
    this.router.navigate(['/segments', this.currentSegmentModel, 'new']);
  }
}
