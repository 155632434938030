import { AbstractControl, ValidatorFn } from "@angular/forms";
import * as moment from 'moment';
import { Moment } from "moment";

export function rangeValidator(minFieldName: string, maxFieldName: string): ValidatorFn {
  return (group: AbstractControl): any => {
    const min = group.get(minFieldName);
    const max = group.get(maxFieldName);
    if (min.disabled || !min.value || isNaN(+min.value)
      || max.disabled || !max.value || isNaN(+max.value)) {
      return null;
    }

    return +min.value > +max.value
      ? { rangeInvalid: true }
      : null;
  };
}

function dateTimeToMoment(date: string, time: string): Moment {
  if (!date || !time || /_/.test(time)) {
    return null;
  }

  const m = moment(date);
  const [ hours, minutes ] = time.split(':');
  m.hour(+hours);
  m.minute(+minutes);
  m.second(0);
  return m;
}

export function rangeDateTimeValidator(startDateName: string, startTimeName: string, endDateName: string, endTimeName: string): ValidatorFn {
  return (group: AbstractControl): any => {
    const startMoment = dateTimeToMoment(
      group.get(startDateName).value,
      group.get(startTimeName).value,
    );
    const endMoment = dateTimeToMoment(
      group.get(endDateName).value,
      group.get(endTimeName).value,
    );

    if (!startMoment || !endMoment) {
      return null;
    }

    return endMoment.diff(startMoment) > 0
      ? null
      : {
        dateTimeRangeInvalid: true
      };
  };
}
