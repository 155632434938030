<ng-container *ngIf="(call$ | async) as call; else noCall">
  <div class="wrap">
    <app-incoming-call #incomingCall [call]="call"></app-incoming-call>
    <ng-container *ngIf="!call.customer">
      <app-customers-finder [formControl]="findCustomer"></app-customers-finder>
    </ng-container>
    <ng-container *ngIf="call.customer">
      <app-customer-card></app-customer-card>
      <div class="panel"><router-outlet></router-outlet></div>
    </ng-container>
  </div>
</ng-container>
<ng-template #noCall>
  <div class="text-center text-muted">No active call</div>
</ng-template>
