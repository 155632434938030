import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isDate, isNil, pickBy } from 'lodash';
import { Observable } from 'rxjs';
import {
  OrderDelivery,
  OrderDeliveryChangeDeliveryDate,
  OrderDeliveryChangeCourier,
  OrderDeliveryFilter,
  RescheduleOrderDeliveryPayload,
  OrderDeliveryActionReason,
  DeliveryTracking,
} from '../../models/order-delivery.model';
import { PagebleEntity } from 'src/app/modules/shared/models/pageble-entity';
import { PageSettings } from 'src/app/modules/shared/helpers/calculate-page';

@Injectable({
  providedIn: 'root',
})
export class OrderDeliveriesService {
  basePath = 'order-deliveries/';

  constructor(private http: HttpClient) {}

  all(
    params: PageSettings,
    filter: OrderDeliveryFilter
  ): Observable<PagebleEntity<OrderDelivery>> {
    const notEmptyFilters = pickBy(
      filter,
      (value) => isDate(value) || !isNil(value)
    );
    const newParams = { ...params, ...notEmptyFilters };
    return this.http.get<PagebleEntity<OrderDelivery>>(this.basePath, {
      params: newParams as any,
    });
  }

  one(id: string): Observable<OrderDelivery> {
    return this.http.get<OrderDelivery>(`${this.basePath}${id}/`);
  }

  getCDEKStatuses(id: string): Observable<DeliveryTracking[]> {
    return this.http.get<DeliveryTracking[]>(`${this.basePath}${id}/cdek-statuses/`);
  }

  refreshCDEKStatuses(id: string, force = false): Observable<DeliveryTracking[]> {
    return this.http.post<DeliveryTracking[]>(`${this.basePath}${id}/cdek-refresh-status/`, null, {
      params: force ? {force: true} : null,
    });
  }

  getDeliveryMethods(id: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}${id}/delivery_methods/`);
  }

  create(data: Partial<OrderDelivery>): Observable<OrderDelivery> {
    return this.http.post<OrderDelivery>(this.basePath, data);
  }

  update(id: string, data: Partial<OrderDelivery>): Observable<OrderDelivery> {
    return this.http.patch<OrderDelivery>(`${this.basePath}${id}/`, data);
  }

  setDeliveryDate(
    id: string,
    data: OrderDeliveryChangeDeliveryDate
  ): Observable<OrderDelivery> {
    return this.http.post<OrderDelivery>(
      `${this.basePath}${id}/set_delivery_date/`,
      data
    );
  }

  assignCourier(
    id: string,
    data: OrderDeliveryChangeCourier
  ): Observable<OrderDelivery> {
    return this.http.post<OrderDelivery>(`${this.basePath}${id}/assign/`, data);
  }

  confirm(
    id: string,
    payload: RescheduleOrderDeliveryPayload
  ): Observable<OrderDelivery> {
    return this.http.post<OrderDelivery>(
      `${this.basePath}${id}/confirm/`,
      payload
    );
  }

  cancel(
    id: string,
    payload: RescheduleOrderDeliveryPayload
  ): Observable<OrderDelivery> {
    return this.http.post<OrderDelivery>(
      `${this.basePath}${id}/cancel/`,
      payload
    );
  }

  reject(
    id: string,
    payload: RescheduleOrderDeliveryPayload
  ): Observable<OrderDelivery> {
    return this.http.post<OrderDelivery>(
      `${this.basePath}${id}/reject/`,
      payload
    );
  }

  reschedule(
    id: string,
    payload: RescheduleOrderDeliveryPayload
  ): Observable<OrderDelivery> {
    return this.http.post<OrderDelivery>(
      `${this.basePath}${id}/reschedule/`,
      payload
    );
  }

  getActionReasons(): Observable<OrderDeliveryActionReason[]> {
    return this.http.get<OrderDeliveryActionReason[]>(
      `order-delivery-action-reasons/`
    );
  }
}
