import { UserAction } from '../actions/user.action';
import * as loginAction from '../actions/user.action';
import { createSelector } from '@ngrx/store';
import { getSharedState } from 'src/app/modules/shared/store/selectors';
import { User } from '../../models/user';
import { Company, CURRENT_COMPANY_KEY } from '../../models/companies';

export interface UserState {
  user: User;
  companies: Company[];
  currentCompany: string;
}

export const initialState: UserState = {
  user: null,
  companies: null,
  currentCompany: localStorage.getItem(CURRENT_COMPANY_KEY)
};

export function UserReducer(
  state: UserState = initialState,
  action: UserAction
): UserState {
  switch (action.type) {
    case loginAction.LOAD_USER_SUCCESS:
      return { ...state, user: action.payload };
    case loginAction.LOAD_COMPANIES_SUCCESS:
      return { ...state, companies: action.payload };
    case loginAction.SET_CURRENT_COMPANY:
      return { ...state, currentCompany: action.id };
    default:
      return state;
  }
}

export const getUserState = createSelector(getSharedState, data => data.user);
