import { OrderState } from '../../models/order-state';

export const orderCreatedMessageType = 'ORDER_CREATED';
export const orderUpdatedMessageType = 'ORDER_UPDATED';

export interface OrderChangedMessage {
  type: typeof orderCreatedMessageType;
  data: {
    state: OrderState;
    id: string;
  };
}
