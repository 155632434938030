<div class="wrap">
  <app-form-status></app-form-status>
  <app-dictionary-form
    [isEdit]="true"
    [dictionary]="dictionary$ | async"
    [title]="'Dictionary' | translate"
    (onSubmit)="changeDictionary($event)"
  >
  </app-dictionary-form>
  <div class="panel">
    <div class="form-header">
      <h3>{{ 'Dictionary items' | translate }}</h3>
    </div>

    <div class="form-fieldset">
        <app-finder (filter)="onFilter($event)"></app-finder>
    </div>

    <div
      *ngIf="
        (dictionaryItems$ | async).length > 0 || isLoading;
        else noDictionaryItems
      "
    >
      <table class="table table-striped" *ngIf="!(isReload$ | async)">
        <thead>
          <tr>
            <th width="20%">{{ 'Name' | translate }}</th>
            <th width="30%">{{ 'Description' | translate }}</th>
            <th width="43%">{{ 'Segment' | translate }}</th>
            <th width="8%">{{ 'Sort' | translate }}</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="!(loading$ | async); else loading">
            <tr
              app-dictionary-item-row-form
              *ngFor="
                let dictionary of (dictionaryItems$ | async);
                trackBy: trackByFn
              "
              [dictionaryItem]="dictionary"
              (changeItem)="changeDictionaryItem($event)"
              (removeItem)="removeDictionaryItem($event)"
            ></tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #noDictionaryItems>
  <div class="panel d-flex align-items-center justify-content-center">
    <span>{{ 'There are no dictionary items' | translate }}</span>
  </div>
</ng-template>
<ng-template #loading> <app-loader></app-loader> </ng-template>
