import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogV1Component } from '../../components/confirmation-dialog-v1/confirmation-dialog-v1.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {

  constructor(
    private modalService: BsModalService
  ) { }

  confirm(message: string, title?: string) {
    const modalRef = this.modalService.show(ConfirmationDialogComponent, { initialState: { message, title } });
    const component: ConfirmationDialogComponent = modalRef.content;
    return component.closed;
  }

  confirmV1(message: string, title?: string, confirmButtonText?: string, declineButtonText?: string, hideCloseIcon?: boolean) {
    const data = {
      initialState: { message, title, confirmButtonText, declineButtonText, hideCloseIcon }
    };
    const modalRef = this.modalService.show(ConfirmationDialogV1Component, data);
    const component: ConfirmationDialogV1Component = modalRef.content;
    return component.closed;
  }
}
