import { Component, OnInit, forwardRef, Input, OnDestroy } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonGroupComponent),
      multi: true
    }
  ]
})
export class ButtonGroupComponent
  implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  options: any[];
  @Input()
  cancelable: any;
  currentValue: FormControl = new FormControl();
  subscription = new Subscription();
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {
    this.subscription.add(
      this.currentValue.valueChanges.subscribe(v => {
        this.onChanged(v);
      })
    );
  }

  ngOnInit() {}

  writeValue(obj): void {
    if (obj !== this.currentValue.value) {
      this.currentValue.setValue(obj);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  setValue(value) {
    if (this.cancelable !== undefined && this.currentValue.value === value) {
      this.currentValue.setValue(this.cancelable);
    } else {
      this.currentValue.setValue(value);
    }
  }

  onChanged(value) {
    if (this.onChange) {
      this.onChange(value);
    }

    if (this.onTouched) {
      this.onTouched();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
