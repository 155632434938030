import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, ReplaySubject, Subscription } from 'rxjs';
import { Segment } from '../../models/segment';
import { PagebleEntity } from '../../../shared/models/pageble-entity';
import {
  FullSegment,
  SegmentFilter,
  UpdatedSegment,
  UpdatedSegmentFilter
} from '../../models/full-segment';
import { pickBy, isEmpty } from 'lodash';
import { PageSettings } from '../../../shared/helpers/calculate-page';
import { SegmentsFilter } from '../../models/segments-filter';
@Injectable({
  providedIn: 'root'
})
export class SegmentsService {
  private basePath = 'segments/';
  private filtersPath = 'segment-filters/';
  private readonly expirationTtl = 10000; // 10 sec
  private subjects: Map<string, ReplaySubject<PagebleEntity<Segment>>> = new Map<string, ReplaySubject<PagebleEntity<Segment>>>();
  private subscriptions: Map<string, Subscription> = new Map<string, Subscription>();
  private actual: Set<string> = new Set<string>();

  constructor(private http: HttpClient) {}

  all(modelId?: string): Observable<PagebleEntity<FullSegment>> {
    const params: any = { page: '1', page_size: '100000' };
    if (modelId) {
      params.model = modelId;
    }
    return this.http.get<PagebleEntity<FullSegment>>(this.basePath, { params });
  }

  byModel( modelId: string, pageSettings: PageSettings, filter: SegmentsFilter ): Observable<PagebleEntity<Segment>> {
    const notEmptyFilters = pickBy(filter, value => !isEmpty(value));
    const params = { ...pageSettings, model: modelId, ...notEmptyFilters } as any;
    const mapKey = JSON.stringify(params);
    if (this.subjects.has(mapKey) && this.actual.has(mapKey)) {
        return this.subjects.get(mapKey);
    } else {
      const subj = new ReplaySubject<PagebleEntity<Segment>>(1);
      const newSubscription = this.http.get<PagebleEntity<Segment>>(this.basePath, { params })
        .subscribe(
          val => {
            subj.next(val);
            setTimeout(() => { // expiration timer
              this.actual.delete(mapKey);
              const subscription = this.subscriptions.get(mapKey);
              if (subscription && subscription.unsubscribe) {
                subscription.unsubscribe();
              }
            }, this.expirationTtl);
          },
          err => {
            subj.error(err);
          }
        );
      this.actual.add(mapKey);
      this.subjects.set(mapKey, subj);
      this.subscriptions.set(mapKey, newSubscription);
      return subj;
    }
  }

  one(id: string): Observable<FullSegment> {
    return this.http.get<FullSegment>(`${this.basePath}${id}/`);
  }

  create(segment: FullSegment): Observable<FullSegment> {
    return this.http.post<FullSegment>(this.basePath, segment);
  }

  update(id: string, segment: UpdatedSegment): Observable<FullSegment> {
    return this.http.patch<FullSegment>(`${this.basePath}${id}/`, segment);
  }

  updateFilter( filterId: string, filter: UpdatedSegmentFilter ): Observable<SegmentFilter> {
    return this.http.patch<SegmentFilter>(`${this.filtersPath}${filterId}/`, filter);
  }

  deleteFilter(filterId: string) {
    return this.http.delete(`${this.filtersPath}${filterId}/`);
  }

  deleteFiltersBatch(filterIds: string[]): Observable<any> {
    return forkJoin(filterIds.map(id => this.deleteFilter(id)));
  }

  addFilter( segmentId: string, filter: UpdatedSegmentFilter ): Observable<SegmentFilter> {
    const params = { ...filter, segment: segmentId };
    return this.http.post<SegmentFilter>(`${this.filtersPath}`, params);
  }
}
