import { createSelector } from '@ngrx/store';
import { getReturnDeliveryState, ReturnDeliveryState } from './return-delivery.reducer';

export const getReturnDeliveries = createSelector(
  getReturnDeliveryState,
  (state: ReturnDeliveryState) => state.returnDeliveries
);

export const getCurrentReturnDelivery = createSelector(
  getReturnDeliveryState,
  (state: ReturnDeliveryState) => state.returnDelivery
);

export const getReturnDeliveriesNextPage = createSelector(
  getReturnDeliveryState,
  (state: ReturnDeliveryState) => state.returnDeliveriesNextPage
);

export const getReturnDeliveriesFilter = createSelector(
  getReturnDeliveryState,
  (state: ReturnDeliveryState) => state.returnDeliveriesFilter
);
export const isLoadingReturnDeliveries = createSelector(
  getReturnDeliveryState,
  (state: ReturnDeliveryState) => state.isLoadingReturnDeliveries
);
export const getReturnDeliveriesOfOrder = createSelector(
  getReturnDeliveryState,
  (state: ReturnDeliveryState) => state.returnDeliveriesOfOrder
);
export const isLoadingReturnDeliveriesOfOrder = createSelector(
  getReturnDeliveryState,
  (state: ReturnDeliveryState) => state.isLoadingReturnDeliveriesOfOrder
);
export const isLoadingReturnDelivery = createSelector(
  getReturnDeliveryState,
  (state: ReturnDeliveryState) => state.isLoadingReturnDelivery
);
export const isReloadReturnDeliveries = createSelector(
  getReturnDeliveryState,
  (state: ReturnDeliveryState) => state.isReload
);
