<div class="modal-body">
  <ng-container *ngIf="!isLoading; else loading">
    <div class="d-flex">
      <h3>{{ segment.name }}</h3>
      <a
        [routerLink]="[
          '/segments',
          models[segment.model]?.model_name,
          segment.id
        ]"
        (click)="hide.emit()"
        *ngIf="models"
        class="btn btn-outline-secondary ml-auto"
        >{{ "Edit" | translate }}</a
      >
    </div>
    <ul class="filters">
      <li *ngFor="let filter of segment.filters">
        <div class="name">
          {{ properties[filter.property.id || filter.property].name }}
          <span *ngIf="filter.exclude">({{ 'Exclude' | translate }})</span>
        </div>
        <div class="operator">
          {{ operatorLabel[filter.operator] | translate }}
          {{ operatorLabelExt[filter.operator] | translate }}
        </div>
        <div class="values">{{ interpolateValue(filter) }}</div>
      </li>
    </ul>
  </ng-container>
</div>

<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
