import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterReducerState, RouterStateSerializer } from '@ngrx/router-store';
import { createFeatureSelector } from '@ngrx/store';
import * as routerAction from '../actions/router.action';
import { Injectable } from "@angular/core";

export interface RouterMetaState {
  crumbs: CrumbDict;
  page_titles: PageTitleDict;
}

export interface CrumbDetail {
  name: string;
  url?: string;
}

export interface CrumbDict {
  [key: string]: CrumbDetail;
}

export interface PageTitleDict {
  [key: string]: string;
}

export interface RouterStateUrl {
  url: string;
  params: any;
  queryParams: Params;
  chain: {
    url: string;
    data: any;
  }[];
}

export const initialState: RouterMetaState = {crumbs: {}, page_titles: {}};

export function RouterMetaReducer(
  state: RouterMetaState = initialState,
  action: routerAction.RouterAction
): RouterMetaState {
  switch (action.type) {
    case routerAction.SET_CRUMB:
      return {
        ...state,
        crumbs: {...state.crumbs, [action.key]: action.value}
      };
    case routerAction.SET_PAGE_TITLE:
      return {
        ...state,
        page_titles: {...state.page_titles, [action.key]: action.value}
      };
    default:
      return state;
  }
}

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    let params: any = {};
    let chain = [];

    let path = '';

    while (route.firstChild) {
      route = route.firstChild;
      params = {...params, ...route.params};
      const url = route.url.map(segment => segment.path).join('/');
      path += url.length ? '/' + url : '';

      if (route.url) {
        chain.push({url: path, data: route.routeConfig.data});
      }
    }

    const {
      url,
      root: {queryParams}
    } = routerState;

    const query = {...queryParams};

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return {url, params, chain, queryParams: query};
  }
}

export const getRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const getRouterMeta = createFeatureSelector<RouterMetaState>(
  'routerMeta'
);
