import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, pluck, scan, startWith, switchMap, tap } from 'rxjs/operators';
import { AppState } from '../../../../store/reducers';
import { Employee } from '../../../employees/models/employee.model';
import { EmployeesService } from '../../../employees/services/employees/employees.service';
import { CallsHistorySetFilter } from '../../store/call.actions';

@Component({
  selector: 'app-employees-finder',
  templateUrl: './employees-finder.component.html',
  styleUrls: ['./employees-finder.component.scss']
})
export class EmployeesFinderComponent implements OnInit, OnDestroy {
  currentValue = new FormControl();
  modelTypeahead$ = new Subject<string>();
  isModelsLoading = false;
  models$: Observable<any>;
  modelsLoad$: Observable<any>;
  subscriptions = new Subscription();
  operators: string[] = [];
  countLoaded: number;
  nextPage = 0;
  fetchMore$ = new BehaviorSubject('');
  hasNextPage = true;

  constructor(private employeeService: EmployeesService, private store: Store<AppState>) {
    this.modelsLoad$ = combineLatest(
      this.modelTypeahead$.pipe(
        startWith(''),
        tap(() => {
          this.nextPage = 0;
          this.hasNextPage = true;
        })
      ),
      this.fetchMore$.pipe(
        filter(_ => !this.isModelsLoading && this.hasNextPage)
      )
    ).pipe(
      debounceTime(100),
      tap(() => (this.isModelsLoading = true)),
      switchMap(([val, _]) => {
        this.nextPage++;
        return this.employeeService
          .filter({search: val, page: this.nextPage})
          .pipe(
            tap(res => (this.hasNextPage = !!res.next)),
            pluck('results')
          );
      })
    );

    this.models$ = this.modelsLoad$.pipe(
      scan((previous, response) => {
        this.isModelsLoading = false;
        const result =
          this.nextPage <= 1 ? response : previous.concat(response);
        this.countLoaded = result.length;

        return result;
      }, [])
    );

    this.subscriptions.add(
      this.currentValue.valueChanges
        .pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
        .subscribe((v: Employee) => {
          if (v && v.id) {
            this.store.dispatch(new CallsHistorySetFilter({employee: v.id}));
          } else {
            this.store.dispatch(new CallsHistorySetFilter({employee: undefined}));
          }
        })
    );
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  fetchMore() {
    if (this.isModelsLoading) {
      return;
    }
    this.fetchMore$.next('');
  }

  scroll({end}) {
    if (this.isModelsLoading) {
      return;
    }
    if (end + 10 >= this.countLoaded) {
      this.fetchMore$.next('');
    }
  }
}
