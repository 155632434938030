import { Component } from '@angular/core';
import { FilterValueAccessor, MakeProvider } from '../filter-value-accessor';
import { SegmentOperator } from '../../../models/segment-property';
import { filter } from 'rxjs/operators';
import * as moment from 'moment';
import {
  DATE_FORMAT,
  dataFormatsConfig,
  DATETIME_FORMAT,
  TIME_FORMAT
} from '../../../../shared/config/data-formats.config';
import { Validators } from '@angular/forms';
import { SelectItem } from '../../../../shared/components/form/select/select.component';
import { NO_EMIT_EVENT } from '../../../../shared/helpers/forms';
import { _ } from '../../../../shared/helpers/translation-marker';
import { TIME_MASK } from '../../../../shared/helpers/input-masks';
import { rangeDateTimeValidator } from '../../../../shared/validators/range.validator';

enum dateOperators {
  RANGE = 'range',
  EXACT_DATES = 'exact_dates',
  EXCLUDE_DATES = 'exclude_dates'
}

const INPUT_INVALID = /_/;

@Component({
  selector: 'app-segment-filter-datetime',
  templateUrl: './segment-filter-datetime.component.html',
  styleUrls: ['./segment-filter-datetime.component.scss'],
  providers: [...MakeProvider(SegmentFilterDatetimeComponent)]
})
export class SegmentFilterDatetimeComponent extends FilterValueAccessor {
  operators: string[] = [dateOperators.RANGE];

  datePickerConfig = dataFormatsConfig;
  timeMask = TIME_MASK;

  ngOnInit() {
    this.initForm();
    this.initSubscriptions();
  }

  initForm() {
    this.form = this.fb.group(
      {
        dateOperator: [null, Validators.required],
        start: [null],
        startTime: [''],
        end: [null],
        endTime: ['']
      },
      {
        validator: rangeDateTimeValidator(
          'start',
          'startTime',
          'end',
          'endTime'
        )
      }
    );
  }

  initSubscriptions() {
    this.subscriptions.add(
      this.form.valueChanges
        .pipe(
          filter(values => {
            return (
              this.form.valid &&
              !INPUT_INVALID.test(values.startTime) &&
              !INPUT_INVALID.test(values.endTime) &&
              !!(values.start || values.end)
            );
          })
        )
        .subscribe(() => {
          this.pushValue({
            ...this.filter,
            ...this.serialize()
          } as any);
        })
    );

    this.subscriptions.add(
      this.start.valueChanges.subscribe(() => {
        if (!this.startTime.value) {
          this.startTime.setValue('00:00', NO_EMIT_EVENT);
        }
      })
    );

    this.subscriptions.add(
      this.end.valueChanges.subscribe(() => {
        if (!this.endTime.value) {
          this.endTime.setValue('23:59', NO_EMIT_EVENT);
        }
      })
    );
  }

  onWrite(values) {
    this.form.patchValue(this.valuesToForm(values), NO_EMIT_EVENT);
  }

  onClearStart() {
    this.start.setValue(null);
    this.startTime.setValue('');
  }

  onClearEnd() {
    this.end.setValue(null);
    this.endTime.setValue('');
  }

  valuesToForm(values): any {
    const { operator, value } = values;
    if (!value || !operator) {
      return {
        dateOperator: dateOperators.RANGE,
        start: null,
        startTime: '',
        end: null,
        endTime: ''
      };
    }

    const dateTimeValue = this.valueToDateTime(value);

    switch (operator) {
      case SegmentOperator.RANGE:
        const [start, startTime] = this.valueToDateTime(value[0]);
        const [end, endTime] = this.valueToDateTime(value[1]);
        return {
          dateOperator: dateOperators.RANGE,
          start,
          startTime,
          end,
          endTime
        };
      case SegmentOperator.GTE:
        return {
          dateOperator: dateOperators.RANGE,
          start: dateTimeValue[0],
          startTime: dateTimeValue[1],
          end: null,
          endTime: ''
        };
      case SegmentOperator.LTE:
        return {
          dateOperator: dateOperators.RANGE,
          start: null,
          startTime: '',
          end: dateTimeValue[0],
          endTime: dateTimeValue[1]
        };
      default:
        console.warn('warn: operator not implemented: ', operator);
        return {
          dateOperator: dateOperators.RANGE,
          start: dateTimeValue[0],
          startTime: dateTimeValue[1],
          end: null,
          endTime: ''
        };
    }
  }

  dateTimeToValue(date, time): string {
    if (!date) {
      return null;
    }
    const m = moment(date);
    if (!time) {
      return m.format(DATE_FORMAT);
    }
    const [hours, minutes] = time.split(':');
    m.hour(hours);
    m.minute(minutes);
    return m.format(DATETIME_FORMAT);
  }

  valueToDateTime(value): [Date, string] {
    if (!value) {
      return [null, ''];
    }
    const m = moment(value, DATETIME_FORMAT);
    const date = m.toDate();
    const time = m.format(TIME_FORMAT);
    console.log(
      '--segment-filter-datetime.component#valueToDateTime',
      date,
      time
    );
    return [date, time];
  }

  serialize() {
    const { start, startTime, end, endTime, dateOperator } = this.form.value;
    const startValue = this.dateTimeToValue(start, startTime);
    const endValue = this.dateTimeToValue(end, endTime);

    let operator;
    let value;

    // TODO @dkchv: remove true after implement other options
    if (dateOperator === dateOperators.RANGE || true) {
      if (startValue && endValue) {
        operator = SegmentOperator.RANGE;
        value = [startValue, endValue];
      } else if (!startValue) {
        operator = SegmentOperator.LTE;
        value = endValue;
      } else if (!endValue) {
        operator = SegmentOperator.GTE;
        value = startValue;
      }
    }

    return {
      operator,
      value
    };
  }

  get start() {
    return this.form && this.form.get('start');
  }

  get end() {
    return this.form && this.form.get('end');
  }

  get startTime() {
    return this.form && this.form.get('startTime');
  }

  get endTime() {
    return this.form && this.form.get('endTime');
  }
}
