import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { isEmpty, last } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../../../../store/reducers';
import { PRODUCT_MODEL_NAME } from '../../../models/segment';
import { SegmentGroup } from '../../../models/segment-group';
import { SegmentProperty } from '../../../models/segment-property';
import { getSegmentGroups } from '../../../store/selectors/segment-groups.selector';
import { getSegmentPropertiesByModel } from '../../../store/selectors/segment-properties.selector';
import { ModelSegmentProperty } from '../model-form/model-form.component';

@Component({
  selector: 'app-model-sub-object-value',
  templateUrl: './model-sub-object-value.component.html',
  styleUrls: ['./model-sub-object-value.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModelSubObjectValueComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ModelSubObjectValueComponent),
      multi: true,
    },
  ],
})
export class ModelSubObjectValueComponent
  implements OnInit, ControlValueAccessor, Validator, OnDestroy
{
  @Input() modelName = PRODUCT_MODEL_NAME;
  @Input()
  hasRemoveButton = false;
  @Input()
  submitted = false;

  get property() {
    return this._property;
  }

  @Input()
  set property(value) {
    if (value) {
      this._property = value;
      if (this.segmentPropertiesSub) {
        this.segmentPropertiesSub.unsubscribe();
      }
      this.segmentPropertiesSub = this.store
        .pipe(
          select(getSegmentPropertiesByModel(this.modelName)),
          map((properties) => properties.filter((p) => p.is_readable)),
          withLatestFrom(this.store.pipe(select(getSegmentGroups)))
        )
        .subscribe(([data, groups]: [SegmentProperty[], SegmentGroup[]]) => {
          this.allSegmentProperties = data;
          const currentProperties = data
            .filter((it) => it.parent === value.id)
            .map((it) => ({ ...it, fieldName: this.getPropertyFieldName(it) }));

          this.properties = this.reorderPropertiesByType(currentProperties);

          this.formGroup = this.fb.group(
            currentProperties.reduce((acc, property: any) => {
              const newAcc = {
                ...acc,
                [property.fieldName]: new FormControl(),
              };

              return newAcc;
            }, {})
          );
          if (this.currentValue) {
            this.formGroup.patchValue(this.currentValue);
          }

          this.subscribeToFormChanges();
        });
    }
  }

  private _property: SegmentProperty;

  formGroup: FormGroup = new FormGroup({});
  subscriptions = new Subject();
  formSubscription: Subscription;
  properties: ModelSegmentProperty[];
  segmentPropertiesSub: Subscription;
  allSegmentProperties: SegmentProperty[];
  value$ = new Subject();
  currentValue;

  onChange = (_) => {};
  onTouched = () => {};

  constructor(private store: Store<AppState>, private fb: FormBuilder) {}

  ngOnInit() {}

  reorderPropertiesByType(properties: SegmentProperty[]): SegmentProperty[] {
    return [
      ...properties.filter((it) => this.isScalar(it)),
      ...properties.filter((it) => this.isObject(it)),
      ...properties.filter((it) => this.isArray(it)),
    ];
  }

  subscribeToFormChanges() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    this.formSubscription = this.formGroup.valueChanges
      .pipe(
        tap((data) => {
          this.onChange(data);
        })
      )
      .subscribe((data) => {});
  }

  getPropertyFieldName(property: SegmentProperty) {
    return last(property.path.split('.'));
  }

  hasChildren(id: string) {
    return (
      this.allSegmentProperties.filter((it) => it.parent === id).length > 0
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
    if (!this.segmentPropertiesSub) {
      this.segmentPropertiesSub.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.subscriptions))
      .subscribe(fn);
  }

  registerOnValidatorChange(fn: () => void): void {}

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    if (!this.formGroup) {
      return null;
    }

    const validationErrors = Object.keys(this.formGroup.controls).reduce(
      (errors: any, name: string) => {
        const controlErrors = this.formGroup.get(name).errors;
        if (controlErrors) {
          errors = { ...controlErrors };
        }
        return errors;
      },
      { ...this.formGroup.errors }
    );

    return isEmpty(validationErrors) ? null : validationErrors;
  }

  isObject(segmentProperty: SegmentProperty) {
    return (
      this.hasChildren(segmentProperty.id) &&
      !segmentProperty.is_many &&
      segmentProperty.widget !== 'enum'
    );
  }

  isScalar(segmentProperty: SegmentProperty) {
    return (
      !this.hasChildren(segmentProperty.id) || segmentProperty.widget === 'enum'
    );
  }

  isArray(segmentProperty: SegmentProperty) {
    return this.hasChildren(segmentProperty.id) && segmentProperty.is_many;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.currentValue = obj;
      if (this.formGroup) {
        this.formGroup.patchValue(obj);
      }
    }
  }
}
