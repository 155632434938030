<ng-select *ngIf="property.is_many; else singleValue"
           [items]="[1]"
           [formControl]='value'
           [addTag]="true"
           [multiple]="true"
           [isOpen]="false"
           (blurEvent)="touch()"></ng-select>
<ng-template #singleValue>
  <input class='form-control value'
         type='text'
         [formControl]="value"
         (blur)="touch()">
</ng-template>