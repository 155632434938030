<div class="wrap list-wrapper">
  <app-customers-filter></app-customers-filter>
  <div class="panel"  *ngIf='(data$ | async).length > 0 || (loading$ | async); else noCustomers;'
       perfectScrollbar
       #infiniteScrollContainer infiniteScroll [scrollWindow]='false' (scrolled)='onScroll()'>
    <table #infiniteScrollableElement class="table table-hover table-striped" *ngIf="!(reloading$ | async)">
      <thead>
      <tr>
        <th class="name">{{'Last name' | translate}}</th>
        <th class="name">{{'First name' | translate}}</th>
        <th class="name">{{'Middle name' | translate}}</th>
        <th class="gender">{{'Gender' | translate}}</th>
        <th class="birthday">{{'Birthday' | translate}}</th>
        <th class="phone">{{'Phone' | translate}}</th>
        <th class="email">{{'Email' | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let customer of (data$ | async)" class="clickable" [routerLink]="['/customers', customer.id]">
        <td class="name">{{customer.last_name}}</td>
        <td class="name">{{customer.first_name}}</td>
        <td class="name">{{customer.middle_name}}</td>
        <td class="gender">{{customer.gender | translate}}</td>
        <td class="birthday">{{customer.birthday | formatDate}}</td>
        <td class="phone">{{customer.phone | formatPhone}}</td>
        <td class="email">{{customer.email}}</td>
      </tr>
      </tbody>
    </table>
    <app-loader *ngIf="(loading$ | async)"></app-loader>
  </div>
</div>
<ng-template #noCustomers>
  <app-empty-list-placeholder [header]="(initial ? 'Apply filters to find the data you need' : 'There are no customers') | translate"></app-empty-list-placeholder>
</ng-template>
