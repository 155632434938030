import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty, isNumber, pickBy } from 'lodash';
import { Observable } from 'rxjs';
import { PageSettings } from '../../shared/helpers/calculate-page';
import { PagebleEntity } from '../../shared/models/pageble-entity';
import { Department, DepartmentsFilter } from '../models/department.model';

@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {
  basePath = 'departments/';

  constructor(private http: HttpClient) {
  }

  all(
    filter: DepartmentsFilter,
    params: PageSettings
  ): Observable<PagebleEntity<Department>> {
    const notEmptyFilters = pickBy(
      filter,
      value => !isEmpty(value) || isNumber(value)
    );
    const paramsAndFilter = {
      ...params,
      ...notEmptyFilters
    };
    return this.http.get<PagebleEntity<Department>>(this.basePath, {
      params: paramsAndFilter as any
    });
  }

  filter(filter): Observable<PagebleEntity<Department>> {
    const notEmptyFilters = pickBy(
      filter,
      value => !isEmpty(value) || isNumber(value)
    );
    return this.http.get<PagebleEntity<Department>>(this.basePath, {
      params: notEmptyFilters
    });
  }
}
