import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppState } from '../../../../store/reducers';
import { Store } from '@ngrx/store';
import { LoadUnfinishedCalls } from '../../store/call.actions';
import { HandleGuardRequestService } from 'src/app/modules/shared/services/handle-guard-request/handle-guard-request.service';

@Injectable({
  providedIn: 'root'
})
export class LoadUnfinishedCallsGuard implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean> | boolean {
    this.store.dispatch(new LoadUnfinishedCalls());
    return true;
  }
}
