import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { _ } from '../../shared/helpers/translation-marker';
import { CallComponent } from '../pages/call/call.component';
import { HistoryComponent } from '../pages/history/history.component';
import { WaitingComponent } from '../pages/waiting/waiting.component';
import { LoadActionReasonsGuard } from './guards/load-action-reasons.guard';
import { LoadCallGuard } from './guards/load-call.guard';
import { LoadCallsHistoryGuard } from './guards/load-calls-history.guard';
import { LoadDirectionsGuard } from './guards/load-directions.guard';
import { LoadSubjectsGuard } from './guards/load-subjects.guard';
import { IsCallFinishedGuard } from './guards/is-call-finished.guard';
import { CallsComponent } from '../pages/calls/calls.component';

export const routes: Routes = [
  {
    path: '',
    component: CallsComponent,
    canActivate: [
      LoadDirectionsGuard,
      LoadSubjectsGuard,
      LoadActionReasonsGuard
    ],
    data: { crumb: { name: _('Calls') } },
    children: [
      {
        path: 'current',
        component: WaitingComponent,
        data: { crumb: { name: _('Currents') } }
      },
      {
        path: 'history',
        component: HistoryComponent,
        data: { crumb: { name: _('Calls history') } },
        canActivate: [LoadCallsHistoryGuard]
      },
      { path: '', redirectTo: '/calls/current', pathMatch: 'full' },
      {
        path: ':callId',
        component: CallComponent,
        data: { crumb: { name: _('Current call') } },
        canActivate: [LoadCallGuard],
        canDeactivate: [IsCallFinishedGuard],
        loadChildren:
          () => import('../../../modules/customers/modules/customers-extra/customers-extra.module').then(m => m.CustomersExtraModule)
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  declarations: [],
  exports: [RouterModule]
})
export class CallsRoutingModule {}
