import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/reducers';
import {
  getNavChain,
  getPageTitle
} from '../../../../store/selectors/router.selector';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnChanges {
  @Input() header: string;
  breadcrumbs$;
  title$;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.breadcrumbs$ = this.store.select(getNavChain);
    this.title$ = this.store.select(getPageTitle);
  }

  ngOnChanges(changes: SimpleChanges) {}
}
