import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoadDictionariesGuard} from './guards/load-dictionaries.guard';
import {DictionariesComponent} from '../pages/dictionaries/dictionaries.component';
import {_} from '../../shared/helpers/translation-marker';
import {DictionaryCreateComponent} from '../pages/dictionary-create/dictionary-create.component';
import {LoadDictionaryGuard} from './guards/load-dictionary.guard';
import {DictionaryItemsComponent} from '../pages/dictionary-items/dictionary-items.component';
import {LoadDictionaryItemsGuard} from './guards/load-dictionary-items.guard';
import {DICTIONARY_ITEM_KEY} from '../models/dictionary-item';

const routes: Routes = [
  {
    path: '',
    data: {
      crumb: {
        name: _('Dictionaries')
      }
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [LoadDictionariesGuard],
        component: DictionariesComponent
      },
      {
        path: 'new',
        component: DictionaryCreateComponent
      },
      {
        path: ':id',
        data: {
          page_id: DICTIONARY_ITEM_KEY
        },
        canActivate: [
          LoadDictionaryGuard
        ],
        children: [
          {
            path: 'items',
            children: [
              {
                path: '',
                pathMatch: 'full',
                component: DictionaryItemsComponent,
                canActivate: [
                  LoadDictionaryItemsGuard
                ]
              }
            ],
          },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DictionariesRoutingModule { }
