import { Pipe, PipeTransform } from '@angular/core';
import { FormatPhoneService } from '../../services/format-phone/format-phone.service';

@Pipe({
  name: 'formatPhone'
})
export class FormatPhonePipe implements PipeTransform {
  constructor(
    private formatPhone: FormatPhoneService
  ) {}
  transform(value: any, args?: any): any {
    return this.formatPhone.format(value);
  }

}
