<ng-container [ngSwitch]='property.widget'>
  <input type="number"
         class="form-control"
         [formControl]="fieldValue"
         *ngSwitchCase='"number"'/>
  <ng-select class="ng-select-autowidth  ng-select-append"
             *ngSwitchCase='"enum"'
             [formControl]="fieldValue"
             [items]='property.property_values'
             [hideSelected]='true'
             [multiple]='property.is_many'
             bindLabel='name'></ng-select>
  <app-model-string-field [formControl]="fieldValue"
                            [property]="property"
                            *ngSwitchDefault></app-model-string-field>
  <app-errors-component [control]="fieldValue"
                        [submitted]="submitted"></app-errors-component>
</ng-container>
