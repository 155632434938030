<ng-select
  [formControl]="currentValue"
  [multiple]="multiple"
  [items]="segments$ | async"
  bindLabel="name"
  [virtualScroll]="true"
  [hideSelected]="true"
  [compareWith]="compareValues"
  [loading]="isSegmentsLoading"
  [typeahead]="segmentTypeahead$"
  (scroll)="scroll($event)"
  (scrollToEnd)="fetchMore()"
>
  <ng-template ng-header-tmp>
    <ng-container *ngIf="models.length > 1">
      <a
        class="switch-model"
        [class.active]="(model$ | async) === m.path"
        *ngFor="let m of models$ | async"
        (click)="model$.next(m.path)"
        >{{ m.name | translate }}</a
      >
    </ng-container>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <app-ng-select-option-with-count
      [name]="item.name"
      [count]="item.count"
      [selected]="item.selected"
      ><a class="info-icon" (click)="showInfo($event, item)"
        >i</a
      ></app-ng-select-option-with-count
    >
  </ng-template>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <span class="ng-value-label">{{ item.name | translate }}</span>
    <a class="ng-value-icon right" (click)="showAsyncInfo($event, item)"
      >&nbsp;i&nbsp;</a
    >
    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true"
      >×</span
    >
  </ng-template>
  <ng-template ng-typetosearch-tmp>
    <div class="ng-option disabled text-black-50">
      {{ 'Start typing' | translate }}...
    </div>
  </ng-template>
  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
    <div class="ng-option disabled text-black-50">
      {{ noItemsFoundMessage | translate }}
    </div>
  </ng-template>

  <ng-template ng-footer-tmp>
    <button
      class="btn btn-ng-select-create-item"
      type="button"
      (click)="showCreateDialog()"
    >
      <i class="material-icons">add</i>{{ 'Create segment' | translate }}
    </button>
  </ng-template>
</ng-select>
