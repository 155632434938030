import { PaymentActions, PaymentActionTypes } from './payment.actions';
import { Payment, PaymentsFilter } from '../../models/payment.model';
import { isReload } from '../../../shared/helpers/calculate-page';

export interface PaymentState {
  payments: Payment[];
  nextPage: string | null;
  isLoadingPayments: boolean;
  isReload: boolean;
  filter: PaymentsFilter;
  payment: Payment;
  isLoadingPayment: boolean;
  refreshingPaymentId: string | null;
}

export const initialState: PaymentState = {
  payments: [],
  nextPage: null,
  isReload: false,
  isLoadingPayments: false,
  filter: {},
  payment: null,
  isLoadingPayment: false,
  refreshingPaymentId: null,
};

export function paymentReducer(
  state = initialState,
  action: PaymentActions
): PaymentState {
  switch (action.type) {
    case PaymentActionTypes.LoadPayments:
      return {
        ...state,
        isLoadingPayments: true,
        isReload: isReload(action)
      };

    case PaymentActionTypes.LoadPaymentsSuccess:
      return {
        ...state,
        isLoadingPayments: false,
        payments: action.payload.results,
        nextPage: action.payload.next,
        isReload: false
      };

    case PaymentActionTypes.LoadPaymentsFail:
      return {
        ...state,
        isLoadingPayments: false,
        isReload: false
      };

    case PaymentActionTypes.ClearPayments:
      return {
        ...state,
        isLoadingPayments: false,
        payments: [],
        nextPage: null,
      };

    case PaymentActionTypes.LoadPayment:
      return {
        ...state,
        isLoadingPayment: true,
        payment: null
      };

    case PaymentActionTypes.LoadPaymentSuccess:
      return {
        ...state,
        isLoadingPayment: false,
        payment: action.payload
      };

    case PaymentActionTypes.LoadPaymentFail:
      return {
        ...state,
        isLoadingPayment: false
      };

    case PaymentActionTypes.RefreshPayment:
      return {
        ...state,
        refreshingPaymentId: action.id,
      };

    case PaymentActionTypes.RefreshPaymentSuccess:
      return {
        ...state,
        payments: state.payments.map((payment) => payment.id === state.refreshingPaymentId ? { ...action.payload } : payment),
        refreshingPaymentId: null,
      };

    case PaymentActionTypes.RefreshPaymentFail:
      return {
        ...state,
        refreshingPaymentId: null,
      };

    case PaymentActionTypes.SetPaymentsFilter:
      return {
        ...state,
        filter: action.payload,
        nextPage: null,
      };

    default:
      return state;
  }
}
