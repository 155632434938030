export const CURRENT_COMPANY_KEY = 'currentCompany';

export interface Company {
  id: string;
  name: string;
  roles: CompanyRole[];
  contacts: {
    site_url: string;
  };
}

export type CompanyRole =
  | 'PRODUCT_CATEGORIES'
  | 'PRODUCTS'
  | 'BANNERS'
  | 'PROMOTIONS'
  | 'DICTIONARIES'
  | 'DEVICES'
  | 'OUTLETS'
  | 'TEMPLATES';
