<form [formGroup]="form" (submit)="submit()">
  <div class="row">
    <div class="col-3">
      <input type="text"
             class="form-control"
             formControlName="search"
             [placeholder]="'First name or middle name or last name' | translate">
    </div>
    <div class="col-2">
      <app-phone-input formControlName="phone"></app-phone-input>
    </div>
    <div class="col-2">
      <input type="text"
             class="form-control"
             formControlName="email"
             [placeholder]="'Email' | translate">
    </div>
    <div class="col-5 pull-right">
      <button [disabled]="form.invalid" type="submit"
              class="btn btn-primary ml-auto d-block">{{ 'Search' | translate }}</button>
    </div>
  </div>
</form>
