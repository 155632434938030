import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PagebleEntity } from 'src/app/modules/shared/models/pageble-entity';
import { pickBy, isDate, isNil } from 'lodash';
import { SegmentRemoteModel } from '../../models/segment-model';

@Injectable({
  providedIn: 'root'
})
export class SegmentRemoteModelService {
  constructor(private http: HttpClient) {}

  filter(params): Observable<PagebleEntity<SegmentRemoteModel>> {
    const notEmptyFilters = pickBy(params, value => !isNil(value));
    return this.http.get<PagebleEntity<SegmentRemoteModel>>(`remote-models/`, {
      params: { ...notEmptyFilters }
    });
  }

  read(id: string, model: string): Observable<SegmentRemoteModel> {
    return this.http.get<SegmentRemoteModel>(`remote-models/${id}`, {
      params: {
        model: model
      }
    });
  }
}
