import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { ofMessage } from 'src/app/modules/shared/helpers/operators/ofMessage';
import { UpdateTaskCountSuccess } from './contact-center.actions';

export const taskCountMessageType = 'TASK_COUNT';

@Injectable()
export class ContactCenterEffects {
  @Effect()
  websocketUpdateTaskCount$ = this.actions$.pipe(
    ofMessage(taskCountMessageType),
    map(message => {
      return new UpdateTaskCountSuccess(message);
    })
  );

  constructor(
    private actions$: Actions
  ) {}
}
