import { Component, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/reducers';
import { Router } from '@angular/router';
import { tap, map, skip, takeUntil } from 'rxjs/operators';
import { Customer } from '../../models/customer';
import { isLoadingCustomers, isReloadCustomers, getCustomersData } from '../../store/customer/customer.selectors';
import { CustomersClear, CustomersLoadDebounced } from '../../store/customer/customer.actions';
import { InfiniteScrollableComponent } from 'src/app/modules/shared/components/infinite-scrollable/InfiniteScrollableComponent';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomersComponent extends InfiniteScrollableComponent<Customer> implements OnInit, OnDestroy {
  initial = true;
  subscriptions = new Subject();

  constructor(
    protected store: Store<AppState>,
    private router: Router
  ) {
    super(store);
    this.store.pipe(select(isLoadingCustomers))
      .pipe(
        skip(1),
        takeUntil(this.subscriptions)
      )
      .subscribe(() => (this.initial = false));
  }

  ngOnInit() {
    this.store.dispatch(new CustomersClear());
  }

  protected registerPipes() {
    this.data$ = this.store.pipe(
      select(getCustomersData),
      tap(data => {
        this.loadedCount = data.data.length;
        this.totalCount = data.totalCount;
      }),
      map(data => data.data)
    );
    this.loading$ = this.store.pipe(select(isLoadingCustomers));
    this.reloading$ = this.store.pipe(select(isReloadCustomers));
  }

  protected getData() {
    if (this.totalCount > this.loadedCount) {
      this.store.dispatch(new CustomersLoadDebounced({isReload: false}));
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new CustomersClear());
    this.subscriptions.next();
    this.subscriptions.complete();
  }
}
