import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import * as SIP from 'sip.js';
import { AppState } from 'src/app/store/reducers';
import { Call, CallDirection, CallSubject } from '../../models/calls.model';
import { SipService } from '../../services/sip/sip.service';
import { AnswerCall, HangUpCall, UpdateCall } from '../../store/call.actions';
import { getDirections, getSubjectsByDirection } from '../../store/call.selector';

@Component({
  selector: 'app-incoming-call',
  templateUrl: './incoming-call.component.html',
  styleUrls: ['./incoming-call.component.scss']
})
export class IncomingCallComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  call: Call;
  @Output()
  hangUp = new EventEmitter<Call>();
  @Output()
  accept = new EventEmitter<Call>();
  sound = new Audio('/assets/Modern_Ring_Ring.mp3');
  private sipInviteOptions: SIP.InviteOptions;
  callForm: FormGroup;

  isCalling$: Observable<boolean>;
  isTalking$: Observable<boolean>;
  isHangUp$: Observable<boolean>;
  isHold$: Observable<boolean>;
  sessionStart$: Observable<Date>;

  directions$: Observable<CallDirection[]>;
  subjects$: Observable<CallSubject[]>;

  @ViewChild('hangUpModal', { static: true }) hangUpModal;
  hangUpModalRef: BsModalRef;

  @ViewChild('finishModal', { static: true }) finishModal;
  finishModalRef: BsModalRef;

  @ViewChild('redirectModal', { static: true }) redirectModal;
  redirectModalRef: BsModalRef;

  comment = new FormControl();
  showComment = false;

  subscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private sipService: SipService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private router: Router
  ) {
    this.callForm = fb.group({
      direction: [],
      subject: []
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.call &&
      (changes.call.firstChange ||
        (changes.call.previousValue &&
          changes.call.currentValue.id !== changes.call.previousValue.id))
    ) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      this.comment.patchValue(changes.call.currentValue.comment, {
        emitEvent: false
      });

      if (changes.call.currentValue.state === 'FINISHED') {
        this.comment.disable();
      } else {
        this.comment.enable();
      }

      this.subscription = this.comment.valueChanges.subscribe(value => {
        this.store.dispatch(
          new UpdateCall({
            id: changes.call.currentValue.id,
            comment: value
          })
        );
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.isCalling$ = this.sipService.isCalling$;
    this.isTalking$ = this.sipService.isTalking$;
    this.isHangUp$ = this.sipService.isHangUp$;
    this.isHold$ = this.sipService.isHold$;
    this.sessionStart$ = this.sipService.sessionStart$;
    this.directions$ = this.store.pipe(select(getDirections));
    this.subjects$ = this.callForm.get('direction').valueChanges.pipe(
      distinctUntilChanged(),
      switchMap(direction => {
        this.callForm.get('subject').reset();
        return this.store.pipe(select(getSubjectsByDirection(direction)));
      })
    );
  }

  addSubject() {
    const subject = this.callForm.get('subject').value;
    this.store.dispatch(
      new UpdateCall({
        id: this.call.id,
        subjects: [...this.call.subjects.map(s => s.id), subject]
      })
    );

    this.callForm.reset();
  }

  removeSubject(id: string) {
    this.store.dispatch(
      new UpdateCall({
        id: this.call.id,
        subjects: this.call.subjects.filter(s => s.id !== id)
      })
    );
  }

  onAccept() {
    this.store.dispatch(new AnswerCall(this.call.id));
    this.sipService.onAccept();
  }

  onDecline() {
    this.store.dispatch(new HangUpCall(this.call.id));
    this.sipService.onDecline();
  }

  onHangUpCall() {
    this.hangUpModalRef = this.modalService.show(this.hangUpModal);
  }

  onFinishCall() {
    this.finishModalRef = this.modalService.show(this.finishModal);
  }

  onHold() {
    // this.store.dispatch(new HoldCall(this.call.id));
    this.sipService.onHold();
  }

  onResume() {
    // this.store.dispatch(new ResumeCall(this.call.id));
    this.sipService.onResume();
  }

  openRedirectDialog() {
    // this.sipService.onHold();

    this.redirectModalRef = this.modalService.show(this.redirectModal);
  }
}
