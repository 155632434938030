import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProtectedGuard, PublicGuard } from 'ngx-auth';
import { LoadUnfinishedCallsGuard } from 'src/app/modules/calls/routing/guards/load-unfinished-call.guard';
import { _ } from '../../../modules/shared/helpers/translation-marker';
import { PrivateRootComponent } from '../pages/private-root/private-root.component';
import { LoadEmployeeActionTypesGuard } from './guards/load-employee-action-types.guard';
import { LoadUserGuard } from './guards/load-user.guard';
import { SetCompanyGuard } from './guards/set-company.guard';
import { LoadContactTipsGuard } from 'src/app/modules/contact-tips/routing/guards/load-contact-tips.guard';
import { LoadOrderDeliveryActionReasonsGuard } from 'src/app/modules/couriers/routing/guards/load-order-delivery-action-reasons.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('../../../modules/auth/auth-cc.module').then(m => m.AuthCcModule),
    canActivate: [PublicGuard, SetCompanyGuard],
  },
  {
    path: '',
    canActivate: [ProtectedGuard],
    children: [
      {
        path: '',
        component: PrivateRootComponent,
        canActivate: [
          ProtectedGuard,
          LoadUserGuard,
          LoadEmployeeActionTypesGuard,
          SetCompanyGuard,
          LoadUnfinishedCallsGuard,
          LoadContactTipsGuard,
          LoadOrderDeliveryActionReasonsGuard,
        ],
        data: { crumb: { name: _('Home') } },
        children: [
          {
            path: 'profile',
            loadChildren:
              () => import('../../../modules/profile/profile.module').then(m => m.ProfileModule),
          },
          {
            path: 'calls',
            loadChildren: () => import('../../../modules/calls/calls.module').then(m => m.CallsModule),
          },
          {
            path: 'customers',
            loadChildren:
              () => import('../../../modules/customers/customers.module').then(m => m.CustomersModule),
          },
          {
            path: 'orders',
            loadChildren: () => import('../../../modules/orders/orders.module').then(m => m.OrdersModule),
          },
          {
            path: 'deliveries',
            loadChildren:
              () => import('../../../modules/couriers/couriers.module').then(m => m.CouriersModule),
          },
          {
            path: 'operators-state',
            loadChildren:
              () => import('../../../modules/operators/operators.module').then(m => m.OperatorsModule),
          },
          {
            path: 'tasks',
            loadChildren: () => import('../../../modules/tasks/tasks.module').then(m => m.TasksModule),
          },
          {
            path: 'payments',
            loadChildren:
              () => import('../../../modules/payments/payments.module').then(m => m.PaymentsModule),
          },
          {
            path: 'reclamations',
            loadChildren:
              () => import('../../../modules/reclamations/reclamations.module').then(m => m.ReclamationsModule),
          },
          {
            path: 'receipts',
            loadChildren:
              () => import('../../../modules/receipts/receipts.module').then(m => m.ReceiptsModule),
          },
          {
            path: 'reports',
            loadChildren:
              () => import('../../../modules/reports/reports.module').then(m => m.ReportsModule),
          },
          {
            path: '',
            redirectTo: '/calls/current',
            pathMatch: 'full',
          },
          {
            path: '**',
            redirectTo: '/calls/current',
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' }),
  ],
  declarations: [],
  exports: [RouterModule],
})
export class ContactCenterRoutingModule {}
