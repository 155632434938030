import { CustomerReducer, CustomerState } from './customer/customer.reducer';
import { ActionReducerMap } from '@ngrx/store';

export interface CustomerModuleState {
  customers: CustomerState;
}

export const reducers: ActionReducerMap<CustomerModuleState> = {
  customers: CustomerReducer
}
