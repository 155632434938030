import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { SendMessage } from 'src/app/store/actions/socket.action';
import { getCollectionTasksCount, getUncollectionTasksCount } from '../../store/contact-center.selector';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const SIDEBAR_EXPANDED = 'SIDEBAR_EXPANDED';
@Component({
  selector: 'app-private-root',
  templateUrl: './private-root.component.html',
  styleUrls: ['./private-root.component.scss']
})
export class PrivateRootComponent implements OnInit {

  subscriptions = new Subject();
  collectionTasks: number;
  uncollectionTasks: number;

  expand: { [key: string]: boolean } =
    JSON.parse(localStorage.getItem(SIDEBAR_EXPANDED)) || {};

  constructor(private store: Store<AppState>) {
    this.store.dispatch(new SendMessage({ type: 'task_count' }));

    this.store
      .pipe(
        select(getCollectionTasksCount),
        takeUntil(this.subscriptions)
      )
      .subscribe(data => (this.collectionTasks = data));

      this.store
      .pipe(
        select(getUncollectionTasksCount),
        takeUntil(this.subscriptions)
      )
      .subscribe(data => (this.uncollectionTasks = data));
  }

  ngOnInit() {}

  toggle(key) {
    this.expand[key] = !this.expand[key];
    localStorage.setItem(SIDEBAR_EXPANDED, JSON.stringify(this.expand));
  }
}
