import { createSelector } from '@ngrx/store';
import { getCustomerState } from './customer.reducer';

export const getCustomers = createSelector(
  getCustomerState,
  data => data.customers
);
export const getCustomersData = createSelector(
  getCustomerState,
  data => ({
    data: data.customers,
    totalCount: data.totalCustomersCount
  })
);
export const getTotalCustomerCount = createSelector(
  getCustomerState,
  data => data.totalCustomersCount
);
export const getCurrentCustomer = createSelector(
  getCustomerState,
  data => data.customer
);
export const isLoadingCustomers = createSelector(
  getCustomerState,
  data => data.isLoadingCustomers
);
export const isReloadCustomers = createSelector(
  getCustomerState,
  data => data.isReload
);
export const isLoadingCustomer = createSelector(
  getCustomerState,
  data => data.isLoadingCustomer
);
export const isUpdatingCustomer = createSelector(
  getCustomerState,
  data => data.updating
);
export const isCustomersReload = createSelector(
  getCustomerState,
  data => data.isReload
);
export const getCustomerError = createSelector(
  getCustomerState,
  data => data.customerError
);
export const getCustomersFilter = createSelector(
  getCustomerState,
  data => data.filter
);
