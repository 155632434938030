import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { cloneDeep, pick, set, omit } from 'lodash';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { AppState } from '../../../../store/reducers';
import { appendOrSetCollection } from '../../../shared/helpers/append-or-set-collection';
import {
  calculatePage,
  OutputPagebleAction,
} from '../../../shared/helpers/calculate-page';
import { forRoute } from '../../../shared/helpers/operators/forRoute';
import { ofMessage } from '../../../shared/helpers/operators/ofMessage';
import { _ } from '../../../shared/helpers/translation-marker';
import { Company } from '../../../shared/models/companies';
import { FieldsError } from '../../../shared/models/http-error';
import { PagebleEntity } from '../../../shared/models/pageble-entity';
import { ErrorHandlingService } from '../../../shared/services/error-handling/error-handling.service';
import { NotificationsService } from '../../../shared/services/notifications/notifications.service';
import { getCurrentCompany } from '../../../shared/store/selectors/user.selector';
import {
  FullSegment,
  SegmentFilter,
  UpdatedSegment,
} from '../../models/full-segment';
import {
  segmentCreatedMessageType,
  SegmentUpdatedMessage,
  segmentUpdatedMessageType,
} from '../../models/messages/segment-updated.message';
import { SegmentModel } from '../../models/segment-model';
import { SegmentsFilter } from '../../models/segments-filter';
import { SegmentsService } from '../../service/segments/segments.service';
import {
  AddSegment,
  SEGMENT_CREATE,
  SEGMENT_FILTER_ADD,
  SEGMENT_LOAD,
  SEGMENT_LOAD_SUCCESS,
  SEGMENT_UPDATE,
  SEGMENT_UPDATE_SUCCESS,
  SegmentCreate,
  SegmentCreateFail,
  SegmentCreateSuccess,
  SegmentFilterAdd,
  SegmentFilterAddFail,
  SegmentFilterAddSuccess,
  SegmentLoad,
  SegmentLoadFail,
  SegmentLoadSuccess,
  SEGMENTS_FILTER_DELETE,
  SEGMENTS_FILTER_DELETE_SUCCESS,
  SEGMENTS_FILTER_UPDATE,
  SEGMENTS_FILTERS_BATCH_DELETE,
  SEGMENTS_FILTERS_BATCH_DELETE_SUCCESS,
  SEGMENTS_LOAD,
  SEGMENTS_LOAD_ALL,
  SEGMENTS_SET_FILTER,
  SegmentsFilterDelete,
  SegmentsFilterDeleteFail,
  SegmentsFilterDeleteSuccess,
  SegmentsFiltersBatchDelete,
  SegmentsFiltersBatchDeleteFail,
  SegmentsFiltersBatchDeleteSuccess,
  SegmentsFilterUpdate,
  SegmentsFilterUpdateFail,
  SegmentsFilterUpdateSuccess,
  SegmentsLoad,
  SegmentsLoadAll,
  SegmentsLoadAllSuccess,
  SegmentsLoadFail,
  SegmentsLoadSuccess,
  SegmentUpdateFail,
  SegmentUpdateSuccess,
  SetSegment,
  SegmentUpdateFromSocket,
} from '../actions/segments.action';
import {
  getSegmentPropertiesState,
  SegmentPropertiesState,
} from '../reducers/segment-properties.reducer';
import {
  getCurrentSegmentModel,
  getSegmentModelByName,
} from '../selectors/segment-models.selector';
import {
  getCurrentSegment,
  getSegmentFilterByTrackId,
  getSegments,
  getSegmentsFilter,
  isSegmentsByModel,
  segmentErrors,
} from '../selectors/segments.selector';
import { Injectable } from '@angular/core';

export type SegmentUpdateActions =
  | SegmentsFilterUpdateSuccess
  | SegmentsFilterDeleteSuccess;

@Injectable()
export class SegmentsEffect {
  constructor(
    private actions$: Actions,
    private segmentsService: SegmentsService,
    private store: Store<AppState>,
    private router: Router,
    private notificationService: NotificationsService,
    private errorHandler: ErrorHandlingService
  ) {}

  @Effect()
  loadSegments$ = this.actions$.pipe(
    ofType(SEGMENTS_LOAD),
    withLatestFrom(this.store.pipe(select(getSegments))),
    map(([action, collection]: [SegmentsLoad, FullSegment[]]) =>
      calculatePage(action, collection.length)
    ),
    withLatestFrom(
      this.store.pipe(select(getCurrentSegmentModel)),
      this.store.pipe(select(getSegmentsFilter))
    ),
    switchMap(
      ([action, segmentModel, segmentsFilter]: [
        OutputPagebleAction<SegmentsLoad>,
        SegmentModel,
        SegmentsFilter
      ]) => {
        return this.segmentsService
          .byModel(segmentModel.id, action.pageSettings, segmentsFilter)
          .pipe(
            withLatestFrom(this.store.pipe(select(getSegments))),
            map(
              ([data, currentSegments]: [
                PagebleEntity<FullSegment>,
                FullSegment[]
              ]) => {
                return new SegmentsLoadSuccess(
                  appendOrSetCollection(action, data, currentSegments)
                );
              }
            ),
            catchError((data) => {
              this.errorHandler.handle(_('Segments load failed'));
              return of(new SegmentsLoadFail());
            })
          );
      }
    )
  );

  @Effect()
  loadAllSegments$ = this.actions$.pipe(
    ofType(SEGMENTS_LOAD_ALL),
    withLatestFrom(
      this.store.pipe(select(getSegments)),
      this.store.pipe(select(isSegmentsByModel))
    ),
    filter(([action, segments, isSegmentsLoadedByModel]) => {
      if (!segments.length || isSegmentsLoadedByModel) {
        return true;
      } else {
        this.store.dispatch(new SegmentsLoadFail());
        return false;
      }
    }),
    map(([action, segments]) => action),
    switchMap((action: SegmentsLoadAll) => {
      return this.store.pipe(
        select(getSegmentModelByName(action.payload)),
        map((model: SegmentModel) => [action, model])
      );
    }),
    switchMap(([_action, model]: [SegmentsLoadAll, SegmentModel]) => {
      return this.segmentsService.all(model && model.id).pipe(
        map((data) => new SegmentsLoadAllSuccess(data)),
        catchError(() => of(new SegmentsLoadFail()))
      );
    })
  );

  @Effect()
  loadSegment$ = this.actions$.pipe(
    ofType(SEGMENT_LOAD),
    switchMap((action: SegmentLoad) => {
      return this.segmentsService.one(action.payload).pipe(
        map((data) => new SegmentLoadSuccess(data)),
        catchError(() => {
          this.errorHandler.handle(_('Segment load failed'));
          return of(new SegmentLoadFail());
        })
      );
    })
  );

  @Effect()
  afterLoadSegment = this.actions$.pipe(
    ofType(SEGMENT_LOAD_SUCCESS),
    withLatestFrom(
      this.store.pipe(select(getSegmentPropertiesState)),
      this.store.pipe(select(getSegments))
    ),
    map(
      ([action, segmentPropertiesState, segments]: [
        SegmentLoadSuccess,
        SegmentPropertiesState,
        FullSegment[]
      ]) => {
        const segment = new FullSegment({ ...action.payload });
        segment.filters = segment.filters.map((it) => {
          const newFilter = { ...it };
          newFilter.property = segmentPropertiesState.segmentProperties.find(
            (p) => p.id === (newFilter.property as any)
          );
          return newFilter;
        }) as any;
        return segments.find((it) => it.id === segment.id)
          ? new SetSegment(segment)
          : new AddSegment(segment);
      }
    )
  );

  @Effect()
  updateSegmentName$ = this.actions$.pipe(
    ofType(SEGMENT_UPDATE),
    withLatestFrom(this.store.pipe(select(getCurrentSegment))),
    switchMap(([action, segment]: [SegmentCreate, FullSegment]) => {
      const id = segment.id;
      const updatedSegment: UpdatedSegment = {
        name: action.payload.name,
      };
      return this.segmentsService.update(id, updatedSegment).pipe(
        map((data) => new SegmentUpdateSuccess(data)),
        catchError((data: HttpErrorResponse) =>
          of(new SegmentUpdateFail(data.error))
        )
      );
    })
  );

  @Effect()
  createSegment$ = this.actions$.pipe(
    ofType(SEGMENT_CREATE),
    withLatestFrom(this.store.pipe(select(getCurrentCompany))),
    switchMap(([action, company]: [SegmentCreate, Company]) => {
      const newFullSegment: FullSegment = new FullSegment({
        model: action.payload.model,
        name: action.payload.name,
        filters: action.payload.filters,
        company: company.id,
      });
      return this.segmentsService.create(newFullSegment).pipe(
        map((data) => new SegmentCreateSuccess(data)),
        catchError((data: HttpErrorResponse) =>
          of(new SegmentCreateFail(this.errorHandler.handle(data.error)))
        )
      );
    })
  );

  @Effect()
  updateSegmentsFilter$ = this.actions$.pipe(
    ofType(SEGMENTS_FILTER_UPDATE),
    switchMap((action: SegmentsFilterUpdate) => {
      if (action.payload) {
        return this.segmentsService
          .updateFilter(
            action.payload.id,
            pick(action.payload, [
              'operator',
              'value',
              'property',
              'exclude',
              'data',
            ])
          )
          .pipe(
            map(
              (data) => new SegmentsFilterUpdateSuccess(data, action.trackId)
            ),
            catchError((data: HttpErrorResponse) => {
              return this.store.pipe(
                select(segmentErrors),
                withLatestFrom(this.store.pipe(select(getCurrentSegment))),
                map(([errors, segment]: [FieldsError, FullSegment]) => {
                  const index = segment.filters.findIndex(
                    (it) => it.id === action.payload.id
                  );
                  const newErrors = cloneDeep(errors);
                  set(
                    newErrors,
                    ['filters', index],
                    this.errorHandler.handle(data)
                  );
                  return new SegmentsFilterUpdateFail(
                    newErrors,
                    action.trackId
                  );
                })
              );
            })
          );
      }
    })
  );

  @Effect()
  deleteSegmentsFilter$ = this.actions$.pipe(
    ofType(SEGMENTS_FILTER_DELETE),
    switchMap((action: SegmentsFilterDelete) => {
      if (action.payload) {
        return this.segmentsService.deleteFilter(action.payload).pipe(
          mapTo(new SegmentsFilterDeleteSuccess(action.trackId)),
          catchError((data: HttpErrorResponse) => {
            this.errorHandler.handle(data.error);
            return of(new SegmentsFilterDeleteFail());
          })
        );
      } else {
        return of(new SegmentsFilterDeleteSuccess(action.trackId));
      }
    })
  );

  @Effect()
  deleteFiltersBatch$ = this.actions$.pipe(
    ofType(SEGMENTS_FILTERS_BATCH_DELETE),
    switchMap((action: SegmentsFiltersBatchDelete) => {
      return this.segmentsService.deleteFiltersBatch(action.payload).pipe(
        mapTo(new SegmentsFiltersBatchDeleteSuccess()),
        catchError((data: HttpErrorResponse) => {
          this.errorHandler.handle(data.error);
          return of(new SegmentsFiltersBatchDeleteFail());
        })
      );
    })
  );

  @Effect()
  addSegmentsFilter$ = this.actions$.pipe(
    ofType(SEGMENT_FILTER_ADD),
    withLatestFrom(this.store.pipe(select(getCurrentSegment))),
    mergeMap(([action, segment]: [SegmentFilterAdd, FullSegment]) => {
      return this.segmentsService
        .addFilter(segment.id, omit(action.payload, '_trackId'))
        .pipe(
          // Update store with data from latest update request
          withLatestFrom(
            this.store.pipe(
              select(getSegmentFilterByTrackId(action.payload._trackId))
            )
          ),
          map(([data, latest]) => {
            const combined = {
              ...data,
              ...pick(latest, ['value', 'operator', 'property', 'data']),
            };

            return new SegmentFilterAddSuccess(
              combined as SegmentFilter,
              action.trackId
            );
          }),
          catchError((data: HttpErrorResponse) => {
            return this.store.pipe(
              select(segmentErrors),
              withLatestFrom(this.store.pipe(select(getCurrentSegment))),
              map(([errors, currentSegment]: [FieldsError, FullSegment]) => {
                const index = currentSegment.filters.findIndex(
                  (it) => it.id === action.payload.id
                );
                const newErrors = cloneDeep(errors);
                set(
                  newErrors,
                  ['filters', index],
                  this.errorHandler.handle(data)
                );
                return new SegmentFilterAddFail(newErrors, action.trackId);
              })
            );
          })
        );
    })
  );

  @Effect()
  afterSetFilter$ = this.actions$.pipe(
    ofType(SEGMENTS_SET_FILTER),
    mapTo(new SegmentsLoad({ isReload: true }))
  );

  @Effect()
  afterUpdateSegment$ = this.actions$.pipe(
    ofType(SEGMENT_UPDATE_SUCCESS),
    map((action: SegmentUpdateSuccess) => new SetSegment(action.payload))
  );

  @Effect()
  afterDeleteFilters$ = this.actions$.pipe(
    ofType(
      SEGMENTS_FILTER_DELETE_SUCCESS,
      SEGMENTS_FILTERS_BATCH_DELETE_SUCCESS
    ),
    withLatestFrom(this.store.pipe(select(getCurrentSegment))),
    switchMap(
      ([action, currentSegment]: [SegmentUpdateActions, FullSegment]) => {
        return this.segmentsService.one(currentSegment.id).pipe(
          map((updatedSegment) => new SetSegment(updatedSegment)),
          catchError(() => of(new SegmentLoadFail()))
        );
      }
    )
  );

  @Effect()
  websocketSegmentUpdated$ = this.actions$.pipe(
    ofMessage(segmentCreatedMessageType, segmentUpdatedMessageType),
    forRoute(this.store, /^(?!\/segments\/\w+\/\S+).*/), // all routes except segment edit/create
    withLatestFrom(this.store.pipe(select(getSegments))),
    filter(([message, segments]: [SegmentUpdatedMessage, FullSegment[]]) => {
      return (
        message.type === segmentCreatedMessageType ||
        segments.map((it) => it.id).includes(message.data.id)
      );
    }),
    map(([message, segments]: [SegmentUpdatedMessage, FullSegment[]]) => {
      return new SegmentLoad(message.data.id);
    })
  );

  @Effect()
  websocketSegmentUpdatedForSegmentsRoute$ = this.actions$.pipe(
    ofMessage(segmentCreatedMessageType, segmentUpdatedMessageType),
    forRoute(this.store, /^(\/segments\/\w+\/\S+).*/), // segment edit/create
    withLatestFrom(this.store.pipe(select(getSegments))),
    filter(([message, segments]: [SegmentUpdatedMessage, FullSegment[]]) => {
      return (
        message.type === segmentCreatedMessageType ||
        segments.map((it) => it.id).includes(message.data.id)
      );
    }),
    map(([message, segments]: [SegmentUpdatedMessage, FullSegment[]]) => {
      return new SegmentUpdateFromSocket(message);
    })
  );
}
