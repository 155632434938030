import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SegmentProperty } from '../../models/segment-property';
import { HttpClient } from '@angular/common/http';
import { PagebleEntity } from '../../../shared/models/pageble-entity';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SegmentPropertiesService {
  constructor(private http: HttpClient) {}

  all(): Observable<SegmentProperty[]> {
    return this.http.get<SegmentProperty[]>('segment-properties/');
  }
}
