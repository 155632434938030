import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { TextMaskModule } from 'angular2-text-mask';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedModule } from '../shared/shared.module';
import { FilterListComponent } from './components/filter-list/filter-list.component';
import { DynamicControlComponent } from './components/model-form/dynamic-control/dynamic-control.component';
import { ModelArrayItemValueComponent } from './components/model-form/model-array-value/model-array-item-value/model-array-item-value.component';
import { ModelArrayValueComponent } from './components/model-form/model-array-value/model-array-value.component';
import { ModelFieldComponent } from './components/model-form/model-field/model-field.component';
import { ModelFormComponent } from './components/model-form/model-form/model-form.component';
import { ModelFullAddressFieldComponent } from './components/model-form/model-full-address-field/model-full-address-field.component';
import { ModelScalarFieldComponent } from './components/model-form/model-scalar-field/model-scalar-field.component';
import { ModelStringFieldComponent } from './components/model-form/model-scalar-field/model-string-field/model-string-field.component';
import { ModelSubObjectValueComponent } from './components/model-form/model-sub-object-value/model-sub-object-value.component';
import { SegmentCreateComponent } from './components/segment-create/segment-create.component';
import { SegmentEditComponent } from './components/segment-edit/segment-edit.component';
import { OperatorOptionComponent } from './components/segment-filters/operator-option/operator-option.component';
import { SegmentFilterDateComponent } from './components/segment-filters/segment-filter-date/segment-filter-date.component';
import { SegmentFilterDatetimeComponent } from './components/segment-filters/segment-filter-datetime/segment-filter-datetime.component';
import { SegmentFilterEnumComponent } from './components/segment-filters/segment-filter-enum/segment-filter-enum.component';
import { SegmentFilterNumberComponent } from './components/segment-filters/segment-filter-number/segment-filter-number.component';
import { SegmentFilterRemoteModelComponent } from './components/segment-filters/segment-filter-remote-model/segment-filter-remote-model.component';
import { SegmentFilterStringComponent } from './components/segment-filters/segment-filter-string/segment-filter-string.component';
import { SegmentFilterComponent } from './components/segment-filters/segment-filter/segment-filter.component';
import { SegmentFormComponent } from './components/segment-form/segment-form.component';
import { SegmentModelsComponent } from './components/segment-models/segment-models.component';
import { SegmentsSearchComponent } from './components/segments-search/segments-search.component';
import { DynamicControlDirective } from './directives/dynamic-control/dynamic-control.directive';
import { SegmentCreatePageComponent } from './pages/segment-create-page/segment-create-page.component';
import { SegmentRootComponent } from './pages/segment-root/segment-root.component';
import { SegmentComponent } from './pages/segment/segment.component';
import { SegmentsComponent } from './pages/segments/segments.component';
import { SegmentsRoutingModule } from './routing/segments-routing.module';
import { SegmentGroupsService } from './service/segment-groups/segment-groups.service';
import { SegmentModelsService } from './service/segment-models/segment-models.service';
import { SegmentPropertiesService } from './service/segment-properties/segment-properties.service';
import { SegmentsService } from './service/segments/segments.service';
import { effects } from './store/effects';
import { reducers } from './store/reducers';
import { SegmentDetailsComponent } from './components/segment-details/segment-details.component';
import { SegmentFilterCheckboxComponent } from './components/segment-filters/segment-filter-checkbox/segment-filter-checkbox.component';

@NgModule({
  imports: [
    CommonModule,
    SegmentsRoutingModule,
    SharedModule,
    TranslateModule,
    InfiniteScrollModule,
    PerfectScrollbarModule,
    EffectsModule.forFeature(effects),
    StoreModule.forFeature('segments', reducers),
    ReactiveFormsModule,
    FormsModule,
    TimepickerModule,
    BsDatepickerModule,
    TextMaskModule,
    AccordionModule,
    SortableModule,
    TabsModule,
    TooltipModule,
  ],
  declarations: [
    SegmentsComponent,
    SegmentComponent,
    SegmentRootComponent,
    SegmentCreatePageComponent,
    SegmentModelsComponent,
    SegmentFormComponent,
    SegmentCreateComponent,
    SegmentEditComponent,
    SegmentFilterComponent,
    SegmentFilterStringComponent,
    SegmentFilterDateComponent,
    SegmentFilterEnumComponent,
    SegmentFilterNumberComponent,
    FilterListComponent,
    SegmentsSearchComponent,
    SegmentFilterDatetimeComponent,
    OperatorOptionComponent,
    SegmentFilterRemoteModelComponent,

    ModelScalarFieldComponent,
    ModelStringFieldComponent,
    ModelFormComponent,
    ModelFieldComponent,
    ModelArrayValueComponent,
    ModelArrayItemValueComponent,
    DynamicControlComponent,
    DynamicControlDirective,
    ModelSubObjectValueComponent,
    ModelFullAddressFieldComponent,
    SegmentDetailsComponent,
    SegmentFilterCheckboxComponent,
  ],
  providers: [
    SegmentsService,
    SegmentModelsService,
    SegmentPropertiesService,
    SegmentGroupsService,
  ],
  exports: [
    SegmentModelsComponent,
    SegmentCreateComponent,
    SegmentFormComponent,
    SegmentFormComponent,
    SegmentEditComponent,
    SegmentFilterStringComponent,
    SegmentFilterDateComponent,
    SegmentFilterEnumComponent,
    SegmentFilterNumberComponent,

    ModelScalarFieldComponent,
    ModelStringFieldComponent,
    ModelFormComponent,
    ModelFieldComponent,
    ModelArrayValueComponent,
    ModelArrayItemValueComponent,
    SegmentDetailsComponent,
  ],
  entryComponents: [
    SegmentCreateComponent,
    SegmentDetailsComponent,
    DynamicControlComponent,
  ],
})
export class SegmentsModule {}
