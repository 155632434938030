import { Injectable } from '@angular/core';
import { Call, SipCall } from '../../models/calls.model';
import * as SIP from 'sip.js';
import { Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { NavigateToCall } from '../../store/call.actions';

@Injectable({
  providedIn: 'root'
})
export class SipService {
  user = null;
  sipSession: SIP.Session | null = null;
  sipUa: SIP.UA;
  isConnected = false;
  invite = new Subject<SipCall>();
  incomingCalls: SipCall[] = [];
  sipInviteOptions: SIP.InviteOptions;

  sound = new Audio('/assets/Modern_Ring_Ring.mp3');

  isCalling = false;
  isTalking = false;
  isHangUp = false;

  isCalling$ = new BehaviorSubject(false);
  isTalking$ = new BehaviorSubject(false);
  isHold$ = new BehaviorSubject(false);
  isHangUp$ = new BehaviorSubject(false);
  sessionStart$ = new Subject<Date>();

  call: SipCall;

  constructor(private store: Store<AppState>, private router: Router) {}

  initSIP(credentials) {
    if (this.sipUa) {
      this.sipUa.stop();
    }

    this.sipUa = new SIP.UA({
      uri: `${credentials.sip_extension}@${credentials.sip_proxy}`,
      transportOptions: {
        wsServers: [`${credentials.sip_ws}`],
        keepAliveInterval: 10
      },
      authorizationUser: credentials.sip_extension,
      password: credentials.sip_password,
      hackWssInTransport: true,
      log: {
        builtinEnabled: false
      }
    });

    this.sipUa;

    this.sipUa.on('registered', () => {
      this.isConnected = true;
    });

    this.subscribeOn(
      'unregistered disconnected',
      this.sipUa,
      () => (this.isConnected = false)
    );

    this.sipUa.on('invite', session => {
      this.call = { sipSession: session };
      this.store.dispatch(
        new NavigateToCall(session.remoteIdentity.displayName)
      );
      this.subscribeOnCall();
      this.startRing();
    });
  }

  subscribeOnCall() {
    this.subscribeOn(
      'cancel rejected failed terminated',
      this.call.sipSession,
      () => {
        this.sessionStart$.next(null);
        this.onHangUp();
      }
    );
  }

  setInviteOptions(options) {
    this.sipInviteOptions = options;
  }

  startRing() {
    this.isCalling$.next(true);
    this.isHold$.next(false);
    this.sound.play();
  }

  stopRing() {
    this.isCalling$.next(false);
    this.sound.pause();
    this.sound.currentTime = 0;
  }

  onAccept() {
    this.isCalling$.next(false);
    this.call.sipSession.accept({
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: false
        }
      }
    });

    this.call.sipSession.on('accepted', () => {
      this.sessionStart$.next(this.call.sipSession.startTime);
      this.isTalking$.next(true);
    });

    this.call.sipSession.on('directionChanged', () => {
      const direction = this.call.sipSession.sessionDescriptionHandler.getDirection();
      if (direction === 'sendrecv') {
        this.isHold$.next(false);
      } else {
        this.isHold$.next(true);
      }
    });

    this.call.sipSession.on('trackAdded', () => {
      const pc = this.call.sipSession.sessionDescriptionHandler.peerConnection;

      // Gets remote tracks
      const remoteStream = new MediaStream();
      pc.getReceivers().forEach(function(receiver) {
        remoteStream.addTrack(receiver.track);
      });
      this.sipInviteOptions.remoteAudio.srcObject = remoteStream;
      this.sipInviteOptions.remoteAudio.play();
    });
    this.stopRing();
  }

  onDecline() {
    this.call.sipSession.reject();
    this.sessionStart$.next(null);
    this.onHangUp();
    this.stopRing();
  }

  onHangUp() {
    // const index = this.getCallIndex(incomingCall);
    this.isTalking$.next(false);
    this.call = null;
    this.stopRing();
  }

  onHold() {
    this.call.sipSession.hold();
  }

  onResume() {
    this.call.sipSession.unhold();
  }

  bye() {
    if (this.isTalking$.getValue) {
      this.isTalking$.next(false);
      this.call.sipSession.bye();
    }
  }

  endAllCalls() {
    this.incomingCalls.map(incomingCall => {
      incomingCall.sipSession.bye();
      incomingCall.sipSession.cancel();
    });
    (this.sipUa as any).removeAllListeners();
  }

  private subscribeOn(
    events: string,
    session: { on: Function },
    handler: Function
  ) {
    events.split(' ').map(eventName => {
      session.on(eventName, event => {
        handler(event, eventName);
      });
    });
  }
}
