import { isReload } from '../../shared/helpers/calculate-page';
import { EmployeeActionType } from '../models/employee-action-types.model';
import { Employee, EmployeesFilter } from '../models/employee.model';
import { EmployeeActions, EmployeeActionTypes } from './employee.actions';

export interface EmployeeState {
  employees: Employee[];
  actionTypes: EmployeeActionType[];
  isLoadingEmployees: boolean;
  isReload: boolean;
  employeesCount: number;
  employeesFilter: EmployeesFilter;
}

export const initialState: EmployeeState = {
  employees: [],
  actionTypes: null,
  isLoadingEmployees: false,
  isReload: false,
  employeesCount: 0,
  employeesFilter: {},
};

export function reducer(
  state = initialState,
  action: EmployeeActions
): EmployeeState {
  switch (action.type) {
    case EmployeeActionTypes.LoadEmployeeActionTypesSuccess:
      return {
        ...state,
        actionTypes: action.payload,
      };
    case EmployeeActionTypes.LoadEmployees:
      return {
        ...state,
        isLoadingEmployees: true,
        isReload: isReload(action),
      };
    case EmployeeActionTypes.LoadEmployeesSuccess:
      return {
        ...state,
        employees: action.payload.results,
        employeesCount: action.payload.count,
        isReload: false,
        isLoadingEmployees: false,
      };
    case EmployeeActionTypes.LoadEmployeesFail:
      return {
        ...state,
        isReload: false,
        isLoadingEmployees: false,
      };
    case EmployeeActionTypes.EmployeesSetFilter:
      return {
        ...state,
        employeesFilter: action.payload,
      };
    default:
      return state;
  }
}
