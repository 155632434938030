import { Action } from '@ngrx/store';
import { PagebleAction, PagebleActionPayload } from '../../../shared/helpers/calculate-page';
import { PagebleEntity } from '../../../shared/models/pageble-entity';
import { CreateCustomerData, Customer, CustomersFilter } from '../../models/customer';
import { FieldsError } from '../../../shared/models/http-error';

export const CUSTOMERS_LOAD_DEBOUNCED = '[Customers] load if not loading';
export const CUSTOMERS_LOAD = '[Customers] load';
export const CUSTOMERS_LOAD_SUCCESS = '[Customers] load success';
export const CUSTOMERS_LOAD_FAIL = '[Customers] load fail';
export const CUSTOMERS_CLEAR = '[Customers] clear';

export const CUSTOMER_LOAD = '[Customers] customer load';
export const CUSTOMER_LOAD_SUCCESS = '[Customers] customer load success';
export const CUSTOMER_LOAD_FAIL = '[Customers] customer load fail';

export const CUSTOMER_CREATE = '[Customers] customer create';
export const CUSTOMER_CREATE_SUCCESS = '[Customers] customer create success';
export const CUSTOMER_CREATE_FAIL = '[Customers] customer create fail';

export const CUSTOMER_UPDATE = '[Customers] customer update';
export const CUSTOMER_UPDATE_SUCCESS = '[Customers] customer update success';
export const CUSTOMER_UPDATE_FAIL = '[Customers] customer update fail';

export const CUSTOMER_DELETE = '[Customers] customer delete';
export const CUSTOMER_DELETE_SUCCESS = '[Customers] customer delete success';
export const CUSTOMER_DELETE_FAIL = '[Customers] customer delete fail';

export const CUSTOMERS_SET_FILTER = '[Customers] set filter';

export class CustomersLoad implements Action, PagebleAction {
  readonly type = CUSTOMERS_LOAD;
  constructor(public payload?: PagebleActionPayload) {}
}

export class CustomersLoadDebounced implements Action, PagebleAction {
  readonly type = CUSTOMERS_LOAD_DEBOUNCED;
  constructor(public payload?: PagebleActionPayload) {}
}

export class CustomersLoadSuccess implements Action {
  readonly type = CUSTOMERS_LOAD_SUCCESS;
  constructor(public payload?: PagebleEntity<Customer>) {}
}

export class CustomersLoadFail implements Action {
  readonly type = CUSTOMERS_LOAD_FAIL;
}

export class CustomersClear implements Action, PagebleAction {
  readonly type = CUSTOMERS_CLEAR;
  constructor() {}
}

export class CustomerLoad implements Action {
  readonly type = CUSTOMER_LOAD;
  constructor(public payload: string) {}
}

export class CustomerLoadSuccess implements Action {
  readonly type = CUSTOMER_LOAD_SUCCESS;
  constructor(public payload: Customer) {}
}

export class CustomerLoadFail implements Action {
  readonly type = CUSTOMER_LOAD_FAIL;
}

export class CustomerCreate implements Action {
  readonly type = CUSTOMER_CREATE;
  constructor(public payload: CreateCustomerData) {}
}

export class CustomerCreateSuccess implements Action {
  readonly type = CUSTOMER_CREATE_SUCCESS;
  constructor(public payload: Customer) {}
}

export class CustomerCreateFail implements Action {
  readonly type = CUSTOMER_CREATE_FAIL;
  constructor(public payload: FieldsError) {}
}

export class CustomerUpdate implements Action {
  readonly type = CUSTOMER_UPDATE;
  constructor(public payload: Customer) {}
}

export class CustomerUpdateSuccess implements Action {
  readonly type = CUSTOMER_UPDATE_SUCCESS;
  constructor(public payload: Customer) {}
}

export class CustomerUpdateFail implements Action {
  readonly type = CUSTOMER_UPDATE_FAIL;
  constructor(public payload: FieldsError) {}
}

export class CustomerDelete implements Action {
  readonly type = CUSTOMER_DELETE;
  constructor(public payload: string) {}
}

export class CustomerDeleteSuccess implements Action {
  readonly type = CUSTOMER_DELETE_SUCCESS;
}

export class CustomerDeleteFail implements Action {
  readonly type = CUSTOMER_DELETE_FAIL;
}

export class CustomersSetFilter implements Action {
    readonly type = CUSTOMERS_SET_FILTER;
    constructor(public payload: CustomersFilter) {}
}

export type CustomerActions =
  | CustomersLoadDebounced
  | CustomersLoad
  | CustomersLoadSuccess
  | CustomersLoadFail
  | CustomersClear
  | CustomerLoad
  | CustomerLoadSuccess
  | CustomerLoadFail
  | CustomerCreate
  | CustomerCreateSuccess
  | CustomerCreateFail
  | CustomerUpdate
  | CustomerUpdateSuccess
  | CustomerUpdateFail
  | CustomerDelete
  | CustomerDeleteSuccess
  | CustomerDeleteFail
  | CustomersSetFilter
  ;
