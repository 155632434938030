export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';
export const SHOW_FORMAT = 'DD.MM.YY';
export const SHOW_DATETIME_FORMAT = 'DD.MM.YY HH:mm';
export const SHOW_DATETIME_FORMAT_FULL_YEAR = 'DD.MM.YYYY HH:mm';
export const backEndDateFormat = DATE_FORMAT;

export const dataFormatsConfig = {
  dateInputFormat: SHOW_FORMAT,
  rangeInputFormat: SHOW_FORMAT,
  containerClass: 'theme-default',
  showWeekNumbers: false
};

export const PHONE_MASK = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
