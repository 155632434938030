<ng-select
  [items]="models$ | async"
  [virtualScroll]="true"
  [hideSelected]="true"
  [loading]="isModelsLoading"
  [typeahead]="modelTypeahead$"
  [formControl]="currentValue"
  [multiple]="property.is_many"
  (scroll)="scroll($event)"
  (scrollToEnd)="fetchMore()"
>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true"
      >×</span
    >
    <span class="ng-value-label">{{ item.display_name || item.name }}</span>
  </ng-template>

  <ng-template ng-option-tmp let-item="item">
    <div>
      {{
        item.display_name +
          (item.display_description ? " - " + item.display_description : "")
      }}
    </div>
  </ng-template>

  <ng-template ng-typetosearch-tmp>
    <div class="ng-option disabled text-black-50">
      {{ "Start typing" | translate }}...
    </div>
  </ng-template>
  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
    <div class="ng-option disabled text-black-50">
      {{ noItemsFoundMessage | translate }}
    </div>
  </ng-template>
  <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
    <div class="ng-option disabled text-black-50">
      {{ "Loading" | translate }}
    </div>
  </ng-template>
</ng-select>
