<div [formGroup]="form">
  <div class="row">
    <div class="col col-3">
      <ng-select
        class="ng-select-autowidth ng-select-prepend select-filter-operator"
        [items]="operators"
        formControlName="operator"
        bindValue="value"
        [clearable]="false"
        [searchable]="false"
        [placeholder]="'select' | translate"
      >
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
          <app-operator-option [item]="item"></app-operator-option>
        </ng-template>
      </ng-select>

      <app-errors-component
        class="mt-1 ml-2"
        [control]="form.get('operator')"
      ></app-errors-component>
    </div>
    <div class="col">
      <!-- HACK: items="[1]" чтобы заставить ng-select отдавать массив строк, вместо массива объектов
      https://github.com/ng-select/ng-select/blob/master/src/ng-select/ng-select.component.ts#L506
      -->
      <ng-select
        *ngIf="isMultiSelect(); else singleValue"
        [items]="[1]"
        formControlName="value"
        [addTag]="addTag"
        [multiple]="true"
        [isOpen]="false"
        #selectElement
      ></ng-select>
      <ng-template #singleValue>
        <input class="form-control value" type="text" formControlName="value" />
      </ng-template>
    </div>
  </div>
  <div class="row">
    <div class="col offset-3">
      <app-errors-component
        class="mt-1 ml-2"
        [control]="form.get('value')"
      ></app-errors-component>
    </div>
  </div>
</div>
