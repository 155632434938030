import { Injectable } from '@angular/core';
import { isNumber } from 'lodash';

@Injectable()
export class NumberDemaskService {

  constructor() { }

  demask(value, isRawValue = false) {
    const str = ('' + value)
      .replace(/\s+/g, '')
      .replace(/_/g, '');
    if (str.length === 0) {
      return null;
    }

    if (isRawValue) {
      return str;
    }

    const result = +str;
    return isNumber(result) ? +str : str;
  }
}
