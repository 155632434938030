import {ActionReducerMap} from '@ngrx/store';
import {DictionaryReducer, DictionaryState} from './dictionary/dictionary.reducer';
import {DictionaryItemsReducer, DictionaryItemsState} from './dictionary-items/dictionary-item.reducer';

export interface DictionaryModuleState {
  dictionaries: DictionaryState;
  dictionaryItems: DictionaryItemsState;
}

export const reducers: ActionReducerMap<DictionaryModuleState> = {
  dictionaries: DictionaryReducer,
  dictionaryItems: DictionaryItemsReducer
}
