<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ 'Specify the reason for the break' | translate }}
  </h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="cancel()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <ul class="action-types" *ngIf="(actionTypes$ | async) as actionTypes">
      <li *ngFor="let actionType of actionTypes">
        <div class="form-check">
          <input
            type="radio"
            class="form-check-input"
            formControlName="action_type"
            [id]="actionType.id"
            [value]="actionType.id"
          />
          <label class="form-check-label" [for]="actionType.id">{{
            actionType.reason
          }}</label>
        </div>
      </li>
    </ul>
    <div class="comment">
      <textarea
        formControlName="comment"
        class="form-control mb-3"
        [placeholder]="('Comment' | translate) + '...'"
      ></textarea>
    </div>
    <button
      class="btn btn-primary btn-block"
      (click)="submit()"
      [disabled]="!form.valid"
    >
      {{ 'Submit' | translate }}
    </button>
  </form>
</div>
