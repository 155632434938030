import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  Subscription
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  pluck,
  scan,
  startWith,
  switchMap,
  tap,
  map
} from 'rxjs/operators';
import { ErrorHandlingService } from 'src/app/modules/shared/services/error-handling/error-handling.service';
import { AppState } from 'src/app/store/reducers';
import { SegmentRemoteModelService } from '../../../service/segment-remote-model/segment-remote-model.service';
import { FilterValueAccessor, MakeProvider } from '../filter-value-accessor';
import { isArray } from 'lodash';

const itemsBeforeFetched = 10;

@Component({
  selector: 'app-segment-filter-remote-model',
  templateUrl: './segment-filter-remote-model.component.html',
  styleUrls: ['./segment-filter-remote-model.component.scss'],
  providers: [...MakeProvider(SegmentFilterRemoteModelComponent)]
})
export class SegmentFilterRemoteModelComponent extends FilterValueAccessor {
  modelTypeahead$ = new Subject<string>();
  isModelsLoading = false;
  models$: Observable<any>;
  modelsLoad$: Observable<any>;
  subscriptions = new Subscription();
  operators: string[] = [];
  countLoaded: number;
  nextPage = 0;
  fetchMore$ = new BehaviorSubject('');
  scrolledToEnd$ = new BehaviorSubject<number>(0);
  needToReset = false;

  constructor(
    protected fb: FormBuilder,
    protected store: Store<AppState>,
    protected errorHandler: ErrorHandlingService,
    private remoteModelService: SegmentRemoteModelService
  ) {
    super(fb, store, errorHandler);

    this.scrolledToEnd$
      .asObservable()
      .pipe(
        distinctUntilChanged(),
        filter(
          end =>
            end > itemsBeforeFetched &&
            !this.isModelsLoading &&
            end + itemsBeforeFetched >= this.countLoaded
        )
      )
      .subscribe(() => this.fetchMore$.next(''));

    this.modelsLoad$ = combineLatest(
      this.modelTypeahead$.pipe(
        startWith(''),
        tap(() => (this.nextPage = 0))
      ),
      this.fetchMore$.pipe()
    ).pipe(
      debounceTime(200),
      filter(_ => !this.isModelsLoading),
      tap(() => (this.isModelsLoading = true)),
      switchMap(([val, fetch]) => {
        this.nextPage++;
        val = !this.needToReset ? val : undefined;
        this.needToReset = false;
        return this.remoteModelService
          .filter({
            model: this.property.remote_model,
            search: val,
            page: this.nextPage
          })
          .pipe(pluck('results'));
      })
    );
  }

  ngOnInit(): void {
    this.initForm();
    this.initSubscriptions();
    this.updateOperators();

    this.models$ = combineLatest(
      this.form.get('value').valueChanges.pipe(startWith([])),
      this.modelsLoad$.pipe(
        scan((previous, response) => {
          this.isModelsLoading = false;
          let result =
            this.nextPage <= 1 ? response : previous.concat(response);
          this.countLoaded = result.length;

          return result;
        }, [])
      )
    ).pipe(
      map(([_, list]) => {
        const currentValue = this.form.get('value').value;
        if (!currentValue) {
          return list;
        }
        return list.filter(value =>
          isArray(currentValue)
            ? currentValue.every((v: any) => v.id !== value.id)
            : currentValue.id !== value.id
        );
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private initForm() {
    this.form = this.fb.group({
      operator: [null, Validators.required],
      value: [null, Validators.required]
    });
  }

  private initSubscriptions() {
    this.subscriptions.add(
      this.form.valueChanges
        .pipe(filter(() => this.form.valid))
        .subscribe(values => {
          this.pushValue({
            ...this.filter,
            ...values
          });
          this.nextPage = 0;
          this.needToReset = true;
          this.fetchMore$.next('');
        })
    );
  }

  updateOperators() {
    if (this.property && this.property.operators) {
      this.operators = this.property.operators;
    }
  }

  onWrite(values) {
    const formValues = {
      ...values,
      operator: values.operator || this.property.operators[0]
    };
    this.form.patchValue(formValues, { emitEvent: false });
  }

  fetchMore() {
    if (this.isModelsLoading) {
      return;
    }
    this.fetchMore$.next('');
  }

  scroll({ end }) {
    this.scrolledToEnd$.next(end);
  }

  compareValues(a: any, b: any): boolean {
    if (b && b.hasOwnProperty('id')) {
      return a.id === b.id;
    } else {
      return a === b;
    }
  }
}
