<div class="input-group">
  <div class="input-group-prepend">
    <div class="input-group-text">+7</div>
  </div>
  <input id="phone"
         type="text"
         [textMask]="{mask: phoneMask, showMask: false}"
         [placeholder]="placeholder"
         [ngClass]="hostClass+' '+customClass"
         class="form-control"
         [formControl]="currentValue"
         [readonly]="readonly"
  >
</div>
