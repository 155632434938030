import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CallActionReason, CallStates } from '../../models/calls.model';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { CallService } from '../../services/call/call.service';
import { ErrorHandlingService } from 'src/app/modules/shared/services/error-handling/error-handling.service';
import { SipService } from '../../services/sip/sip.service';
import { take, filter } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { FinishCall } from '../../store/call.actions';
import { getHangUpReasons, getActiveCall } from '../../store/call.selector';
import { Router } from '@angular/router';

@Component({
  selector: 'app-finish-call',
  templateUrl: './finish-call.component.html',
  styleUrls: ['./finish-call.component.scss']
})
export class FinishCallComponent implements OnInit {
  @Input() call: string;
  @Input() modalRef: BsModalRef;
  confirmed = false;
  reasons$: Observable<CallActionReason[]>;
  form: FormGroup;
  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private sipService: SipService,
    private callService: CallService,
    private errorHandler: ErrorHandlingService,
    private router: Router
  ) {
    this.form = this.fb.group({
      reason: [null, Validators.required],
      state: ['FINISH', Validators.required],
      comment: ['']
    });
  }

  ngOnInit() {
    this.reasons$ = this.store.pipe(select(getHangUpReasons));
  }

  submit() {
    if (this.form.valid) {
      this.store.dispatch(new FinishCall(this.call, this.form.value));
      this.store
        .pipe(
          select(getActiveCall),
          filter(call => call.state === CallStates.FINISHED),
          take(1)
        )
        .subscribe(() => {
          this.modalRef.hide();
        });
    }
  }
}
