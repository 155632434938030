<a (click)="togglePanel()" *ngIf="!isPanelVisible" class="floating-btn">?</a>

<div class="tips-panel" *ngIf="isPanelVisible">
  <!-- направления тематики -->
  <app-tips-panel-content
    (close)="togglePanel()"
    *ngIf="screen === 'DIRECTIONS'"
  >
    <ul class="directions">
      <li
        *ngFor="let direction of (directions$ | async)"
        [class.expandable]="direction.subjects?.length"
        [class.expanded]="directionExpanded[direction.id]"
        [class.collapsed]="!directionExpanded[direction.id]"
      >
        <a (click)="toggleDirection(direction.id)">
          {{ direction.name }}
          <i class="material-icons ml-auto"
            >{{
              directionExpanded[direction.id] ? 'expand_less' : 'expand_more'
            }}
          </i>
        </a>
        <ul class="subjects">
          <li *ngFor="let subject of direction.subjects">
            <a (click)="goToSubject(subject)">{{ subject.name }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </app-tips-panel-content>

  <!-- вопросы -->
  <app-tips-panel-content
    [header]="'Subjects' | translate"
    [hasBack]="true"
    (close)="togglePanel()"
    (back)="back()"
    *ngIf="screen === 'SUBJECT'"
  >
    <ul class="questions">
      <li *ngFor="let tip of tipsBySubject">
        <a (click)="goToTip(tip)"
          ><strong>{{ tip.name }}</strong></a
        >
        <div class="brief" [innerHTML]="tip.description">
          {{ tip.description }}
        </div>
      </li>
    </ul>
  </app-tips-panel-content>

  <!-- вопросы -->
  <app-tips-panel-content
    [header]="
      this.currentSubject ? currentSubject.name : ('Subjects' | translate)
    "
    [hasBack]="true"
    (close)="togglePanel()"
    (back)="back()"
    *ngIf="screen === 'SEARCH'"
  >
    <app-loader *ngIf="isSearching"></app-loader>
    <p
      class="text-muted text-center mt-3"
      *ngIf="!isSearching && !tipsFound.length"
    >
      {{ 'Nothing found' | translate }}
    </p>
    <ul class="questions" *ngIf="!isSearching && tipsFound.length">
      <li *ngFor="let tip of tipsFound">
        <a (click)="goToTip(tip)"
          ><strong>{{ tip.name }}</strong></a
        >
        <div class="brief" [innerHTML]="tip.description">
          {{ tip.description }}
        </div>
      </li>
    </ul>
  </app-tips-panel-content>

  <!-- развернутый вопрос -->
  <app-tips-panel-content
    [header]="
      prevScreen === 'SEARCH'
        ? ('Search results' | translate)
        : currentSubject.name
    "
    [hasBack]="true"
    (back)="back()"
    (close)="togglePanel()"
    *ngIf="screen === 'TIP'"
  >
    <div class="detailed-tip">
      <h4>{{ currentTip.name }}</h4>
      <div [innerHTML]="currentTip.description"></div>
    </div>
  </app-tips-panel-content>
  <div class="search-bar">
    <i class="material-icons search">search</i>
    <input
      type="text"
      class="form-control"
      [placeholder]="('Search' | translate) + '...'"
      [formControl]="searchTermControl"
    />
    <i
      class="material-icons clear"
      (click)="clearSearch()"
      *ngIf="searchTermControl.value?.length"
      >close</i
    >
  </div>
</div>
